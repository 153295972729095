import uniqid from 'uniqid';
import styled from "styled-components";
import { cloneDeep, uniq } from "lodash";
import { useApi } from "../../hooks/use-api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { FC, useContext, useState } from "react";
import { Accordion } from "@szhsin/react-accordion";
import { useTheme } from "../../hooks/use-theme.hook";
import { useNotify } from "../../hooks/use-notify.hook";
import { Page } from "../../components/Layout/Page/Page";
import { Switch, TextInput } from "../../components/Form";
import { Group } from "../../components/Layout/Group/Group";
import { TestCard } from "../../components/App/Cards/TestCard";
import { ProgressStep } from "../../components/App/ProgressStep";
import { useNotification } from "../../hooks/use-notification.hook";
import { RichText } from "../../components/Form/RichText/RichText";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TopMenu, TopMenuProfile } from "../../components/App/TopMenu";
import { Test, TestQuestion, TestQuestionOption } from "shared-library";
import { CheckBoxInput } from "../../components/Form/CheckBox/CheckBox";
import { OutlineButton, StepButton } from "../../components/Form/Button";
import { SelectInput } from "../../components/Form/SelectInput/SelectInput";
import { ResourceType, Sortable } from "../../components/App/Sortable/Sortable";
import { ArrowUp, CaretLeft, CaretRight, PlusLg, Trash } from "react-bootstrap-icons";
import { ChildErrors, findError, useForm, validateNested } from "../../hooks/use-form.hook";
import { StyledAccordionItem, StyledContentWrapper, StyledErrorText, StyledHR, StyledIcon, StyledChapterChildTitle, StyledSpan, StyledTitle, StyledTitleDesc, forPhoneOnly } from "../../components/Elements";

const StyledGroup = styled(Group)`
    .left {
        flex-basis: 35%;
        ${forPhoneOnly("flex-basis: 100%;")}
    }
`
enum TestSteps {
    QUESTIONS = "questions",
    SETTINGS = "settings",
}

export const AdminTestEditor: FC<{ testInput: Test }> = ({ testInput }) => {

    const navigate = useNavigate();
    const { notify } = useNotification();
    const { createUpdateTest } = useApi();
    const queryClient = useQueryClient();
    const { state, setResources } = useContext(AuthContext);
    const { onSubmit, onChange, formValues, errors, updateValues } = useForm({
        defaultValues: testInput,
        validations: {
            description: { required: true, max: 1000 },
            title: { required: true, min: 3, max: 260 },
        }
    })
    const theme = useTheme()
    const [childErrors, setChildErrors] = useState<Array<ChildErrors>>([]);
    const [currentTestStep, setCurrentTestStep] = useState<TestSteps>(TestSteps.QUESTIONS);
    const { isPending: mutatingTestAsync, mutateAsync: mutateTestAsync } = useMutation({
        mutationFn: createUpdateTest,
    });

    const Steps = [
        TestSteps.QUESTIONS,
        TestSteps.SETTINGS
    ]

    const rearrangeQuestions = (nodes: ResourceType[]) => {
        const _test = cloneDeep(formValues)
        _test.questions = []
        for (const node of nodes) {
            const question = formValues.questions.find(({ uiid }) => `${uiid}` === node.id)
            if (question) {
                _test.questions = [..._test.questions, question]
            }
        }
        updateFormValues({ ..._test })
    }

    const updateFormValues = (test: Test, validate = false) => {
        updateValues({ ...test })
        if (validate) {
            validateTest(test)
            return
        }
        setChildErrors([])
    }

    const validateTest = (test: Test) => {
        let errors: Array<ChildErrors> = []
        for (let index = 0; index < test.questions.length; index++) {
            const question = test.questions[index];
            const result = validateNested<TestQuestion>(question, {
                question: { required: true, max: 2000, min: 3 },
            })

            if (!result.valid && result.errors.question) {
                errors.push({ [`question${question.uiid}`]: result.errors.question })
            }

            if (!question.options.find(({ isAnswer }) => isAnswer)) {
                errors.push({ [`question${question.uiid}options`]: `Question ${index + 1} requires at least an option to be selected!` })
            }

            if (question.options.length === 1) {
                errors.push({ [`question${question.uiid}optionsLength`]: `At least 2 options are required!` })
            }

            for (const option of question.options) {
                const result = validateNested<TestQuestionOption>(option, {
                    option: { required: true, max: 260 },
                })
                if (!result.valid && result.errors.option) {
                    errors.push({ [`question${question.uiid}options${option.uiid}`]: result.errors.option })
                }
            }
        }
        setChildErrors(errors)
        return !errors.length
    }

    const updateTest = async (test: Test) => {
        if (!mutatingTestAsync) {
            if (!validateTest(test)) {
                if (currentTestStep !== TestSteps.QUESTIONS) {
                    setCurrentTestStep(TestSteps.QUESTIONS)
                    setTimeout(() => validateTest(test))
                }
                return
            }
            try {
                const updated = await mutateTestAsync(test)
                queryClient.removeQueries({ queryKey: ["test", test.id], exact: true })
                updateFormValues(updated)
                notify({ type: "success", title: "Successful!", body: `Test has been successfully ${test.id ? 'updated' : 'created'}!` });
                const { activeTests, ...newResource } = state.resources;
                setResources(newResource)
                navigate(`/${state.user?.info.session.role}/tests`)
            } catch (error) {
            }
        }
    }

    const pushQuestion = () => {
        updateFormValues({
            ...formValues,
            questions: [
                ...formValues.questions,
                {
                    options: uniq([uniqid(), uniqid(), uniqid(), uniqid()]).map(uiid => ({
                        uiid,
                        option: "",
                        optionId: "",
                        isAnswer: false,
                    })),
                    question: "",
                    questionId: "",
                    uiid: uniqid()
                }
            ]
        })
    }

    const moveBackward = () => {
        const currentStepIndex = Steps.findIndex(step => step === currentTestStep)
        setCurrentTestStep(Steps[currentStepIndex - 1])
    }

    const moveForward = () => {
        const currentStepIndex = Steps.findIndex(step => step === currentTestStep)
        setCurrentTestStep(Steps[currentStepIndex + 1])
    }

    const TestPassMarks = [
        { value: 30, label: '30%' },
        { value: 40, label: '40%' },
        { value: 50, label: '50%' },
        { value: 60, label: '60%' },
        { value: 70, label: '70%' },
        { value: 80, label: '80%' },
        { value: 90, label: '90%' },
        { value: 100, label: '100%' },
    ]

    return (<Page>
        <StyledGroup direction="row">
            <StyledContentWrapper className="left slideInLeft">
                <Group>
                    <TopMenu hideProfile hideNotification titles={[{
                        text: 'Tests',
                        link: `/${state.user?.info.session.role}/tests`
                    }, {
                        text: formValues.id ? 'Edit' : 'New',
                    }]} />
                    <Group gap="xs">
                        <br />
                        <br />
                        <TestCard active={true} test={formValues} key={formValues.id} />
                    </Group>
                </Group>
            </StyledContentWrapper>
            <Group className="slideInRight" style={{ flex: 1 }}>
                <Group direction="row" align="center" justify="end">
                    <TopMenuProfile />
                </Group>
                <br />
                <form onSubmit={onSubmit(updateTest)}>
                    <Group style={{ marginBottom: '20px', padding: '20px' }}>

                        {
                            TestSteps.QUESTIONS === currentTestStep && <>
                                <Group gap="sm">
                                    <StyledTitle>
                                        <b>Questions</b>
                                    </StyledTitle>
                                    <ProgressStep totalSteps={Steps.length} active={Steps.findIndex(step => step === currentTestStep) + 1} />
                                    <StyledTitleDesc>
                                        Create engaging quizzes to assess students' understanding and reinforce learning objectives. Add a variety of questions to challenge learners and provide valuable feedback on their progress.
                                    </StyledTitleDesc>
                                </Group>
                                <StyledHR />
                                <Group gap="sm">
                                    <TextInput
                                        isRequired
                                        type="text"
                                        label="Title"
                                        placeholder="Title"
                                        error={errors.title}
                                        value={formValues.title}
                                        onChange={onChange("title")}
                                    />

                                    <RichText
                                        isRequired
                                        error={errors.description}
                                        placeholder="More details"
                                        value={formValues.description}
                                        onChange={onChange("description")}
                                    />
                                    <Group align="end">
                                        <OutlineButton text="Question" onClick={pushQuestion} icon={<PlusLg size={14} />} />
                                    </Group>

                                    {
                                        formValues.questions.length > 0 && <>
                                            <Sortable
                                                nodes={formValues.questions.map((question, questionIndex) => ({
                                                    id: `${question.uiid}`,
                                                    node: <QuestionComponent
                                                        position={questionIndex + 1}
                                                        errors={childErrors}
                                                        question={question}
                                                        onDelete={() => updateFormValues({ ...formValues, questions: formValues.questions.filter(({ uiid }) => question.uiid !== uiid) })}
                                                        onDeleteOption={(deletedOptionuiid) => updateFormValues({ ...formValues, questions: formValues.questions.map(_question => question.uiid === _question.uiid ? { ..._question, options: _question.options.filter(({ uiid }) => deletedOptionuiid !== uiid) } : _question) })}
                                                        onChange={(question) => updateFormValues({ ...formValues, questions: formValues.questions.map((questionOri, _index) => _index === questionIndex ? question : questionOri) })}
                                                    />
                                                })
                                                )}
                                                onChange={(_nodes) => rearrangeQuestions(_nodes)}
                                                key={formValues.id}
                                                disabled={formValues.questions.length < 2}
                                                style={{
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    marginBottom: '10px',
                                                    backgroundColor: theme.colors.grey6
                                                }}
                                            />
                                        </>
                                    }
                                </Group>
                            </>
                        }

                        {
                            TestSteps.SETTINGS === currentTestStep && <>
                                <Group gap="sm">
                                    <StyledTitle>
                                        <b>Settings</b>
                                    </StyledTitle>
                                    <ProgressStep totalSteps={Steps.length} active={Steps.findIndex(step => step === currentTestStep) + 1} />
                                    <StyledTitleDesc>
                                        Manage the test experience effortlessly by adjusting specifying maximum attempts, navigation and options' arrangement preferences.
                                    </StyledTitleDesc>
                                </Group>
                                <StyledHR />
                                <Group gap="none">
                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Allow Backward Navigation</StyledChapterChildTitle>
                                            <StyledTitleDesc>Students can navigate to previously answered test questions if this setting is <b>ON</b>.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={formValues.settings.allowBackNavigation} onChange={(allowBackNavigation) => updateFormValues({ ...formValues, settings: { ...formValues.settings, allowBackNavigation } })}></Switch>
                                    </Group>
                                    <StyledHR />

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Allow Forward Navigation</StyledChapterChildTitle>
                                            <StyledTitleDesc>Students can navigate to next test questions with unanswered previous questions if this setting is <b>ON</b>.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={formValues.settings.allowForwardNavigationWithUnansweredPreviousQuestions} onChange={(allowForwardNavigationWithUnansweredPreviousQuestions) => updateFormValues({ ...formValues, settings: { ...formValues.settings, allowForwardNavigationWithUnansweredPreviousQuestions } })}></Switch>
                                    </Group>
                                    <StyledHR />

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Allow Answer Change</StyledChapterChildTitle>
                                            <StyledTitleDesc>First option selected is taken as the final answer with no option to change if this setting is <b>ON</b>.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={formValues.settings.allowAnswerChange} onChange={(allowAnswerChange) => updateFormValues({ ...formValues, settings: { ...formValues.settings, allowAnswerChange } })}></Switch>
                                    </Group>
                                    <StyledHR />

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Auto-Show Correct Answer</StyledChapterChildTitle>
                                            <StyledTitleDesc>Correct answer is automatically shown when answer is submitted if this setting is <b>ON</b>.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={formValues.settings.autoShowCorrectAnswerAfterAnswering} onChange={(autoShowCorrectAnswerAfterAnswering) => updateFormValues({ ...formValues, settings: { ...formValues.settings, autoShowCorrectAnswerAfterAnswering } })}></Switch>
                                    </Group>
                                    <StyledHR />
                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Shuffle Questions' Arrangements</StyledChapterChildTitle>
                                            <StyledTitleDesc>Questions are displayed sequentially if this setting is <b>OFF</b>.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={formValues.settings.shuffleQuestionArrangements} onChange={(shuffleQuestionArrangements) => updateFormValues({ ...formValues, settings: { ...formValues.settings, shuffleQuestionArrangements } })}></Switch>
                                    </Group>
                                    <StyledHR />

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Shuffle Options' Arrangements</StyledChapterChildTitle>
                                            <StyledTitleDesc>Options' arrangements will be shuffled if this setting is <b>ON</b>.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={formValues.settings.shuffleOptionArrangements} onChange={(shuffleOptionArrangements) => updateFormValues({ ...formValues, settings: { ...formValues.settings, shuffleOptionArrangements } })}></Switch>
                                    </Group>
                                    <StyledHR />

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Disable Question Selection</StyledChapterChildTitle>
                                            <StyledTitleDesc>Copying of question will be disabled if this setting is <b>ON</b>.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={formValues.settings.disableTextCopy} onChange={(disableTextCopy) => updateFormValues({ ...formValues, settings: { ...formValues.settings, disableTextCopy } })}></Switch>
                                    </Group>


                                    <StyledHR />
                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Maximum Attempt</StyledChapterChildTitle>
                                            <StyledTitleDesc>Total number of times this test can be taken per student, test can be taken unlimited amount of times if left empty.</StyledTitleDesc>
                                        </Group>
                                        <TextInput
                                            label=""
                                            size='sm'
                                            placeholder="Max Attempt"
                                            type="number"
                                            onChange={(maxAttempt) => updateFormValues({ ...formValues, settings: { ...formValues.settings, maxAttempt: +maxAttempt } })}
                                            value={formValues.settings.maxAttempt ? formValues.settings.maxAttempt.toString() : ""}
                                            isRequired
                                            style={{
                                                maxWidth: '50px'
                                            }}
                                        />
                                    </Group>
                                    <StyledHR />

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Test Duration (In Minutes)</StyledChapterChildTitle>
                                            <StyledTitleDesc>Set a maximum duration for completing the test, test will have no time constraints if left empty.</StyledTitleDesc>
                                        </Group>
                                        <Group direction="row" wrap="nowrap" gap="xs" align="center">
                                            <TextInput
                                                label=""
                                                size='sm'
                                                placeholder="Duration "
                                                type="number"
                                                onChange={(durationInMins) => updateFormValues({ ...formValues, settings: { ...formValues.settings, durationInMins: +durationInMins } })}
                                                value={formValues.settings.durationInMins ? formValues.settings.durationInMins.toString() : ""}
                                                isRequired
                                                style={{
                                                    maxWidth: '50px'
                                                }}
                                            />
                                            <StyledSpan><small>mins</small></StyledSpan>
                                        </Group>
                                    </Group>
                                    <StyledHR />

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Test Pass Mark (In Percentage)</StyledChapterChildTitle>
                                            <StyledTitleDesc>Set a pass mark for the test.</StyledTitleDesc>
                                        </Group>
                                        <Group direction="row" wrap="nowrap" gap="xs" align="center">
                                            <SelectInput
                                                label=""
                                                size='sm'
                                                placeholder="Test Pass Mark"
                                                onChange={(passMarkInPercentage) => updateFormValues({ ...formValues, settings: { ...formValues.settings, passMarkInPercentage: +passMarkInPercentage } })}
                                                value={TestPassMarks.find(d => d.value === formValues.settings.passMarkInPercentage)}
                                                options={TestPassMarks}
                                                isRequired
                                                parentStyle={{
                                                    maxWidth: '120px'
                                                }}
                                            />
                                        </Group>
                                    </Group>

                                </Group>
                            </>
                        }
                        <br />
                        <Group direction="row" justify="space-between">
                            <Group direction="row">
                                <StepButton
                                    type="button"
                                    disabled={Steps.findIndex(step => step === currentTestStep) === 0}
                                    leftIcon={<CaretLeft size={14} />}
                                    onClick={moveBackward}
                                    text="Prev"
                                />
                                <StepButton
                                    type="button"
                                    disabled={Steps.findIndex(step => step === currentTestStep) === Steps.length - 1}
                                    rightIcon={<CaretRight size={14} />}
                                    onClick={moveForward}
                                    text="Next"
                                />
                            </Group>

                            {
                                formValues.questions.length > 0 && <StepButton
                                    type="submit"
                                    text="Finish"
                                    loading={mutatingTestAsync}
                                    leftIcon={<ArrowUp size={14} />}
                                />
                            }
                        </Group>
                    </Group>
                </form>
            </Group>
        </StyledGroup>
    </Page>
    );
}


const QuestionComponent: FC<{
    position: number;
    onDelete: () => void;
    question: TestQuestion;
    errors: Array<ChildErrors>;
    onChange: (question: TestQuestion) => void;
    onDeleteOption: (optionuiid: string) => void;
}> = (props) => {

    const theme = useTheme()
    const { confirm } = useNotify()
    const { question, onChange, position, onDelete, errors, onDeleteOption } = props

    const hasAtLeastError = (_key: string): boolean => {
        for (const error of errors) {
            for (const key in error) {
                if (key.startsWith(_key)) return true
            }
        }
        return false
    }

    return (
        <Group key={question.uiid} style={{ flex: 1 }}>
            <Accordion>
                <StyledAccordionItem header={<StyledChapterChildTitle
                    style={{
                        color: hasAtLeastError(`question${question.uiid}`) ? theme.colors.danger : theme.colors.black
                    }}
                >Question {position}</StyledChapterChildTitle>}>
                    <Group style={{ padding: '10px' }}>
                        <Group direction="row" align="center">
                            <RichText
                                error={findError(errors, `question${question.uiid}`)}
                                placeholder={`Question ${position} description`}
                                parentStyle={{ flex: 1 }}
                                onChange={(_question) => _question !== question.question ? onChange({ ...question, question: _question }) : null}
                                value={question.question}
                                isRequired
                            />
                            <StyledIcon style={{ marginTop: '25px' }} onClick={() => confirm({ onConfirmation: onDelete })}>
                                <Trash style={{ color: theme.colors.danger }} />
                            </StyledIcon>
                        </Group>
                        <Group direction="row" justify="space-between">
                            <div>
                                <StyledSpan><small>Check all relevant answers</small></StyledSpan>
                                {
                                    findError(errors, `question${question.uiid}options`) ? <StyledErrorText>- {findError(errors, `question${question.uiid}options`)}</StyledErrorText> : null
                                }

                                {
                                    findError(errors, `question${question.uiid}optionsLength`) ? <StyledErrorText>- {findError(errors, `question${question.uiid}optionsLength`)}</StyledErrorText> : null
                                }
                            </div>

                            <OutlineButton text="Option" onClick={() => onChange({
                                ...question, options: [...question.options, {
                                    option: "",
                                    optionId: "",
                                    isAnswer: false,
                                    uiid: uniqid()
                                }]
                            })} icon={<PlusLg size={14} />} />
                        </Group>
                        <Group gap="xs">
                            {
                                question.options.map((questionOption, questionOptionIndex) => <Group key={questionOptionIndex} gap="sm" wrap="nowrap" justify="space-between" align="center" direction="row">
                                    <CheckBoxInput
                                        parentStyle={{ marginTop: '5px' }}
                                        onChange={(isAnswer) => onChange({ ...question, options: question.options.map((updatedOption, index) => index === questionOptionIndex ? { ...updatedOption, isAnswer } : updatedOption) })}
                                        value={!!questionOption.isAnswer}
                                    />
                                    <TextInput
                                        isRequired
                                        type="text"
                                        parentStyle={{ flex: 1 }}
                                        placeholder={`Option ${questionOptionIndex + 1}`}
                                        error={findError(errors, `question${question.uiid}options${questionOption.uiid}`)}
                                        value={questionOption.option}
                                        onChange={(option) => onChange({ ...question, options: question.options.map((updatedOption, index) => index === questionOptionIndex ? { ...updatedOption, option } : updatedOption) })}
                                    />
                                    <StyledIcon className={`small ${question.options.length <= 2 ? 'disabledEvents' : ''}`} onClick={() => confirm({ onConfirmation: () => onDeleteOption(questionOption.uiid) })} style={{ color: theme.colors.grey }}>
                                        <Trash />
                                    </StyledIcon>
                                </Group>)
                            }
                        </Group>
                    </Group>
                </StyledAccordionItem>
            </Accordion>
        </Group>
    )
}