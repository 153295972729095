import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled, { CSSProperties } from 'styled-components';
import { InputContainer } from '../InputContainer/InputContainer';

interface RichTextProps {
    value: string;
    placeholder?: string;
    error?: string;
    isRequired?: boolean;
    description?: string;
    style?: CSSProperties;
    parentStyle?: CSSProperties;
    label?: string;
    onChange: (value: string) => void;
}

const StyledReactQuill = styled.div`
    .quill {
        color: ${(props) => props.theme.colors.gray6};
        background: ${(props) => props.theme.colors.white};
    }

    .ql-container,
    .ql-toolbar {
        &.ql-snow {
            border-color: ${(props) => props.theme.colors.gray};
        }
    }

    .hasError {
        .ql-container,
        .ql-toolbar {
            &.ql-snow {
                border-color: ${(props) => props.theme.colors.danger};
            }
        }
    }

    .ql-toolbar {
        &.ql-snow {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }
    }

    .ql-container {
        &.ql-snow {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;

            .ql-editor {
                min-height: 150px;
            }
        }
    }

`

export const RichText = (props: RichTextProps) => {

    const classNames = `${props.error ? 'hasError' : ''}`;

    return (<InputContainer
        label={props.label}
        description={props.description}
        error={props.error || ""}
        isRequired={props.isRequired}
        style={props.parentStyle}
        render={(ID) => (
            <StyledReactQuill id={ID}>
                <ReactQuill
                    theme="snow"
                    style={{ ...props.style || {} }}
                    value={props.value}
                    className={classNames}
                    placeholder={props.placeholder}
                    onChange={(newValue, delta, source) => {
                        if (source === 'user') {
                            props.onChange(newValue);
                        }
                    }}

                    modules={{
                        toolbar: [
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            ['link'],
                            // ['link', 'image'],
                        ],
                    }}
                />
            </StyledReactQuill>
        )}
    />);
}