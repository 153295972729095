import moment from 'moment';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { useWindowSize } from 'react-use';
import { useApi } from "../../hooks/use-api";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { Accordion } from '@szhsin/react-accordion';
import { useTheme } from "../../hooks/use-theme.hook";
import { TopMenu } from "../../components/App/TopMenu";
import { downloadFile } from '../../utils/downloadFile';
import { Dropdown } from "../../components/App/Dropdown";
import { Page } from "../../components/Layout/Page/Page";
import ConfettiExplosion from 'react-confetti-explosion';
import { StepButton } from '../../components/Form/Button';
import { Spinner } from "../../components/Spinner/Spinner";
import { PopUpForm } from '../../components/App/PopUpForm';
import { Group } from "../../components/Layout/Group/Group";
import { TutorPreview } from '../../components/App/TutorCards';
import { ReviewCards } from '../../components/App/ReviewCards';
import { ProgressStep } from '../../components/App/ProgressStep';
import { NavPills } from "../../components/App/NavPills/NavPills";
import { LoadMore } from '../../components/App/LoadMore/LoadMore';
import { Carousel } from '../../components/App/Carousel/Carousel';
import { AccessDeniedError, NotFoundError } from "../ErrorPage/Errors";
import { FileViewer } from '../../components/App/FolderCards/FileViewer';
import { PageFilters } from '../../components/App/PageFilters/PageFilters';
import { ProgressBar } from "../../components/App/ProgressBar/ProgressBar";
import { TestFrame } from '../../components/App/TestResponder/TestResponder';
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { ShareToSocial } from '../../components/App/ShareToSocial/ShareToSocial';
import { ReviewCourse, ReviewTutor } from '../../components/App/ReviewEditor/ReviewEditor';
import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RenderNewEventForm } from '../../components/App/DashboardCalendar/DashboardCalendar';
import { StyledAccordionItem, StyledChapterChildTitle, StyledChapterTitle, StyledContentWrapper, StyledH5, StyledH6, StyledHR, StyledIcon, StyledSmall, StyledSpan, StyledTitle, StyledTitleDescDiv, forPhoneOnly, growSmaller, growSmallerShadow } from "../../components/Elements";
import { Alarm, Award, BlockquoteLeft, CheckCircle, CheckSquareFill, CloudDownload, Dot, FiletypeMp3, FiletypeMp4, FiletypePdf, FiletypePng, FiletypePpt, Hourglass, Lock, Play, SendCheckFill, Share, Square, StarHalf, ThreeDots, TrophyFill, Unlock } from "react-bootstrap-icons";
import { getCourseChapterProgressPercentage, getCourseProgressPercentage, Course, CourseCurriculumChapter, CourseCurriculumChapterLecture, CourseCurriculumChapterLectureResource, CourseLectureResourceTypes, Review, FolderFile, CourseTutor, EnrollmentStatus, AllTestSchema, UserStatEnrollment, UserStatEnrollmentLecture, TestAttempt, PageFiltersValues, Event, EventRecurrence, EventStatus, RecordTypes } from 'shared-library';


const StyledGroupContainer = styled(Group)`

    .course-viewer {
        ${forPhoneOnly("order: 2;")}

        .content {
            padding: 20px;
            min-height: 500px;
            border-radius: 8px;
            background: ${props => props.theme.colors.white};
            ${forPhoneOnly("min-height: 300px;")}

            .carousel-root {
                max-width: 100%;
            }

            .slide {
                display: flex;
                justify-content: center;
            }

            .carousel.carousel-slider .control-arrow {
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }

    .course-content {
        min-width: 300px;
        ${forPhoneOnly("order: 1; margin-bottom: 20px; min-width: 100%;")}

        .row {
            border-radius: 6px;
            padding: 8px 10px;

            &.active {
                ${growSmallerShadow}
            }

            &.clickable {
                cursor: pointer;
                ${growSmaller}
            }
        }

    }
`

const StyledCourseSectionWrapper = styled(Group)`
    padding: 6px 10px;
    border-radius: 8px;
    background: ${props => props.theme.colors.white};

    .fileRow {
        ${growSmaller}
        padding: 6px 10px;
        border-radius: 6px;
        border: 1px solid ${props => props.theme.colors.grey6};
    }
`

export const StudentCourseLearn = () => {

    const theme = useTheme();
    const { courseId } = useParams()
    const { width, height } = useWindowSize()
    const { state, setUserInfo } = useContext(AuthContext);
    const { getCourse, submitLearningProgress } = useApi();
    const { data: course, isLoading } = useQuery({
        queryKey: ["course", courseId],
        queryFn: () => getCourse(courseId as string),
    });
    let { isPending: mutating, mutateAsync: mutateSubmitLearningProgressAsync } = useMutation({
        mutationFn: submitLearningProgress
    })

    const [showCompletion, setShowCompletion] = useState(false)
    const [progressPercentage, setProgressPercentage] = useState(0)
    const [focusedLearning, setFocusedLearning] = useState<{
        chapter: CourseCurriculumChapter;
        lecture: CourseCurriculumChapterLecture;
        lectureStatRecord: UserStatEnrollmentLecture;
    }>()
    const [reviewCourseEditorCanvasOpen, setReviewCourseEditorCanvasOpen] = useState(false)
    const [enrollment, setEnrollment] = useState<UserStatEnrollment | undefined>(state.user ? state.user.info.user.stats.courses.enrollments.find(({ status, courseId: _courseId }) => status === EnrollmentStatus.APPROVED && _courseId === courseId) : undefined)

    useEffect(() => {
        if (enrollment?.endDate) {
            setShowCompletion(true)
        }
    }, [enrollment?.endDate])

    useEffect(() => {
        if (course && enrollment) {
            const progress = getCourseProgressPercentage(course, [enrollment])
            if (progressPercentage !== progress) {
                setProgressPercentage(progress)
            }
        }
    }, [course, enrollment, progressPercentage])

    const isLocked = useCallback((lecture: CourseCurriculumChapterLecture) => {
        if (enrollment?.lectures?.find(({ lectureId, endDate }) => lectureId === lecture.lectureId && endDate)) {
            return false
        }
        if (focusedLearning?.lecture.lectureId === lecture.lectureId) {
            return enrollment?.lectures?.find(({ lectureId }) => lectureId === lecture.lectureId)?.waitingForApproval
        }
        return course?.settings.sequentialLearning.enable && !enrollment?.lectures?.find(({ lectureId, endDate }) => lectureId === lecture.lectureId && endDate)
    }, [course?.settings.sequentialLearning.enable, enrollment?.lectures, focusedLearning?.lecture.lectureId])

    const getStarted = async () => {
        if (!courseId || mutating) return
        try {
            const enrollmentUpdated = await mutateSubmitLearningProgressAsync({
                courseId,
                actionType: "start-course"
            })
            setEnrollment(_ => ({ ...enrollmentUpdated }))
            if (state.user) {
                setUserInfo({
                    ...state.user.info.user,
                    stats: {
                        ...state.user.info.user.stats,
                        courses: {
                            ...state.user.info.user.stats.courses,
                            enrollments: state.user.info.user.stats.courses.enrollments.map(_enrollment => _enrollment.courseId === enrollment?.courseId ? { ...enrollmentUpdated } : { ..._enrollment })
                        }
                    }
                })
            }
            continueLearning(enrollmentUpdated)
        } catch (error) {
        }
    }

    const continueLearning = (_enrollment?: UserStatEnrollment) => {
        const enrollmentRecord = _enrollment || enrollment
        if (course && enrollmentRecord) {
            for (const chapter of course.curriculum.chapters) {
                const nextLecture = chapter.lectures.find(({ lectureId }) => !enrollmentRecord.lectures.find(({ lectureId: _lectureId, endDate }) => lectureId === _lectureId && endDate))
                if (!nextLecture) continue
                let lectureStatRecord = enrollmentRecord.lectures.find(({ lectureId }) => lectureId === nextLecture.lectureId)
                if (!lectureStatRecord) {
                    lectureStatRecord = {
                        lectureId: nextLecture.lectureId,
                        waitingForApproval: course.settings.sequentialLearning.enable && chapter.lectures.findIndex(({ lectureId }) => lectureId === nextLecture.lectureId) !== 0
                    }
                }
                setFocusedLearning({ chapter, lecture: nextLecture, lectureStatRecord })
                break
            }
        }
    }

    const continueLecture = (chapter: CourseCurriculumChapter, nextLecture: CourseCurriculumChapterLecture) => {
        if (course && enrollment) {
            let lectureStatRecord = enrollment.lectures.find(({ lectureId }) => lectureId === nextLecture.lectureId)
            if (!lectureStatRecord) {
                lectureStatRecord = {
                    lectureId: nextLecture.lectureId,
                    waitingForApproval: course.settings.sequentialLearning.enable && chapter.lectures.findIndex(({ lectureId }) => lectureId === nextLecture.lectureId) !== 0
                }
            }
            setShowCompletion(false)
            setFocusedLearning({ chapter, lecture: nextLecture, lectureStatRecord })
        }
    }

    const onFinishLecture = async () => {
        if (!courseId || mutating || !focusedLearning) return
        try {
            const enrollmentUpdated = await mutateSubmitLearningProgressAsync({
                courseId,
                actionType: "finish-lecture",
                lectureId: focusedLearning.lecture.lectureId
            })
            setEnrollment(_ => ({ ...enrollmentUpdated }))
            if (state.user) {
                setUserInfo({
                    ...state.user.info.user,
                    stats: {
                        ...state.user.info.user.stats,
                        courses: {
                            ...state.user.info.user.stats.courses,
                            enrollments: state.user.info.user.stats.courses.enrollments.map(_enrollment => _enrollment.courseId === enrollment?.courseId ? { ...enrollmentUpdated } : { ..._enrollment })
                        }
                    }
                })
            }
            continueLearning(enrollmentUpdated)
        } catch (error) {
        }
    }

    if (!course || isLoading) {
        if (!course && !isLoading) {
            return <NotFoundError />
        }
        return <Spinner text="Preparing Course" style={{ margin: 200 }} />
    }

    if (!enrollment) return <AccessDeniedError />

    return (
        <Page>
            <StyledContentWrapper>
                <Group>
                    <TopMenu hideNotification titles={[{
                        text: 'Courses',
                        link: `/${state.user?.info.session.role}/courses`
                    }, {
                        text: course.title,
                    }]} />
                    <Group gap="xs" className="slideUp">
                        <br />
                        <br />
                        <StyledGroupContainer direction='row' justify='space-between'>
                            <Group className='course-viewer' style={{ flex: 1 }}>
                                <Group gap="xs" direction="row">
                                    {
                                        !focusedLearning ? <br /> : <>
                                            <b>{focusedLearning.chapter.title}</b>
                                            <b>/</b>
                                            <StyledSpan>{focusedLearning.lecture.title}</StyledSpan>
                                        </>
                                    }
                                </Group>

                                <Group align="center" gap='none' wrap='nowrap' justify="center" className='content'>
                                    {
                                        showCompletion ? <>
                                            <ConfettiExplosion
                                                force={1}
                                                duration={5000}
                                                particleCount={1000}
                                                width={width}
                                                height={height} />
                                            <Award color={theme.colors.success} size={42} />
                                            <StyledH5 style={{ color: theme.colors.success }}><b>Congratulations! &nbsp;🎉</b></StyledH5>
                                            <StyledTitleDescDiv className='short'>
                                                You've successfully completed <b>{course.title}</b>!
                                            </StyledTitleDescDiv>
                                            <br />
                                            <StyledTitleDescDiv className='short'>
                                                Take a moment to celebrate your achievement and reflect on all that you've learned. Your dedication and hard work have paid off, and you're now equipped with valuable knowledge and skills to apply in your personal and professional endeavors.
                                                Keep up the great work, and continue to pursue your learning journey!
                                            </StyledTitleDescDiv>
                                            <br />
                                            {
                                                !enrollment.courseRatingId && <StepButton leftIcon={<StarHalf />} onClick={() => setReviewCourseEditorCanvasOpen(true)} classNamesArr={['success']} text='Leave a review' />
                                            }
                                        </> : <>
                                            {
                                                !focusedLearning ? <>
                                                    <img src="/assets/images/start-test.png" alt="Start learning logo" />
                                                    {
                                                        !enrollment.startDate ? <>
                                                            <StyledTitleDescDiv className='short'>
                                                                Get set to dive into <b>{course.title}</b>! 🚀
                                                            </StyledTitleDescDiv>
                                                            <StyledTitleDescDiv className='short'>
                                                                Prepare for an enriching learning experience that'll expand your horizons and boost your skills. Let's embark on this journey together!
                                                            </StyledTitleDescDiv>
                                                            <br />
                                                            <StepButton leftIcon={<Play size={14} />} loading={mutating} onClick={getStarted} text="Get Started" />
                                                        </> : <>
                                                            <StyledTitleDescDiv className='short'>
                                                                Welcome back to <b>{course.title}</b>! 📚
                                                            </StyledTitleDescDiv>
                                                            <StyledTitleDescDiv className='short'>
                                                                It's time to pick up right where you left off and continue your learning adventure. Get ready to dive back into the material and keep progressing towards your goals.
                                                            </StyledTitleDescDiv>
                                                            <br />
                                                            <StepButton leftIcon={<Play size={14} />} loading={mutating} onClick={() => continueLearning()} text="Continue" />
                                                        </>
                                                    }
                                                </> :
                                                    <>
                                                        {
                                                            mutating ? <Spinner /> : <>
                                                                {
                                                                    focusedLearning.lectureStatRecord.waitingForApproval ? <>
                                                                        <Hourglass className='shake' size={22} color={theme.colors.success} />
                                                                        <StyledTitleDescDiv className='short shake'>
                                                                            <b>Waiting for tutor's approval to release the next lecture.</b> ⏳
                                                                        </StyledTitleDescDiv>
                                                                        <StyledTitleDescDiv className='short'>
                                                                            We've informed your tutor about the next lecture.
                                                                            Sit tight, and we'll keep you updated on its release!
                                                                        </StyledTitleDescDiv>
                                                                    </> :
                                                                        <LearningItems
                                                                            courseId={course.id}
                                                                            enrollment={enrollment}
                                                                            onFinishLecture={onFinishLecture}
                                                                            key={focusedLearning.lecture.lectureId}
                                                                            lectureTitle={focusedLearning.lecture.title}
                                                                            resources={focusedLearning.lecture.resources}
                                                                        />
                                                                }
                                                            </>
                                                        }
                                                    </>
                                            }
                                        </>
                                    }
                                </Group>
                                <Group gap='none'>
                                    <Group direction='row' align='center' justify='space-between'>
                                        <StyledSmall style={{ color: theme.colors.primary2 }}><b>{progressPercentage}%</b> completed</StyledSmall>
                                        <StyledSpan>
                                            <TrophyFill color={theme.colors.primary2} />
                                        </StyledSpan>
                                    </Group>
                                    <ProgressBar bgColor={theme.colors.primary2} value={progressPercentage} color={course.color} />
                                </Group>
                                <LearningOptions
                                    course={course}
                                    enrollment={enrollment}
                                    reviewEditorCanvasOpen={reviewCourseEditorCanvasOpen}
                                    setReviewCourseEditorCanvasOpen={setReviewCourseEditorCanvasOpen}
                                    onCourseReviewSubmitted={(review) => {
                                        setEnrollment(enrollment => (enrollment ? { ...enrollment, courseRatingId: review.id } : undefined))
                                    }}
                                    onReminderSubmitted={(event) => {
                                        setEnrollment(enrollment => (enrollment ? { ...enrollment, courseReminderId: event.id } : undefined))
                                    }}
                                />
                            </Group>
                            <Group className='course-content'>
                                <b>Course Content</b>
                                {
                                    course.curriculum.chapters.map((chapter, chapterIndex) => <Accordion style={{
                                        padding: '8px',
                                        borderRadius: '5px',
                                        marginBottom: '-10px',
                                        backgroundColor: theme.colors.grey4
                                    }} key={chapter.chapterId}>
                                        <StyledAccordionItem initialEntered={!chapterIndex || chapter.chapterId === focusedLearning?.chapter.chapterId || !!enrollment?.endDate} header={<StyledChapterChildTitle>
                                            {
                                                getCourseChapterProgressPercentage(chapter, enrollment) === 100 ? <CheckSquareFill size={12} style={{ color: theme.colors.success }} /> :
                                                    <Square size={12} style={{ color: theme.colors.gray4 }} />
                                            }
                                            &nbsp;
                                            {chapter.title}
                                        </StyledChapterChildTitle>}>
                                            <StyledHR />
                                            <Group gap='xs'>
                                                {
                                                    chapter.lectures.map(lecture => <Group className={`row ${lecture.lectureId === focusedLearning?.lecture.lectureId ? 'active' : ''} ${isLocked(lecture) ? 'disabledEvents' : 'clickable'}`} onClick={() => continueLecture(chapter, lecture)} direction="row" key={lecture.lectureId} align="center">
                                                        <Group direction="row" align="center" gap="xs" style={{ flex: 1 }}>
                                                            {
                                                                enrollment.lectures.find(({ lectureId, endDate }) => lectureId === lecture.lectureId && endDate) ? <CheckSquareFill size={12} style={{ color: theme.colors.success }} /> :
                                                                    <Square size={12} style={{ color: theme.colors.gray4 }} />
                                                            }
                                                            <StyledSmall>{lecture.title}</StyledSmall>
                                                        </Group>
                                                        <StyledSmall>
                                                            {isLocked(lecture) ? <Lock /> : <Unlock />}
                                                        </StyledSmall>
                                                    </Group>)
                                                }
                                            </Group>
                                        </StyledAccordionItem>
                                    </Accordion>)
                                }
                            </Group>
                        </StyledGroupContainer>
                    </Group>
                </Group>
            </StyledContentWrapper>
        </Page>
    )
}

const LearningItems = ({ resources, lectureTitle, onFinishLecture, enrollment, courseId }: { courseId: string; enrollment: UserStatEnrollment; lectureTitle: string; resources: Array<CourseCurriculumChapterLectureResource>; onFinishLecture: () => void; }) => {

    const theme = useTheme()
    const { getTestAttempt } = useApi()
    const [defaultSelectedItem] = useState(0)
    const [{ testAttempt, testIsLoading }, setTestData] = useState<{ testIsLoading: boolean; testAttempt: TestAttempt | undefined }>({ testIsLoading: false, testAttempt: undefined })

    const startTest = async (test: AllTestSchema) => {
        if (!testIsLoading) {
            try {
                const testAttempt = await getTestAttempt({ testId: test.testId, courseId })
                const testIsLoading = false
                setTestData({ testIsLoading, testAttempt })
            } catch (error) {
            }
        }
    }

    const prepareResources = useMemo(() => {
        const items: ReactElement[] = resources.map(resource => <Group align='center' justify='center' style={{
            width: '100%',
            height: '100%',
        }}
            key={resource.lectureResourceId}>
            {
                resource.file && <FileViewer file={resource.file} />
            }
            {
                resource.lectureResourceType === CourseLectureResourceTypes.ARTICLES && <>
                    {parse(resource.text)}
                </>
            }
            {
                resource.test && <Group align="center" justify="center" style={{ padding: '0px 20px' }}>
                    {
                        testAttempt?.testId !== resource.test.testId ? <>
                            <img src="/assets/images/start-test.png" style={{ width: '230px' }} alt="Start test logo" />
                            <StyledTitle>
                                {resource.test.title}
                            </StyledTitle>
                            <ProgressStep totalSteps={resource.test.totalQuestions + 1} active={1} />
                            <StyledTitleDescDiv className='short'>
                                {parse(resource.test.description)}
                            </StyledTitleDescDiv>
                            <StepButton rightIcon={<Play />} loading={testIsLoading} onClick={() => startTest(resource.test as AllTestSchema)} text={testAttempt?.completed ? "View Result" : "Start Test"} />
                        </> : <TestFrame attempt={testAttempt} />
                    }
                </Group>
            }
        </Group>)
        items.push(<Group gap='xs' align='center' key={resources.length}>
            <SendCheckFill size={22} color={theme.colors.success} />
            <StyledTitleDescDiv className='short'>
                End of <b>{lectureTitle}</b>. 🎓
            </StyledTitleDescDiv>
            <StyledTitleDescDiv className='short'>
                Take a moment to celebrate your achievement and reflect on all that you've learned.
            </StyledTitleDescDiv>
            {
                !enrollment.endDate && <StepButton onClick={onFinishLecture} leftIcon={<CheckCircle />} text='Complete Lecture' style={{ width: 150 }} />
            }
        </Group>)
        return items
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources, testAttempt?.testId, lectureTitle, testAttempt, testIsLoading, enrollment.endDate, theme.colors.success])

    return (
        <Carousel
            elements={prepareResources}
            defaultSelectedItem={defaultSelectedItem}
        />
    )
}


const LearningOptions = ({
    course,
    enrollment,
    onReminderSubmitted,
    reviewEditorCanvasOpen,
    onCourseReviewSubmitted,
    setReviewCourseEditorCanvasOpen
}: {
    course: Course;
    enrollment: UserStatEnrollment;
    reviewEditorCanvasOpen: boolean;
    onReminderSubmitted?: (v: Event) => void;
    onCourseReviewSubmitted?: (v: Review) => void;
    setReviewCourseEditorCanvasOpen: (v: boolean) => void;
}) => {

    const { state, setUserInfo } = useContext(AuthContext);
    const { getCourseReviews, getDownloadableCourseMaterials, getDownloadURL } = useApi();
    const [currentTab, setCurrentTab] = useState<"summary" | "reviews" | "materials">("summary");
    const [reviewsFilters] = useState<{
        limit: number;
        courseId: string;
        nextBatchIndex?: string;
    }>({
        limit: 10,
        courseId: course.id,
    })
    const theme = useTheme()
    const [reminderOptionOpened, setReminderOptionOpened] = useState(false);
    const { data: reviews, isLoading: reviewsLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch: refetchReviews } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getCourseReviews({
            nextBatchIndex,
            limit: reviewsFilters.limit,
            courseId: reviewsFilters.courseId,
        }),
        enabled: currentTab === "reviews",
        initialPageParam: reviewsFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["reviews", course.id, reviewsFilters.limit, reviewsFilters.nextBatchIndex],
    });
    const [downloadingFiles, setDownloadingFiles] = useState<Array<string>>([]);
    const [shareToSocialCanvasOpen, setShareToSocialCanvasOpen] = useState(false);
    const [reviewTutorEditorCanvasOpen, setReviewTutorEditorCanvasOpen] = useState(false);

    const [downloadableMaterialsFilters, setDownloadableMaterialsFilters] = useState<{
        query: string;
        limit: number;
        course: Course;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
        enrollment: UserStatEnrollment;
    }>({
        course,
        limit: 20,
        query: "",
        enrollment,
        filter: PageFiltersValues.latestFirst
    })
    const { data: downloadableMaterials, isLoading: downloadableMaterialsLoading, isFetchingNextPage: downloadableMaterialsFetchingNextPage, fetchNextPage: downloadableMaterialsFetchNextPage, hasNextPage: downloadableMaterialsHasNextPage } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getDownloadableCourseMaterials({
            nextBatchIndex,
            limit: downloadableMaterialsFilters.limit,
            query: downloadableMaterialsFilters.query,
            filter: downloadableMaterialsFilters.filter,
            course: downloadableMaterialsFilters.course,
            enrollment: downloadableMaterialsFilters.enrollment,
        }),
        enabled: currentTab === "materials",
        initialPageParam: downloadableMaterialsFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["downloadableMaterials", downloadableMaterialsFilters.filter, downloadableMaterialsFilters.enrollment.enrollmentId, downloadableMaterialsFilters.query, downloadableMaterialsFilters.limit, downloadableMaterialsFilters.nextBatchIndex],
    });

    const onQueryChange = (query: string) => {
        setDownloadableMaterialsFilters({ ...downloadableMaterialsFilters, query })
    }

    const onFiltersChange = (filter?: PageFiltersValues) => {
        setDownloadableMaterialsFilters({ ...downloadableMaterialsFilters, filter })
    }

    const flattenDownloadableMaterials = useCallback(() => {
        let downloadableMaterialsArr: Array<FolderFile> = []
        if (downloadableMaterials?.pages) {
            for (const page of downloadableMaterials.pages) {
                downloadableMaterialsArr = [...downloadableMaterialsArr, ...page.data]
            }
        }
        return downloadableMaterialsArr
    },
        [downloadableMaterials?.pages]
    );

    const onTabChange = (_newTab: string) => {
        const newTab = _newTab as typeof currentTab
        setCurrentTab(newTab)
    }

    const flattenReviews = useCallback(() => {
        let _reviews: Array<Review> = []
        if (reviews?.pages) {
            for (const page of reviews.pages) {
                _reviews = [..._reviews, ...page.data]
            }
        }
        return _reviews
    },
        [reviews?.pages]
    );

    const reviewSubmissionSuccess = (submittedReview: Review) => {
        if (currentTab === "reviews") {
            refetchReviews()
        }
        if (state.user) {
            setUserInfo({
                ...state.user.info.user,
                stats: {
                    ...state.user.info.user.stats,
                    courses: {
                        ...state.user.info.user.stats.courses,
                        enrollments: state.user.info.user.stats.courses.enrollments.map(_enrollment => _enrollment.courseId === enrollment.courseId ? { ..._enrollment, courseRatingId: submittedReview.id } : { ..._enrollment })
                    }
                }
            })
        }
        onCourseReviewSubmitted && onCourseReviewSubmitted(submittedReview)
    }

    const eventSubmissionSuccess = (submittedEvent: Event) => {
        if (state.user) {
            setUserInfo({
                ...state.user.info.user,
                stats: {
                    ...state.user.info.user.stats,
                    courses: {
                        ...state.user.info.user.stats.courses,
                        enrollments: state.user.info.user.stats.courses.enrollments.map(_enrollment => _enrollment.courseId === enrollment.courseId ? { ..._enrollment, courseReminderId: submittedEvent.id } : { ..._enrollment })
                    }
                }
            })
        }
        onReminderSubmitted && onReminderSubmitted(submittedEvent)
    }

    const getDownloadableResources = useCallback(() => {
        const defaultResponse = 0
        if (!course) return defaultResponse
        let totalResources = 0
        for (const chapter of course.curriculum.chapters) {
            for (const lecture of chapter.lectures) {
                if (!enrollment.lectures.find(({ lectureId, startDate }) => lectureId === lecture.lectureId && startDate)) {
                    continue
                }
                for (const item of lecture.resources) {
                    if (item.downloadable) {
                        totalResources++
                    }
                }
            }
        }
        return totalResources
    },
        [course, enrollment]
    );

    const downloadMaterial = async (file: FolderFile) => {
        if (downloadingFiles.includes(file.id)) return;
        setDownloadingFiles(sdf => ([...sdf, file.id]))
        try {
            const url = await getDownloadURL({ file })
            downloadFile(url.url, file.fileName, true)
        } catch (error) {
        }
        setDownloadingFiles(sdf => sdf.filter(_sdf => _sdf !== file.id))
    }

    return (
        <>
            <Group direction="row" style={{
                padding: '10px 12px',
                borderRadius: '8px',
                background: theme.colors.white
            }} justify="space-between">
                <NavPills active={currentTab} tabs={[
                    {
                        id: "summary",
                        text: "Summary",
                        onclick: onTabChange,
                        icon: <BlockquoteLeft size={16} />,
                    },
                    {
                        id: "materials",
                        text: "Materials",
                        onclick: onTabChange,
                        icon: <CloudDownload size={16} />,
                    },
                    {
                        id: "reviews",
                        text: "Reviews",
                        onclick: onTabChange,
                        icon: <StarHalf size={16} />,
                    },
                ]} />

                <StyledSpan>
                    <Dropdown margin={"md"} controller={<StyledIcon>
                        <ThreeDots />
                    </StyledIcon>} options={[
                        {
                            text: "Share Course",
                            disabled: !course.settings.visibleToNonEnrolled,
                            onClick: () => setShareToSocialCanvasOpen(true),
                            icon: <Share style={{ color: theme.colors.secondary }} />,
                        },
                        {
                            demarcateTop: true,
                            text: "Review Course",
                            disabled: !!enrollment.courseRatingId,
                            onClick: () => setReviewCourseEditorCanvasOpen(true),
                            icon: <StarHalf style={{ color: theme.colors.success }} />,
                        },
                        ...(course.tutor ? [
                            {
                                text: "Review Tutor",
                                onClick: () => setReviewTutorEditorCanvasOpen(true),
                                icon: <StarHalf style={{ color: theme.colors.secondary }} />,
                            }
                        ] : []),

                    ]} />
                </StyledSpan>
            </Group>

            {
                currentTab === "summary" && <>
                    {
                        !enrollment.courseReminderId && !enrollment.endDate && <>
                            <StyledCourseSectionWrapper style={{ padding: 20 }}>
                                <PopUpForm
                                    controller={
                                        <Group style={{ position: 'relative' }} direction='row' gap='sm' wrap='nowrap' align='center' justify='start'>
                                            <StyledSpan>
                                                <Alarm className='shake' size={36} />
                                            </StyledSpan>
                                            <Group gap='none'>
                                                <b>Click here to create a reminder for this course!</b>
                                                <StyledSmall>
                                                    Set aside dedicated time each week to study and engage with the material. Creating a reminder on your calendar or smartphone can help you stay on track and make the most out of your learning experience.
                                                </StyledSmall>
                                            </Group>
                                        </Group>
                                    }
                                    direction='start'
                                    controllerDisabled={reminderOptionOpened}
                                    children={<RenderNewEventForm event={{
                                        id: "",
                                        creationDate: "",
                                        color: course.color,
                                        courseId: course.id,
                                        sortKey: "",
                                        companyId: "",
                                        userId: "",
                                        updatedOn: "",
                                        recordType: RecordTypes.USEREVENT,
                                        status: EventStatus.PENDING,
                                        recurring: EventRecurrence.DAILY,
                                        title: `Continue ${course.title}`,
                                        description: "Remember, consistency is key to success!",
                                        day: moment().add(1, "day").format("YYYY-MM-DD 09:00:00"),
                                    }} onCompleted={eventSubmissionSuccess} />}
                                    margin='sm'
                                    title={<StyledH6><b>Reminder</b></StyledH6>}
                                    stateUpdate={setReminderOptionOpened}

                                />
                            </StyledCourseSectionWrapper>
                            <StyledHR />
                        </>
                    }

                    <StyledCourseSectionWrapper style={{ padding: 20 }}>
                        <Group gap='none'>
                            <StyledTitle><b>{course.title}</b></StyledTitle>
                            <StyledTitleDescDiv>
                                {parse(course.description)}
                            </StyledTitleDescDiv>
                        </Group>
                    </StyledCourseSectionWrapper>
                    {
                        course.tutor && <>
                            <StyledHR />
                            <StyledCourseSectionWrapper style={{ padding: 20 }}>
                                <TutorPreview tutor={course.tutor} />
                            </StyledCourseSectionWrapper>
                        </>
                    }
                </>
            }

            {
                currentTab === "materials" && <StyledCourseSectionWrapper style={{ padding: 20 }}>
                    {
                        false ? <Spinner text="Fetching Materials" style={{ margin: 60 }} /> : <>
                            <StyledChapterTitle><b>Downloadable Course Materials</b><StyledSpan><small style={{ paddingLeft: '5px', fontSize: '10px', fontWeight: 'lighter' }}>({getDownloadableResources()})</small></StyledSpan></StyledChapterTitle>

                            <Group align='end' justify='end'>
                                <PageFilters hideLayout query={downloadableMaterialsFilters.query} filter={downloadableMaterialsFilters.filter} onQueryChange={onQueryChange} onFiltersChange={onFiltersChange} />
                            </Group>

                            {
                                !downloadableMaterials || downloadableMaterialsLoading ? <Spinner text="Fetching Materials" style={{ margin: 60 }} /> :
                                    <>
                                        {
                                            flattenDownloadableMaterials().length > 0 ? <>
                                                {
                                                    flattenDownloadableMaterials().map(file => <Group className='fileRow' align='center' direction='row' wrap='nowrap' justify='space-between'>
                                                        <Group direction='row' gap='sm' align='center'>
                                                            <StyledSpan>
                                                                {file.fileType === "PRESENTATIONS" && <FiletypePpt size={22} />}
                                                                {file.fileType === "AUDIOS" && <FiletypeMp3 size={22} />}
                                                                {file.fileType === "VIDEOS" && <FiletypeMp4 size={22} />}
                                                                {file.fileType === "DOCUMENTS" && <FiletypePdf size={22} />}
                                                                {file.fileType === "PHOTOS" && <FiletypePng size={22} />}
                                                            </StyledSpan>
                                                            <Group gap='none'>
                                                                <b>{file.fileName}</b>
                                                                <Group gap="none" direction="row">
                                                                    <StyledSmall>{file.ext.toUpperCase()}</StyledSmall>
                                                                    <StyledSmall><Dot size={16} /></StyledSmall>
                                                                    <StyledSmall>{file.fileSize.total} {file.fileSize.fileSizeIn.toUpperCase()}</StyledSmall>
                                                                </Group>
                                                            </Group>
                                                        </Group>
                                                        <StyledIcon onClick={() => downloadMaterial(file)} className={`small ${downloadingFiles.includes(file.id) ? 'disabledEvents' : ''}`}>
                                                            <CloudDownload color={theme.colors.grey} />
                                                        </StyledIcon>
                                                    </Group>)
                                                }
                                                {
                                                    downloadableMaterialsHasNextPage && <LoadMore loading={downloadableMaterialsFetchingNextPage} onclick={downloadableMaterialsFetchNextPage} />
                                                }
                                            </> :
                                                <StyledCourseSectionWrapper align="center" gap="none">
                                                    <img src="/assets/images/emptyFile.png" style={{ marginBottom: -30 }} width={200} height={150} alt="Empty Notification Icon" />
                                                    <StyledSmall><i>No downloadable file</i></StyledSmall>
                                                    <br />
                                                </StyledCourseSectionWrapper>
                                        }

                                    </>
                            }
                        </>
                    }
                </StyledCourseSectionWrapper>
            }

            {
                currentTab === "reviews" && <Group>
                    {
                        reviewsLoading ? <Spinner text="Fetching Reviews" style={{ margin: 60 }} /> : <>
                            {
                                reviews && !!flattenReviews().length && <>
                                    <StyledCourseSectionWrapper style={{ padding: 20 }}>
                                        <StyledChapterTitle><b>Reviews</b><StyledSpan><small style={{ paddingLeft: '5px', fontSize: '10px', fontWeight: 'lighter' }}>({course.stats.totalRatings})</small></StyledSpan></StyledChapterTitle>
                                        <ReviewCards reviews={flattenReviews()} />
                                        {
                                            hasNextPage && <LoadMore loading={isFetchingNextPage} onclick={fetchNextPage} />
                                        }
                                    </StyledCourseSectionWrapper>
                                </>
                            }
                        </>
                    }
                </Group>
            }
            <ShareToSocial open={shareToSocialCanvasOpen} url={course.url} title={course.title} setOpen={canvasState => { setShareToSocialCanvasOpen(canvasState); }} />
            <ReviewTutor open={reviewTutorEditorCanvasOpen} tutor={course.tutor as CourseTutor} setOpen={canvasState => { setReviewTutorEditorCanvasOpen(canvasState); }} />
            <ReviewCourse open={reviewEditorCanvasOpen} enrollment={enrollment} courseTitle={course.title} onSuccess={reviewSubmissionSuccess} setOpen={canvasState => { setReviewCourseEditorCanvasOpen(canvasState); }} />
        </>
    )
}