"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyProfilePages = exports.ProfilePages = void 0;
var ProfilePages;
(function (ProfilePages) {
    ProfilePages["Notifications"] = "notifications";
    ProfilePages["LoginActivities"] = "login-activities";
    ProfilePages["SecuritySettings"] = "security-settings";
    ProfilePages["PersonalInformation"] = "personal-information";
})(ProfilePages || (exports.ProfilePages = ProfilePages = {}));
var CompanyProfilePages;
(function (CompanyProfilePages) {
    CompanyProfilePages["Billing"] = "billing";
    CompanyProfilePages["Themes"] = "theme-settings";
    CompanyProfilePages["CompanyInformation"] = "company-information";
})(CompanyProfilePages || (exports.CompanyProfilePages = CompanyProfilePages = {}));
