import styled from 'styled-components';
import { TextInput } from '../../Form';
import { Button } from '../../Form/Button';
import { Group } from '../../Layout/Group/Group';
import { PageFiltersValues } from 'shared-library';
import { AuthContext } from '../../../context/auth';
import { useTheme } from '../../../hooks/use-theme.hook';
import { StyledDropdown, forPhoneOnly, grow } from '../../Elements';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CheckSquare, Filter, Grid, List, Search, Square } from 'react-bootstrap-icons';

interface NavPillProps {
    query?: string;
    hideLayout?: boolean;
    filter?: PageFiltersValues;
    onQueryChange?: (query: string) => void;
    onFiltersChange?: (filter?: PageFiltersValues) => void;
}

const StyledSpan = styled.span`
    ${grow}
    cursor: pointer;
    border-radius: 4px;
    padding: 3px 5px 0px 5px;
    &.active {
        color: ${props => props.theme.colors.primary};
        background: ${props => props.theme.colors.primary4};
    }
    ${forPhoneOnly("margin: 3px 0px;")}
`

export const PageFilters: React.FC<NavPillProps> = ({ query, filter, onQueryChange, onFiltersChange, hideLayout = false }) => {

    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { state, setPreferences } = useContext(AuthContext);
    const [data, setData] = useState({ query: query || "", filter });

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && !((event.target as Node) instanceof SVGElement)) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [data]);

    const _onQueryChange = (query: string) => {
        setData({ ...data, query })
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onQueryChange && onQueryChange(data.query)
        }
    };

    return (
        <Group direction='row' gap='xs' style={{ position: "relative" }}>
            <TextInput
                label=""
                value={data.query}
                rightIcon={<Search />}
                placeholder='Search...'
                onChange={_onQueryChange}
                onKeyDown={handleKeyPress}
            />

            <div style={{ position: 'relative' }} ref={dropdownRef}>
                <div className='pointer' onClick={() => setIsOpen(true)}>
                    <Group direction='row' align='center' gap='xs' style={{
                        height: '35px',
                        padding: '4px 15px',
                        borderRadius: '3px',
                        color: theme.colors.grey,
                        backgroundColor: theme.colors.white,
                        border: '1px solid ' + theme.colors.gray,
                    }}>
                        <Filter />
                        <small style={{ paddingTop: '2px' }}>Filters</small>
                    </Group>
                </div>
                {
                    isOpen && <StyledDropdown style={{ top: "55px" }}>
                        {
                            [
                                {
                                    text: "Latest First",
                                    value: PageFiltersValues.latestFirst,
                                    icon: data.filter === PageFiltersValues.latestFirst ? <CheckSquare /> : <Square />,
                                },
                                {
                                    text: "Latest Last",
                                    value: PageFiltersValues.latestLast,
                                    icon: data.filter === PageFiltersValues.latestLast ? <CheckSquare /> : <Square />,
                                },
                                {
                                    text: "Alphabetical (a-z)",
                                    value: PageFiltersValues.azSort,
                                    icon: data.filter === PageFiltersValues.azSort ? <CheckSquare /> : <Square />
                                },
                                {
                                    text: "Alphabetical (z-a)",
                                    value: PageFiltersValues.zaSort,
                                    icon: data.filter === PageFiltersValues.zaSort ? <CheckSquare /> : <Square />
                                }
                            ].map(({ icon, text, value }) => <p key={value} onClick={() => setData({ ...data, filter: value })}>
                                {icon}
                                {text}
                            </p>)
                        }
                        <Group style={{
                            padding: '10px 8px 4px 8px'
                        }}>
                            <Button text='Filter' onClick={() => {
                                onFiltersChange && onFiltersChange(data.filter)
                                setIsOpen(false)
                            }} />
                        </Group>
                    </StyledDropdown>
                }
            </div>
            {
                !hideLayout && <Group direction='row' align='center' gap='xs' style={{
                    height: '35px',
                    padding: '0 4px',
                    borderRadius: '3px',
                    color: theme.colors.grey,
                    backgroundColor: theme.colors.white,
                    border: '1px solid ' + theme.colors.gray,
                }}>
                    <StyledSpan onClick={() => setPreferences({ ...state.preferences, grid: true })} className={`${state.preferences.grid ? 'active' : ''}`}><Grid /></StyledSpan>
                    <StyledSpan onClick={() => setPreferences({ ...state.preferences, grid: false })} className={`${!state.preferences.grid ? 'active' : ''}`}><List /></StyledSpan>
                </Group>
            }
        </Group>
    );
};