import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { NotFoundError } from "../ErrorPage/Errors";
import { TopMenu } from "../../components/App/TopMenu";
import { Page } from "../../components/Layout/Page/Page";
import { CourseGroupResourceTypes } from 'shared-library';
import { Group } from "../../components/Layout/Group/Group";
import { StyledContentWrapper } from "../../components/Elements";
import { FolderNavigation } from "../../components/App/FolderNavigation/FolderNavigation";

export const AdminUploadsNew = () => {

    const { _id } = useParams()
    const { state } = useContext(AuthContext);
    const id = _id as keyof typeof CourseGroupResourceTypes

    if (![
        "DOCUMENTS",
        "AUDIOS",
        "VIDEOS",
        "PRESENTATIONS",
        "PHOTOS",
    ].includes(id)) {
        return <NotFoundError />;
    }

    return (
        <Page>
            <StyledContentWrapper>
                <Group>
                    <TopMenu className="slideDown" hideNotification titles={[{
                        text: 'Uploads',
                        link: `/${state.user?.info.session.role}/uploads`
                    }, {
                        text: id,
                    }]} />
                    <FolderNavigation isPage={true} folderId={id} />
                </Group>
            </StyledContentWrapper>
        </Page>
    );
};