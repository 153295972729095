export const downloadFile = (url: string, fileName?: string, newTab?: boolean) => {
    // const blob = new Blob([content], { type: 'application/octet-stream' });
    // const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    if (fileName) {
        a.download = fileName;
    }
    document.body.appendChild(a);
    if (newTab) {
        a.setAttribute("target", "_blank")
    }
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};