import Moment from "react-moment";
import { useContext } from "react";
import styled from "styled-components";
import { Group } from "../../Layout/Group/Group";
import { TopMenuProfile } from "./TopMenuProfile";
import { AuthContext } from "../../../context/auth";
import { Pencil, PlusLg } from "react-bootstrap-icons";
import { StyledH4, StyledNavLink, boxShadow } from "../../Elements";
import { AppNotifications } from "../AppNotifications/AppNotifications";

interface TopMenuProps {
    titles: Array<{
        text: string;
        link?: string;
    }>;
    className?: string;
    hideProfile?: boolean;
    hideNotification?: boolean;
    newItemLink?: string | ((open: boolean) => void);
    editItemLink?: string | ((open: boolean) => void);
}

const StyledPageTitle = styled(StyledH4)`
    text-transform: none;
    color: ${(props) => props.theme.colors.black};

    a {
        color: ${(props) => props.theme.colors.black};
    }

    &.last {
        font-size: 22px;
        line-height: 24px;
        color: ${(props) => props.theme.colors.grey};
    }
`

const StyledPageDesc = styled.p`
    margin-top: -7px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.primary};
`

const StyledMenuPlus = styled.div`
    border-radius: 4px;
    padding: 10px 12px 6px 12px;
    color: ${(props) => props.theme.colors.white};
    align-items: center;
    ${(props) => boxShadow(props.theme.colors.primary2)}
    background-color: ${(props) => props.theme.colors.primary};
`

const StyledMenuPencil = styled.div`
    border-radius: 4px;
    padding: 10px 12px 6px 12px;
    align-items: center;
    color: ${(props) => props.theme.colors.white};
    ${(props) => boxShadow(props.theme.colors.secondary2)}
    background-color: ${(props) => props.theme.colors.secondary};
`

export const TopMenu = (props: TopMenuProps) => {

    const { state } = useContext(AuthContext)
    const { hideProfile = false, hideNotification = false, className, titles, newItemLink, editItemLink } = props

    return (
        <Group direction="row" className={className} wrap="nowrap" justify="space-between" style={{ height: '100%' }}>
            <Group gap="xs">
                <Group direction="row" wrap="nowrap" align="center" gap="xs">
                    {
                        titles.map((title, index) => <StyledPageTitle key={index} className={!title.link && titles.length > 1 && index === props.titles.length - 1 ? 'last' : ''}>
                            {
                                title.link ? <Group direction="row" gap="xs" wrap="nowrap"><StyledNavLink to={title.link}>{title.text}</StyledNavLink> / </Group> : <span className="truncate">{title.text}</span>
                            }
                        </StyledPageTitle>)
                    }
                </Group>
                {
                    titles.length > 0 && <StyledPageDesc>
                        <Moment format={state.user?.info.user.dateFormat} />
                    </StyledPageDesc>
                }
            </Group>
            <Group direction="row" wrap="nowrap" align="center" gap="smm">
                {
                    newItemLink ? <>
                        {
                            typeof newItemLink === typeof "" ? <StyledNavLink to={newItemLink as string}>
                                <StyledMenuPlus>
                                    <PlusLg size={20} />
                                </StyledMenuPlus>
                            </StyledNavLink> : <StyledMenuPlus className="pointer" onClick={() => typeof newItemLink === "function" ? newItemLink(true) : ""}>
                                <PlusLg size={20} />
                            </StyledMenuPlus>
                        }
                    </> : <>
                        {
                            editItemLink && <>
                                {
                                    typeof editItemLink === typeof "" ? <StyledNavLink to={editItemLink as string}>
                                        <StyledMenuPencil>
                                            <Pencil size={20} />
                                        </StyledMenuPencil>
                                    </StyledNavLink> : <StyledMenuPencil className="pointer" onClick={() => typeof editItemLink === "function" ? editItemLink(true) : ""}>
                                        <Pencil size={20} />
                                    </StyledMenuPencil>
                                }
                            </>
                        }
                    </>
                }
                {
                    !hideProfile && <TopMenuProfile />
                }
                {
                    !hideNotification && <AppNotifications />
                }
            </Group>
        </Group>
    );
};
