import moment from 'moment';
import parse from 'html-react-parser';
import styled from "styled-components";
import { Charts } from '../Charts/Charts';
import { TestAttempt } from "shared-library";
import { TestCard } from "../Cards/TestCard";
import { Page } from "../../Layout/Page/Page";
import { useNavigate } from 'react-router-dom';
import { ProgressStep } from "../ProgressStep";
import { useApi } from "../../../hooks/use-api";
import { Group } from "../../Layout/Group/Group";
import styles from './TestResponder.module.scss';
import { useMutation } from "@tanstack/react-query";
import { AuthContext } from "../../../context/auth";
import { TopMenu, TopMenuProfile } from "../TopMenu";
import { useTheme } from '../../../hooks/use-theme.hook';
import { FC, useContext, useEffect, useState } from "react";
import { OutlineButton, StepButton } from '../../Form/Button';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { RadioButtonInput } from '../../Form/RadioButton/RadioButton';
import { ArrowLeft, ArrowRepeat, ArrowRight, Forward, HourglassSplit } from "react-bootstrap-icons";
import { forPhoneOnly, StyledContentWrapper, StyledH5, StyledTitle, StyledTitleDesc, StyledTitleDescDiv } from "../../Elements";
import { NotFoundError } from '../../../pages/ErrorPage/Errors';

const StyledGroup = styled(Group)`
    .left {
        flex-basis: 35%;
        ${forPhoneOnly("flex-basis: 100%;")}
    }
`

export const TestResponder: FC<{ attempt: TestAttempt }> = ({ attempt }) => {

    const navigate = useNavigate();
    const { state } = useContext(AuthContext);

    if (!attempt.test) return <NotFoundError />

    return (<Page>
        <StyledGroup direction="row">
            <StyledContentWrapper className="left slideInLeft">
                <Group>
                    <TopMenu hideProfile hideNotification titles={[{
                        text: 'Tests',
                        link: `/${state.user?.info.session.role}/tests`
                    }, {
                        text: attempt.isPreview ? 'Preview' : attempt.test.title,
                    }]} />
                    <Group gap="xs">
                        <br />
                        <br />
                        <TestCard active inProgress test={{
                            title: attempt.test.title,
                            status: attempt.test.status,
                            creationDate: attempt.test.creationDate,
                        }} key={attempt.test.id} />
                    </Group>
                </Group>
            </StyledContentWrapper>
            <Group className="slideInRight" style={{ flex: 1 }}>
                <Group direction="row" align="center" justify="end">
                    <TopMenuProfile />
                </Group>
                <br />
                <Group style={{ marginBottom: '20px', padding: '20px' }}>
                    <TestFrame attempt={attempt} onTestFinish={() => navigate(`/${state.user?.info.session.role}/tests`)} />
                </Group>
            </Group>
        </StyledGroup>
    </Page>
    );
}


export const TestFrame = ({ attempt, onTestFinish }: { attempt: TestAttempt; onTestFinish?: (ta: TestAttempt) => void }) => {

    const theme = useTheme();
    const { state } = useContext(AuthContext);
    const [timeLeft, setTimeLeft] = useState<number>();
    const [restarting, setRestarting] = useState(false);
    const { submitTestAnswer, getTestAttempt } = useApi();
    const [testAttempt, setTestAttempt] = useState(attempt);
    let { isPending, mutateAsync } = useMutation({
        mutationFn: submitTestAnswer
    })

    useEffect(() => {
        if (testAttempt.currentQuestion.timeLeftInSeconds) {
            setTimeLeft(Date.now() + (testAttempt.currentQuestion.timeLeftInSeconds * 1000))
        }
    }, [testAttempt.currentQuestion.timeLeftInSeconds])

    const retakeTest = async () => {
        if (!restarting) {
            setRestarting(true)
            const testAttempt = await getTestAttempt({ testId: attempt.testId, courseId: attempt.courseId })
            setTestAttempt(testAttempt)
            setRestarting(false)
            if (testAttempt.currentQuestion.timeLeftInSeconds) {
                setTimeLeft(Date.now() + (testAttempt.currentQuestion.timeLeftInSeconds * 1000))
            }
        }
    }

    useEffect(() => {
        if (testAttempt.currentQuestion.timeLeftInSeconds) {
            const targetTime = moment().add(testAttempt.currentQuestion.timeLeftInSeconds, 'seconds');
            const interval = setInterval(() => {
                const duration = moment.duration(targetTime.diff(moment()));
                if (duration.asMilliseconds() <= 0) {
                    clearInterval(interval);
                    onFinishClick("time-out")
                }
            }, 1000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testAttempt.currentQuestion.timeLeftInSeconds, testAttempt.id]);

    const onSelect = async (selected: boolean, optionId: string) => {
        let answers = selected ? (testAttempt.currentQuestion.question.answersCount === 1 ? [optionId] : [...testAttempt.currentQuestion.question.answers, optionId]) : testAttempt.currentQuestion.question.answers.filter((oid) => oid !== optionId)
        if (!testAttempt.test || (!testAttempt.test.settings.allowAnswerChange && testAttempt.currentQuestion.question.answersCount === testAttempt.currentQuestion.question.answers.length)) {
            // Do not update
            answers = testAttempt.currentQuestion.question.answers
        }

        setTestAttempt((selTest) => ({
            ...selTest,
            currentQuestion: {
                ...selTest.currentQuestion,
                question: {
                    ...selTest.currentQuestion.question,
                    answers
                }
            }
        }))

        try {
            if (testAttempt.test && testAttempt.test.settings.autoShowCorrectAnswerAfterAnswering && answers.length === testAttempt.currentQuestion.question.answersCount) {
                const updatedTestAttempt = await mutateAsync({
                    answers,
                    attemptId: testAttempt.id,
                    actionType: "get-answers",
                    testId: testAttempt.testId,
                    questionId: testAttempt.currentQuestion.question.questionId,
                })
                setTestAttempt(updatedTestAttempt)
            }
        } catch (error) {
        }
    }

    const onNextClick = async () => {
        try {
            const updatedTestAttempt = await mutateAsync({
                attemptId: testAttempt.id,
                actionType: "next-question",
                testId: testAttempt.testId,
                answers: testAttempt.currentQuestion.question.answers,
                questionId: testAttempt.currentQuestion.question.questionId,
            })
            setTestAttempt(updatedTestAttempt)
        } catch (error) {
        }
    }

    const onPrevClick = async () => {
        try {
            const updatedTestAttempt = await mutateAsync({
                attemptId: testAttempt.id,
                actionType: "prev-question",
                testId: testAttempt.testId,
                answers: testAttempt.currentQuestion.question.answers,
                questionId: testAttempt.currentQuestion.question.questionId,
            })
            setTestAttempt(updatedTestAttempt)
        } catch (error) {
        }
    }

    const onFinishClick = async (actionType: "submit" | "time-out" = "submit") => {
        if (isPending) return
        try {
            const updatedTestAttempt = await mutateAsync({
                actionType,
                attemptId: testAttempt.id,
                testId: testAttempt.testId,
                answers: testAttempt.currentQuestion.question.answers,
                questionId: testAttempt.currentQuestion.question.questionId,
            })
            setTestAttempt(updatedTestAttempt)
        } catch (error) {
        }
    }

    const countdownRenderer = ({ hours, minutes, seconds, completed }: CountdownRenderProps) => {
        if (completed) {
            // Render a complete state
            return <span>Time's up!</span>;
        } else {
            // Render a countdown
            return (
                <span>
                    {hours > 0 ? `${hours}h : ` : ""}
                    {minutes > 0 ? `${minutes}m : ` : ""}
                    {seconds}s
                </span>
            );
        }
    }

    if (!attempt.test || !testAttempt.test) return <NotFoundError />

    return (
        <Group align="center" justify="center">
            {
                testAttempt.completed ? <Group gap='xs' align='center'>
                    <div style={{
                        width: '150px',
                        height: '150px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundImage: 'url(/assets/images/finishLine.png)'
                    }}></div>
                    <Group gap='none' align='center'>
                        <StyledH5 style={{ color: theme.colors.success }}><b>Congrats!</b></StyledH5>
                        <StyledH5>
                            {testAttempt.test.title}
                        </StyledH5>
                        <StyledTitleDescDiv className='short'>
                            {parse(testAttempt.test.description)}
                        </StyledTitleDescDiv>
                    </Group>
                    <Group gap='none' align='center'>
                        <StyledTitleDescDiv style={{ paddingBottom: '10px' }}>
                            Awesome <b>{state.user?.nickName}</b>, you did great!
                        </StyledTitleDescDiv>
                        <Charts
                            type="doughnut"
                            color={testAttempt.finalScoreInPercentage >= testAttempt.test.settings.passMarkInPercentage ? theme.colors.success : (testAttempt.finalScoreInPercentage < testAttempt.test.settings.passMarkInPercentage - 20 ? theme.colors.danger : theme.colors.secondary)}
                            percentage={testAttempt.finalScoreInPercentage}
                            label="overall score" />
                    </Group>
                    <Group direction='row' align='center' gap='sm'>
                        {
                            testAttempt.attemptCount < testAttempt.test.settings.maxAttempt && <OutlineButton text="Retake" loading={restarting} rightIcon={<ArrowRepeat />} onClick={retakeTest} color="success" />
                        }
                        {
                            onTestFinish && <OutlineButton text="Continue" rightIcon={<Forward />} onClick={() => onTestFinish(testAttempt)} color="primary" />
                        }
                    </Group>
                </Group> :
                    <>
                        {
                            timeLeft && <Group direction='row' gap='xs' align='center'>
                                <HourglassSplit size={20} color={theme.colors.secondary} />
                                <StyledTitleDesc>
                                    <Countdown date={timeLeft} renderer={countdownRenderer} />
                                </StyledTitleDesc>
                            </Group>
                        }
                        <StyledTitle>
                            {testAttempt.test.title}
                        </StyledTitle>
                        <ProgressStep totalSteps={testAttempt.nextQuestions.length + testAttempt.previousQuestions.length + 1} active={testAttempt.previousQuestions.length + 1} />
                        <StyledTitleDescDiv className={`${testAttempt.test.settings.disableTextCopy ? 'disableSelect' : ''}`}>
                            {parse(testAttempt.currentQuestion.question.question)}
                        </StyledTitleDescDiv>
                        <Group align='center' gap='md' className={isPending ? styles.busy : ''}>
                            <Group className={testAttempt.currentQuestion.question.completed ? styles.busy : ''}>
                                {
                                    testAttempt.currentQuestion.question.options.map(option => <RadioButtonInput key={option.optionId}
                                        label={option.option}
                                        onChange={selected => onSelect(selected, option.optionId)}
                                        value={!!option.isAnswer || testAttempt.currentQuestion.question.answers.includes(option.optionId)}
                                        theme={option.isAnswer ? 'success' : (testAttempt.currentQuestion.question.answers.includes(option.optionId) && option.isAnswer === false) ? 'danger' : 'primary'}
                                        disabled={!testAttempt.test?.settings.allowAnswerChange && !testAttempt.currentQuestion.question.answers.includes(option.optionId) && testAttempt.currentQuestion.question.answersCount === testAttempt.currentQuestion.question.answers.length}
                                    />)
                                }
                            </Group>
                            <Group direction="row" gap="xs">
                                {
                                    testAttempt.test.settings.allowBackNavigation && <StepButton leftIcon={<ArrowLeft />} disabled={!testAttempt.currentQuestion.previousQuestionId} onClick={onPrevClick} text="Prev" />
                                }
                                {
                                    testAttempt.currentQuestion.nextQuestionId ?
                                        <StepButton rightIcon={<ArrowRight />} disabled={(!testAttempt.test.settings.allowForwardNavigationWithUnansweredPreviousQuestions && testAttempt.currentQuestion.question.answers.length !== testAttempt.currentQuestion.question.answersCount)} onClick={onNextClick} text="Next" /> :
                                        <StepButton rightIcon={<ArrowRight />} disabled={testAttempt.currentQuestion.question.answers.length !== testAttempt.currentQuestion.question.answersCount} onClick={() => onFinishClick()} text="Finish" />
                                }
                            </Group>
                        </Group>
                    </>
            }
        </Group>
    )
}