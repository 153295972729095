export const parseResponse = async (response: Response) => {
  const { status, statusText } = response;
  if (status === 500) throw statusText;
  try {
    const data = await response.json();
    return { data, status };
  } catch (e) {
    return { data: [], status };
  }
};
