import { useContext } from "react";
import styled from "styled-components";
import styles from "./Navbar.module.scss";
import { Link, NavLink } from "react-router-dom";
import { DoorClosed } from "react-bootstrap-icons";
import { AuthContext } from "../../../context/auth";
import { StyledIcon, forPhoneOnly } from "../../Elements";
import { CompanyContext } from "../../../context/company";

interface MenuLink {
    to: string;
    name: string;
}

const menuLinks: MenuLink[] = [];

const StyledNav = styled.nav`
    color: ${(props) => props.theme.colors.gray4};
    background-color: ${(props) => props.theme.colors.white};

    .inner {
        ${forPhoneOnly("padding: 0 1rem;")}
    }

    ul {
        li {
            a {
                color: ${(props) => props.theme.colors.gray5};
                &.active {
                    background-color: ${(props) => props.theme.colors.gray};
                }
            }
        }
    }
`

export const Navbar = () => {
    const { logout, state } = useContext(AuthContext);
    const { state: AppCompany } = useContext(CompanyContext)

    return (
        <StyledNav className={styles.navMenu}>
            <div className={`inner ${styles.inner}`}>
                <Link to="/" className={styles.navLogo} role="logo">
                    <img src={AppCompany.company?.logos.sm} width="100%" alt={AppCompany.company?.name + " logo"} />
                </Link>

                {state.user && (
                    <ul>
                        {menuLinks.map(({ to, name }) => {
                            return (
                                <li key={to}>
                                    <NavLink to={to} className={({ isActive }) => (isActive ? styles.active : "")}>
                                        {name}
                                    </NavLink>
                                </li>
                            );
                        })}

                        <li className={styles.logOut}>
                            <StyledIcon title="Logout" onClick={logout}>
                                <DoorClosed />
                            </StyledIcon>
                        </li>
                    </ul>
                )}
            </div>
        </StyledNav>
    );
};
