"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statsPercentageCalculator = exports.percentageCalculator = void 0;
const percentageCalculator = (progress, total) => {
    if (total <= 0 || progress <= 0 || progress > total)
        return 0;
    const percentage = (progress / total) * 100;
    return percentage % 1 !== 0 ? parseFloat(percentage.toFixed(2)) : percentage;
};
exports.percentageCalculator = percentageCalculator;
const statsPercentageCalculator = (current, prev) => {
    if (!prev && current)
        return 100;
    if (prev && !current)
        return -100;
    if (prev >= current)
        return (0, exports.percentageCalculator)(current, prev);
    return 100 + (0, exports.percentageCalculator)(prev, current);
};
exports.statsPercentageCalculator = statsPercentageCalculator;
