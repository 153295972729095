"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pluralize = void 0;
const pluralize = (word, n) => {
    const logic = () => {
        // Words ending in 'y' with a consonant before it: change 'y' to 'ies'
        if (word.endsWith('y') && !['a', 'e', 'i', 'o', 'u'].includes(word[word.length - 2].toLowerCase())) {
            return word.slice(0, -1) + 'ies';
        }
        // Words ending in 's', 'x', 'z', 'ch', 'sh': add 'es'
        if (['s', 'x', 'z', 'ch', 'sh'].some(suffix => word.endsWith(suffix))) {
            return word + 'es';
        }
        // Add 's' to other words
        return word + 's';
    };
    return n > 1 ? logic() : word;
};
exports.pluralize = pluralize;
