import { CSSProperties, ReactNode } from "react";

interface ItemProps {
  flex?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

export const Item = ({ flex = "1", style = {}, children }: ItemProps) => {
  const inlineStyle = { ...style, flex };
  return <div style={inlineStyle}>{children}</div>;
};
