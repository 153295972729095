import { FC } from "react";
import { OffCanvas } from "react-offcanvas";
import { useCopyToClipboard } from "react-use";
import { Group } from "../../Layout/Group/Group";
import { Copy, Share, X } from "react-bootstrap-icons";
import { useTheme } from "../../../hooks/use-theme.hook";
import { StyledOffCanvasMenu, StyledH5, StyledHR, StyledCreationWrapper, StyledIcon, StyledSpan, StyledBadge } from "../../Elements";
import { FacebookShareButton, LinkedinShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton, TwitterIcon, FacebookIcon, WhatsappIcon, TelegramIcon, RedditIcon, LinkedinIcon } from "react-share";

export const ShareToSocial: FC<{ open: boolean; setOpen: (open: boolean) => void; onSuccess?: () => void; url: string; title: string; }> = ({ title, open, setOpen, onSuccess, url }) => {

    const theme = useTheme();
    const [state, copyToClipboard] = useCopyToClipboard();

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <Share size={20} style={{ marginRight: '5px' }} /> Share to Social
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper gap="sm" align="center">
                        <StyledBadge>{title}</StyledBadge>
                        <Group direction="row" gap="xs">
                            <FacebookShareButton
                                title={title}
                                url={url}
                            >
                                <FacebookIcon size={32} round={true}></FacebookIcon>
                            </FacebookShareButton>

                            <TwitterShareButton
                                title={title}
                                url={url}
                            >
                                <TwitterIcon size={32} round={true}></TwitterIcon>
                            </TwitterShareButton>

                            <WhatsappShareButton
                                title={title}
                                url={url}
                            >
                                <WhatsappIcon size={32} round={true}></WhatsappIcon>
                            </WhatsappShareButton>

                            <TelegramShareButton
                                title={title}
                                url={url}
                            >
                                <TelegramIcon size={32} round={true}></TelegramIcon>
                            </TelegramShareButton>

                            <RedditShareButton
                                title={title}
                                url={url}
                            >
                                <RedditIcon size={32} round={true}></RedditIcon>
                            </RedditShareButton>

                            <LinkedinShareButton
                                title={title}
                                url={url}
                            >
                                <LinkedinIcon size={32} round={true}></LinkedinIcon>
                            </LinkedinShareButton>
                        </Group>
                        <Group direction="row" align="center" wrap="nowrap" style={{
                            padding: '4px 10px',
                            borderRadius: 4,
                            border: `1px solid ${theme.colors.gray}`
                        }} justify="space-between">
                            <StyledSpan className="truncate">{url}</StyledSpan>
                            <StyledIcon className="small" onClick={() => copyToClipboard(url)}>
                                <StyledSpan>
                                    <Copy />
                                </StyledSpan>
                            </StyledIcon>
                        </Group>
                        {state.error ? <p>Unable to copy value: {state.error.message}</p> : null}
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}