"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flattenArrayOfObjects = void 0;
const flattenArrayOfObjects = (arr) => {
    return arr.reduce((acc, obj) => {
        Object.keys(obj).forEach((key) => {
            acc[key] = obj[key];
        });
        return acc;
    }, {});
};
exports.flattenArrayOfObjects = flattenArrayOfObjects;
