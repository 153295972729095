import styled from 'styled-components';
import { XLg } from 'react-bootstrap-icons';
import { Group } from '../../Layout/Group/Group';
import React, { useState, ReactNode, CSSProperties } from 'react';
import { StyledHR, StyledSpan, boxShadow, forPhoneOnly, grow } from '../../Elements';

type Margin = "sm" | "md";

const gapMarginMap: Record<Margin, CSSProperties> = {
    sm: { top: "40px" },
    md: { top: "55px" },
};

interface PopUpFormProps {
    margin?: Margin;
    title: ReactNode;
    children: ReactNode;
    controller: ReactNode;
    direction?: "end" | "start";
    controllerDisabled?: boolean;
    stateUpdate?: (state: boolean) => void;
}

const StyledFormContainer = styled.div`
    ${grow}
    z-index: 1;
    width: 350px;     
    padding: 10px; 
    position: absolute; 
    ${(props) => boxShadow(props.theme.colors.primary4)}
    background-color: ${(props) => props.theme.colors.white};
    ${forPhoneOnly("right: -47px;  width: 330px;  top: 50px;")}
`

const StyledController = styled.div`
    &.disabled {
        opacity: 0.5;
    }
`

export const PopUpForm = (props: PopUpFormProps) => {

    const { direction = "end" } = props

    const [isOpen, setIsOpen] = useState(false);
    const closeParent = () => updateState(false);
    const updateState = (to: boolean) => {
        setIsOpen(to);
        props.stateUpdate && props.stateUpdate(to)
    }

    return (
        <div style={{ position: 'relative' }}>
            <StyledController className={`${props.controllerDisabled ? 'disabled' : 'pointer'}`} onClick={() => !props.controllerDisabled && updateState(true)}>
                {props.controller}
            </StyledController>
            {isOpen && (
                <StyledFormContainer style={{ ...(props.margin ? gapMarginMap[props.margin] : {}), ...(direction === "end" ? { right: '-15px' } : { left: '-15px' }) }}>
                    <Group direction='row' align='center' justify='space-between'>
                        {props.title}
                        <StyledSpan className='pointer' onClick={() => updateState(false)}>
                            <XLg size={22} />
                        </StyledSpan>
                    </Group>
                    <StyledHR />
                    {
                        React.Children.map(props.children, child => {
                            if (React.isValidElement(child)) {
                                return React.cloneElement<any>(child, { closeParent });
                            }
                            return child;
                        })
                    }
                </StyledFormContainer>
            )}
        </div>
    );
};

