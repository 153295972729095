import { grow } from '../../Elements';
import styled from 'styled-components';
import React, { ReactNode } from 'react';

interface NavPillProps {
    tabs: Array<{
        id: string;
        text: ReactNode;
        icon?: ReactNode;
        onclick: (id: string) => void;
    }>
    active: string;
}

const StyledButton = styled.button`
    border: none;
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 8px 14px;
    border-radius: 4px;
    color: ${props => props.theme.colors.primary};
    background-color: transparent;
    font-size: small;
    ${grow}
    &.active {
        background-color: ${props => props.theme.colors.primary4};
    }
`

const StyledNavContainer = styled.div`
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`

export const NavPills: React.FC<NavPillProps> = (props) => {
    return (
        <StyledNavContainer>
            {props.tabs.map((tab) => (
                <StyledButton
                    key={tab.id}
                    className={props.active === tab.id ? 'active' : ''}
                    onClick={() => tab.onclick(tab.id)}
                >
                    {tab.icon}
                    {tab.text}
                </StyledButton>
            ))}
        </StyledNavContainer>
    );
};