"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeIds = void 0;
var ThemeIds;
(function (ThemeIds) {
    ThemeIds["Nexus"] = "Nexus";
    ThemeIds["Nebula"] = "Nebula";
    ThemeIds["Mirage"] = "Mirage";
    ThemeIds["Elysium"] = "Elysium";
    ThemeIds["Equinox"] = "Equinox";
    ThemeIds["Serenity"] = "Serenity";
    ThemeIds["Radiance"] = "Radiance";
    ThemeIds["Luminary"] = "Luminary";
    ThemeIds["Catalyst"] = "Catalyst";
    ThemeIds["Spectrum"] = "Spectrum";
})(ThemeIds || (exports.ThemeIds = ThemeIds = {}));
