
export class DiskManager {
    private authStorageKey: string
    constructor(authStorageKey: string) {
        this.authStorageKey = authStorageKey
    }

    save(value: string) {
        try {
            localStorage.setItem(this.authStorageKey, value);
        } catch (error) {
            // Local storage is not allowed in cognito mode, try session storage instead
            sessionStorage.setItem(this.authStorageKey, value);
        }
    }

    retrieve(): string | null {
        try {
            return localStorage.getItem(this.authStorageKey);
        } catch (error) {
            // Local storage is not allowed in cognito mode, try session storage instead
        }
        try {
            return sessionStorage.getItem(this.authStorageKey);
        } catch (error) {
            // Local storage is not allowed in cognito mode, try session storage instead
        }
        return null
    }

    delete() {
        try {
            localStorage.removeItem(this.authStorageKey);
        } catch (error) {
            // Local storage is not allowed in cognito mode, try session storage instead
        }
        try {
            sessionStorage.removeItem(this.authStorageKey);
        } catch (error) {
            // Local storage is not allowed in cognito mode, try session storage instead
        }
    }

}