import styled from "styled-components";
import styles from "./Spinner.module.scss";
import { keyboardFocusOnly } from "../../Elements";
import { CSSProperties, MouseEvent, ReactNode } from "react";

interface ButtonProps {
    text?: string;
    icon?: ReactNode;
    rightIcon?: ReactNode;
    color?: "primary" | "danger" | "success" | "secondary";
    disabled?: boolean;
    loading?: boolean;
    style?: CSSProperties;
    type?: "button" | "submit";
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled.button`
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
    padding: 0rem 1rem;
    height: 2.4rem;
    min-width: 160px;
    border: none;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    transition: all 0.2s;
    line-height: 1.2rem;
    text-align: left;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 3px;
    ${keyboardFocusOnly}

    &:hover {
        background-color: ${(props) => props.theme.colors.primary4};
        color: ${(props) => props.theme.colors.white};
        text-decoration: none;
    }
    
    &:active {
        transform: scale(0.99);
        background-color: ${(props) => props.theme.colors.primary4};
    }

    &.success {
        background-color: ${(props) => props.theme.colors.success};
        &:hover {
            background-color: ${(props) => props.theme.colors.success2};
        }
    }

    &.danger {
        background-color: ${(props) => props.theme.colors.danger};
    
        &:hover {
            background-color: ${(props) => props.theme.colors.danger4};
        }
    }

    &.secondary {
        background-color: ${(props) => props.theme.colors.secondary};
    
        &:hover {
            background-color: ${(props) => props.theme.colors.secondary4};
        }
    }

    .text {
        overflow-wrap: break-word;
    }
    
    &:disabled {
        opacity: .6;
        background-color: ${(props) => props.theme.colors.grey};
        color: ${(props) => props.theme.colors.gray5};
        cursor: not-allowed;
    
        .icon {
          svg {
            stroke: ${(props) => props.theme.colors.gray5};
            fill: ${(props) => props.theme.colors.gray5};
    
            path {
              fill: ${(props) => props.theme.colors.gray5};
            }
          }
        }
    
        &.danger {
            color: ${(props) => props.theme.colors.white};
            background-color: ${(props) => props.theme.colors.danger};
    
          &:hover {
            background-color: ${(props) => props.theme.colors.danger4};
          }
        }

        &.success {
            color: ${(props) => props.theme.colors.white};
            background-color: ${(props) => props.theme.colors.success};
      
            &:hover {
              background-color: ${(props) => props.theme.colors.success4};
            }
          }
    
        &:active {
          transform: none;
          background-color: ${(props) => props.theme.colors.gray4};
        }
      }  
`

export const Button = (props: ButtonProps) => {
    const {
        text,
        icon,
        rightIcon,
        disabled = false,
        loading = false,
        color = "primary",
        style = {},
        type = "button",
        onClick,
    } = props;

    const classNames = ["btn", color];

    return (
        <StyledButton
            className={classNames.join(" ")}
            onClick={onClick}
            disabled={disabled}
            style={style}
            type={type}
        >
            {loading && <Spinner />}
            {!loading && icon && <div className="icon">{icon}</div>}
            {!loading && <div className="text">{text}</div>}
            {!loading && rightIcon && <>{rightIcon}</>}
        </StyledButton>
    );
};


const StyledSpinnerDiv = styled.div`
    background: ${(props) => props.theme.colors.white};
`

export const Spinner = () => {
    return (
        <div className={styles.ellipsis}>
            <StyledSpinnerDiv></StyledSpinnerDiv>
            <StyledSpinnerDiv></StyledSpinnerDiv>
            <StyledSpinnerDiv></StyledSpinnerDiv>
            <StyledSpinnerDiv></StyledSpinnerDiv>
        </div>
    );
};
