import { InputContainer } from "../";
import { CSSProperties } from "react";
import styled from "styled-components";
import { growSmaller } from "../../Elements";
import { Group } from "../../Layout/Group/Group";
import { FormComponentProps } from "../../../types/form-component-props";


interface CheckboxInputProps extends FormComponentProps {
    value: boolean;
    error?: string;
    label?: string;
    centered?: boolean;
    description?: string;
    showErrorMsg?: boolean;
    parentStyle?: CSSProperties;
    onChange: (value: boolean) => void;
}

const CheckboxContainer = styled.label`
  cursor: pointer;
  user-select: none;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const CustomCheckbox = styled.div<{ checked: boolean }>`
  ${growSmaller}
  width: 1.5em;
  height: 1.5em;
  border-radius: 4px;
  transition: all 0.3s;
  display: inline-block;
  background: ${(props) => (props.checked ? props.theme.colors.primary4 : props.theme.colors.white)};
  border: 1px solid ${(props) => (props.checked ? props.theme.colors.primary4 : props.theme.colors.primary2)};
`;

const CheckmarkIcon = styled.svg<{ checked: boolean }>`
  fill: none;
  stroke-width: 1px;
  stroke: ${(props) => props.theme.colors.primary};
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
`;


const Label = styled.div`
  font-weight: 300;
  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.gray4};
`;

export const CheckBoxInput = (props: CheckboxInputProps) => {
    const {
        value,
        label,
        error,
        onChange,
        description,
        centered = true,
        showErrorMsg = true,
    } = props;

    return (
        <InputContainer
            description={description}
            error={showErrorMsg ? error : ""}
            style={props.parentStyle}
            render={(ID) => (
                <CheckboxContainer id={ID}>
                    <HiddenCheckbox checked={value} onChange={() => onChange(!value)} />
                    <Group direction="row" justify={centered ? "center" : "start"} align="center" gap="sm">
                        <CustomCheckbox checked={value}>
                            <CheckmarkIcon
                                viewBox="0 0 24 24"
                                checked={value}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <polyline points="20 6 9 17 4 12" />
                            </CheckmarkIcon>
                        </CustomCheckbox>
                        {
                            label && <Label>{label}</Label>
                        }
                    </Group>
                </CheckboxContainer>
            )}
        />
    );
};
