"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnrollmentActions = exports.EnrollmentStatus = void 0;
var EnrollmentStatus;
(function (EnrollmentStatus) {
    EnrollmentStatus["PENDING"] = "pending";
    EnrollmentStatus["REJECTED"] = "rejected";
    EnrollmentStatus["APPROVED"] = "approved";
})(EnrollmentStatus || (exports.EnrollmentStatus = EnrollmentStatus = {}));
var EnrollmentActions;
(function (EnrollmentActions) {
    EnrollmentActions["Unlock"] = "unlock";
    EnrollmentActions["Payment"] = "payment";
    EnrollmentActions["Approval"] = "approval";
})(EnrollmentActions || (exports.EnrollmentActions = EnrollmentActions = {}));
