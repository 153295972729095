import { useParams } from "react-router-dom";
import { useApi } from "../../hooks/use-api";
import { useQuery } from "@tanstack/react-query";
import { NotFoundError } from "../ErrorPage/Errors";
import { Spinner } from "../../components/Spinner/Spinner";
import { TestResponder } from "../../components/App/TestResponder/TestResponder";

export const AdminTestsPreview = () => {

    const { getTestAttempt } = useApi()
    const { testId = "" } = useParams()

    const { data: testAttempt, isLoading } = useQuery({
        queryKey: ["testAttempt", testId],
        queryFn: () => getTestAttempt({ testId })
    })

    if (!testAttempt || isLoading) {
        return isLoading ? <Spinner text="Fetching Test" style={{ margin: 60 }} /> : <NotFoundError />
    }

    return (<TestResponder attempt={testAttempt} />);
}
