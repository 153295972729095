import moment from "moment";
import { cloneDeep } from "lodash";
import { validURL } from "shared-library";
import { FormEvent, useCallback, useState } from "react";

const emailRegEx = new RegExp(/.+@.+\..+/);

type Errors<T> = Partial<Record<keyof T, string>>;

interface Validation {
  required?: boolean;
  min?: number;
  max?: number;
  url?: boolean;
  email?: boolean;
  date?: boolean;
}

export type ChildErrors = { [key: string]: string };
type Validations<T> = Partial<Record<keyof T, Validation>>;

interface useFormProps<T> {
  defaultValues: T;
  validations?: Validations<T>;
}

const validateField = (value: unknown, validation: Validation) => {
  const { required, min, max, email, date, url } = validation;

  if (required && !value) return "This field is required";

  if (min && typeof value === "string" && value.length < min)
    return `Must be at least ${min} characters`;

  if (max && typeof value === "string" && value.length > max)
    return `Must not be larger that ${max} characters`;

  if (email && typeof value === "string" && !value.match(emailRegEx))
    return "This must be a valid email address";

  if (url && typeof value === "string" && value && !validURL(value))
    return "This must be a valid URL";

  if (date && typeof value === "string" && value && !moment(value).isValid())
    return "This must be a valid date";
};

export const useForm = <T>({
  defaultValues,
  validations,
}: useFormProps<T>) => {
  const [originalFormValues] = useState<T>(() => cloneDeep(defaultValues));
  const [formValues, setFormValues] = useState<T>(() => defaultValues);
  const [errors, setErrors] = useState<Errors<T>>(() => ({}));

  type valueOf<T> = T[keyof T]

  const onChange = useCallback(
    (key: keyof T) => {
      return (newValue: valueOf<T>) => {
        const validation = validations?.[key];
        if (validation) {
          const validationResult = validateField(newValue, validation);
          setErrors((err) => ({
            ...err,
            ...{ [key]: validationResult },
          }));
        }
        setFormValues((values) => ({
          ...values,
          ...{ [key]: newValue },
        }));
      };
    },
    [validations]
  );

  const validate = useCallback(() => {
    let isValid = true;
    if (validations) {
      for (const key in validations) {
        const value = formValues[key];
        const validation = validations?.[key];
        if (!validation) continue;
        const validationResult = validateField(value, validation);
        if (validationResult) {
          isValid = false;
          setErrors((errs) => ({
            ...errs,
            ...{ [key]: validationResult },
          }));
        }
      }
    }
    return isValid;
  }, [validations, formValues]);

  const onSubmit = useCallback(
    (callback: (formValues: T) => void) => {
      return (e: FormEvent) => {
        e.preventDefault();
        if (validate()) callback(formValues);
      };
    },
    [formValues, validate]
  );

  const reset = useCallback(() => setFormValues(() => cloneDeep(originalFormValues)), [originalFormValues])
  const updateValues = useCallback((d: T) => setFormValues(() => ({ ...formValues, ...d })), [formValues])
  return { onChange, onSubmit, formValues, errors, validate, updateValues, reset };
};

export const validateNested = <T>(values: T, validations: Validations<T>) => {
  const response: {
    valid: boolean;
    errors: { [K in keyof T]?: string };
  } = {
    valid: true,
    errors: {}
  }
  if (validations) {
    for (const key in validations) {
      const value = values[key];
      const validation = validations[key];
      if (!validation) continue;
      const validationResult = validateField(value, validation);
      if (validationResult) {
        response.valid = false;
        response.errors[key] = validationResult;
      }
    }
  }
  return response;
};

export const findError = (errors: Array<ChildErrors>, key: string): string | undefined => {
  const error = errors.find(error => error[key]);
  return error ? error[key] : ""
}

export const childrenHasError = (errors: Array<ChildErrors>, prefix: string): boolean => {
  for (const error of errors) {
    for (const key in error) {
      if (key.startsWith(prefix)) return true
    }
  }
  return false
}