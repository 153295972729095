import { InputContainer } from "../";
import styled from "styled-components";
import styles from "./TextInput.module.scss";
import { CSSProperties, ReactNode } from "react";
import { FormComponentProps } from "../../../types/form-component-props";

type Size = "sm" | "md";

const sizeMap: Record<Size, string> = {
    sm: styles.sm,
    md: styles.md,
};

interface TextInputProps extends FormComponentProps {
    size?: Size;
    value: string;
    type?: string;
    label?: string;
    error?: string;
    readonly?: boolean;
    placeholder?: string;
    isRequired?: boolean;
    description?: string;
    rightIcon?: ReactNode;
    style?: CSSProperties;
    showErrorMsg?: boolean;
    parentStyle?: CSSProperties;
    onChange: (value: string) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const StyledInput = styled.input`
  color: ${(props) => props.theme.colors.gray6};
  border: 1px solid ${(props) => props.theme.colors.gray};

  &::placeholder {
    color: ${(props) => props.theme.colors.gray2};
  }

  &.hasError {
    border-color: ${(props) => props.theme.colors.danger};
  }
`

const StyledTextArea = styled.textarea`
  padding: 10px;
  min-height: 100px;
  color: ${(props) => props.theme.colors.gray6};
  border: 1px solid ${(props) => props.theme.colors.gray};

  &::placeholder {
    color: ${(props) => props.theme.colors.gray2};
  }

  &.hasError {
    border-color: ${(props) => props.theme.colors.danger};
  }
`

export const TextInput = (props: TextInputProps) => {
    const {
        value,
        label,
        error,
        onChange,
        rightIcon,
        onKeyDown,
        style = {},
        isRequired,
        description,
        placeholder,
        size = "md",
        type = "text",
        readonly = false,
        showErrorMsg = true,
    } = props;

    const classNames = `${styles.input} ${sizeMap[size]} ${error && 'hasError'} ${rightIcon && styles.hasrighticon}`;

    return (
        <InputContainer
            label={label}
            rightIcon={rightIcon}
            description={description}
            error={showErrorMsg ? error : ""}
            isRequired={isRequired}
            style={props.parentStyle}
            render={(ID) => (
                <StyledInput
                    id={ID}
                    type={type}
                    style={style}
                    value={value}
                    readOnly={readonly}
                    className={classNames}
                    placeholder={placeholder}
                    onKeyDown={(e) => onKeyDown && onKeyDown(e)}
                    onChange={({ target }) => onChange(target.value)}
                />
            )}
        />
    );
};

export const TextArea = (props: TextInputProps) => {
    const {
        value,
        label,
        error,
        onChange,
        rightIcon,
        style = {},
        isRequired,
        description,
        placeholder,
        size = "md",
        readonly = false,
        showErrorMsg = true,
    } = props;

    const classNames = `${styles.input} ${sizeMap[size]} ${error && 'hasError'} ${rightIcon && styles.hasrighticon}`;

    return (
        <InputContainer
            label={label}
            rightIcon={rightIcon}
            description={description}
            error={showErrorMsg ? error : ""}
            isRequired={isRequired}
            style={props.parentStyle}
            render={(ID) => (
                <StyledTextArea
                    id={ID}
                    style={style}
                    value={value}
                    readOnly={readonly}
                    className={classNames}
                    placeholder={placeholder}
                    onChange={({ target }) => onChange(target.value)}
                />
            )}
        />
    );
};
