import parse from 'html-react-parser';
import styled from "styled-components";
import { useCallback, useState } from "react";
import { useApi } from "../../hooks/use-api";
import { useNavigate } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Page } from "../../components/Layout/Page/Page";
import { StepButton } from "../../components/Form/Button";
import { Spinner } from "../../components/Spinner/Spinner";
import { Group } from "../../components/Layout/Group/Group";
import { Collection, Pencil, Play } from "react-bootstrap-icons";
import { ProgressStep } from "../../components/App/ProgressStep";
import { TestCard } from "../../components/App/Cards/TestCard";
import { LoadMore } from "../../components/App/LoadMore/LoadMore";
import { Test, TestStatus, PageFiltersValues } from "shared-library";
import { TopMenu, TopMenuProfile } from "../../components/App/TopMenu";
import { StyledContentWrapper, StyledTitle, StyledTitleDescDiv, forPhoneOnly } from "../../components/Elements";


const StyledGroup = styled(Group)`
    .left {
        flex-basis: 35%;
        ${forPhoneOnly("flex-basis: 100%;")}
    }
`

export const AdminTests = () => {

    const { getTests } = useApi();
    const navigate = useNavigate();
    const [selectedTest, setSelectedTest] = useState<Test | undefined>();
    const [testFilters] = useState<{
        limit: number;
        query?: string;
        status?: TestStatus;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
    }>({
        limit: 30,
        nextBatchIndex: "",
        filter: PageFiltersValues.latestFirst
    })
    const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getTests({
            nextBatchIndex,
            limit: testFilters.limit,
            query: testFilters.query,
            status: testFilters.status,
            filter: testFilters.filter,
        }),
        initialPageParam: testFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["tests", testFilters.status, testFilters.query, testFilters.limit, testFilters.nextBatchIndex],
    });

    const flattenTests = useCallback(() => {
        let tests: Array<Test> = []
        if (data?.pages) {
            for (const page of data.pages) {
                tests = [...tests, ...page.data]
            }
        }
        return tests
    },
        [data?.pages]
    );

    return (
        <Page>
            <StyledGroup direction="row">
                <StyledContentWrapper className="left slideInLeft">
                    <Group>
                        <TopMenu hideProfile hideNotification titles={[{
                            text: 'Tests',
                        }]} newItemLink={'new'} />
                        <Group gap="xs">
                            <br />
                            <br />
                            {
                                !data || isLoading ? <Spinner text="Fetching Tests" style={{ margin: 60 }} /> :
                                    <>
                                        {
                                            flattenTests().map(test => <TestCard active={test.id === selectedTest?.id} test={test} key={test.id} onClick={() => setSelectedTest(test)} />)
                                        }
                                        {
                                            hasNextPage && <LoadMore loading={isFetchingNextPage} onclick={fetchNextPage} />
                                        }
                                    </>
                            }
                        </Group>
                    </Group>
                </StyledContentWrapper>
                <Group className="slideInRight" style={{ flex: 1 }}>
                    <Group direction="row" align="center" justify="end">
                        <TopMenuProfile />
                    </Group>
                    {
                        !selectedTest ?
                            <Group align="center" justify="center"><img src="/assets/images/select-test-to-continue.png" style={{ width: '350px', opacity: 0.8, marginTop: '100px' }} alt="Select to continue" /></Group> :
                            <Group align="center" justify="center" style={{ padding: '0px 20px' }}>
                                <img src="/assets/images/start-test.png" style={{ width: '230px', marginTop: '100px' }} alt="Start test logo" />
                                <StyledTitle>
                                    {selectedTest.title}
                                </StyledTitle>
                                <ProgressStep totalSteps={selectedTest.questions.length + 1} active={1} />
                                <StyledTitleDescDiv style={{ textAlign: 'center' }}>
                                    {parse(selectedTest.description)}
                                </StyledTitleDescDiv>
                                <Group direction="row" gap="xs">
                                    <StepButton rightIcon={<Pencil />} onClick={() => navigate(`edit/${selectedTest.id}`)} text="Edit" />
                                    <StepButton rightIcon={<Play />} onClick={() => navigate(`preview/${selectedTest.id}`)} text="Preview" />
                                    <StepButton disabled={selectedTest.status === TestStatus.DRAFT} rightIcon={<Collection />} onClick={() => navigate(`responses/${selectedTest.id}`)} text="Responses" />
                                </Group>
                            </Group>
                    }
                </Group>
            </StyledGroup>
        </Page>
    );
}