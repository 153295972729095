import { FileTypes } from "shared-library";
import { FileUploader } from "react-drag-drop-files";
import { useTheme } from "../../../hooks/use-theme.hook";
import styled, { CSSProperties } from 'styled-components';
import { InputContainer } from "../InputContainer/InputContainer";
import { useNotification } from "../../../hooks/use-notification.hook";

interface FileUploadProps {
    name?: string;
    label?: string;
    error?: string;
    disabled?: boolean;
    multiple?: boolean;
    classNames?: string;
    placeholder?: string;
    isRequired?: boolean;
    description?: string;
    style?: CSSProperties;
    showErrorMsg?: boolean;
    types: Array<FileTypes>;
    parentStyle?: CSSProperties;
    onChange: (file: File | FileList) => void;
}

const StyledFileUploader = styled.div`
    .uploadPane {
        z-index: 1;
        border-radius: 3px;
        padding: 30px !important;
        min-width: 100% !important;
        max-width: 100% !important;
        color: ${props => props.theme.colors.grey5} !important;
        border: 1px dashed ${props => props.theme.colors.grey5} !important;
    
        svg {
            margin-right: 20px;
    
            path {
                fill: ${props => props.theme.colors.grey5} !important;
            }
        }
    
        .btn-select-file,
        .btn-select-file:hover {
            color: ${props => props.theme.colors.grey5};
            font-size: 12px;
            background-color: white;
            border: 1px solid ${props => props.theme.colors.grey5};
            margin-top: 5px;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 3px !important;
        }
    }
`

export const FileUpload = (props: FileUploadProps) => {

    const theme = useTheme();
    const { notify } = useNotification();
    const classNames = `${props.error ? 'hasError' : ''} ${props.classNames || ''} uploadPane`;

    return (<InputContainer
        label={props.label}
        description={props.description}
        error={props.showErrorMsg ? props.error : ""}
        isRequired={props.isRequired}
        style={props.parentStyle}
        render={(ID) => (
            <StyledFileUploader id={ID}>
                <FileUploader
                    classes={classNames}
                    multiple={props.multiple}
                    disabled={props.disabled}
                    required={props.isRequired}
                    handleChange={props.onChange}
                    label={props.placeholder}
                    name={props.name}
                    types={props.types}
                    dropMessageStyle={{
                        border: 'none',
                        color: theme.colors.primary,
                        backgroundColor: theme.colors.primary4
                    }}
                    onSizeError={() => notify({ type: "error", title: "File too large!", body: "Selected file is too large." })}
                    onTypeError={() => notify({ type: "error", title: "Invalid file type!", body: "Selected file type is not allowed." })}
                />
            </StyledFileUploader>
        )}
    />);
}
