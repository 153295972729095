import { ReactSortable } from "react-sortablejs";
import { Group } from "../../Layout/Group/Group";
import { ReactNode, CSSProperties } from "react";
import { GripVertical } from "react-bootstrap-icons";
import { useTheme } from "../../../hooks/use-theme.hook";

interface SortableProps {
    nodes: ResourceType[];
    disabled?: boolean;
    style?: CSSProperties;
    onChange: (nodes: ResourceType[]) => void
}

export interface ResourceType {
    id: string;
    node: ReactNode;
}

export const Sortable: React.FC<SortableProps> = (props) => {

    const {
        style,
        disabled,
        nodes,
        onChange
    } = props
    const theme = useTheme()

    return (
        <ReactSortable
            group="groupName"
            animation={200}
            list={nodes}
            disabled={disabled}
            setList={onChange}
            handle={".my-handle"}
            delay={0}
        >
            {
                nodes.map((resource) => (
                    <Group direction="row" wrap="nowrap" style={{ ...style || {} }} gap="xs" align="center" key={resource.id}>
                        <div className="my-handle">
                            <GripVertical style={{ marginTop: '6px', color: theme.colors.grey }} size={16} />
                        </div>
                        {resource.node}
                    </Group>
                ))}
        </ReactSortable>
    );
};
