import { FC } from "react";
import Moment from "react-moment";
import { TestStatus } from "shared-library";
import { useTheme } from "../../../hooks/use-theme.hook";
import { StyledTestGroup, StyledTitleDesc } from "../../Elements";
import { Check2All, ChevronRight, HourglassBottom, Pencil } from "react-bootstrap-icons";

interface TestCardProps {
    test: {
        title: string;
        status: TestStatus;
        creationDate: string;
    };
    active?: boolean;
    inProgress?: boolean;
    onClick?: () => void;
}

export const TestCard: FC<TestCardProps> = ({ onClick, active, test, inProgress }) => {
    const theme = useTheme();

    return (
        <StyledTestGroup className={`${active ? 'active' : ''} ${onClick ? 'pointer' : ''}`} onClick={() => onClick && onClick()}>
            <span className="icon">
                {
                    inProgress ? <HourglassBottom /> : <>
                        {
                            test.status === TestStatus.DRAFT ? <Pencil /> : <Check2All />
                        }
                    </>
                }
            </span>
            <div style={{ flex: 1 }}>
                <b>{test.title || 'Untitled test'}</b>
                <StyledTitleDesc style={{ color: theme.colors.gray3, marginTop: '-4px' }}>
                    {
                        inProgress ? <small><i>In progress...</i></small> : <>
                            {
                                test.status === TestStatus.ACTIVE && <small>Created <Moment fromNow>{test.creationDate}</Moment></small>
                            }
                            {
                                test.status === TestStatus.DRAFT && <small><i>Draft</i></small>
                            }
                        </>
                    }
                </StyledTitleDesc>
            </div>
            <span className="icon-right"><ChevronRight /></span>
        </StyledTestGroup>
    );
};
