import Moment from "react-moment";
import { upperFirst } from "lodash";
import styled from "styled-components";
import { Dropdown } from "../Dropdown";
import { Group } from "../../Layout/Group/Group";
import { AuthContext } from "../../../context/auth";
import { MailEditor } from "../MailEditor/MailEditor";
import { useTheme } from "../../../hooks/use-theme.hook";
import { useNotify } from "../../../hooks/use-notify.hook";
import { FC, useCallback, useContext, useState } from "react";
import { Tutor, createImageFromInitials, RoleTypes } from "shared-library";
import { EnvelopePlus, ThreeDots, ThreeDotsVertical, Trash } from "react-bootstrap-icons";
import { StyledSpan, StyledTable, StyledTitleDesc, StyledUserPicture, forPhoneOnly, growSmaller } from "../../Elements";


interface TutorCardsProps {
    className?: string;
    tutors: Array<Tutor>;
}

const StyledTutorCardWrapper = styled.div`
    display: flex;
    gap: 0rem;
    flex-wrap: wrap;
    flex-direction: row;
    justify: center;
    margin-bottom: 20px;

    .tutorCardWrapper {
        padding: 0px 6px 10px 6px;
        flex-basis: 25%;
        ${forPhoneOnly("padding: 0px 2px 6px 2px; flex-basis: 100%;")}

        .tutorCard {
            ${growSmaller}
            padding: 32px 14px;
            border-radius: 8px;
            text-align: center;
            position: relative;
            background: ${props => props.theme.colors.white};

            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }
    
            .moreDetails {
                position: absolute;
                right: 20px;
                top: 20px;
                color: ${props => props.theme.colors.grey};
            }
        }
    }
`

const StyledTutorTableWrapper = styled.div`
    max-width: 100% !important;
    ${forPhoneOnly("padding: 8px;overflow-x: auto;")}

    td:nth-child(2) {
        width: 65%;
        ${forPhoneOnly("width: 20%;")}
    }

    td:nth-child(3) {
        width: 80px;
    }
`

export const TutorCards: FC<TutorCardsProps> = (props) => {

    const theme = useTheme();
    const { confirm } = useNotify();
    // const navigate = useNavigate()
    const { state } = useContext(AuthContext);
    const [tutorToMail, setTutorToMail] = useState<Tutor>();
    const [mailEditorCanvasOpen, setMailEditorCanvasOpen] = useState(false)

    const { tutors } = props

    const getOptions = useCallback((tutor: Tutor) => {
        return [
            ...(state.user?.info.session.role !== RoleTypes.STUDENT ? [
                // {
                //     text: "Reviews",
                //     disabled: !tutor.stats.totalRatings,
                //     onClick: () => navigate(`${tutor.sub}/reviews`),
                //     icon: <StarHalf style={{ color: theme.colors.secondary }} />,
                // },
                {
                    icon: <EnvelopePlus style={{ color: theme.colors.success }} />,
                    text: "Email tutor",
                    onClick: () => {
                        setTutorToMail(tutor);
                        setMailEditorCanvasOpen(true)
                    },
                },
                ...(state.user?.info.session.role === RoleTypes.ADMIN ? [
                    {
                        demarcateTop: true,
                        icon: <Trash style={{ color: theme.colors.danger }} />,
                        text: "Delete",
                        onClick: () => confirm({
                            onConfirmation: console.log
                        })
                    }
                ] : []),
            ] : []),
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user?.info.session.role, theme.colors.danger, theme.colors.success])

    return (<Group style={{ marginTop: '30px' }}>
        {
            state.preferences.grid && <StyledTutorCardWrapper className={`${props.className ? props.className : ''} truncate`}>
                {
                    tutors.map(tutor => <Group key={tutor.sub} className="tutorCardWrapper truncate">
                        <Group className="tutorCard" justify="center" align="center">
                            <img src={tutor.picture || createImageFromInitials(tutor.fullName, theme.colors.white, theme.colors.secondary, 150)} alt="Profile" />
                            <div className="moreDetails">
                                <Dropdown margin={"sm"} controller={<>
                                    <ThreeDotsVertical size={22} />
                                </>} options={getOptions(tutor)} />
                            </div>
                            <Group gap="none">
                                <StyledSpan style={{ color: theme.colors.black, fontSize: '18px', fontWeight: 'bold' }} className="truncate">{tutor.fullName}</StyledSpan>
                                <small>{tutor.email}</small>
                                <small>{upperFirst(tutor.status)}</small>
                                <small><Moment date={tutor.creationDate} format="MMM Do, YYYY" /></small>
                            </Group>
                        </Group>
                    </Group>)
                }
            </StyledTutorCardWrapper>
        }

        {
            !state.preferences.grid && <StyledTutorTableWrapper className={`${props.className ? props.className : ''}`}>
                <StyledTable className="hasSnNo">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Details</th>
                            <th>Status</th>
                            <th>Creation Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tutors.map(tutor => <tr key={tutor.sub}>
                                <td style={{ paddingTop: '5px' }}>
                                    <StyledUserPicture src={tutor.picture || createImageFromInitials(tutor.fullName, theme.colors.white, theme.colors.secondary, 40)} alt="Profile" />
                                </td>
                                <td>
                                    <b>{tutor.fullName}</b>
                                    <StyledTitleDesc>{tutor.email}</StyledTitleDesc>
                                </td>
                                <td>
                                    <small>{upperFirst(tutor.status)}</small>
                                </td>
                                <td><small><Moment date={tutor.creationDate} format="MMM Do, YYYY" /></small></td>
                                <td>
                                    <div style={{ position: 'relative', color: theme.colors.grey }}>
                                        <Dropdown margin={"sm"} controller={<>
                                            <ThreeDots size={22} />
                                        </>} options={getOptions(tutor)} />
                                    </div>
                                </td>
                            </tr>)
                        }
                    </tbody>
                </StyledTable>
            </StyledTutorTableWrapper>
        }
        <MailEditor open={mailEditorCanvasOpen} emails={tutorToMail ? [tutorToMail.email] : []} setOpen={canvasState => { setMailEditorCanvasOpen(canvasState); }} />
    </Group>)
}