import moment from 'moment';
import CountUp from "react-countup";
import parse from 'html-react-parser';
import styled from "styled-components";
import { RoleTypes } from "shared-library";
import { useApi } from "../../hooks/use-api";
import { AuthContext } from "../../context/auth";
import { Accordion } from "@szhsin/react-accordion";
import { NotFoundError } from "../ErrorPage/Errors";
import { useTheme } from '../../hooks/use-theme.hook';
import { Page } from "../../components/Layout/Page/Page";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useContext, useState } from "react";
import { Spinner } from "../../components/Spinner/Spinner";
import { Group } from "../../components/Layout/Group/Group";
import { TutorPreview } from '../../components/App/TutorCards';
import { ReviewCards } from "../../components/App/ReviewCards";
import { LoadMore } from "../../components/App/LoadMore/LoadMore";
import { DurationInOptions, LevelOptions } from "../../utils/data";
import { useNotification } from '../../hooks/use-notification.hook';
import { Button, OutlineButton } from "../../components/Form/Button";
import { TopMenu, TopMenuProfile } from "../../components/App/TopMenu";
import { useCurrencySymbol } from '../../hooks/use-currency-symbol.hook';
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { Review, pluralize, CourseLectureResourceTypes, EnrollmentActions, EnrollmentStatus } from "shared-library";
import { ArrowRight, Ban, BarChart, Book, BookFill, CircleFill, ClockFill, CloudDownload, GraphUp, Heart, HeartFill, Key, List, PatchCheck, PeopleFill, Phone, Play, StarFill, Stopwatch } from "react-bootstrap-icons";
import { StyledAccordionItem, StyledBanner, StyledBorderedDiv, StyledContentWrapper, StyledH3, StyledHR, StyledChapterChildTitle, StyledChapterTitle, StyledSmall, StyledSpan, boxShadow, forPhoneOnly, StyledTitleDescDiv } from "../../components/Elements";

const StyledGroup = styled(Group)`
    .left {
        flex-basis: 35%;
        ${forPhoneOnly("flex-basis: 100%;")}
    }
`

const StyledCourseGroup = styled(Group)`
    ${forPhoneOnly("justify-content: center;align-items: center;")}
`

const StyledCourseContentGroup = styled(Group)`
    padding-left: 20px;
    ${forPhoneOnly("padding-left: 0px;")}
    .pane {
        ${forPhoneOnly("flex-basis: 100%;")}
    }
`

const StyledCourseImage = styled.div<{ $url: string, $color: string }>`
    ${props => boxShadow(props.theme.colors.black3)}
    width: 230px;
    height: 150px;
    margin-top: -50px;
    border-radius: 8px;
    border: 5px solid ${props => props.$color};
    background: url(${props => props.$url}) no-repeat center;
    background-size: cover;
    ${forPhoneOnly("margin-top: -80px;")}
`

export const AdminCoursesView = () => {

    const theme = useTheme();
    const navigate = useNavigate();
    const { courseId } = useParams();
    const { notify } = useNotification();
    const { prepPrice } = useCurrencySymbol();
    const { state, setUserInfo } = useContext(AuthContext);
    const { getCourse, getCourseReviews, updateUserinfo, enroll, getUserinfo } = useApi()
    const { data: course, isLoading } = useQuery({
        queryKey: ["course", courseId],
        queryFn: () => getCourse(courseId as string),
    });

    const [reviewsFilters] = useState<{
        limit: number;
        courseId: string;
        nextBatchIndex?: string;
    }>({
        limit: 10,
        courseId: courseId as string,
    })
    const { data: reviews, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getCourseReviews({
            nextBatchIndex,
            limit: reviewsFilters.limit,
            courseId: reviewsFilters.courseId,
        }),
        initialPageParam: reviewsFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["reviews", courseId, reviewsFilters.limit, reviewsFilters.nextBatchIndex],
    });

    const { isPending: mutatingFavoriteCoursesAsync, mutateAsync: mutateFavoriteCoursesAsync } = useMutation({
        mutationFn: updateUserinfo,
    });

    const { isPending: mutatingCourseEnrollmentAsync, mutateAsync: mutateCourseEnrollmentAsync } = useMutation({
        mutationFn: enroll,
    });

    const flattenReviews = useCallback(() => {
        let _reviews: Array<Review> = []
        if (reviews?.pages) {
            for (const page of reviews.pages) {
                _reviews = [..._reviews, ...page.data]
            }
        }
        return _reviews
    },
        [reviews?.pages]
    );

    const getHoursOfOnDemandVideo = useCallback(() => {
        const defaultResponse = "0 min"
        if (!course) return defaultResponse
        let totalSeconds = 0
        for (const chapter of course.curriculum.chapters) {
            for (const lecture of chapter.lectures) {
                for (const item of lecture.resources) {
                    if (item.file?.durationInSeconds && item.lectureResourceType === CourseLectureResourceTypes.VIDEOS) {
                        totalSeconds += item.file?.durationInSeconds
                    }
                }
            }
        }
        if (!totalSeconds) return defaultResponse
        const duration = moment.duration(totalSeconds, "seconds");
        const hours = duration.hours();
        if (hours) return `${Math.ceil(hours)} ${pluralize('hour', Math.ceil(hours))}`
        const minutes = duration.minutes();
        if (minutes) return `${Math.ceil(minutes)} ${pluralize('minute', Math.ceil(minutes))}`
        const seconds = duration.seconds();
        if (seconds) return `${Math.ceil(seconds)} ${pluralize('second', Math.ceil(seconds))}`
        return defaultResponse
    },
        [course]
    );

    const getDownloadableResources = useCallback(() => {
        const defaultResponse = 0
        if (!course) return defaultResponse
        let totalResources = 0
        for (const chapter of course.curriculum.chapters) {
            for (const lecture of chapter.lectures) {
                for (const item of lecture.resources) {
                    if (item.downloadable) {
                        totalResources++
                    }
                }
            }
        }
        return totalResources
    },
        [course]
    );

    const getTotalTests = useCallback(() => {
        const defaultResponse = 0
        if (!course) return defaultResponse
        let totalTests = 0
        for (const chapter of course.curriculum.chapters) {
            for (const lecture of chapter.lectures) {
                for (const item of lecture.resources) {
                    if (item.lectureResourceType === CourseLectureResourceTypes.TESTS) {
                        totalTests++
                    }
                }
            }
        }
        return totalTests
    },
        [course]
    );

    const addPopWishlist = async () => {
        if (!state.user?.info || !course) return
        try {
            const prop = state.user.info.user.stats.courses.favorites.includes(course.id) ? "stats.courses.favorites.pop" : "stats.courses.favorites.push"
            const updatedUserInfo = await mutateFavoriteCoursesAsync({
                prop,
                userInformation: {
                    ...state.user.info.user,
                    stats: {
                        ...state.user.info.user.stats,
                        courses: {
                            ...state.user.info.user.stats.courses,
                            favorites: state.user.info.user.stats.courses.favorites.includes(course.id) ? state.user.info.user.stats.courses.favorites.filter(fc => fc !== course.id) : [...state.user.info.user.stats.courses.favorites, course.id],
                        }
                    }
                }
            })
            setUserInfo(updatedUserInfo)
            notify({ type: "success", title: "Successful!", body: `Bookmarks has been successfully updated!` });
        } catch (error) {
        }
    }

    const enrollCourse = async () => {
        if (!course) return
        try {
            const enrollmentRecord = await mutateCourseEnrollmentAsync({ courseId: course.id })
            setUserInfo(await getUserinfo())
            if (enrollmentRecord.actionsRequired.includes(EnrollmentActions.Payment)) {
                navigate(`/payment/${enrollmentRecord.invoiceNumber}`)
            } else if (enrollmentRecord.actionsRequired.includes(EnrollmentActions.Approval)) {
                notify({ type: "success", title: "Successful!", body: `Access request to this course has been sent!` });
            } else if (enrollmentRecord.status === EnrollmentStatus.APPROVED) {
                navigate(`/${state.user?.info.session.role}/courses/${course.id}/learn`)
            }
        } catch (error) {
        }
    }

    const ButtonCTA = useCallback(() => {
        if (state.user && course) {
            const enrollment = state.user.info.user.stats.courses.enrollments.find(({ courseId }) => courseId === course.id)
            if (!enrollment) {
                return <Button disabled={state.user?.info.session.role !== RoleTypes.STUDENT} onClick={enrollCourse} text={course.price.amount > 0 ? `Enroll For ${prepPrice(course.price.currency, course.price.amount)}` : "Enroll Now"} loading={mutatingCourseEnrollmentAsync} rightIcon={<ArrowRight size={16} />} color="success" />
            }
            if (enrollment.status === EnrollmentStatus.REJECTED) {
                return <Button disabled={true} text={"Enrollment Rejected"} loading={mutatingCourseEnrollmentAsync} rightIcon={<Ban size={16} />} color="danger" />
            }
            if (enrollment.status === EnrollmentStatus.PENDING) {
                if (enrollment.actionsRequired.includes(EnrollmentActions.Approval)) {
                    return <Button disabled={true} text={"Enrollment Requested"} loading={mutatingCourseEnrollmentAsync} color="success" />
                }
                if (enrollment.actionsRequired.includes(EnrollmentActions.Payment) && course.price.amount > 0) {
                    // TODO:: Continue to navigate to payment page
                    // TODO:: Allow duplication of course resource
                    return <Button disabled={state.user?.info.session.role !== RoleTypes.STUDENT} text={"Pay Now"} loading={mutatingCourseEnrollmentAsync} color="success" />
                }
            }
            if (enrollment.status === EnrollmentStatus.APPROVED) {
                return <Button disabled={state.user?.info.session.role !== RoleTypes.STUDENT} onClick={() => navigate(`/${state.user?.info.session.role}/courses/${course.id}/learn`)} text={"Continue Learning"} loading={mutatingCourseEnrollmentAsync} rightIcon={<ArrowRight size={16} />} color="success" />
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user, course, mutatingCourseEnrollmentAsync])

    if (!course || isLoading) {
        if (!course && !isLoading) {
            return <NotFoundError />
        }
        return <Spinner text="Fetching Course" style={{ margin: 200 }} />
    }

    return (
        <Page>
            <StyledGroup direction="row">
                <StyledContentWrapper className="left slideInLeft">
                    <Group>
                        <TopMenu hideProfile hideNotification titles={[{
                            text: 'Courses',
                            link: `/${state.user?.info.session.role}/courses`
                        }, {
                            text: course.title,
                        }]} editItemLink={[RoleTypes.ADMIN, RoleTypes.TUTOR].includes(state.user?.info.session.role as RoleTypes) ? `/${state.user?.info.session.role}/courses/${course.id}/edit` : undefined} />
                        <Group gap="xs">
                            <br />
                            <br />
                            <StyledH3><b>{course.title}</b></StyledH3>
                            <StyledTitleDescDiv>
                                {parse(course.description)}
                            </StyledTitleDescDiv>
                            {
                                reviews && !!flattenReviews().length && <>
                                    <StyledHR />
                                    <StyledChapterTitle><b>Reviews</b><StyledSpan><small style={{ paddingLeft: '5px', fontSize: '10px', fontWeight: 'lighter' }}>(<CountUp end={course.stats.totalRatings} />)</small></StyledSpan></StyledChapterTitle>
                                    <ReviewCards reviews={flattenReviews()} />
                                    {
                                        hasNextPage && <LoadMore loading={isFetchingNextPage} onclick={fetchNextPage} />
                                    }
                                </>
                            }
                        </Group>
                    </Group>
                </StyledContentWrapper>
                <Group className="slideInRight" style={{ flex: 1 }}>
                    <Group direction="row" align="center" justify="end">
                        <TopMenuProfile />
                    </Group>
                    <StyledBanner style={{ marginTop: '20px' }} $url={course.assets.coverPhoto?.url || '/assets/images/ImagePlaceholder.png'} />
                    <StyledCourseContentGroup>
                        <StyledCourseGroup direction="row">
                            <StyledCourseImage $url={course.assets.thumbnail?.url || '/assets/images/ImagePlaceholder.png'} $color={course.color} />
                            <Group gap="sm">
                                <Group direction="row">
                                    <Group direction="row" justify="center" gap="xs" align="center"><Book style={{ color: theme.colors.success }} /> <StyledSmall><b style={{ margin: '0 0 0 3px' }}><CountUp end={course.curriculum.chapters.length} /></b> {pluralize('Chapter', course.curriculum.chapters.length)}</StyledSmall></Group>
                                    <Group direction="row" justify="center" gap="xs" align="center"><Stopwatch style={{ color: theme.colors.secondary }} /> <StyledSmall><b style={{ margin: '0 0 0 3px' }}><CountUp end={course.duration.unit} /></b> {DurationInOptions.find(({ value }) => value === course.duration.in)?.label}</StyledSmall></Group>
                                    <Group direction="row" justify="center" gap="xs" align="center"><BarChart style={{ color: theme.colors.danger }} /> <StyledSmall>{LevelOptions.find(({ value }) => value === course.level)?.label}</StyledSmall></Group>
                                    <Group direction="row" justify="center" gap="xs" align="center"><PeopleFill style={{ color: theme.colors.primary }} /> <StyledSmall><b style={{ margin: '0 0 0 3px' }}><CountUp end={course.stats.totalEnrolled} /></b> {pluralize('Student', course.stats.totalEnrolled)}</StyledSmall></Group>
                                </Group>
                                <StyledCourseGroup direction="row">
                                    <OutlineButton disabled={state.user?.info.session.role !== RoleTypes.STUDENT} loading={mutatingFavoriteCoursesAsync} onClick={addPopWishlist} text={state.user?.info.user.stats.courses.favorites?.includes(course.id) ? "Added to Wishlist" : "Add to Wishlist"} style={{ height: '38px' }} rightIcon={state.user?.info.user.stats.courses.favorites?.includes(course.id) ? <HeartFill /> : <Heart />} color="success" />
                                    {ButtonCTA()}
                                </StyledCourseGroup>
                            </Group>
                        </StyledCourseGroup>
                        <Group direction="row" style={{ marginTop: '10px' }}>
                            <Group className="pane" style={{ flex: 1 }} gap="xs">
                                <StyledChapterTitle style={{ marginBottom: '10px' }}><b>Course Content</b><StyledSpan><small style={{ paddingLeft: '5px', fontSize: '10px', fontWeight: 'lighter' }}>(<CountUp end={course.curriculum.chapters.length} /> {pluralize('Chapter', course.curriculum.chapters.length)})</small></StyledSpan></StyledChapterTitle>
                                {
                                    course.curriculum.chapters.map(chapter => <Accordion style={{
                                        padding: '10px',
                                        borderRadius: '5px',
                                        marginBottom: '10px',
                                        backgroundColor: theme.colors.grey6
                                    }} key={chapter.chapterId}>
                                        <StyledAccordionItem header={<StyledChapterChildTitle>{chapter.title}</StyledChapterChildTitle>}>
                                            <div style={{ padding: '10px' }}>
                                                {
                                                    chapter.lectures.map(lecture => <Group direction="row" align="center" key={lecture.lectureId} style={{
                                                        borderBottom: '1px solid ' + theme.colors.grey4,
                                                        padding: '6px 0'
                                                    }}>
                                                        <Group direction="row" align="center" gap="sm" style={{ flex: 1 }}>
                                                            <CircleFill size={8} style={{ color: course.color }} />
                                                            {lecture.title}
                                                        </Group>
                                                        <StyledSmall>
                                                            <CountUp end={lecture.resources.length} /> study {pluralize('material', lecture.resources.length)}
                                                        </StyledSmall>
                                                    </Group>)
                                                }
                                            </div>
                                        </StyledAccordionItem>
                                    </Accordion>)
                                }

                                {
                                    course.tutor && <>
                                        <StyledHR />
                                        <StyledBorderedDiv>
                                            <TutorPreview tutor={course.tutor} />
                                        </StyledBorderedDiv>
                                    </>
                                }
                            </Group>
                            <Group className="pane">
                                <br />
                                <StyledBorderedDiv>
                                    <Group gap="xs">
                                        <StyledChapterTitle><b>Summary</b></StyledChapterTitle>
                                        <Group direction="row" gap="xs" align="center"><StarFill style={{ color: theme.colors.success }} /> <StyledSmall>Reviews: <b style={{ margin: '0 0 0 3px' }}><CountUp end={course.stats.totalRatings} /></b></StyledSmall></Group>
                                        <Group direction="row" gap="xs" align="center"><PeopleFill style={{ color: theme.colors.black }} /> <StyledSmall>Enrolled: <b style={{ margin: '0 0 0 3px' }}><CountUp end={course.stats.totalEnrolled} /> {pluralize('student', course.stats.totalEnrolled)}</b></StyledSmall></Group>
                                        <Group direction="row" gap="xs" align="center"><ClockFill style={{ color: theme.colors.secondary }} /> <StyledSmall>Duration: <b style={{ margin: '0 0 0 3px' }}><CountUp end={course.duration.unit} /> {DurationInOptions.find(({ value }) => value === course.duration.in)?.label}</b></StyledSmall></Group>
                                        <Group direction="row" gap="xs" align="center"><BookFill style={{ color: theme.colors.primary }} /> <StyledSmall>Chapters: <b style={{ margin: '0 0 0 3px' }}><CountUp end={course.curriculum.chapters.length} /></b></StyledSmall></Group>
                                        <Group direction="row" gap="xs" align="center"><GraphUp style={{ color: theme.colors.success }} /> <StyledSmall>Level: <b style={{ margin: '0 0 0 3px' }}>{LevelOptions.find(({ value }) => value === course.level)?.label}</b></StyledSmall></Group>
                                    </Group>
                                </StyledBorderedDiv>
                                <StyledHR />
                                <StyledBorderedDiv>
                                    <Group gap="xs">
                                        <StyledChapterTitle><b>Includes</b></StyledChapterTitle>
                                        <Group direction="row" gap="xs" align="center"><Play style={{ color: theme.colors.primary }} /> <StyledSmall><b style={{ margin: '0 0 0 3px' }}>{getHoursOfOnDemandVideo()}</b> on-demand video</StyledSmall></Group>
                                        <Group direction="row" gap="xs" align="center"><CloudDownload style={{ color: theme.colors.secondary }} /> <StyledSmall><b style={{ margin: '0 0 0 3px' }}><CountUp end={getDownloadableResources()} /></b> downloadable  {pluralize('resource', getDownloadableResources())}</StyledSmall></Group>
                                        <Group direction="row" gap="xs" align="center"><Key style={{ color: theme.colors.danger }} /> <StyledSmall><b style={{ margin: '0 0 0 3px' }}>{course.settings.completionExperience.access.unit}</b> {course.settings.completionExperience.access.in} access</StyledSmall></Group>
                                        <Group direction="row" gap="xs" align="center"><Phone style={{ color: theme.colors.success }} /> <StyledSmall>Access on mobile & laptop</StyledSmall></Group>
                                        <Group direction="row" gap="xs" align="center"><List style={{ color: theme.colors.black }} /> <StyledSmall><b style={{ margin: '0 0 0 3px' }}><CountUp end={getTotalTests()} /></b> {pluralize('Test', getTotalTests())}</StyledSmall></Group>
                                        {
                                            course.settings.completionExperience.issuesCertificate && <Group direction="row" gap="xs" align="center"><PatchCheck style={{ color: theme.colors.success }} /> <StyledSmall>Certificate of Completion</StyledSmall></Group>
                                        }
                                    </Group>
                                </StyledBorderedDiv>
                            </Group>
                        </Group>
                    </StyledCourseContentGroup>
                </Group>
            </StyledGroup>
        </Page>
    );
};
