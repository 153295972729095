import moment from "moment";
import { InputContainer } from "..";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { CSSProperties, ReactNode } from "react";
import { defaultDateFormat } from "shared-library";
import "react-datepicker/dist/react-datepicker.css";
import { FormComponentProps } from "../../../types/form-component-props";

type Size = "sm" | "md";
interface DateTimeInputProps extends FormComponentProps {
    size?: Size;
    value: string;
    error?: string;
    label?: string;
    minDate?: Date;
    maxDate?: Date;
    format?: string;
    dateFormat?: "Pp";
    classNames?: string;
    placeholder?: string;
    isRequired?: boolean;
    description?: string;
    rightIcon?: ReactNode;
    showErrorMsg?: boolean;
    showTimeSelect?: boolean;
    showYearDropdown?: boolean;
    parentStyle?: CSSProperties;
    showMonthDropdown?: boolean;
    onChange: (value: string) => void;
}

const StyledInput = styled.div`
    .react-datepicker-wrapper {
        width: 100%;
        input {

            width: 100%;
            color: ${(props) => props.theme.colors.gray6};
            border: 1px solid ${(props) => props.theme.colors.primary2};
            padding: 0rem 0.5rem;
            border-radius: 3px;

            &::placeholder {
                color: ${(props) => props.theme.colors.gray2};
            }
        
            &.hasError {
                border-color: ${(props) => props.theme.colors.danger};
            }
        }
    }

    .react-datepicker {
        font-family: "Nunito";
        border-radius: 0px;
        border-color: ${(props) => props.theme.colors.primary2};

        .react-datepicker__header {
            background-color: ${(props) => props.theme.colors.grey6};
            border-color: ${(props) => props.theme.colors.primary2};
        }

        .react-datepicker__day--selected {
            background-color: ${(props) => props.theme.colors.primary};
        }

        .react-datepicker__time-list-item--selected {
            background-color: ${(props) => props.theme.colors.primary} !important;
        }

        .react-datepicker__time-container  {
            border-color: ${(props) => props.theme.colors.primary2};
        }
    }

    .react-datepicker__triangle {
        fill: ${(props) => props.theme.colors.grey6} !important;
        color: ${(props) => props.theme.colors.grey6} !important;
        stroke: ${(props) => props.theme.colors.primary2} !important;
    }

    &.md {
        .react-datepicker-wrapper {
            input {
                height: 2.2rem;
            }
        }
    }

    &.sm {
        .react-datepicker-wrapper {
            input {
                height: 1.8rem;
                font-size: 0.8rem;
            }
        }
    }
`

export const DateTimeInput = (props: DateTimeInputProps) => {
    const {
        value = "",
        label,
        showTimeSelect,
        format = defaultDateFormat,
        description,
        error,
        rightIcon,
        minDate,
        maxDate,
        dateFormat,
        showErrorMsg = true,
        placeholder,
        classNames,
        size = "md",
        isRequired,
        onChange,
        showYearDropdown = false,
        showMonthDropdown = false,
    } = props;


    const handleDateChange = (date: Date) => {
        if (onChange) onChange(date ? moment(date).format(format) : "")
    }

    return (
        <InputContainer
            label={label}
            rightIcon={rightIcon}
            description={description}
            error={showErrorMsg ? error : ""}
            isRequired={isRequired}
            style={props.parentStyle}
            render={(ID) => (
                <StyledInput className={size} id={ID}>
                    <DatePicker
                        minDate={minDate}
                        maxDate={maxDate}
                        dateFormat={dateFormat}
                        onChange={handleDateChange}
                        className={classNames || ""}
                        placeholderText={placeholder}
                        showTimeSelect={showTimeSelect}
                        showYearDropdown={showYearDropdown}
                        onKeyDown={(e) => e.preventDefault()}
                        showMonthDropdown={showMonthDropdown}
                        selected={value ? moment(value).toDate() : undefined}
                    />
                </StyledInput>
            )}
        />
    );
};
