import { CSSProperties, ReactNode } from "react";
import { StyledH4, StyledSpan, StyledStatsCard } from "../../Elements";

export const StatsCard = ({ title, mainColor, miniColor, icon, value, styles = {} }: { styles?: CSSProperties; value: ReactNode; title: string; mainColor: string; miniColor: string; icon: ReactNode; }): JSX.Element => {
    return <StyledStatsCard wrap="nowrap" align="center" style={styles} direction="row" justify="space-between">
        <div>
            <StyledSpan style={{ fontSize: '12px' }}>{title}</StyledSpan>
            <StyledH4 style={{ marginTop: '-6px', color: mainColor }}>
                {value}
            </StyledH4>
        </div>
        <div style={{
            color: mainColor,
            background: miniColor,
            borderRadius: '6px',
            padding: '10px 14px',
            display: 'flex',
            alignItems: 'center'
        }}>
            {icon}
        </div>
    </StyledStatsCard>
}