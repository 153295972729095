import { FC } from "react";
import { uniq } from "lodash";
import { TextInput } from "../../Form";
import { Button } from "../../Form/Button";
import { OffCanvas } from "react-offcanvas";
import { useTheme } from "styled-components";
import { useApi } from "../../../hooks/use-api";
import { Group } from "../../Layout/Group/Group";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "../../../hooks/use-form.hook";
import { EnvelopePlus, X } from "react-bootstrap-icons";
import { RichText } from "../../Form/RichText/RichText";
import { useNotification } from "../../../hooks/use-notification.hook";
import { StyledOffCanvasMenu, StyledH5, StyledHR, StyledCreationWrapper, StyledBadge } from "../../Elements";

export const MailEditor: FC<{ open: boolean; setOpen: (open: boolean) => void; onSuccess?: () => void; emails: Array<string> }> = ({ open, setOpen, onSuccess, emails }) => {

    const theme = useTheme();
    const { mail } = useApi();
    const { notify } = useNotification();

    const { onSubmit, errors, onChange, formValues, reset } = useForm({
        defaultValues: {
            body: "",
            subject: "",
        },
        validations: {
            body: { max: 20000, required: true },
            subject: { max: 200, required: true },
        }
    })
    const { isPending: mutatingMailAsync, mutateAsync: mutateMailAsync } = useMutation({
        mutationFn: mail,
    });

    const sendMail = async (data: { body: string; subject: string; }) => {
        try {
            await mutateMailAsync({ emails: uniq(emails), body: data.body, subject: data.subject })
            notify({ type: "success", title: "Successful!", body: `Mail has been successfully sent!` });
            reset()
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <EnvelopePlus size={20} style={{ marginRight: '5px' }} /> Mail
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper>
                        <form onSubmit={onSubmit(sendMail)}>
                            <Group gap="xs" direction="row">
                                {
                                    uniq(emails).map(email => <StyledBadge key={email}>{email}</StyledBadge>)
                                }
                            </Group>
                            <StyledHR />
                            <Group gap='sm'>
                                <TextInput
                                    isRequired
                                    type="text"
                                    label="Subject"
                                    placeholder="Subject"
                                    error={errors.subject}
                                    value={formValues.subject}
                                    onChange={onChange("subject")}
                                />

                                <RichText
                                    isRequired
                                    label="Email Body"
                                    parentStyle={{ maxWidth: '100%' }}
                                    value={formValues.body}
                                    placeholder={`Email Body`}
                                    error={errors.body}
                                    onChange={onChange("body")}
                                />
                                <br />
                                <Button text={"Update"} type='submit' disabled={!uniq(emails).length} loading={mutatingMailAsync} />
                            </Group>
                        </form>
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}