import CountUp from "react-countup";
import parse from 'html-react-parser';
import { Group } from "../../Layout/Group/Group";
import { Book, People } from "react-bootstrap-icons";
import { useTheme } from "../../../hooks/use-theme.hook";
import { CourseTutor, createImageFromInitials } from "shared-library";
import { StyledChapterTitle, StyledSmall, StyledTitleDescDiv, StyledUserPicture } from "../../Elements";

export const TutorPreview = ({ tutor }: { tutor: CourseTutor }) => {
    const theme = useTheme();

    return (
        <Group gap="sm">
            <StyledChapterTitle><b>About the Tutor</b></StyledChapterTitle>
            <Group direction="row" style={{ marginBottom: '10px' }}>
                <StyledUserPicture style={{ marginTop: '3px' }} src={tutor.picture || createImageFromInitials(tutor.fullName, theme.colors.white, theme.colors.secondary)} alt="Profile" />
                <Group gap="none">
                    <div><b>{tutor.firstName}</b></div>
                    <StyledSmall>{tutor.position} {tutor.companyName}</StyledSmall>
                    <Group direction="row">
                        <Group direction="row" justify="center" gap="xs" align="center"><Book style={{ color: theme.colors.success }} /> <StyledSmall><b style={{ margin: '0 0 0 3px' }}><CountUp end={tutor.stats.courses.tutoring.length} /></b> Courses</StyledSmall></Group>
                        <Group direction="row" justify="center" gap="xs" align="center"><People style={{ color: theme.colors.primary }} /> <StyledSmall><b style={{ margin: '0 0 0 3px' }}><CountUp end={tutor.students} /></b> Students</StyledSmall></Group>
                    </Group>
                </Group>
            </Group>

            <StyledTitleDescDiv>
                {
                    !!tutor.bio && parse(tutor.bio)
                }
            </StyledTitleDescDiv>
        </Group>
    )
}