import { useContext } from "react";
import { AuthContext } from "../../context/auth";
import { useTheme } from "../../hooks/use-theme.hook";
import { CompanyContext } from "../../context/company";
import { AdminCoursesEditor } from './AdminCoursesEditor';
import { CourseDurationIn, CourseForm, CourseLevels, CourseStatus, CourseSteps } from 'shared-library';

export const AdminCoursesNew = () => {
    const theme = useTheme();
    const { state } = useContext(AuthContext)
    const { state: AppCompany } = useContext(CompanyContext)
    const defaultEmptyValues: CourseForm = {
        title: "",
        description: "",
        isPartialUpdate: false,
        step: CourseSteps.BasicInformation,
        status: CourseStatus.DRAFT,
        tutorId: state.user?.sub || "",
        color: theme.colors.secondary,
        assets: {
            videoId: "",
            thumbnailId: "",
            coverPhotoId: ""
        },
        settings: {
            visibleToNonEnrolled: true,
            sequentialLearning: {
                enable: false,
                autoUnlock: false,
            },
            requestEnrollment: true,
            completionExperience: {
                access: {
                    unit: 3,
                    in: CourseDurationIn.MONTHS
                },
                issuesCertificate: true
            },
            notifyAdminOnCurriculumCompletion: false
        },
        curriculum: {
            chapters: []
        },
        price: {
            amount: 0,
            currency: AppCompany.company?.billing.currency as string
        },
        level: CourseLevels.BEGINNER,
        duration: {
            unit: 1,
            in: CourseDurationIn.WEEKS
        }
    }
    return <AdminCoursesEditor courseDefaultValues={defaultEmptyValues} />;
};
