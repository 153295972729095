import { Link } from "react-router-dom";
import styled from "styled-components";
import { Group } from "../Layout/Group/Group";
import { OffCanvasMenu } from "react-offcanvas";
import { CaretDown } from "react-bootstrap-icons";
import { AccordionItem as Item } from "@szhsin/react-accordion";
import { boxShadow, forPhoneOnly, grow, growShadow, growSmaller, keyboardFocusOnly } from "./Mixins";

export const StyledA = styled.a`
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    font-weight: 300;

    &:active {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
        font-weight: 300;
    }

    &:hover {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
    }
    ${keyboardFocusOnly}
`

export const StyledNavLink = styled(Link)`
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    font-weight: 300;
    
    &:active {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
        font-weight: 300;
    }

    &:hover {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
    }
    ${keyboardFocusOnly}
`

export const StyledProfilePicture = styled.img`
    border-radius: 16px;
    width: 50px;
    height: 50px;
    border: 6px solid ${(props) => props.theme.colors.secondary3}
`

export const StyledUserPicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`

export const StyledHR = styled.hr<{ $color?: string; }>`
    margin: 0.75rem 0;
    border: 0;
    height: 1px;
    background: ${(props) => props.$color || props.theme.colors.grey3};
`

export const StyledDesc = styled.p`
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray4};
`

export const StyledH1 = styled.h1`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray5};
`

export const StyledH2 = styled.h2`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray5};
`

export const StyledH3 = styled.h3`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray5};
`

export const StyledH4 = styled.h4`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray5};
`

export const StyledH5 = styled.h5`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray5};
`

export const StyledH6 = styled.h6`
    font-weight: 500;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.gray5};
`

export const StyledErrorText = styled.div`
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.danger};
`

export const StyledSpan = styled.span`
    position: relative;
    color: ${(props) => props.theme.colors.grey};
    &.disabled {
      cursor: not-allowed;
      opacity: 0.3;
  }
`

export const StyledSmall = styled.small`
    font-size: 12px;
    position: relative;
    color: ${(props) => props.theme.colors.gray4};
`

export const StyledIsRequired = styled.span`
    color: ${(props) => props.theme.colors.danger};
`

export const StyledContentWrapper = styled.div`
    padding: 20px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.bg};
    ${forPhoneOnly("padding: 14px;border-radius: 16px;")}
`

export const StyledTitle = styled.div`
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
`

export const StyledTitleDesc = styled.p`
    font-size: 0.9rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.gray4};
`

export const StyledTitleDescDiv = styled.div`
    font-size: 0.9rem;
    font-weight: 300;
    color: ${(props) => props.theme.colors.gray4};

    &.short {
      text-align: center;
      max-width: 700px;
      ${forPhoneOnly("max-width: 250px;")}
    }
`

const ItemWithChevron = ({ header, ...rest }: any) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <CaretDown className="chevron-down" />
      </>
    }
  />
);

export const StyledAccordionItem = styled(ItemWithChevron)`
.szh-accordion__item {
  &-btn {
    flex: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    background-color: transparent;
    border: none;
    &:hover {
      background-color: transparent;
    }
  }

  &-content {
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
  }

  &-panel {
  }
}

.chevron-down {
  margin-left: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}

&.szh-accordion__item--expanded {
  .szh-accordion__item-btn {
    background-color: transparent;
  }
  .chevron-down {
    transform: rotate(180deg);
  }
}
`;

export const StyledIcon = styled.div`
    ${grow}
    cursor: pointer;
    border-radius: 7px;
    padding: 10px 10px 7px 10px;
    &.small {
      padding: 5px 5px 2px 7px;
    }
    background: ${props => props.theme.colors.white};

    &.disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }
`

export const StyledTable = styled.table`
  width: 100%;
  border-radius: 14px;
  border-collapse: collapse;
  background: ${props => props.theme.colors.white};
  max-width: 100%;

  th {
    padding: 8px 4px;
    text-align: left;
    border-bottom: 3px solid ${props => props.theme.colors.grey6};
    ${forPhoneOnly("padding: 10px;")}
  }
  th:last-child {
    text-align: right;
    padding-right: 10px;
    ${forPhoneOnly("padding-right: 4px;")}
  }

  td {
    padding: 4px;
    border-bottom: 1px solid ${props => props.theme.colors.grey6};
    ${forPhoneOnly("padding: 10px;")}
  }

  &.hasSnNo {
    td:first-child {
      width: 50px;
      text-align: center;
    }
  }

  td:last-child {
    text-align: right;
    padding-right: 10px;
  }

  tbody tr {
    ${grow}
  }

  tbody tr.active {
    ${props => boxShadow(props.theme.colors.grey6)}
  }

  tbody tr:hover,
  tbody tr.active {
    background: ${props => props.theme.colors.grey6};
  }
`;

export const StyledChapterTitle = styled(StyledTitle)`
    color: ${(props) => props.theme.colors.black};
`

export const StyledChapterChildTitle = styled(StyledChapterTitle)`
    padding-top: 1px;
    font-size: 14px;
`

export const StyledBanner = styled.div<{ $url: string; $height?: string; $css?: string; }>`
    background: url(${props => props.$url}) no-repeat center;
    background-size: cover;
    height: ${props => props.$height || '180px'};
    width: 100%;
    ${props => props.$css || ''}
    ${forPhoneOnly("height: 140px;")}
`

export const StyledBorderedDiv = styled.div`
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.grey3};
`

export const StyledDirection = styled.div`
  ${growSmaller}
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 33px;
  width: 33px;
  color: ${props => props.theme.colors.black};
  border: 1px solid ${props => props.theme.colors.grey3};
`

export const StyledOffCanvasMenu = styled(OffCanvasMenu)`
    &.closed {
        display: none;
    }

    &.opened {
        z-index: 10;
        height: 100%;
        width: 500px !important;
        background: ${props => props.theme.colors.grey6};
        border-left: 6px solid ${props => props.theme.colors.white};
        ${forPhoneOnly("width: 100% !important;")}

        .offcanvas-content {
           height: 90%;
          width: 100%;
          overflow-y: auto;
        }
    }
`

export const StyledSampleFileDownloadContainer = styled(Group)`
  ${growSmaller}
  border-radius: 3px;
  padding: 10px 20px;
  min-width: 100%;
  max-width: 100%;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  border: 1px dashed ${props => props.theme.colors.primary2};
  background: ${props => props.theme.colors.primary4};
  small {
    font-size: 12px;
    color: ${props => props.theme.colors.primary};
  };
`

export const StyledDropdown = styled.div`
  ${grow}
  z-index: 2; 
  right: -10px; 
  width: 175px;     
  padding: 10px 0; 
  position: absolute; 
  border-radius: 4px;
  ${(props) => boxShadow(props.theme.colors.primary4)}
  background-color: ${(props) => props.theme.colors.white};

  p {
    ${grow}
    display: flex;
    direction: row;
    gap: .8rem;
    font-size: 14px;
    padding: 5px 15px; 
    align-items: center;
    cursor: pointer;
    color: ${(props) => props.theme.colors.black};

    &:hover {
        background-color: ${(props) => props.theme.colors.grey4};
    }
  }

  p.disabled {
    cursor: normal;
  }
`

export const StyledGroupRow = styled(Group)`

  .col2 {
    width: 18%;
    ${forPhoneOnly("width: 100%;")}
  }

  .col3 {
    width: 20%;
    ${forPhoneOnly("width: 100%;")}
  }

  .col4 {
    width: 24%;
    ${forPhoneOnly("width: 100%;")}
  }

  .col6 {
    width: 48%;
    ${forPhoneOnly("width: 100%;")}
  }

  .col8 {
    width: 50%;
  }

  .col12 {
    width: 100%;
  }
`

export const StyledCreationWrapper = styled(Group)`
    padding: 30px 20px;
    border-radius: 4px;
    background: ${props => props.theme.colors.white};
`

export const StyledTestGroup = styled.div`
    ${grow}
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${props => props.theme.colors.white};
    padding: 12px 16px;
    border-radius: 12px;
    margin-bottom: 10px;
    gap: 0.5rem;

    .icon {
        border-radius: 4px;
        padding: 4px 6px 0px 6px;
        color: ${props => props.theme.colors.gray2};
        border: 1px solid ${props => props.theme.colors.gray2};
    }

    .icon-right {
        color: ${props => props.theme.colors.gray2};
    }

    &.active {
        ${growShadow}
        background: transparent;
    }
`

export const StyledStatsCard = styled(Group)`
    ${growSmaller}
    padding: 4px 20px;
    border-radius: 6px;
    background: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.bg};
    ${forPhoneOnly("width: 100%; align-items: center !important;")}
`

export const StyledBadge = styled.span`
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 4px;
    text-align: center;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.grey};

    &.success {
      background: ${props => props.theme.colors.success};
    }

    &.danger {
      background: ${props => props.theme.colors.danger};
    }
`

export const StyledAlert = styled(Group)`
    font-size: 12px;
    padding: 10px 16px;
    border-radius: 4px;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primary};

    &.info {
      color: ${props => props.theme.colors.primary};
      background: ${props => props.theme.colors.primary4};
    }

    &.success {
      color: ${props => props.theme.colors.success};
      background: ${props => props.theme.colors.success4};
    }

    &.danger {
      color: ${props => props.theme.colors.danger};
      background: ${props => props.theme.colors.danger4};
    }
`

export const StyledLargeAddWrapper = styled(Group)`
    font-size: 72px;
    width: 290px;
    height: 200px;
    border-radius: 16px;
    text-align: center;
    cursor: pointer;
    color: ${props => props.theme.colors.grey2};
    background: ${props => props.theme.colors.grey6};
    ${forPhoneOnly(" width: 100%;")}
`

export const StyledDIYWebsiteBuilderContainer = styled.div`
    min-height: 100vh;
`