import uniqid from 'uniqid';
import parse from 'html-react-parser';
import styled from "styled-components";
import { useApi } from '../../hooks/use-api';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../context/auth";
import { Accordion } from '@szhsin/react-accordion';
import { useTheme } from '../../hooks/use-theme.hook';
import { CompanyContext } from '../../context/company';
import { useNotify } from "../../hooks/use-notify.hook";
import { Page } from "../../components/Layout/Page/Page";
import { Switch, TextInput } from "../../components/Form";
import { FC, useContext, useEffect, useState } from "react";
import { Group } from "../../components/Layout/Group/Group";
import { FileInput } from "../../components/Form/FileUpload";
import { useMutation, useQuery } from '@tanstack/react-query';
import { ProgressStep } from "../../components/App/ProgressStep";
import { useNotification } from '../../hooks/use-notification.hook';
import { RichText } from "../../components/Form/RichText/RichText";
import { DurationInOptions, LevelOptions } from "../../utils/data";
import { TopMenu, TopMenuProfile } from "../../components/App/TopMenu";
import { CheckBoxInput } from '../../components/Form/CheckBox/CheckBox';
import { useCurrencySymbol } from '../../hooks/use-currency-symbol.hook';
import { OutlineButton, StepButton } from '../../components/Form/Button';
import { SelectInput } from "../../components/Form/SelectInput/SelectInput";
import { ResourceType, Sortable } from "../../components/App/Sortable/Sortable";
import { ChildErrors, childrenHasError, findError, useForm, validateNested } from '../../hooks/use-form.hook';
import { StyledAccordionItem, StyledBanner, StyledContentWrapper, StyledHR, StyledIcon, StyledChapterChildTitle, StyledChapterTitle, StyledTitle, StyledTitleDesc, forPhoneOnly, StyledSpan, StyledSmall } from "../../components/Elements";
import { CaretLeft, Trash, PlusSquare, CaretRight, FolderPlus, BlockquoteLeft, FiletypeMp3, FiletypeMp4, FiletypePpt, UiRadios, FiletypePng, FiletypePdf, X, ArrowUpShort, ArrowDownShort, ExclamationTriangle } from "react-bootstrap-icons";
import { AllTestSchema, AllCourseTutorsSchema, hexToRgba, CourseCurriculumChapter, CourseCurriculumChapterLecture, CourseCurriculumChapterLectureResource, CourseDurationIn, CourseForm, CourseLectureResourceTypes, CourseLevels, CourseStatus, CourseSteps } from 'shared-library';

const StyledGroup = styled(Group)`
    .left {
        width: 35%;
        ${forPhoneOnly("width: 100%;")}
    }
`

interface FirstSectionInputs {
    title: string;
    description: string;
    level: CourseLevels;
    durationUnit: number;
    durationIn: CourseDurationIn;
}

export const AdminCoursesEditor = ({ courseDefaultValues }: { courseDefaultValues: CourseForm }) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const { confirm } = useNotify();
    const { notify } = useNotification();
    const { getSymbol } = useCurrencySymbol();
    const { createCourse, getResources } = useApi();
    const { state, setResources } = useContext(AuthContext);
    const { state: AppCompany } = useContext(CompanyContext);
    const [childErrors, setChildErrors] = useState<Array<ChildErrors>>([]);
    const { isPending: mutatingCourseAsync, mutateAsync: mutateCourseAsync } = useMutation({
        mutationFn: createCourse,
    });

    const { data: resources } = useQuery({
        queryKey: ["allActiveTutors", "allActiveTests"],
        queryFn: () => getResources(["activeTutors", "activeTests"]),
        enabled: !state.resources.activeTutors?.length || !state.resources.activeTests?.length
    });

    useEffect(() => {
        if (resources) {
            setResources({ ...state.resources, activeTests: resources.activeTests as Array<AllTestSchema>, activeTutors: resources.activeTutors as Array<AllCourseTutorsSchema> })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources])

    const { formValues: firstSectionFormValues, onChange: firstSectionOnChange, onSubmit: firstSectionOnSubmit, errors: firstSectionErrors } = useForm<FirstSectionInputs>({
        defaultValues: {
            title: courseDefaultValues.title,
            level: courseDefaultValues.level,
            durationIn: courseDefaultValues.duration.in,
            description: courseDefaultValues.description,
            durationUnit: courseDefaultValues.duration.unit,
        },
        validations: {
            durationUnit: { required: true },
            title: { required: true, min: 3 },
            description: { required: true, max: 3000 }
        }
    })

    const Steps = [
        CourseSteps.BasicInformation,
        CourseSteps.CourseMedia,
        CourseSteps.Curriculum,
        CourseSteps.Settings,
    ]

    const [newCourse, setNewCourse] = useState<CourseForm>(courseDefaultValues);

    const moveBackward = () => {
        const currentStepIndex = Steps.findIndex(step => step === newCourse.step)
        setNewCourse({ ...newCourse, step: Steps[currentStepIndex - 1] })
    }

    const finishEditing = (courseId: string) => {
        const { activeCourses, ...newResource } = state.resources;
        setResources(newResource)
        notify({ type: "success", title: "Successful!", body: `Course has been successfully updated!` });
        navigate(`/${state.user?.info.session.role}/courses/${courseId}/view`)
    }

    const moveForwardFromFirstSection = async (data: FirstSectionInputs) => {
        try {
            const updatedNewCourse: CourseForm = {
                ...newCourse,
                title: data.title,
                level: data.level,
                description: data.description,
                duration: {
                    in: data.durationIn,
                    unit: +data.durationUnit,
                }
            }
            const updateValues = await mutateCourseAsync(updatedNewCourse)
            if (courseDefaultValues.isPartialUpdate) {
                finishEditing(updateValues.id)
                return
            }
            const currentStepIndex = Steps.findIndex(step => step === newCourse.step)
            setNewCourse({ ...updateValues, step: Steps[currentStepIndex + 1], isPartialUpdate: courseDefaultValues.isPartialUpdate })
        } catch (error) {
        }
    }

    const moveForwardFromSecondSection = async () => {
        try {
            const updateValues = await mutateCourseAsync(newCourse)
            if (courseDefaultValues.isPartialUpdate) {
                finishEditing(updateValues.id)
                return
            }
            const currentStepIndex = Steps.findIndex(step => step === newCourse.step)
            setNewCourse({ ...updateValues, step: Steps[currentStepIndex + 1], isPartialUpdate: courseDefaultValues.isPartialUpdate })
        } catch (error) {
        }
    }

    const moveForwardFromThirdSection = async () => {

        const validateCurriculum = () => {
            let errors: Array<ChildErrors> = []
            for (let index = 0; index < newCourse.curriculum.chapters.length; index++) {
                const chapter = newCourse.curriculum.chapters[index];

                const result = validateNested<CourseCurriculumChapter>(chapter, {
                    title: { required: true, max: 200 },
                })
                if (!result.valid && result.errors.title) {
                    errors.push({ [`chapters.${chapter.uiid}.title`]: result.errors.title })
                }

                for (let chapterIndex = 0; chapterIndex < chapter.lectures.length; chapterIndex++) {
                    const lecture = chapter.lectures[chapterIndex];

                    const result = validateNested<CourseCurriculumChapterLecture>(lecture, {
                        title: { required: true, max: 200 },
                    })
                    if (!result.valid && result.errors.title) {
                        errors.push({ [`chapters.${chapter.uiid}.lectures.${lecture.uiid}.title`]: result.errors.title })
                    }
                    for (let resourceIndex = 0; resourceIndex < lecture.resources.length; resourceIndex++) {
                        const resource = lecture.resources[resourceIndex];
                        const result = validateNested<CourseCurriculumChapterLectureResource>(resource, {
                            testId: { required: resource.lectureResourceType === CourseLectureResourceTypes.TESTS },
                            text: { required: resource.lectureResourceType === CourseLectureResourceTypes.ARTICLES, max: 2000 },
                            fileId: { required: resource.lectureResourceType !== CourseLectureResourceTypes.TESTS && resource.lectureResourceType !== CourseLectureResourceTypes.ARTICLES },
                        })
                        if (!result.valid) {
                            if (result.errors.text) {
                                errors.push({ [`chapters.${chapter.uiid}.lectures.${lecture.uiid}.resources.${resource.uiid}.text`]: result.errors.text })
                            }
                            if (result.errors.testId) {
                                errors.push({ [`chapters.${chapter.uiid}.lectures.${lecture.uiid}.resources.${resource.uiid}.testId`]: result.errors.testId })
                            }
                            if (result.errors.fileId) {
                                errors.push({ [`chapters.${chapter.uiid}.lectures.${lecture.uiid}.resources.${resource.uiid}.fileId`]: result.errors.fileId })
                            }
                        }
                    }
                }
            }
            setChildErrors(errors)
            return !errors.length
        }

        if (!validateCurriculum()) return
        try {
            const updateValues = await mutateCourseAsync(newCourse)
            if (courseDefaultValues.isPartialUpdate) {
                finishEditing(updateValues.id)
                return
            }
            const currentStepIndex = Steps.findIndex(step => step === newCourse.step)
            setNewCourse({ ...updateValues, step: Steps[currentStepIndex + 1], isPartialUpdate: courseDefaultValues.isPartialUpdate })
        } catch (error) {
        }
    }

    const moveForwardFromFourthSection = async () => {
        try {
            setNewCourse({ ...newCourse, status: CourseStatus.ACTIVE })
            const updatedCourseDetails = await mutateCourseAsync({ ...newCourse, status: CourseStatus.ACTIVE })
            finishEditing(updatedCourseDetails.id)
        } catch (error) {
        }
    }

    const rearrangeLectures = (chapterUIID: string, nodes: ResourceType[]) => {
        const chapterIndex = newCourse.curriculum.chapters.findIndex(({ uiid }) => uiid === chapterUIID)
        if (chapterIndex === -1) return
        const _newCourse = JSON.parse(JSON.stringify(newCourse))
        _newCourse.curriculum.chapters[chapterIndex].lectures = []
        for (const node of nodes) {
            const lecture = newCourse.curriculum.chapters[chapterIndex].lectures.find(lect => lect.uiid.toString() === node.id)
            if (lecture) {
                _newCourse.curriculum.chapters[chapterIndex].lectures = [..._newCourse.curriculum.chapters[chapterIndex].lectures, lecture]
            }
        }
        setNewCourse({ ..._newCourse })
    }

    const addChapter = () => {
        setNewCourse({
            ...newCourse,
            curriculum: {
                ...newCourse.curriculum,
                chapters: [
                    ...newCourse.curriculum.chapters,
                    {
                        title: "",
                        lectures: [],
                        chapterId: "",
                        uiid: uniqid()
                    }
                ]
            }
        })
    }

    const addLecture = (chapterUIID: string) => {
        setNewCourse({
            ...newCourse,
            curriculum: {
                ...newCourse.curriculum,
                chapters: newCourse.curriculum.chapters.map(chapter => {
                    return chapter.uiid === chapterUIID ? {
                        ...chapter,
                        lectures: [
                            ...chapter.lectures,
                            {
                                title: "",
                                lectureId: "",
                                resources: [],
                                uiid: uniqid(),
                            },
                        ]
                    } : chapter
                })
            }
        })
        setChildErrors([])
    }

    return (
        <Page>
            <StyledGroup direction="row">
                <StyledContentWrapper className="left slideInLeft">
                    <Group style={{ maxWidth: '100%' }}>
                        <TopMenu hideProfile hideNotification className="slideDown" titles={[{
                            text: 'Courses',
                            link: `/${state.user?.info.session.role}/courses`
                        }, {
                            text: newCourse.id ? 'Edit' : 'New',
                        }]} />
                        <Group gap="xs">
                            <br />
                            <br />
                            <Group style={{
                                color: newCourse.color,
                                background: hexToRgba(newCourse.color, 10),
                            }}>
                                <StyledBanner $url={`${newCourse.assets.thumbnail?.url || '/assets/images/ImagePlaceholder.jpg'}`} $height="250px" />
                                {
                                    (!!newCourse.title || !!newCourse.description) && <Group gap="xs" style={{ padding: '10px' }}>
                                        <Group gap="xs" direction="row">
                                            <div style={{ flex: 1, fontSize: '18px' }}><b>{newCourse.title}</b></div>
                                        </Group>
                                        <small>{parse(newCourse.description)}</small>
                                    </Group>
                                }
                            </Group>
                        </Group>
                    </Group>
                </StyledContentWrapper>
                <Group className="slideInRight" style={{ flex: 1 }}>
                    <Group direction="row" align="center" justify="end">
                        <TopMenuProfile />
                    </Group>
                    <Group gap="sm" style={{ padding: '20px' }}>

                        <br />
                        {
                            CourseSteps.BasicInformation === newCourse.step && <form onSubmit={firstSectionOnSubmit(moveForwardFromFirstSection)}>

                                <Group gap="sm" style={{ marginBottom: '20px' }}>
                                    <StyledTitle>
                                        <b>Basic Information</b>
                                    </StyledTitle>
                                    <ProgressStep totalSteps={Steps.length} active={Steps.findIndex(step => step === newCourse.step) + 1} />
                                    <StyledTitleDesc>
                                        Craft a compelling narrative about your course to captivate prospective learners.
                                    </StyledTitleDesc>
                                </Group>

                                <Group gap="sm">

                                    <TextInput
                                        isRequired
                                        type="text"
                                        label="Course title"
                                        placeholder="Course title"
                                        error={firstSectionErrors.title}
                                        value={firstSectionFormValues.title}
                                        onChange={firstSectionOnChange("title")}
                                    />

                                    <Group direction="row" align="center" gap="sm">
                                        <TextInput
                                            type="number"
                                            label="Duration"
                                            placeholder="Duration"
                                            parentStyle={{ flex: 1 }}
                                            error={firstSectionErrors.durationUnit}
                                            onChange={firstSectionOnChange("durationUnit")}
                                            value={firstSectionFormValues.durationUnit.toString()}
                                            isRequired
                                        />
                                        <SelectInput
                                            isRequired
                                            label="Duration in"
                                            placeholder="Duration in"
                                            parentStyle={{ flex: 1 }}
                                            options={DurationInOptions}
                                            error={firstSectionErrors.durationIn}
                                            onChange={firstSectionOnChange("durationIn")}
                                            value={DurationInOptions.find(d => d.value === firstSectionFormValues.durationIn)}
                                        />
                                        <SelectInput
                                            isRequired
                                            label="Level"
                                            placeholder="Level"
                                            options={LevelOptions}
                                            parentStyle={{ flex: 1 }}
                                            error={firstSectionErrors.level}
                                            onChange={firstSectionOnChange("level")}
                                            value={LevelOptions.find(d => d.value === firstSectionFormValues.level)}
                                        />
                                    </Group>
                                    <RichText
                                        isRequired
                                        label="Description"
                                        placeholder="Description"
                                        error={firstSectionErrors.description}
                                        value={firstSectionFormValues.description}
                                        onChange={firstSectionOnChange("description")}
                                    />
                                </Group>

                                <br />
                                <Group direction="row" justify="end">
                                    {
                                        !courseDefaultValues.isPartialUpdate && <StepButton
                                            disabled={Steps.findIndex(step => step === newCourse.step) === 0}
                                            leftIcon={<CaretLeft size={14} />}
                                            onClick={moveBackward}
                                            text="Go Back"
                                        />
                                    }

                                    <StepButton
                                        loading={mutatingCourseAsync}
                                        rightIcon={<CaretRight size={14} />}
                                        type='submit'
                                        text={newCourse.id ? "Update & Continue" : "Create & Continue"}
                                    />
                                </Group>
                            </form>
                        }

                        {
                            CourseSteps.CourseMedia === newCourse.step && <>
                                <Group gap="sm" style={{ marginBottom: '20px' }}>
                                    <StyledTitle>
                                        <b>Course Media</b>
                                    </StyledTitle>
                                    <ProgressStep totalSteps={Steps.length} active={Steps.findIndex(step => step === newCourse.step) + 1} />
                                    <StyledTitleDesc>
                                        Enhance your course with captivating visuals and multimedia elements to create an immersive learning experience.
                                        Upload images, videos, presentations, or any other relevant media to complement your course content and engage learners.                                    </StyledTitleDesc>
                                </Group>

                                <Group gap="xs">
                                    <FileInput
                                        label="Course Photo"
                                        file={newCourse.assets.thumbnail}
                                        onChange={thumbnail => thumbnail ? setNewCourse({ ...newCourse, assets: { ...newCourse.assets, thumbnail, thumbnailId: thumbnail?.id || "" } }) : null}
                                        isRequired
                                        fileType={"PHOTOS"} />
                                    <FileInput
                                        label="Cover Photo"
                                        file={newCourse.assets.coverPhoto}
                                        onChange={coverPhoto => setNewCourse({ ...newCourse, assets: { ...newCourse.assets, coverPhoto, coverPhotoId: coverPhoto?.id || "" } })}
                                        fileType={"PHOTOS"} />
                                    <FileInput
                                        label="Video (Optional)"
                                        file={newCourse.assets.video}
                                        onChange={video => setNewCourse({ ...newCourse, assets: { ...newCourse.assets, video, videoId: video?.id || "" } })}
                                        fileType={"VIDEOS"} />
                                </Group>

                                <br />
                                <Group direction="row" justify="end">
                                    {
                                        !courseDefaultValues.isPartialUpdate && <StepButton
                                            disabled={Steps.findIndex(step => step === newCourse.step) === 0}
                                            leftIcon={<CaretLeft size={14} />}
                                            onClick={moveBackward}
                                            text="Go Back"
                                        />
                                    }

                                    <StepButton
                                        disabled={!newCourse.assets.thumbnail}
                                        loading={mutatingCourseAsync}
                                        rightIcon={<CaretRight size={14} />}
                                        onClick={moveForwardFromSecondSection}
                                        text={newCourse.id ? "Update & Continue" : "Create & Continue"}
                                    />
                                </Group>
                            </>
                        }

                        {
                            CourseSteps.Curriculum === newCourse.step && <>
                                <Group gap="sm" style={{ marginBottom: '20px' }}>
                                    <StyledTitle>
                                        <b>Curriculum</b>
                                    </StyledTitle>
                                    <ProgressStep totalSteps={Steps.length} active={Steps.findIndex(step => step === newCourse.step) + 1} />
                                    <StyledTitleDesc>
                                        Construct a comprehensive curriculum that guides learners through their educational journey.
                                        Add chapters or modules, lessons, tests, and downloadable resources to create a structured learning path that ensures mastery of course material.
                                    </StyledTitleDesc>
                                    <OutlineButton text="Add Chapter" onClick={addChapter} icon={<FolderPlus size={14} />} />
                                </Group>

                                {
                                    newCourse.curriculum.chapters.map(chapter => <Group key={chapter.uiid} gap="sm" style={{
                                        padding: '14px',
                                        borderRadius: '8px',
                                        border: `3px solid ${theme.colors.grey6}`
                                    }}>

                                        <Group direction="row" align="center">
                                            <TextInput
                                                isRequired
                                                type="text"
                                                value={chapter.title}
                                                label="Chapter title"
                                                parentStyle={{ flex: 1 }}
                                                placeholder="Chapter title"
                                                error={findError(childErrors, `chapters.${chapter.uiid}.title`)}
                                                onChange={(title) => { setNewCourse({ ...newCourse, curriculum: { ...newCourse.curriculum, chapters: newCourse.curriculum.chapters.map(_chapter => _chapter.uiid === chapter.uiid ? { ..._chapter, title } : _chapter) } }); setChildErrors([]) }}
                                            />
                                            <StyledIcon className={newCourse.curriculum.chapters.length > 1 ? "" : "disabledEvents"} title='Delete Chapter' style={{ marginTop: '25px' }} onClick={() => confirm({ onConfirmation: () => setNewCourse({ ...newCourse, curriculum: { ...newCourse.curriculum, chapters: newCourse.curriculum.chapters.filter(_chapter => _chapter.uiid !== chapter.uiid) } }) })}>
                                                <Trash style={{ color: theme.colors.danger }} />
                                            </StyledIcon>
                                        </Group>

                                        <Group direction="row" align="center" justify="space-between">
                                            <StyledChapterTitle><b>{chapter.title}</b></StyledChapterTitle>
                                            <OutlineButton text="Lecture" onClick={() => addLecture(chapter.uiid)} icon={<PlusSquare size={14} />} />
                                        </Group>

                                        {
                                            !!chapter.lectures.length && <Sortable nodes={chapter.lectures.map(lecture => ({
                                                id: lecture.uiid.toString(),
                                                node: <LectureComponent
                                                    lecture={lecture}
                                                    errors={childErrors}
                                                    chapterUiid={chapter.uiid}
                                                    tests={(state.resources.activeTests || [])}
                                                    onDelete={() => { setNewCourse({ ...newCourse, curriculum: { ...newCourse.curriculum, chapters: newCourse.curriculum.chapters.map(_chapter => _chapter.uiid === chapter.uiid ? { ..._chapter, lectures: _chapter.lectures.filter(_lec => _lec.uiid !== lecture.uiid) } : _chapter) } }); setChildErrors([]) }}
                                                    onChange={(lecture) => { setNewCourse({ ...newCourse, curriculum: { ...newCourse.curriculum, chapters: newCourse.curriculum.chapters.map(_chapter => _chapter.uiid === chapter.uiid ? { ..._chapter, lectures: _chapter.lectures.map(_lec => _lec.uiid === lecture.uiid ? lecture : _lec) } : _chapter) } }); setChildErrors([]) }}
                                                />
                                            }))}
                                                onChange={(_nodes) => rearrangeLectures(chapter.uiid, _nodes)} key={chapter.uiid} disabled={chapter.lectures.length < 2} style={{
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    marginBottom: '10px',
                                                    backgroundColor: theme.colors.grey6
                                                }} />
                                        }

                                    </Group>
                                    )
                                }

                                <br />
                                <Group direction="row" justify="end">
                                    {
                                        !courseDefaultValues.isPartialUpdate && <StepButton
                                            disabled={Steps.findIndex(step => step === newCourse.step) === 0}
                                            leftIcon={<CaretLeft size={14} />}
                                            onClick={moveBackward}
                                            text="Go Back"
                                        />
                                    }
                                    <StepButton
                                        disabled={!newCourse.curriculum.chapters.length}
                                        loading={mutatingCourseAsync}
                                        rightIcon={<CaretRight size={14} />}
                                        onClick={moveForwardFromThirdSection}
                                        text={newCourse.id ? "Update & Continue" : "Create & Continue"}
                                    />
                                </Group>
                            </>
                        }

                        {
                            CourseSteps.Settings === newCourse.step && <>
                                <Group gap="sm" style={{ marginBottom: '20px' }}>
                                    <StyledTitle>
                                        <b>Settings</b>
                                    </StyledTitle>
                                    <ProgressStep totalSteps={Steps.length} active={Steps.findIndex(step => step === newCourse.step) + 1} />
                                    <StyledTitleDesc>
                                        Manage the learning experience effortlessly by adjusting settings for course enrollment, notifications, comprehensive sequential learning and personalized mastery of each course.
                                    </StyledTitleDesc>
                                </Group>

                                <Group gap="none">

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Enrollment Request</StyledChapterChildTitle>
                                            <StyledTitleDesc>This setting determines whether course enrollment requires approval before payment can be processed.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={newCourse.settings.requestEnrollment} onChange={(requestEnrollment) => setNewCourse({ ...newCourse, settings: { ...newCourse.settings, requestEnrollment } })}></Switch>
                                    </Group>
                                    <StyledHR />

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Price</StyledChapterChildTitle>
                                            <StyledTitleDesc>Define price to pay before enrollment.</StyledTitleDesc>
                                        </Group>
                                        <Group direction='row' wrap='nowrap' align='center' gap='xs'>
                                            {getSymbol(AppCompany.company?.billing.currency as string)}
                                            <TextInput
                                                size='sm'
                                                type="number"
                                                placeholder="Price"
                                                style={{
                                                    maxWidth: '70px'
                                                }}
                                                onChange={(amount) => setNewCourse({ ...newCourse, price: { ...newCourse.price, amount: +amount } })}
                                                value={newCourse.price.amount.toString()}
                                            />
                                        </Group>
                                    </Group>
                                    <StyledHR />

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Visible to non-enrolled students</StyledChapterChildTitle>
                                            <StyledTitleDesc>Course will be visible to new and unregistered student if this setting is <b>ON</b>.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={newCourse.settings.visibleToNonEnrolled} onChange={(visibleToNonEnrolled) => setNewCourse({ ...newCourse, settings: { ...newCourse.settings, visibleToNonEnrolled } })}></Switch>
                                    </Group>
                                    <StyledHR />
                                    <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Notify me when student finishes curriculum</StyledChapterChildTitle>
                                            <StyledTitleDesc>You’ll be notified when a student completes a curriculum.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={newCourse.settings.notifyAdminOnCurriculumCompletion} onChange={(notifyAdminOnCurriculumCompletion) => setNewCourse({ ...newCourse, settings: { ...newCourse.settings, notifyAdminOnCurriculumCompletion } })}></Switch>
                                    </Group>
                                    <StyledHR />
                                    <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Activate Sequential Learning</StyledChapterChildTitle>
                                            <StyledTitleDesc>All curricula are locked by default, ensuring a sequential learning experience where mastery of each course is required to unlock the next.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={newCourse.settings.sequentialLearning.enable} onChange={(enable) => setNewCourse({ ...newCourse, settings: { ...newCourse.settings, sequentialLearning: { ...newCourse.settings.sequentialLearning, enable } } })}></Switch>
                                    </Group>
                                    <StyledHR />
                                    <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Sequential Learning: Master One, Auto-Unlock the Next</StyledChapterChildTitle>
                                            <StyledTitleDesc>Automatically unlock the next phase of the learning journey only after mastering the current course for a focused and comprehensive educational experience.</StyledTitleDesc>
                                        </Group>
                                        <Switch value={newCourse.settings.sequentialLearning.autoUnlock} onChange={(autoUnlock) => setNewCourse({ ...newCourse, settings: { ...newCourse.settings, sequentialLearning: { ...newCourse.settings.sequentialLearning, autoUnlock } } })}></Switch>
                                    </Group>
                                    <StyledHR />
                                    <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Issues certificate upon completion</StyledChapterChildTitle>
                                            <StyledTitleDesc>Offers certificate of completion upon course completion?</StyledTitleDesc>
                                        </Group>
                                        <Switch value={newCourse.settings.completionExperience.issuesCertificate} onChange={(issuesCertificate) => setNewCourse({ ...newCourse, settings: { ...newCourse.settings, completionExperience: { ...newCourse.settings.completionExperience, issuesCertificate } } })}></Switch>
                                    </Group>

                                    <StyledHR />
                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Theme</StyledChapterChildTitle>
                                            <StyledTitleDesc>Define course theme by selecting a color from the provided set of colors.</StyledTitleDesc>
                                        </Group>
                                        <TextInput
                                            size='sm'
                                            type="color"
                                            placeholder="Color"
                                            style={{
                                                maxWidth: '50px'
                                            }}
                                            onChange={color => setNewCourse({ ...newCourse, color })}
                                            value={newCourse.color}
                                        />
                                    </Group>

                                    <StyledHR />
                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Access to Course Materials After Completion</StyledChapterChildTitle>
                                            <StyledTitleDesc>Define duration students can still access course materials after course completion.</StyledTitleDesc>
                                        </Group>
                                        <Group direction='row' gap='xs'>
                                            <TextInput
                                                size='sm'
                                                type="number"
                                                placeholder="Duration"
                                                style={{
                                                    maxWidth: '50px'
                                                }}
                                                onChange={(unit) => setNewCourse({ ...newCourse, settings: { ...newCourse.settings, completionExperience: { ...newCourse.settings.completionExperience, access: { ...newCourse.settings.completionExperience.access, unit: +unit } } } })}
                                                value={newCourse.settings.completionExperience.access.unit.toString()}
                                            />
                                            <SelectInput
                                                size='sm'
                                                placeholder="Duration in"
                                                parentStyle={{ flex: 1 }}
                                                options={DurationInOptions}
                                                onChange={(_in) => setNewCourse({ ...newCourse, settings: { ...newCourse.settings, completionExperience: { ...newCourse.settings.completionExperience, access: { ...newCourse.settings.completionExperience.access, in: _in as CourseDurationIn } } } })}
                                                value={DurationInOptions.find(d => d.value === newCourse.settings.completionExperience.access.in)}
                                            />
                                        </Group>
                                    </Group>
                                    <StyledHR />

                                    <Group direction="row" justify="space-between" wrap="nowrap" align="center">
                                        <Group gap="none">
                                            <StyledChapterChildTitle>Tutor</StyledChapterChildTitle>
                                            <StyledTitleDesc>Select tutor from the provided list of tutors.</StyledTitleDesc>
                                        </Group>
                                        <SelectInput
                                            size='sm'
                                            placeholder="Tutor"
                                            onChange={(tutorId) => setNewCourse({ ...newCourse, tutorId })}
                                            options={(state.resources.activeTutors || []).map(({ sub: value, fullName: label }) => ({ value, label }))}
                                            value={(state.resources.activeTutors || []).map(({ sub: value, fullName: label }) => ({ value, label })).find(d => d.value === newCourse.tutorId)}
                                        />
                                    </Group>
                                </Group>

                                <br />
                                <Group direction="row" justify="end">
                                    {
                                        !courseDefaultValues.isPartialUpdate && <StepButton
                                            disabled={Steps.findIndex(step => step === newCourse.step) === 0}
                                            leftIcon={<CaretLeft size={14} />}
                                            onClick={moveBackward}
                                            text="Go Back"
                                        />
                                    }
                                    <StepButton
                                        loading={mutatingCourseAsync}
                                        rightIcon={<CaretRight size={14} />}
                                        onClick={moveForwardFromFourthSection}
                                        text={newCourse.id ? "Update" : "Create"}
                                    />
                                </Group>
                            </>
                        }
                    </Group>
                </Group>
            </StyledGroup>
        </Page>
    );
};


const LectureComponent: FC<{
    chapterUiid: string;
    onDelete: () => void;
    errors: Array<ChildErrors>;
    tests: Array<AllTestSchema>;
    lecture: CourseCurriculumChapterLecture;
    onChange: (lecture: CourseCurriculumChapterLecture) => void
}> = (props) => {

    const theme = useTheme()
    const { confirm } = useNotify()
    const { lecture, onChange, tests, chapterUiid, onDelete, errors } = props

    const toolbars = [
        {
            icon: <BlockquoteLeft />,
            text: CourseLectureResourceTypes.ARTICLES
        },
        {
            icon: <FiletypePng />,
            text: CourseLectureResourceTypes.PHOTOS
        },
        {
            icon: <FiletypeMp3 />,
            text: CourseLectureResourceTypes.AUDIOS
        },
        {
            icon: <FiletypeMp4 />,
            text: CourseLectureResourceTypes.VIDEOS
        },
        {
            icon: <FiletypePdf />,
            text: CourseLectureResourceTypes.DOCUMENTS
        },
        {
            icon: <FiletypePpt />,
            text: CourseLectureResourceTypes.PRESENTATIONS
        },
        {
            icon: <UiRadios />,
            text: CourseLectureResourceTypes.TESTS
        }
    ]

    const addLectureResource = (lectureResourceType: typeof CourseLectureResourceTypes[keyof typeof CourseLectureResourceTypes]) => {
        onChange({
            ...lecture,
            resources: [
                ...lecture.resources,
                {
                    text: "",
                    fileId: "",
                    lectureResourceType,
                    lectureResourceId: "",
                    downloadable: false,
                    uiid: uniqid()
                },
            ]
        })
    }

    const swapLectureResource = (fromIndex: number, toIndex: number) => {
        const toIndexValue = lecture.resources[toIndex]
        const fromIndexValue = lecture.resources[fromIndex]
        if (fromIndexValue && toIndexValue) {
            onChange({
                ...lecture,
                resources: lecture.resources.map((resource, resourceIndex) => resourceIndex === fromIndex ? toIndexValue : (resourceIndex === toIndex ? fromIndexValue : resource))
            })
        }
    }

    return (<Group key={lecture.uiid} style={{ flex: 1 }}>
        <Accordion>
            <StyledAccordionItem header={<StyledChapterChildTitle> <Group align='center' gap='xs' direction='row'>{childrenHasError(errors, `chapters.${chapterUiid}.lectures.${lecture.uiid}`) ? <ExclamationTriangle className='shake' color={theme.colors.danger} /> : null} {lecture.title}</Group> </StyledChapterChildTitle>}>
                <Group style={{ padding: '10px' }}>
                    <Group direction="row" align="center">
                        <TextInput
                            type="text"
                            isRequired
                            label="Subject"
                            placeholder="Subject"
                            value={lecture.title}
                            parentStyle={{ flex: 1 }}
                            error={findError(errors, `chapters.${chapterUiid}.lectures.${lecture.uiid}.title`)}
                            onChange={(title) => onChange({ ...lecture, title })}
                        />
                        <StyledIcon title='Delete Lecture' style={{ marginTop: '25px' }} onClick={() => confirm({ onConfirmation: onDelete })}>
                            <Trash style={{ color: theme.colors.danger }} />
                        </StyledIcon>
                    </Group>
                    <Group direction="row" style={{ color: theme.colors.black2 }} gap="xs" align="center">
                        <StyledSmall>Add lecture resource</StyledSmall>
                        {
                            toolbars.map(({ icon, text }, index) => <Group key={index} className={text === CourseLectureResourceTypes.TESTS && !tests.length ? "disabledEvents" : ""} onClick={() => addLectureResource(text)} direction="row" gap="xs" align="center">
                                <Group direction="row" gap="xs" className="pointer" style={{
                                    padding: '5px 10px',
                                    borderRadius: '3px',
                                    background: theme.colors.white
                                }}>
                                    {icon}
                                    <StyledSmall>{text}</StyledSmall>
                                </Group>
                                {index !== toolbars.length - 1 && <span>/</span>}
                            </Group>)
                        }
                    </Group>

                    {
                        lecture.resources.map((lectureResource, lectureResourceIndex) => <Group key={lectureResource.uiid} gap='sm' wrap='nowrap' style={{
                            borderRadius: '8px',
                            padding: '6px 14px 14px 14px',
                            background: theme.colors.white
                        }}>
                            <Group direction='row' align='center' justify='space-between'>
                                <Group style={{ flex: 1 }}>
                                    <CheckBoxInput
                                        centered={false}
                                        label='Downloadable resource'
                                        parentStyle={{ marginTop: '5px' }}
                                        onChange={(downloadable) => onChange({ ...lecture, resources: lecture.resources.map(_resource => _resource.uiid === lectureResource.uiid ? { ..._resource, downloadable } : _resource) })}
                                        value={lectureResource.downloadable}
                                    />
                                </Group>
                                <Group gap="xs" direction='row'>
                                    <StyledSpan style={{
                                        padding: '2px 4px 0px 4px',
                                        borderRadius: '4px',
                                        color: theme.colors.black, border: '2px solid ' + theme.colors.grey6
                                    }} onClick={() => swapLectureResource(lectureResourceIndex, lectureResourceIndex - 1)} className={`${lectureResourceIndex === 0 ? "disabled" : "pointer"}`}>
                                        <ArrowUpShort size={16} />
                                    </StyledSpan>
                                    <StyledSpan style={{
                                        padding: '2px 4px 0px 4px',
                                        borderRadius: '4px',
                                        color: theme.colors.black, border: '2px solid ' + theme.colors.grey6
                                    }} onClick={() => swapLectureResource(lectureResourceIndex, lectureResourceIndex + 1)} className={`${lectureResourceIndex === lecture.resources.length - 1 ? "disabled" : "pointer"}`}>
                                        <ArrowDownShort size={16} />
                                    </StyledSpan>
                                    <StyledSpan style={{
                                        padding: '2px 4px 0px 4px',
                                        borderRadius: '4px',
                                        color: theme.colors.danger,
                                        border: '2px solid ' + theme.colors.grey6
                                    }} className='pointer' onClick={() => confirm({
                                        onConfirmation: () => onChange({
                                            ...lecture,
                                            resources: lecture.resources.filter(({ uiid }) => uiid !== lectureResource.uiid),
                                        })
                                    })}>
                                        <X size={14} />
                                    </StyledSpan>
                                </Group>
                            </Group>

                            {
                                lectureResource.lectureResourceType === CourseLectureResourceTypes.ARTICLES && <Group style={{ flex: 1 }}>
                                    <RichText
                                        label=""
                                        isRequired
                                        parentStyle={{ flex: 1 }}
                                        value={lectureResource.text}
                                        placeholder={`Compose an epic${lecture.title?.trim() ? ' for ' + lecture.title.trim() : ''}`}
                                        error={findError(errors, `chapters.${chapterUiid}.lectures.${lecture.uiid}.resources.${lectureResource.uiid}.text`)}
                                        onChange={(text) => onChange({ ...lecture, resources: lecture.resources.map(_resource => _resource.uiid === lectureResource.uiid ? { ..._resource, text } : _resource) })}
                                    />
                                </Group>
                            }

                            {
                                lectureResource.lectureResourceType === CourseLectureResourceTypes.PHOTOS && <Group style={{ flex: 1 }}>
                                    <FileInput
                                        isRequired
                                        file={lectureResource.file}
                                        parentStyle={{ flex: 1 }}
                                        label={`Upload lecture's photo`}
                                        fileType={"PHOTOS"}
                                        error={findError(errors, `chapters.${chapterUiid}.lectures.${lecture.uiid}.resources.${lectureResource.uiid}.fileId`)}
                                        onChange={(file) => onChange({ ...lecture, resources: lecture.resources.map(_resource => _resource.uiid === lectureResource.uiid ? { ..._resource, file, fileId: file?.id } : _resource) })}
                                    />
                                </Group>
                            }

                            {
                                lectureResource.lectureResourceType === CourseLectureResourceTypes.AUDIOS && <Group style={{ flex: 1 }}>
                                    <FileInput
                                        isRequired
                                        file={lectureResource.file}
                                        parentStyle={{ flex: 1 }}
                                        label={`Upload lecture's audio`}
                                        fileType={"AUDIOS"}
                                        error={findError(errors, `chapters.${chapterUiid}.lectures.${lecture.uiid}.resources.${lectureResource.uiid}.fileId`)}
                                        onChange={(file) => onChange({ ...lecture, resources: lecture.resources.map(_resource => _resource.uiid === lectureResource.uiid ? { ..._resource, file, fileId: file?.id } : _resource) })}
                                    />
                                </Group>
                            }

                            {
                                lectureResource.lectureResourceType === CourseLectureResourceTypes.VIDEOS && <Group style={{ flex: 1 }}>
                                    <FileInput
                                        isRequired
                                        file={lectureResource.file}
                                        parentStyle={{ flex: 1 }}
                                        label={`Upload lecture's video`}
                                        fileType={"VIDEOS"}
                                        error={findError(errors, `chapters.${chapterUiid}.lectures.${lecture.uiid}.resources.${lectureResource.uiid}.fileId`)}
                                        onChange={(file) => onChange({ ...lecture, resources: lecture.resources.map(_resource => _resource.uiid === lectureResource.uiid ? { ..._resource, file, fileId: file?.id } : _resource) })}
                                    />
                                </Group>
                            }

                            {
                                lectureResource.lectureResourceType === CourseLectureResourceTypes.DOCUMENTS && <Group style={{ flex: 1 }}>
                                    <FileInput
                                        isRequired
                                        file={lectureResource.file}
                                        parentStyle={{ flex: 1 }}
                                        label={`Upload lecture's document`}
                                        fileType={"DOCUMENTS"}
                                        error={findError(errors, `chapters.${chapterUiid}.lectures.${lecture.uiid}.resources.${lectureResource.uiid}.fileId`)}
                                        onChange={(file) => onChange({ ...lecture, resources: lecture.resources.map(_resource => _resource.uiid === lectureResource.uiid ? { ..._resource, file, fileId: file?.id } : _resource) })}
                                    />
                                </Group>
                            }

                            {
                                lectureResource.lectureResourceType === CourseLectureResourceTypes.PRESENTATIONS && <Group style={{ flex: 1 }}>
                                    <FileInput
                                        isRequired
                                        file={lectureResource.file}
                                        parentStyle={{ flex: 1 }}
                                        label={`Upload lecture's presentation file`}
                                        fileType={"PRESENTATIONS"}
                                        error={findError(errors, `chapters.${chapterUiid}.lectures.${lecture.uiid}.resources.${lectureResource.uiid}.fileId`)}
                                        onChange={(file) => onChange({ ...lecture, resources: lecture.resources.map(_resource => _resource.uiid === lectureResource.uiid ? { ..._resource, file, fileId: file?.id } : _resource) })}
                                    />
                                </Group>
                            }

                            {
                                lectureResource.lectureResourceType === CourseLectureResourceTypes.TESTS && <Group style={{ flex: 1 }}>
                                    <SelectInput
                                        isRequired
                                        label="Test"
                                        placeholder="Test"
                                        parentStyle={{ flex: 1 }}
                                        options={tests.map(test => ({ value: test.testId, label: test.title }))}
                                        error={findError(errors, `chapters.${chapterUiid}.lectures.${lecture.uiid}.resources.${lectureResource.uiid}.testId`)}
                                        onChange={testId => onChange({ ...lecture, resources: lecture.resources.map(_resource => _resource.uiid === lectureResource.uiid ? { ..._resource, testId } : _resource) })}
                                        value={tests.map(test => ({ value: test.testId, label: test.title })).find(d => d.value === lectureResource.testId)}
                                    />
                                </Group>
                            }

                        </Group>)
                    }
                </Group>
            </StyledAccordionItem>
        </Accordion>
    </Group>
    )
}