import { useState } from "react"
import { StepButton } from "../Button"
import { FileUpload } from "../FileUpload"
import { FileTypes } from "shared-library"
import { Group } from "../../Layout/Group/Group"
import { ProgressStep } from "../../App/ProgressStep"
import { CloudDownload, CaretLeft, CaretRight, CloudUpload } from "react-bootstrap-icons"
import { StyledTitle, StyledTitleDesc, StyledSampleFileDownloadContainer, StyledSmall } from "../../Elements"


interface BulkUploaderProps<T> {
    schema: T;
    loading?: boolean;
    onDataReady: (data: Array<T>) => void
}

enum Steps {
    upload = "2",
    download = "1",
}

export const BulkUploader = <T,>({ schema, onDataReady, loading = false }: BulkUploaderProps<T>) => {

    const [data, setData] = useState<Array<T>>([])
    const [currentStep, setCurrentStep] = useState(Steps.download)

    const generateCsv = () => {
        const csvContent = "data:text/csv;charset=utf-8," + Object.keys(schema as object).join(',') + "\n";
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'bulkUpload.csv');
        document.body.appendChild(link);
        link.click();
    };

    const hasNonEmptyValue = (obj: T): boolean => {
        for (const key in obj) {
            if (obj?.hasOwnProperty(key) && obj[key]) {
                return true;
            }
        }
        return false;
    }

    const handleFileUpload = (file: File) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target) {
                const result = event.target.result as string;
                const rows = result.split('\n').slice(1);
                const newData: T[] = rows.map(row => {
                    const rows = row.split(',');
                    let _index = 0
                    let oneRow = {} as { [K in keyof T]: any };
                    for (const key in schema) {
                        oneRow[key] = ""
                        if (rows.length > _index) {
                            oneRow[key] = rows[_index]
                        }
                        _index++
                    }
                    return oneRow;
                });
                setData(newData.filter(hasNonEmptyValue));
            }
        };
        reader.readAsText(file);
    };

    return (
        <>
            <Group>
                <Group gap="xs">
                    <StyledTitle>
                        <b>Step {currentStep}</b>
                    </StyledTitle>
                    <ProgressStep totalSteps={Object.keys(Steps).length} active={+currentStep} />
                    <StyledTitleDesc>
                        {
                            currentStep === Steps.download && <>Please locate the section below and click on it to initiate the download of the bulk upload template CSV file, do not alter the first row.</>
                        }
                        {
                            currentStep === Steps.upload && <>Please locate the section below and click on it or drag the downloaded file to initiate bulk upload from the template CSV file.</>
                        }
                    </StyledTitleDesc>
                </Group>
                {
                    currentStep === Steps.download && <><StyledSampleFileDownloadContainer onClick={generateCsv} align="center" direction="row">
                        <CloudDownload />
                        <small><u>Click here</u> to download template CSV file</small>
                    </StyledSampleFileDownloadContainer>
                        <br />
                    </>
                }
                {
                    currentStep === Steps.upload && <FileUpload
                        types={[FileTypes.CSV]}
                        label="Bulk create"
                        isRequired
                        placeholder="Browse or drop CSV file here."
                        onChange={(file) => handleFileUpload(file as File)}
                    />
                }
                {
                    data.length > 0 && <>
                        <StyledSmall><i><b>{data.length}</b> record(s)</i></StyledSmall>
                    </>
                }
            </Group>
            <Group direction="row">
                <StepButton
                    disabled={currentStep === Steps.download}
                    leftIcon={<CaretLeft size={14} />}
                    onClick={() => setCurrentStep(Steps.download)}
                    text="Prev"
                />
                {
                    data.length && currentStep === Steps.upload ? <StepButton
                        rightIcon={<CloudUpload size={14} />}
                        onClick={() => onDataReady(data)}
                        loading={loading}
                        text={`Upload`}
                    /> : <StepButton
                        disabled={currentStep === Steps.upload}
                        rightIcon={<CaretRight size={14} />}
                        onClick={() => setCurrentStep(Steps.upload)}
                        text="Next"
                    />
                }
            </Group>
        </>
    )
}