import moment from "moment";
import { trimEnd } from "lodash";
import { Config } from "../config";
import { useContext } from "react";
import { useFetch } from "./use-fetch";
import { themes } from "../utils/data";
import { AuthContext } from "../context/auth";
import { CompanyContext } from "../context/company";
import { useConsoleLog } from "./use-console-log.hook";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { durationFromVideoFile } from "../utils/durationFromVideoFile";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { CognitoUserAttribute, CognitoUserPool } from "amazon-cognito-identity-js";
import { RoleTypes, sortAlphabetically, sortByCreationDate, randomID, MoreUserDetails, UserInformation, MoreUserDetailsSession, UserStatEnrollment, Course, CourseForm, CourseGroupResourceTypes, CourseStatus, CourseTabPills, Company, CompanyBillingAddress, CompanyBillingFullAddress, Invoice, InvoiceStatus, PaymentMethods, Test, TestAttempt, TestStatus, NewStudentForm, Student, StudentStatus, NewTutorForm, Tutor, TutorStatus, Policy, Review, Enrollment, EnrollmentStatus, Notification, NotificationStatus, Event, AuthStateResourcesValues, FolderFile, Paginated, ErrorCodes, Certificate, DashboardData, AppThemes, RequestResponse, PageFiltersValues, RecordTypes, ResponseStatusMessages, DynamicConfig, getSizeFromFile, encodeBase64, UserInformationUpdateProp, CompanyInformationUpdateProp, EnrollmentActions } from "shared-library";


export const useApi = () => {

    const { log } = useConsoleLog();
    const { state } = useContext(AuthContext);
    const { get, remove, post, patch, put } = useFetch();
    const { state: AppCompany } = useContext(CompanyContext);

    const getDomainDetails = async ({ domain }: { domain: string; }): Promise<{ company: Company; config: DynamicConfig; }> => {
        const { data } = await get<RequestResponse<{ company: Company; config: DynamicConfig; }>>(`${Config.APIBaseUrl}company?domain=${domain}`);

        data.company.theme = themes.find(({ id }) => id === data.company.themeId)
        if (!data.company.theme) {
            data.company.theme = themes.find(({ isDefault }) => isDefault) as AppThemes
        }
        log({ getDomainDetails: data })
        return data
    }

    const getSessionDetails = async ({ jwt }: { jwt: string; }): Promise<MoreUserDetails> => {
        const companyId = AppCompany.company?.id as string
        log(companyId)
        const { data } = await get<RequestResponse<MoreUserDetails>>(`${Config.APIBaseUrl}auth/session`, {
            headers: {
                "X-Client-Id": companyId,
                Authorization: `Bearer ${jwt}`,
                "Content-Type": "application/json",
            },
        });
        log({ getSessionDetails: data })
        return data
    }

    const endSession = async ({ sessionSortKey }: { sessionSortKey: string; }): Promise<boolean> => {
        const companyId = AppCompany.company?.id as string
        log(sessionSortKey)
        try {
            const { data } = await remove<RequestResponse<true>>(`${Config.APIBaseUrl}auth/session`, {}, {
                headers: {
                    "X-Client-Id": companyId,
                    "X-Session-Id": sessionSortKey,
                    "Content-Type": "application/json",
                },
            });
            log({ endSession: data.data })
            return data.data
        } catch (error) {
            log(error)
        }
        return false
    }

    const manageSessionProfile = async (role?: RoleTypes): Promise<RoleTypes> => {
        const { data } = role ? await put<RequestResponse<RoleTypes>>(`${Config.APIBaseUrl}auth/session/profile/${role}`, {}) : await remove<RequestResponse<RoleTypes>>(`${Config.APIBaseUrl}auth/session/profile`)
        log({ manageSessionProfile: data.data })
        return data.data
    }

    const getTutors = async ({
        status,
        limit,
        query,
        filter,
        nextBatchIndex
    }: {
        limit: number;
        query?: string;
        status?: TutorStatus;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
    }): Promise<Paginated<Tutor>> => {
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(query ? { query } : {}),
            ...(filter ? { filter } : {}),
            ...(status ? { status } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {})
        }).toString();
        const { data } = await get<RequestResponse<Paginated<Tutor>>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/tutors?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getTutors: data })
        return data
    }

    const getResources = async (resourceTypes: Array<keyof AuthStateResourcesValues>): Promise<AuthStateResourcesValues> => {
        const { data } = await get<RequestResponse<AuthStateResourcesValues>>(`${Config.APIBaseUrl}resources?resourceTypes=${resourceTypes.join(',')}`);
        log({ getResources: data })
        return data
    }

    const createTutors = async (newTutors: Array<NewTutorForm>): Promise<true> => {
        const { data } = await post<RequestResponse<true>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/tutors`, newTutors);
        log({ createTutors: data })
        return data.data
    }

    const getStudents = async ({
        status,
        limit,
        query,
        filter,
        nextBatchIndex
    }: {
        limit: number;
        query?: string;
        status?: StudentStatus;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
    }): Promise<Paginated<Student>> => {
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(query ? { query } : {}),
            ...(filter ? { filter } : {}),
            ...(status ? { status } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {})
        }).toString();
        const { data } = await get<RequestResponse<Paginated<Student>>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/students?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getStudents: data })
        return data
    }

    const createStudents = async (newStudents: Array<NewStudentForm>): Promise<true> => {
        const { data } = await post<RequestResponse<true>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/students`, newStudents);
        log({ createStudents: data })
        return data.data
    }

    const getCourses = async ({
        status,
        limit,
        query,
        filter,
        nextBatchIndex,
        enrollmentStatus
    }: {
        limit: number;
        query?: string;
        status?: CourseStatus;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
        enrollmentStatus?: CourseTabPills;
    }): Promise<Paginated<Course>> => {

        if (state.user?.info.session.role === RoleTypes.STUDENT) {
            status = CourseStatus.ACTIVE
        }
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(query ? { query } : {}),
            ...(filter ? { filter } : {}),
            ...(status ? { status } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {}),
            ...(enrollmentStatus ? { enrollmentStatus } : {})
        }).toString();
        const { data } = await get<RequestResponse<Paginated<Course>>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/courses?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getCourses: data })
        return data
    }

    const createCourse = async (newCourse: CourseForm): Promise<Course> => {
        const { data } = await post<RequestResponse<Course>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/courses`, newCourse);
        log({ createCourse: data })
        return data.data
    }

    const getCourse = async (courseId: string): Promise<Course> => {
        const { data } = await get<RequestResponse<Course>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/courses/${courseId}`);
        log({ getCourse: data })
        return data
    }

    const getNotifications = async ({
        limit,
        status,
        nextBatchIndex
    }: {
        limit: number;
        nextBatchIndex?: string;
        status?: NotificationStatus;
    }): Promise<Paginated<Notification>> => {
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(status ? { status } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {})
        }).toString();
        const { data } = await get<RequestResponse<Paginated<Notification>>>(`${Config.APIBaseUrl}user/notifications?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getNotifications: data })
        return data
    }

    const markNotificationsAsRead = async (notificationSortKeys: string[]): Promise<boolean> => {
        const { data } = await post<RequestResponse<boolean>>(`${Config.APIBaseUrl}user/notifications`, { notificationSortKeys });
        log({ markNotificationsAsRead: data })
        return data.data
    }

    const deleteNotifications = async (notificationSortKeys: string[]): Promise<boolean> => {
        const { data } = await patch<RequestResponse<boolean>>(`${Config.APIBaseUrl}user/notifications`, { notificationSortKeys });
        log({ deleteNotifications: data })
        return data.data
    }

    const createUpdateTest = async (test: Test): Promise<Test> => {
        test.status = TestStatus.ACTIVE
        const { data } = test.id ? await patch<RequestResponse<Test>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/tests`, test) : await post<RequestResponse<Test>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/tests`, test);
        log({ createUpdateTest: data })
        return data.data
    }

    const getTests = async ({
        limit,
        query,
        status,
        filter,
        nextBatchIndex
    }: {
        limit: number;
        query?: string;
        status?: TestStatus;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
    }): Promise<Paginated<Test>> => {
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(query ? { query } : {}),
            ...(filter ? { filter } : {}),
            ...(status ? { status } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {})
        }).toString();
        const { data } = await get<RequestResponse<Paginated<Test>>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/tests?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getTests: data })
        return data
    }

    const getTest = async (testId: string): Promise<Test> => {
        const { data } = await get<RequestResponse<Test>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/tests/${testId}`);
        log({ getTest: data })
        return data
    }

    const getFiles = async ({
        limit,
        query,
        filter,
        _fileType,
        nextBatchIndex
    }: {
        limit: number;
        query?: string;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
        _fileType?: keyof typeof CourseGroupResourceTypes;
    }): Promise<Paginated<FolderFile>> => {
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(query ? { query } : {}),
            ...(filter ? { filter } : {}),
            ...(_fileType ? { _fileType } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {})
        }).toString();

        const { data } = await get<RequestResponse<Paginated<FolderFile>>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/storage?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getFiles: data })
        return data
    }

    const uploadFile = async ({ file, fileType, isPublic }: { isPublic: boolean; file: File; fileType: keyof typeof CourseGroupResourceTypes; }): Promise<FolderFile> => {
        if (!AppCompany.config) throw ErrorCodes.NO_UPDATABLE_PARAM

        const ext = file.name.split('.').pop()?.toLowerCase() || ""
        const filePath = `${AppCompany.company?.id}/${moment().format("YYYYMMDDHHmmss")}-${randomID(12)}.${ext}`;
        const s3Key = `${AppCompany.config?.storage.uploadPath}${filePath}`;

        const s3Client = new S3Client({
            credentials: fromCognitoIdentityPool({
                identityPoolId: AppCompany.config.auth.identityPoolId,
                clientConfig: { region: AppCompany.config.storage.region },
                logins: {
                    [`cognito-idp.${AppCompany.config.storage.region}.amazonaws.com/${AppCompany.config.auth.userPoolId}`]: state.user?.jwt as string
                }
            }),
            region: AppCompany.config.storage.region
        });

        let durationInSeconds = 0;
        let durationInSecondsPromise: Promise<number> | undefined;
        const uploadCommand = new PutObjectCommand({
            Body: file,
            Key: s3Key,
            ContentType: file.type,
            // ACL: isPublic ? 'public-read' : 'private',
            ACL: 'private',
            Bucket: AppCompany.config?.storage.bucketName
        });

        if (fileType === "VIDEOS") {
            durationInSecondsPromise = durationFromVideoFile(file);
        }

        const response = await s3Client.send(uploadCommand);
        if (durationInSecondsPromise) {
            durationInSeconds = await durationInSecondsPromise
        }

        const folderFile: FolderFile = {
            ext,
            s3Key,
            id: "",
            fileType,
            isPublic,
            sortKey: "",
            companyId: "",
            creatorSub: "",
            creationDate: "",
            durationInSeconds,
            recordType: RecordTypes.COMPANYFILE,
            fileSize: getSizeFromFile(file.size),
            url: `${AppCompany.config.storage.bucketURL}${filePath}`,
            fileName: trimEnd(file.name, `.${file.name.split('.').pop()}`)
        }
        log(response)
        const { data } = await post<RequestResponse<FolderFile>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/storage`, folderFile);
        log({ uploadFile: data })
        return data.data
    }

    const updateFile = async (file: FolderFile): Promise<FolderFile> => {
        const { data } = await patch<RequestResponse<FolderFile>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/storage/${file.id}`, file);
        log({ updateFile: data })
        return data.data
    }

    const deleteFile = async (file: FolderFile): Promise<boolean> => {
        const { data } = await remove<RequestResponse<true>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/storage/${file.id}`)
        log({ deleteFile: data.data })
        return data.data
    }

    const getEvents = async (prefix: string): Promise<Array<Event>> => {
        const { data } = await get<RequestResponse<Array<Event>>>(`${Config.APIBaseUrl}user/events/${prefix}`);
        log({ getEvents: data })
        return data
    }

    const createEvent = async (event: Event): Promise<Event> => {
        const { data } = await post<RequestResponse<Event>>(`${Config.APIBaseUrl}user/events`, event);
        log({ createEvent: data })
        return data.data
    }

    const updateEvent = async (event: Event): Promise<Event> => {
        const { data } = await patch<RequestResponse<Event>>(`${Config.APIBaseUrl}user/events`, event);
        log({ updateEvent: data })
        return data.data
    }

    const deleteEvent = async ({ sortKey }: Event): Promise<boolean> => {
        const { data } = await remove<RequestResponse<boolean>>(`${Config.APIBaseUrl}user/events/${encodeBase64(sortKey)}`);
        log({ deleteEvent: data })
        return data.data
    }

    const getDashboardData = async (): Promise<DashboardData> => {
        const { data } = await get<RequestResponse<DashboardData>>(`${Config.APIBaseUrl}user/dashboard`);
        log({ getDashboardData: data })
        return data
    }

    const getTestAttempt = async ({ testId, courseId }: { testId: string; courseId?: string; }): Promise<TestAttempt> => {
        const { data } = await post<RequestResponse<TestAttempt>>(`${Config.APIBaseUrl}user/tests/attempts/${testId}`, { courseId });
        log({ getTestAttempt: data })
        return data.data
    }

    const getTestAttempts = async ({
        limit,
        testId,
        userSubs = [],
        courseIds = [],
        nextBatchIndex
    }: {
        limit: number;
        testId: string;
        nextBatchIndex?: string;
        userSubs?: Array<string>;
        courseIds?: Array<string>;
    }): Promise<Paginated<TestAttempt>> => {

        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(nextBatchIndex ? { nextBatchIndex } : {}),
            ...(userSubs ? { userSubs: userSubs.join(',') } : {}),
            ...(courseIds ? { courseIds: courseIds.join(',') } : {}),
        }).toString();
        const { data } = await get<RequestResponse<Paginated<TestAttempt>>>(`${Config.APIBaseUrl}user/tests/attempts/${testId}?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getTestAttempts: data })
        return data
    }

    const submitTestAnswer = async ({ attemptId, testId, questionId, answers, actionType }: { attemptId: string; questionId: string; testId: string; answers: Array<string>; actionType: "time-out" | "submit" | "next-question" | "prev-question" | "get-answers"; }): Promise<TestAttempt> => {
        const { data } = await post<RequestResponse<TestAttempt>>(`${Config.APIBaseUrl}user/tests/${actionType}/${testId}`, { attemptId, answers, questionId });
        log({ submitTestAnswer: data })
        return data.data
    }

    const updatePersonalInformationDetails = async ({
        dob,
        email,
        mobile,
        picture,
        address,
        website,
        timezone,
        lastName,
        fullName,
        nickName,
        firstName,
    }: {
        dob?: string;
        email?: string;
        mobile?: string;
        picture?: string;
        address?: string;
        website?: string;
        timezone?: string;
        lastName?: string;
        fullName?: string;
        nickName?: string;
        firstName?: string;
    }): Promise<boolean> => {

        const userPool = new CognitoUserPool({
            UserPoolId: AppCompany.config?.auth.userPoolId as string,
            ClientId: AppCompany.config?.auth.userPoolClientId as string
        });

        const user = userPool.getCurrentUser()
        const attributes = [
            ...(email !== undefined ? [new CognitoUserAttribute({ Name: 'email', Value: email })] : []),
            ...(dob !== undefined ? [new CognitoUserAttribute({ Name: 'birthdate', Value: dob })] : []),
            ...(fullName !== undefined ? [new CognitoUserAttribute({ Name: 'name', Value: fullName })] : []),
            ...(picture !== undefined ? [new CognitoUserAttribute({ Name: 'picture', Value: picture })] : []),
            ...(address !== undefined ? [new CognitoUserAttribute({ Name: 'address', Value: address })] : []),
            ...(website !== undefined ? [new CognitoUserAttribute({ Name: 'website', Value: website })] : []),
            ...(timezone !== undefined ? [new CognitoUserAttribute({ Name: 'locale', Value: timezone })] : []),
            ...(nickName !== undefined ? [new CognitoUserAttribute({ Name: 'nickname', Value: nickName })] : []),
            ...(mobile !== undefined ? [new CognitoUserAttribute({ Name: 'phone_number', Value: mobile })] : []),
            ...(lastName !== undefined ? [new CognitoUserAttribute({ Name: 'family_name', Value: lastName })] : []),
            ...(firstName !== undefined ? [new CognitoUserAttribute({ Name: 'given_name', Value: firstName })] : []),
        ];

        const { data } = await new Promise<RequestResponse<boolean>>(async (resolve, reject) => {
            if (!attributes.length) {
                return reject(ErrorCodes.NO_UPDATABLE_PARAM)
            }
            if (!user) return reject(ErrorCodes.USER_NOT_FOUND)
            await new Promise(res => user.getSession(res));
            user.updateAttributes(attributes, async (error) => {
                if (error) return reject(error.message);
                try {
                    log({ sync: await syncUserinfo() })
                } catch (error) {
                }
                resolve({
                    statusMessage: ResponseStatusMessages.SUCCESS,
                    data: true
                })
            });
        })
        return data
    };

    const updateUserinfo = async ({ userInformation, prop }: { userInformation: UserInformation; prop: UserInformationUpdateProp }): Promise<UserInformation> => {
        const { data } = await patch<RequestResponse<UserInformation>>(`${Config.APIBaseUrl}user/settings/user-info`, { userInformation, prop });
        const address = [
            userInformation.fullAddress.line1,
            userInformation.fullAddress.line2,
            userInformation.fullAddress.zip,
            userInformation.fullAddress.city,
            userInformation.fullAddress.state,
            userInformation.fullAddress.country,
        ].filter(v => v).join(", ")
        await updatePersonalInformationDetails({ address })
        return data.data
    }

    const syncUserinfo = async (): Promise<boolean> => {
        const { data } = await put<RequestResponse<boolean>>(`${Config.APIBaseUrl}user/settings/user-info`, {});
        return data.data
    }

    const getUserinfo = async (): Promise<UserInformation> => {
        const { data } = await get<RequestResponse<UserInformation>>(`${Config.APIBaseUrl}user/settings/user-info`);
        log({ getUserinfo: data })
        return data
    }

    const changePassword = async ({
        oldPassword,
        newPassword
    }: {
        oldPassword: string;
        newPassword: string;
    }): Promise<true> => {

        const userPool = new CognitoUserPool({
            UserPoolId: AppCompany.config?.auth.userPoolId as string,
            ClientId: AppCompany.config?.auth.userPoolClientId as string
        });

        const user = userPool.getCurrentUser()
        const { data } = await new Promise<RequestResponse<true>>(async (resolve, reject) => {
            if (!user) return reject(ErrorCodes.USER_NOT_FOUND)
            await new Promise(res => user.getSession(res));
            user.changePassword(oldPassword, newPassword, (err) => {
                if (err) return reject(err.message)
                resolve({
                    statusMessage: ResponseStatusMessages.SUCCESS,
                    data: true
                })
            });
        })
        return data
    };

    const updateCompanyInfo = async ({ companyInformation, prop }: { companyInformation: Company; prop: CompanyInformationUpdateProp }): Promise<Company> => {
        const { data } = await patch<RequestResponse<Company>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/settings`, { companyInformation, prop });
        log({ updateCompanyInfo: data })
        return data.data
    }

    const updateCompanyBillingAddress = async ({ primary, fullAddress }: {
        primary?: string;
        fullAddress: CompanyBillingFullAddress;
    }): Promise<CompanyBillingAddress> => {
        const { data } = await post<RequestResponse<CompanyBillingAddress>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/settings`, { billingAddress: { fullAddress, primary } });
        log({ updateCompanyBillingAddress: data })
        return data.data
    }

    // export const signUp = (email: string, password: string) => {
    //     return new Promise((resolve, reject) => {
    //         userPool.signUp(email, password, [], [], (err, result) => {
    //             if (err) {
    //                 reject(err);
    //                 return;
    //             }
    //             if (!result) {
    //                 reject(`Unknown error occurred`);
    //                 return;
    //             }
    //             resolve(result.user);
    //         });
    //     });
    // };

    const getLoginActivities = async ({
        limit,
        query,
        nextBatchIndex,
        filter = PageFiltersValues.latestFirst
    }: {
        limit: number;
        query?: string;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
    }): Promise<Paginated<MoreUserDetailsSession>> => {
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(query ? { query } : {}),
            ...(filter ? { filter } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {})
        }).toString();
        const { data } = await get<RequestResponse<Paginated<MoreUserDetailsSession>>>(`${Config.APIBaseUrl}user/settings/login-activities?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getLoginActivities: data })
        return data
    }

    const getInvoices = async ({
        limit,
        query,
        studentId,
        nextBatchIndex,
        filter = PageFiltersValues.latestFirst
    }: {
        limit: number;
        query?: string;
        studentId?: string;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
    }): Promise<Paginated<Invoice>> => {
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(query ? { query } : {}),
            ...(filter ? { filter } : {}),
            ...(studentId ? { studentId } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {})
        }).toString();
        const { data } = await get<RequestResponse<Paginated<Invoice>>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/settings/invoices?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getInvoices: data })
        return data
    }

    const getPolicies = async (): Promise<Array<Policy>> => {
        const { data } = await get<RequestResponse<Array<Policy>>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/policies`);
        log({ getPolicies: data })
        return data
    }

    const createUpdatePolicy = async (policy: Policy): Promise<Policy> => {
        if (policy.file?.id !== policy.fileId) {
            policy.fileId = policy.file?.id
        }
        delete policy.file
        const { data } = policy.id ? await patch<RequestResponse<Policy>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/policies`, policy) : await post<RequestResponse<Policy>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/policies`, policy);
        log({ createUpdatePolicy: data })
        return data.data
    }

    const deletePolicy = async (policyId: string): Promise<boolean> => {
        const { data } = await remove<RequestResponse<true>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/policies/${policyId}`)
        log({ deletePolicy: data.data })
        return data.data
    }

    const getCertificates = async ({
        limit,
        query,
        courseId,
        studentId,
        nextBatchIndex,
        filter = PageFiltersValues.latestFirst
    }: {
        limit: number;
        query?: string;
        courseId?: string;
        studentId?: string;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
    }): Promise<Paginated<Certificate>> => {
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(query ? { query } : {}),
            ...(filter ? { filter } : {}),
            ...(courseId ? { courseId } : {}),
            ...(studentId ? { studentId } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {})
        }).toString();
        const { data } = await get<RequestResponse<Paginated<Certificate>>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/certificates?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getCertificates: data })
        return data
    }

    const createUpdateCertificate = async (certificate: Certificate): Promise<Certificate> => {

        if (certificate.file && certificate.file.id !== certificate.fileId) {
            certificate.fileId = certificate.file.id
        }
        delete certificate.file
        const { data } = certificate.id ? await patch<RequestResponse<Certificate>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/certificates`, certificate) : await post<RequestResponse<Certificate>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/certificates`, certificate);
        log({ createUpdateCertificate: data })
        return data.data
    }

    const deleteCertificate = async (certificateSortKey: string): Promise<boolean> => {
        const { data } = await remove<RequestResponse<true>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/certificates/${encodeBase64(certificateSortKey)}`)
        log({ deleteCertificate: data })
        return data.data
    }

    const mail = async ({ emails, body, subject }: { emails: Array<string>; body: string; subject: string; }): Promise<true> => {
        const { data } = await new Promise<RequestResponse<true>>((resolve) => {
            resolve({
                statusMessage: ResponseStatusMessages.SUCCESS,
                data: true
            })
        })
        log({ mail: data })
        return data
    }

    const terminateEnrollment = async ({ enrollmentSortKey }: {
        enrollmentSortKey: string;
    }): Promise<Enrollment> => {
        const { data } = await remove<RequestResponse<Enrollment>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/enrollments/${encodeBase64(enrollmentSortKey)}`)
        log({ terminateEnrollment: data })
        return data.data
    }

    const fixEnrollmentActionRequired = async ({ enrollmentSortKey, actionRequired }: {
        enrollmentSortKey: string;
        actionRequired: EnrollmentActions;
    }): Promise<boolean> => {
        const { data } = await patch<RequestResponse<boolean>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/enrollments`, { enrollmentSortKey, actionRequired })
        log({ fixEnrollmentActionRequired: data })
        return data.data
    }

    const getEnrollments = async ({
        limit,
        filter,
        courseIds,
        statusIds,
        studentIds,
        nextBatchIndex
    }: {
        limit: number;
        nextBatchIndex?: string;
        courseIds?: Array<string>;
        studentIds?: Array<string>;
        filter?: PageFiltersValues;
        statusIds?: Array<EnrollmentStatus>;
    }): Promise<Paginated<Enrollment>> => {
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(filter ? { filter } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {}),
            ...(statusIds ? { statusIds: statusIds.join(',') } : {}),
            ...(courseIds ? { courseIds: courseIds.join(',') } : {}),
            ...(studentIds ? { studentIds: studentIds.join(',') } : {}),
        }).toString();
        const { data } = await get<RequestResponse<Paginated<Enrollment>>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/enrollments?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getEnrollments: data })
        return data
    }

    const enrollAStudent = async (params: {
        courseId: string;
        studentId: string;
        enrollmentId?: string;
        paymentStatus: InvoiceStatus;
        paymentMethod: PaymentMethods;
    }): Promise<Enrollment> => {
        const { data } = await post<RequestResponse<Enrollment>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/enrollments`, params);
        log({ enrollAStudent: data })
        return data.data
    }

    const enroll = async (param: {
        courseId: string;
    }): Promise<Enrollment> => {
        const { data } = await put<RequestResponse<Enrollment>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/enrollments`, param)
        log({ enrollAStudent: data })
        return data.data
    }

    const submitLearningProgress = async ({ courseId, lectureId, actionType }: { courseId: string; lectureId?: string; actionType: "start-course" | "finish-lecture"; }): Promise<UserStatEnrollment> => {
        const { data } = await post<RequestResponse<UserStatEnrollment>>(`${Config.APIBaseUrl}user/learning/${actionType}`, { courseId, lectureId });
        log({ submitLearningProgress: data })
        return data.data
    }

    const getCourseReviews = async ({
        limit,
        query,
        courseId,
        nextBatchIndex
    }: {
        limit: number;
        query?: string;
        courseId: string;
        nextBatchIndex?: string;
    }): Promise<Paginated<Review>> => {
        const qparam = new URLSearchParams({
            limit: limit.toString(),
            ...(query ? { query } : {}),
            ...(nextBatchIndex ? { nextBatchIndex } : {}),
        }).toString();
        const { data } = await get<RequestResponse<Paginated<Review>>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/reviews/courses/${courseId}?${qparam}`);
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getCourseReviews: data })
        return data
    }

    const deleteReviews = async ({ reviewIds, courseId }: { courseId: string; reviewIds: string[] }): Promise<boolean> => {
        const { data } = await patch<RequestResponse<true>>(`${Config.APIBaseUrl}company/${state.user?.info.session.role}/reviews/courses/${courseId}`, { reviewIds })
        log({ deleteReviews: data })
        return data.data
    }

    const submitAReview = async ({
        rating,
        courseId,
        tutorId,
        reviewId,
        description,
    }: {
        rating: number;
        tutorId?: string;
        reviewId?: string;
        courseId?: string;
        description: string;
    }): Promise<Review> => {
        const param = {
            rating,
            reviewId,
            description
        };

        let url = `${Config.APIBaseUrl}company/${state.user?.info.session.role}/reviews/courses/${courseId}`
        if (tutorId) {
            url = `${Config.APIBaseUrl}company/${state.user?.info.session.role}/reviews/tutors/${tutorId}`
        }
        const { data } = await post<RequestResponse<Review>>(url, param)
        log({ submitAReview: data })
        return data.data
    }

    const getDownloadableCourseMaterials = async ({
        limit,
        query,
        course,
        filter,
        enrollment,
        nextBatchIndex
    }: {
        query: string;
        limit: number;
        course: Course;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
        enrollment: UserStatEnrollment;
    }): Promise<Paginated<FolderFile>> => {
        // This should not be an api call
        const { data } = await new Promise<RequestResponse<Paginated<FolderFile>>>((resolve) => {
            let data: Array<FolderFile> = []
            for (const chapter of course.curriculum.chapters) {
                for (const lecture of chapter.lectures) {
                    if (!enrollment.lectures.find(({ lectureId, startDate }) => lectureId === lecture.lectureId && startDate)) {
                        continue
                    }
                    for (const item of lecture.resources) {
                        if (item.downloadable && item.file) {
                            data.push(item.file)
                        }
                    }
                }
            }

            if (query) data = data.filter(d => JSON.stringify(d).toLowerCase().includes(query.toLowerCase()))
            if (filter === PageFiltersValues.zaSort) {
                data = sortAlphabetically(data, "fileName").reverse()
            }
            if (filter === PageFiltersValues.azSort) {
                data = sortAlphabetically(data, "fileName")
            }
            if (filter === PageFiltersValues.latestLast) {
                data = sortByCreationDate(data, "creationDate", true)
            }
            if (filter === PageFiltersValues.latestFirst) {
                data = sortByCreationDate(data, "creationDate", false)
            }

            if (nextBatchIndex) {
                const nextBatchIndexInArray = data.findIndex(d => d.id === nextBatchIndex)
                if (nextBatchIndexInArray >= -1) {
                    data = data.slice(nextBatchIndexInArray)
                }
            }
            if (limit) {
                if (data.length > limit) nextBatchIndex = data[limit].id
                else nextBatchIndex = ""
                data = data.slice(0, limit)
            }

            resolve({
                statusMessage: ResponseStatusMessages.SUCCESS,
                data: { nextBatchIndex, data }
            })
        })
        if (!data.nextBatchIndex) delete data.nextBatchIndex
        log({ getDownloadableCourseMaterials: data })
        return data
    }

    const getDownloadURL = async ({
        file,
    }: {
        file: FolderFile;
    }): Promise<{ url: string }> => {
        const { data } = await new Promise<RequestResponse<{ url: string }>>((resolve) => {
            setTimeout(() => {
                resolve({
                    statusMessage: ResponseStatusMessages.SUCCESS,
                    data: { url: file.url }
                })
            }, 100)
        })
        log({ getDownloadURL: data })
        return data
    }

    return {
        mail,
        enroll,
        getTest,
        getFiles,
        getTests,
        getCourse,
        getEvents,
        getTutors,
        updateFile,
        getCourses,
        uploadFile,
        endSession,
        deleteFile,
        getPolicies,
        createEvent,
        getUserinfo,
        deleteEvent,
        updateEvent,
        getStudents,
        getInvoices,
        getResources,
        createCourse,
        deletePolicy,
        syncUserinfo,
        createTutors,
        deleteReviews,
        submitAReview,
        enrollAStudent,
        getDownloadURL,
        changePassword,
        updateUserinfo,
        createStudents,
        getTestAttempt,
        getEnrollments,
        getCertificates,
        getTestAttempts,
        getDomainDetails,
        submitTestAnswer,
        getDashboardData,
        getNotifications,
        createUpdateTest,
        getCourseReviews,
        deleteCertificate,
        updateCompanyInfo,
        getSessionDetails,
        createUpdatePolicy,
        getLoginActivities,
        deleteNotifications,
        terminateEnrollment,
        manageSessionProfile,
        submitLearningProgress,
        createUpdateCertificate,
        markNotificationsAsRead,
        fixEnrollmentActionRequired,
        updateCompanyBillingAddress,
        getDownloadableCourseMaterials,
        updatePersonalInformationDetails
    }
}