import { ReactNode } from "react";
import styles from "./Page.module.scss";

interface PageProps {
    children: ReactNode;
}

export const Page = ({ children }: PageProps) => {
    const classNames = [styles.container];

    return (
        <div className={classNames.join(" ")}>
            {children}
        </div>
    );
};
