import Moment from "react-moment";
import { upperFirst } from "lodash";
import { Config } from "../../config";
import styled from "styled-components";
import { themes } from "../../utils/data";
import { OffCanvas } from "react-offcanvas";
import { useApi } from "../../hooks/use-api";
import { TextInput } from "../../components/Form";
import { useForm } from "../../hooks/use-form.hook";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "../../hooks/use-theme.hook";
import { TopMenu } from "../../components/App/TopMenu";
import { CompanyContext } from "../../context/company";
import { downloadFile } from "../../utils/downloadFile";
import DIYWebsiteBuilder from "diy-website-builder-lib";
import { Page } from "../../components/Layout/Page/Page";
import { Group } from "../../components/Layout/Group/Group";
import { AuthContext } from "../../context/auth/auth.context";
import { LoadMore } from "../../components/App/LoadMore/LoadMore";
import { Button, StepButton } from "../../components/Form/Button";
import { NavPills } from "../../components/App/NavPills/NavPills";
import { useNotification } from "../../hooks/use-notification.hook";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { CheckBoxInput } from "../../components/Form/CheckBox/CheckBox";
import { CreditCard } from "../../components/App/CreditCard/CreditCard";
import { useCurrencySymbol } from "../../hooks/use-currency-symbol.hook";
import { SelectInput } from "../../components/Form/SelectInput/SelectInput";
import { useState, useContext, FC, useEffect, useRef, useCallback } from "react";
import { BuildingGear, Camera2, CloudDownload, CurrencyExchange, GeoAlt, Images, Map, Palette2, PencilFill, Person, Plus, PlusLg, QuestionCircleFill, X } from "react-bootstrap-icons";
import { AppThemes, FullAddressInformation, createImageFromInitials, CompanyProfilePages, PageFiltersValues, CompanyBillingFullAddress, Invoice, InvoiceStatus, countries } from "shared-library";
import { StyledAlert, StyledBadge, StyledCreationWrapper, StyledDIYWebsiteBuilderContainer, StyledGroupRow, StyledH5, StyledH6, StyledHR, StyledIcon, StyledLargeAddWrapper, StyledOffCanvasMenu, StyledProfilePicture, StyledSmall, StyledSpan, StyledTitleDescDiv, growShadow, transition } from "../../components/Elements";


const StyledName = styled.div`
    font-size: 28px;
    margin-top: -10px;
    margin-bottom: -7px;
    color: ${(props) => props.theme.colors.black};
`

const StyledPageDesc = styled.p`
    font-size: 18px;
    color: ${(props) => props.theme.colors.primary};
`

const StyledProfilePictureContainer = styled.div`
    width: 100px;
    height: 100px;
    cursor: pointer;
    position: relative;

    .iconContainer {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 6px;
        ${transition}
        padding-top: 28%;
        position: absolute;
        border-radius: 1px;
        text-align: center;
        visibility: hidden;
        background: rgba(0,0,0,0.1);
        color: ${props => props.theme.colors.black};
    }

    &:hover {
        .iconContainer {
            visibility: visible;
        }
    }
`

const StyledThemeCard = styled(Group)`
    width: 250px;
    padding: 14px;
    border-radius: 6px;
    .main {
        height: 20px;
    }
    .sub {
        width: 65%;
        height: 15px;
    }
    .themeText {
        padding: 4px 10px;
    }
    ${growShadow()}
`

export const AdminCompanyProfile = ({ page }: { page: CompanyProfilePages; }) => {

    const blankAddress: CompanyBillingFullAddress = {
        address: "",
        addressId: "",
        creationDate: "",
        details: {
            zip: "",
            city: "",
            state: "",
            line1: "",
            line2: "",
            country: "",
            countryCode: ""
        }
    }

    const theme = useTheme();
    const navigate = useNavigate()
    const profileInputFile = useRef<HTMLInputElement>(null)
    const { state: AppCompany, changeTheme, updateCompany } = useContext(CompanyContext)
    const { updateCompanyInfo, getInvoices } = useApi()
    const { state } = useContext(AuthContext)
    const [billingAddress, setBillingAddress] = useState(blankAddress)

    const { mutateAsync: mutateCompanyInformationAsync } = useMutation({
        mutationFn: updateCompanyInfo,
    });

    const { prepPrice } = useCurrencySymbol()
    const [companyInfoCanvasOpen, setCompanyInfoCanvasOpen] = useState(false)
    const [billingAddressCanvasOpen, setBillingAddressCanvasOpen] = useState(false)

    const [invoiceFilters] = useState<{
        limit: number;
        query?: string;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
    }>({
        limit: 10,
        query: "",
        filter: PageFiltersValues.latestFirst,
    })
    const { data: invoices, isFetchingNextPage: invoiceIsFetchingNextPage, fetchNextPage: invoiceFetchNextPage, hasNextPage: invoiceHasNextPage } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getInvoices({
            nextBatchIndex,
            limit: invoiceFilters.limit,
            query: invoiceFilters.query,
            filter: invoiceFilters.filter,
        }),
        enabled: page === CompanyProfilePages.Billing,
        initialPageParam: invoiceFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["invoices", invoiceFilters.query, invoiceFilters.limit, invoiceFilters.nextBatchIndex],
    });

    const flattenInvoices = useCallback(() => {
        let invoiceArr: Array<Invoice> = []
        if (invoices?.pages) {
            for (const page of invoices.pages) {
                invoiceArr = [...invoiceArr, ...page.data]
            }
        }
        return invoiceArr
    },
        [invoices?.pages]
    );


    // const updateNotificationSettings = useCallback(async (property: keyof UserInformationSettingsNotifications, value: boolean) => {
    //     if (!state.user?.info) return
    //     const updatedNotificationSettings = { ...notificationSettings, [property]: value }
    //     setNotificationSettings(updatedNotificationSettings)
    //     try {
    //         const updatedUserInfo = await mutateUserInfoAsync({
    //             ...state.user.info.user,
    //             settings: {
    //                 ...state.user.info.user.settings,
    //                 notifications: updatedNotificationSettings
    //             }
    //         })
    //         setUserInfo(updatedUserInfo)

    //     } catch (error) {
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [state.user?.info])

    const tabs = [
        {
            icon: <BuildingGear />,
            text: "Company Information",
            id: CompanyProfilePages.CompanyInformation,
            onclick: () => navigate(`/${state.user?.info.session.role}/${CompanyProfilePages.CompanyInformation}`),
        },
        {
            text: "Billing",
            icon: <CurrencyExchange />,
            id: CompanyProfilePages.Billing,
            onclick: () => navigate(`/${state.user?.info.session.role}/${CompanyProfilePages.Billing}`),
        },
        {
            icon: <Palette2 />,
            text: "Theme Settings",
            id: CompanyProfilePages.Themes,
            onclick: () => navigate(`/${state.user?.info.session.role}/${CompanyProfilePages.Themes}`),
        },
    ]

    const handleProfilePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            console.log(event.target.files[0]);
        }
    };

    const updateTheme = async (theme: AppThemes) => {
        changeTheme(theme)
        try {
            if (AppCompany.company) {
                const updatedCompanyInfo = await mutateCompanyInformationAsync({
                    companyInformation: {
                        ...AppCompany.company,
                        themeId: theme.id,
                    },
                    prop: "theme"
                })

                updatedCompanyInfo.theme = themes.find(({ id }) => id === updatedCompanyInfo.themeId)
                if (!updatedCompanyInfo.theme) {
                    updatedCompanyInfo.theme = themes.find(({ isDefault }) => isDefault) as AppThemes
                }
                updateCompany(updatedCompanyInfo)
            }
        } catch (error) {
        }
    }

    return (
        <Page>
            <Group style={{ padding: '0px 20px' }}>
                <TopMenu className="slideDown" hideNotification titles={[]} />
                <Group gap="md" className="slideUp">
                    <Group direction="row" align="end">
                        <input type='file' accept="image/*" ref={profileInputFile} onChange={handleProfilePictureChange} style={{ display: 'none' }} />
                        <StyledProfilePictureContainer onClick={() => profileInputFile?.current?.click && profileInputFile.current.click()}>
                            <div className="iconContainer">
                                <Camera2 size={28} />
                            </div>
                            <StyledProfilePicture style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '8px'
                            }} src={AppCompany.company?.logos.nm || createImageFromInitials(AppCompany.company?.name as string, theme.colors.white, theme.colors.secondary, 200)} alt="Company logo" />
                        </StyledProfilePictureContainer>
                        <Group gap="none">
                            <StyledPageDesc>{AppCompany.company?.subscription.name as string}</StyledPageDesc>
                            <StyledName>{AppCompany.company?.name}</StyledName>
                            <StyledSpan>{AppCompany.company?.address}</StyledSpan>
                        </Group>
                    </Group>
                    <Group direction='row'>
                        <NavPills active={page} tabs={tabs} />
                    </Group>
                    <Group>
                        {
                            page === CompanyProfilePages.CompanyInformation && <>
                                <Group gap="none">
                                    <StyledName>{tabs.find(({ id }) => id === page)?.text}</StyledName>
                                    <StyledTitleDescDiv>Edit your company's information and address.</StyledTitleDescDiv>
                                </Group>

                                <Group justify="space-between" style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: theme.colors.grey6
                                }} wrap="nowrap" align="center" direction="row">
                                    <StyledH6>Basic Information</StyledH6>
                                    <StyledIcon onClick={() => setCompanyInfoCanvasOpen(true)} className="small">
                                        <PencilFill />
                                    </StyledIcon>
                                </Group>

                                <StyledGroupRow wrap="nowrap" direction="row" style={{
                                    paddingBottom: '10px',
                                    borderBottom: '1px solid ' + theme.colors.grey6
                                }}>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Company Name</StyledSmall>
                                            <p>{AppCompany.company?.name}</p>
                                        </Group>
                                    </div>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Contact email</StyledSmall>
                                            <p>{AppCompany.company?.email}</p>
                                        </Group>
                                    </div>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Phone No.</StyledSmall>
                                            <p>{AppCompany.company?.mobile || <StyledSmall>N/A</StyledSmall>}</p>
                                        </Group>
                                    </div>

                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Main Website</StyledSmall>
                                            <div style={{
                                                fontSize: '14px',
                                                color: theme.colors.gray5
                                            }}>{AppCompany.company?.website ? <Link to={AppCompany.company.website} style={{
                                                textDecoration: "none",
                                                color: theme.colors.primary,
                                            }} rel="noreferrer" target="_blank">{AppCompany.company.website}</Link> : <StyledSmall>N/A</StyledSmall>}</div>
                                        </Group>
                                    </div>
                                </StyledGroupRow>
                                <StyledGroupRow wrap="nowrap" direction="row">
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Office Address</StyledSmall>
                                            <p>{AppCompany.company?.address || <StyledSmall>N/A</StyledSmall>}</p>
                                        </Group>
                                    </div>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Registration Number</StyledSmall>
                                            <p>{AppCompany.company?.registrationNumber || <StyledSmall>N/A</StyledSmall>}</p>
                                        </Group>
                                    </div>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Registered Country</StyledSmall>
                                            <p>{AppCompany.company?.country || <StyledSmall>N/A</StyledSmall>}</p>
                                        </Group>
                                    </div>
                                </StyledGroupRow>
                                <CompanyInformationEditor open={companyInfoCanvasOpen} setOpen={setCompanyInfoCanvasOpen} />
                            </>
                        }

                        {
                            page === CompanyProfilePages.Billing && <>
                                <Group gap="none">
                                    <StyledName>{tabs.find(({ id }) => id === page)?.text}</StyledName>
                                    <StyledTitleDescDiv>Edit your company's billing information and address, payments and subscription plans.</StyledTitleDescDiv>
                                </Group>
                                <Group justify="space-between" style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: theme.colors.grey6
                                }} wrap="nowrap" align="center" direction="row">
                                    <StyledH6>Subscription History</StyledH6>
                                </Group>

                                <Group gap="none">
                                    <Group justify="space-between" style={{
                                        padding: '14px 0 14px 10px',
                                        borderLeft: '4px solid ' + theme.colors.success3,
                                    }} wrap="nowrap" direction="row">
                                        <Group gap="none" align="start">
                                            <StyledBadge className="success">Active</StyledBadge>
                                            <b>{AppCompany.company?.subscription.name} {!!AppCompany.company?.subscription.frequency && upperFirst(AppCompany.company?.subscription.frequency as string)}</b>
                                            <Group gap="md" direction="row">
                                                <Group style={{ width: '200px' }} gap="none">
                                                    <StyledSmall style={{ color: theme.colors.grey }}>Subscription ID</StyledSmall>
                                                    <p>{AppCompany.company?.subscription.id}</p>
                                                </Group>
                                                <Group style={{ width: '200px' }} gap="none">
                                                    <StyledSmall style={{ color: theme.colors.grey }}>Start Date</StyledSmall>
                                                    <p>
                                                        {
                                                            AppCompany.company?.subscription.startDate ? <Moment format={state.user?.info.user.dateFormat}>{AppCompany.company?.subscription.startDate}</Moment> : <StyledSmall>N/A</StyledSmall>
                                                        }
                                                    </p>
                                                </Group>
                                                {
                                                    !!AppCompany.company?.subscription.nextBillingDate && <Group style={{ width: '200px' }} gap="none">
                                                        <StyledSmall style={{ color: theme.colors.grey }}>Next Billing Date</StyledSmall>
                                                        <p>
                                                            <Moment format={state.user?.info.user.dateFormat}>{AppCompany.company?.subscription.nextBillingDate}</Moment>
                                                        </p>
                                                    </Group>
                                                }
                                                {
                                                    !!AppCompany.company?.subscription.endDate && <Group style={{ width: '200px' }} gap="none">
                                                        <StyledSmall style={{ color: theme.colors.grey }}>End Date</StyledSmall>
                                                        <p>
                                                            <Moment format={state.user?.info.user.dateFormat}>{AppCompany.company?.subscription.endDate}</Moment>
                                                        </p>
                                                    </Group>
                                                }
                                            </Group>
                                        </Group>
                                        <StepButton text="Change Plan" leftIcon={<CurrencyExchange />} classNamesArr={["success"]} />
                                    </Group>
                                    {
                                        !!AppCompany.company?.subscription.lastSubscription && <Group style={{
                                            padding: '14px 0 14px 10px',
                                            borderTop: '1px solid ' + theme.colors.grey6,
                                            borderBottom: '1px solid ' + theme.colors.grey6,
                                            borderLeft: '4px solid ' + theme.colors.grey6,
                                        }} align="start" gap="none">
                                            <StyledBadge className="danger">Expired</StyledBadge>
                                            <b>{AppCompany.company?.subscription.lastSubscription.name} {!!AppCompany.company?.subscription.lastSubscription.frequency && upperFirst(AppCompany.company?.subscription.lastSubscription.frequency as string)}</b>
                                            <Group gap="md" direction="row">
                                                <Group gap="none" style={{ width: '200px' }}>
                                                    <StyledSmall style={{ color: theme.colors.grey }}>Subscription ID</StyledSmall>
                                                    <p>{AppCompany.company?.subscription.lastSubscription.id}</p>
                                                </Group>
                                                <Group gap="none" style={{ width: '200px' }}>
                                                    <StyledSmall style={{ color: theme.colors.grey }}>Start Date</StyledSmall>
                                                    <p>
                                                        {
                                                            AppCompany.company?.subscription.lastSubscription.startDate ? <Moment format={state.user?.info.user.dateFormat}>{AppCompany.company?.subscription.lastSubscription.startDate}</Moment> : <StyledSmall>N/A</StyledSmall>
                                                        }
                                                    </p>
                                                </Group>
                                                {
                                                    !!AppCompany.company?.subscription.lastSubscription.endDate && <Group gap="none" style={{ width: '200px' }}>
                                                        <StyledSmall style={{ color: theme.colors.grey }}>End Date</StyledSmall>
                                                        <p>
                                                            <Moment format={state.user?.info.user.dateFormat}>{AppCompany.company?.subscription.lastSubscription.endDate}</Moment>
                                                        </p>
                                                    </Group>
                                                }
                                            </Group>
                                        </Group>
                                    }
                                </Group>

                                <br />
                                <Group justify="space-between" style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: theme.colors.grey6
                                }} wrap="nowrap" align="center" direction="row">
                                    <StyledH6>Payment Cards</StyledH6>
                                </Group>

                                <Group direction="row">
                                    {
                                        AppCompany.company?.billing.paymentMethod.cards.map(card => <CreditCard
                                            key={card.id}
                                            active={card.id === AppCompany.company?.billing.paymentMethod.primary}
                                            card={card}
                                        />)
                                    }
                                    <StyledLargeAddWrapper align="center" justify="center">
                                        <PlusLg />
                                    </StyledLargeAddWrapper>
                                </Group>

                                <br />
                                <Group justify="space-between" style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: theme.colors.grey6
                                }} wrap="nowrap" align="center" direction="row">
                                    <StyledH6>Billing Addresses</StyledH6>
                                    <StyledIcon onClick={() => { setBillingAddress({ ...blankAddress }); setBillingAddressCanvasOpen(true) }} className={`small ${AppCompany.company && AppCompany.company?.billing.addresses.fullAddresses.length >= 10 ? "disabledEvents" : ""}`}>
                                        <Plus />
                                    </StyledIcon>
                                </Group>

                                <Group gap="none">
                                    {
                                        AppCompany.company?.billing.addresses.fullAddresses.map((address, index) => <Group
                                            key={index}
                                            direction="row"
                                            justify="space-between"
                                            wrap="nowrap"
                                            style={{
                                                padding: '10px 0 10px 10px',
                                                borderLeft: '4px solid ' + (address.addressId === AppCompany.company?.billing.addresses.primary ? theme.colors.success3 : theme.colors.grey6),
                                            }}>
                                            <Group gap="none">
                                                <StyledSmall style={{ color: theme.colors.grey }}>Address</StyledSmall>
                                                <p>{address.address}</p>
                                            </Group>
                                            <Group gap="none" align="end">
                                                <span className="pointer" onClick={() => { setBillingAddress({ ...address }); setBillingAddressCanvasOpen(true) }} style={{ color: theme.colors.primary }}>change</span>
                                                <StyledSmall><i>Added on: <Moment format={state.user?.info.user.dateFormat + ", hh:mm A"}>{address.creationDate}</Moment></i></StyledSmall>
                                            </Group>
                                        </Group>)
                                    }
                                </Group>

                                <br />
                                <Group justify="space-between" style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: theme.colors.grey6
                                }} wrap="nowrap" align="center" direction="row">
                                    <StyledH6>Invoices</StyledH6>
                                </Group>

                                <Group gap="none">
                                    {
                                        flattenInvoices().map(invoice => <Group
                                            key={invoice.id}
                                            direction="row"
                                            align="center"
                                            justify="space-between"
                                            wrap="nowrap"
                                            style={{
                                                padding: '10px 0 10px 10px',
                                                borderBottom: '1px solid ' + theme.colors.grey6,
                                                borderLeft: '4px solid ' + (invoice.invoiceStatus === InvoiceStatus.Due ? theme.colors.danger3 : theme.colors.grey6),
                                            }}>
                                            <Group style={{ flex: 1 }} direction="row" gap="none">
                                                <Group style={{ width: '200px' }} gap="none">
                                                    <StyledSmall style={{ color: theme.colors.grey }}>Invoice Number</StyledSmall>
                                                    <p>{invoice.invoiceNumber}</p>
                                                </Group>

                                                <Group style={{ width: '200px' }} gap="none">
                                                    <StyledSmall style={{ color: theme.colors.grey }}>Description</StyledSmall>
                                                    {
                                                        invoice.description.map((description, index) => <p key={index}>- <small>{description}</small></p>)
                                                    }
                                                </Group>

                                                <Group style={{ width: '200px' }} gap="none">
                                                    <StyledSmall style={{ color: theme.colors.grey }}>Date</StyledSmall>
                                                    <p><Moment format={state.user?.info.user.dateFormat}>{invoice.creationDate}</Moment></p>
                                                </Group>

                                                <Group style={{ width: '200px' }} gap="none">
                                                    <StyledSmall style={{ color: theme.colors.grey }}>Amount</StyledSmall>
                                                    <p>{prepPrice(invoice.currency, invoice.amount)}</p>
                                                </Group>

                                                <Group style={{ width: '200px' }} gap="none" align="start">
                                                    <StyledSmall style={{ color: theme.colors.grey }}>Status</StyledSmall>
                                                    <StyledBadge className={invoice.invoiceStatus === InvoiceStatus.Due ? "danger" : (invoice.invoiceStatus === InvoiceStatus.Paid ? "success" : "")}>{upperFirst(invoice.invoiceStatus)}</StyledBadge>
                                                </Group>

                                            </Group>

                                            <StyledIcon onClick={() => downloadFile(invoice.downloadURL, undefined, true)} className="small">
                                                <CloudDownload color={theme.colors.grey} />
                                            </StyledIcon>
                                        </Group>)
                                    }
                                </Group>
                                {
                                    invoiceHasNextPage && <LoadMore loading={invoiceIsFetchingNextPage} onclick={invoiceFetchNextPage} />
                                }
                                <br />
                                <br />
                                <BillingAddressEditor open={billingAddressCanvasOpen} billingAddress={billingAddress} setOpen={setBillingAddressCanvasOpen} />
                            </>
                        }

                        {
                            page === CompanyProfilePages.Themes && <>
                                <Group gap="none">
                                    <StyledName>{tabs.find(({ id }) => id === page)?.text}</StyledName>
                                    <StyledTitleDescDiv>Personalise your company's website look and feel here.</StyledTitleDescDiv>
                                </Group>

                                <Group style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: theme.colors.grey6
                                }} wrap="nowrap" align="center" direction="row">
                                    <StyledH6>Landing page</StyledH6>
                                </Group>

                                <Group gap="xs" align="start">
                                    <StyledThemeCard gap="xs" style={{ background: theme.colors.white }}>
                                        {
                                            AppCompany.company?.landingPageDetails.thumbnails.desktop ? <>
                                                <img width="100%" alt="Landing page thumbnail" src={AppCompany.company.landingPageDetails.thumbnails.desktop} />
                                            </> : <>
                                                <Group gap="xs" style={{ flex: 1 }}>
                                                    <div className="main" style={{ background: theme.colors.lightGray, borderRadius: '4px' }}>
                                                    </div>
                                                </Group>
                                                <Group align="center" direction="row">
                                                    <Group gap="xs" style={{ flex: 1 }}>
                                                        <div className="main" style={{ background: theme.colors.gray, borderColor: theme.colors.gray4, borderRadius: '4px' }}>
                                                        </div>
                                                        <div className="sub" style={{ background: theme.colors.lightGray, borderColor: theme.colors.lightGray, borderRadius: '4px' }}></div>
                                                    </Group>
                                                    <Group gap="none" align="center" className="themeText" style={{ background: theme.colors.lightGray, borderRadius: '10px' }}>
                                                        <div style={{ color: theme.colors.gray4, padding: '10px 10px 6px 10px' }}><Images /></div>
                                                        <small style={{ backgroundColor: theme.colors.grey, width: '50px' }}></small>
                                                    </Group>
                                                </Group>
                                                <Group gap="xs" style={{ flex: 1, marginTop: '4px' }}>
                                                    <div className="main" style={{ background: theme.colors.lightGray, borderRadius: '4px' }}>
                                                    </div>
                                                </Group>
                                            </>
                                        }


                                    </StyledThemeCard>
                                    <StepButton classNamesArr={["success"]} onClick={() => navigate(`/admin/theme-settings/customise`)} style={{ marginTop: '10px', width: '250px' }} text={"Edit"} />
                                </Group>
                                <br />
                                <Group style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: theme.colors.grey6
                                }} wrap="nowrap" align="center" direction="row">
                                    <StyledH6>Dashboard's Color scheme</StyledH6>
                                </Group>

                                <Group direction="row">
                                    {
                                        themes.map(theme => <Group gap="xs" key={theme.id}>
                                            <StyledThemeCard gap="xs" style={{ background: theme.colors.white }}>
                                                <StyledH6>{theme.id}</StyledH6>
                                                <Group align="center" direction="row">
                                                    <Group gap="none" className="themeText" style={{ background: theme.colors.bg }}>
                                                        <div style={{ color: theme.colors.black }}><b>ABCDEF</b></div>
                                                        <small style={{ color: theme.colors.grey }}>GHIJKLMN</small>
                                                    </Group>
                                                    <Group gap="xs" style={{ flex: 1 }}>
                                                        <div className="main" style={{ background: theme.colors.primary, borderColor: theme.colors.primary4 }}></div>
                                                        <div className="sub" style={{ background: theme.colors.secondary, borderColor: theme.colors.secondary4 }}></div>
                                                    </Group>

                                                </Group>
                                            </StyledThemeCard>
                                            {
                                                theme.id !== AppCompany.company?.themeId && <StepButton classNamesArr={["success"]} onClick={() => updateTheme(theme)} style={{ marginTop: '10px' }} text={"Activate " + theme.id} />
                                            }
                                        </Group>)
                                    }
                                </Group>
                            </>
                        }
                    </Group>
                </Group>
            </Group>
        </Page>
    );
};

const CompanyInformationEditor: FC<{ open: boolean; setOpen: (open: boolean) => void; onSuccess?: () => void }> = ({ open, setOpen, onSuccess }) => {

    interface DefaultValues {
        name: string;
        email: string;
        mobile: string;
        website: string;
        country: string;
        registrationNumber: string;
    }

    const theme = useTheme();
    const { notify } = useNotification();
    const { updateCompanyInfo } = useApi()
    const { state: AppCompany, updateCompany } = useContext(CompanyContext)

    const defaultValues: DefaultValues = {
        name: AppCompany.company?.name || "",
        email: AppCompany.company?.email || "",
        mobile: AppCompany.company?.mobile || "",
        website: AppCompany.company?.website || "",
        country: AppCompany.company?.country || "",
        registrationNumber: AppCompany.company?.registrationNumber || "",
    }
    const { onSubmit, errors, onChange, formValues } = useForm({
        defaultValues,
        validations: {
            mobile: { max: 14 },
            website: { url: true },
            name: { max: 200, required: true },
            email: { email: true, required: true },
        }
    })
    const { onSubmit: onAddressSubmit, errors: addressErrors, onChange: addressOnChange, formValues: addressFormValues } = useForm<FullAddressInformation>({
        defaultValues: {
            zip: AppCompany.company?.fullAddress.zip || "",
            city: AppCompany.company?.fullAddress.city || "",
            state: AppCompany.company?.fullAddress.state || "",
            line1: AppCompany.company?.fullAddress.line1 || "",
            line2: AppCompany.company?.fullAddress.line2 || "",
            country: AppCompany.company?.fullAddress.country || "",
            countryCode: AppCompany.company?.fullAddress.countryCode || "",
        },
        validations: {
            zip: { max: 10 },
            city: { max: 60 },
            state: { max: 60 },
            line1: { max: 120 },
            line2: { max: 120 },
        }
    })
    const [isBasic, setIsBasic] = useState(true)
    const { isPending: mutatingCompanyInformationAsync, mutateAsync: mutateCompanyInformationAsync } = useMutation({
        mutationFn: updateCompanyInfo,
    });

    const updateCompanyInfoFunc = async (data: DefaultValues) => {
        if (!AppCompany.company) return
        try {
            const updatedCompanyInfo = await mutateCompanyInformationAsync({
                companyInformation: {
                    ...AppCompany.company,
                    name: data.name,
                    email: data.email,
                    mobile: data.mobile,
                    website: data.website,
                    country: data.country,
                },
                prop: "basic"
            })
            updateCompany(updatedCompanyInfo)

            notify({ type: "success", title: "Successful!", body: `Basic Information has been successfully updated!` });
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    const updateAddress = async (data: FullAddressInformation) => {
        if (!AppCompany.company) return
        try {
            if (data.country) {
                data.countryCode = countries.find(({ name }) => name === data.country)?.name as string
            }
            const updatedCompanyInfo = await mutateCompanyInformationAsync({
                companyInformation: {
                    ...AppCompany.company,
                    fullAddress: data,
                },
                prop: "fullAddress"
            })
            updateCompany(updatedCompanyInfo)
            notify({ type: "success", title: "Successful!", body: `Address has been successfully created!` });
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <Person size={20} style={{ marginRight: '5px' }} /> Basic Information
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" wrap="nowrap" style={{ padding: '4px 20px' }}>
                    <NavPills active={isBasic ? "basic" : "address"} tabs={[
                        {
                            id: "basic",
                            icon: <Person />,
                            text: "Basic",
                            onclick: () => setIsBasic(true),
                        },
                        {
                            id: "address",
                            text: "Address",
                            icon: <GeoAlt />,
                            onclick: () => setIsBasic(false),
                        },

                    ]} />
                    <StyledCreationWrapper>
                        {
                            isBasic ? <form onSubmit={onSubmit(updateCompanyInfoFunc)}>
                                <Group gap='sm'>
                                    <TextInput
                                        isRequired
                                        type="text"
                                        error={errors.name}
                                        label="Company Name"
                                        value={formValues.name}
                                        placeholder="Company Name"
                                        onChange={onChange("name")}
                                    />

                                    <Group direction="row" gap="sm" justify="space-between" wrap="nowrap">
                                        <TextInput
                                            isRequired
                                            type="email"
                                            label="Email"
                                            placeholder="Email"
                                            error={errors.email}
                                            value={formValues.email}
                                            onChange={onChange("email")}
                                        />

                                        <TextInput
                                            type="text"
                                            label="Phone No."
                                            error={errors.mobile}
                                            placeholder="Phone No."
                                            value={formValues.mobile}
                                            style={{ width: '180px' }}
                                            onChange={onChange("mobile")}
                                        />

                                    </Group>
                                    <TextInput
                                        type="url"
                                        label="Main Website"
                                        error={errors.website}
                                        placeholder="Main Website"
                                        value={formValues.website}
                                        onChange={onChange("website")}
                                    />

                                    <TextInput
                                        type="text"
                                        error={errors.registrationNumber}
                                        label="Company Registration Number"
                                        value={formValues.registrationNumber}
                                        placeholder="Company Registration Number"
                                        onChange={onChange("registrationNumber")}
                                    />

                                    <SelectInput
                                        label="Country"
                                        placeholder="Country"
                                        error={errors.country}
                                        parentStyle={{ width: '100%' }}
                                        onChange={onChange("country")}
                                        options={countries.map(({ name: value, name: label }) => ({ value, label }))}
                                        value={countries.map(({ name: value, name: label }) => ({ value, label })).find(d => d.value === formValues.country)}
                                    />

                                    <br />
                                    <Button text={"Save"} type='submit' loading={mutatingCompanyInformationAsync} />
                                </Group>
                            </form> : <form onSubmit={onAddressSubmit(updateAddress)}>
                                <Group gap='sm'>
                                    <TextInput
                                        type="text"
                                        label="Line 1"
                                        placeholder="Line 1"
                                        error={addressErrors.line1}
                                        value={addressFormValues.line1}
                                        onChange={addressOnChange("line1")}
                                    />

                                    <TextInput
                                        type="text"
                                        label="Line 2"
                                        placeholder="Line 2"
                                        error={addressErrors.line2}
                                        value={addressFormValues.line2}
                                        onChange={addressOnChange("line2")}
                                    />

                                    <TextInput
                                        type="text"
                                        label="Post Code"
                                        placeholder="Post Code"
                                        error={addressErrors.zip}
                                        value={addressFormValues.zip}
                                        onChange={addressOnChange("zip")}
                                    />

                                    <TextInput
                                        type="text"
                                        label="City"
                                        placeholder="City"
                                        error={addressErrors.city}
                                        value={addressFormValues.city}
                                        onChange={addressOnChange("city")}
                                    />

                                    <Group direction="row" justify="space-between" wrap="nowrap">

                                        <TextInput
                                            type="text"
                                            label="State"
                                            placeholder="State"
                                            error={addressErrors.state}
                                            value={addressFormValues.state}
                                            onChange={addressOnChange("state")}
                                        />

                                        <SelectInput
                                            label="Country"
                                            placeholder="Country"
                                            error={addressErrors.country}
                                            parentStyle={{ width: '180px' }}
                                            onChange={addressOnChange("country")}
                                            options={countries.map(({ name: value, name: label }) => ({ value, label }))}
                                            value={countries.map(({ name: value, name: label }) => ({ value, label })).find(d => d.value === addressFormValues.country)}
                                        />

                                    </Group>

                                    <br />
                                    <Button text={"Update Address"}
                                        disabled={
                                            !addressFormValues.zip &&
                                            !addressFormValues.city &&
                                            !addressFormValues.line2 &&
                                            !addressFormValues.line1 &&
                                            !addressFormValues.state &&
                                            !addressFormValues.country
                                        } type='submit' loading={mutatingCompanyInformationAsync} />
                                </Group>
                            </form>
                        }

                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}

const BillingAddressEditor: FC<{ open: boolean; billingAddress: CompanyBillingFullAddress; setOpen: (open: boolean) => void; onSuccess?: () => void }> = ({ open, setOpen, onSuccess, billingAddress }) => {

    const theme = useTheme();
    const { notify } = useNotification();
    const { updateCompanyBillingAddress } = useApi()
    const { state: AppCompany, updateCompany } = useContext(CompanyContext)
    const [primary, setPrimary] = useState(AppCompany.company?.billing.addresses.primary)

    const { onSubmit: onAddressSubmit, errors: addressErrors, updateValues: addressUpdateValues, onChange: addressOnChange, reset: addressReset, formValues: addressFormValues } = useForm<FullAddressInformation>({
        defaultValues: billingAddress.details,
        validations: {
            zip: { max: 10, required: true },
            city: { max: 60, required: true },
            state: { max: 60, required: true },
            line1: { max: 120, required: true },
            country: { required: true },
            line2: { max: 120 },
        }
    })
    const { isPending: mutatingCompanyBillingAddressAsync, mutateAsync: mutateCompanyBillingAddressAsync } = useMutation({
        mutationFn: updateCompanyBillingAddress,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => addressUpdateValues(billingAddress.details), [billingAddress])

    const updateAddress = async (data: FullAddressInformation) => {
        if (!AppCompany.company) return
        try {
            if (data.country) {
                data.countryCode = countries.find(({ name }) => name === data.country)?.name as string
            }
            const updatedBillingAddressInfo = await mutateCompanyBillingAddressAsync({
                primary,
                fullAddress: {
                    ...billingAddress,
                    details: {
                        ...data
                    }
                }
            })
            updateCompany({
                ...AppCompany.company,
                billing: {
                    ...AppCompany.company.billing,
                    addresses: updatedBillingAddressInfo
                }
            })
            setPrimary(updatedBillingAddressInfo.primary)
            addressReset()
            notify({ type: "success", title: "Successful!", body: `Billing Address has been successfully updated!` });
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <Map size={20} style={{ marginRight: '5px' }} /> Billing Address
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" wrap="nowrap" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper>
                        <form onSubmit={onAddressSubmit(updateAddress)}>
                            <Group gap='sm'>
                                {
                                    AppCompany.company?.billing.addresses.primary && AppCompany.company?.billing.addresses.primary === billingAddress.addressId ? <StyledAlert align="center" wrap="nowrap" gap="sm" direction="row" className="info">
                                        <QuestionCircleFill size={18} />
                                        <b>Please note that you are editting your default billing address.</b>
                                    </StyledAlert> :
                                        <CheckBoxInput
                                            centered={false}
                                            label='Set as default billing address'
                                            parentStyle={{ marginTop: '5px' }}
                                            value={primary === billingAddress.addressId}
                                            onChange={(primary) => setPrimary(primary ? billingAddress.addressId : AppCompany.company?.billing.addresses.primary)}
                                        />
                                }

                                <StyledHR />
                                <TextInput
                                    isRequired
                                    type="text"
                                    label="Line 1"
                                    placeholder="Line 1"
                                    error={addressErrors.line1}
                                    value={addressFormValues.line1}
                                    onChange={addressOnChange("line1")}
                                />

                                <TextInput
                                    type="text"
                                    label="Line 2"
                                    placeholder="Line 2"
                                    error={addressErrors.line2}
                                    value={addressFormValues.line2}
                                    onChange={addressOnChange("line2")}
                                />

                                <TextInput
                                    type="text"
                                    isRequired
                                    label="Post Code"
                                    placeholder="Post Code"
                                    error={addressErrors.zip}
                                    value={addressFormValues.zip}
                                    onChange={addressOnChange("zip")}
                                />

                                <TextInput
                                    type="text"
                                    label="City"
                                    isRequired
                                    placeholder="City"
                                    error={addressErrors.city}
                                    value={addressFormValues.city}
                                    onChange={addressOnChange("city")}
                                />

                                <Group direction="row" justify="space-between" wrap="nowrap">

                                    <TextInput
                                        type="text"
                                        label="State"
                                        isRequired
                                        placeholder="State"
                                        error={addressErrors.state}
                                        value={addressFormValues.state}
                                        onChange={addressOnChange("state")}
                                    />

                                    <SelectInput
                                        label="Country"
                                        isRequired
                                        placeholder="Country"
                                        error={addressErrors.country}
                                        parentStyle={{ width: '180px' }}
                                        onChange={addressOnChange("country")}
                                        options={countries.map(({ name: value, name: label }) => ({ value, label }))}
                                        value={countries.map(({ name: value, name: label }) => ({ value, label })).find(d => d.value === addressFormValues.country)}
                                    />
                                </Group>

                                <br />
                                <Button text={(billingAddress.addressId ? "Update" : "Create") + " Address"}
                                    disabled={
                                        !addressFormValues.zip &&
                                        !addressFormValues.city &&
                                        !addressFormValues.line2 &&
                                        !addressFormValues.line1 &&
                                        !addressFormValues.state &&
                                        !addressFormValues.country
                                    } type='submit' loading={mutatingCompanyBillingAddressAsync} />
                            </Group>
                        </form>
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}


export const AdminCompanyWebsiteEditor = () => {

    const theme = useTheme()
    const { updateCompanyInfo } = useApi()
    const { state: AppCompany, updateCompany } = useContext(CompanyContext);
    const { mutateAsync: mutateCompanyInformationAsync } = useMutation({
        mutationFn: updateCompanyInfo,
    });

    const onPublish = async (url: string, thumbnails: { mobile?: string; desktop?: string; }) => {
        try {
            if (AppCompany.company) {
                const updatedCompanyInfo = await mutateCompanyInformationAsync({
                    companyInformation: {
                        ...AppCompany.company,
                        themeId: theme.id,
                        landingPageDetails: {
                            ...AppCompany.company.landingPageDetails,
                            url,
                            thumbnails,
                        }
                    },
                    prop: "theme"
                })

                updatedCompanyInfo.theme = themes.find(({ id }) => id === updatedCompanyInfo.themeId)
                if (!updatedCompanyInfo.theme) {
                    updatedCompanyInfo.theme = themes.find(({ isDefault }) => isDefault) as AppThemes
                }
                updateCompany(updatedCompanyInfo)
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        const container = document.getElementById('containerEditor')
        if (container && AppCompany.company?.landingPageDetails.shortId) {
            new DIYWebsiteBuilder({
                onPublish,
                container,
                mode: "website",
                allowPageCRUD: false,
                defaultFontName: "Nunito",
                sidebarColor: theme.colors.white,
                hoverColor: theme.colors.primary,
                primaryColor: theme.colors.primary,
                apiKey: Config.DIYWebsiteBuilderAPIKey,
                initializePages: ["index", "tnc", "privacy", "refund"],
                shortId: AppCompany.company.landingPageDetails.shortId,
                exitURL: window.location.href.replace(`theme-settings/customise`, "theme-settings"),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AppCompany.company?.landingPageDetails.shortId, theme.colors.primary, theme.colors.white])

    return (
        <StyledDIYWebsiteBuilderContainer id='containerEditor'></StyledDIYWebsiteBuilderContainer>
    );
};