import { StyledDropdown, StyledHR } from '../../Elements';
import { useState, useEffect, useRef, ReactNode, CSSProperties, FC } from 'react';

type Margin = "sm" | "md";

const gapMarginMap: Record<Margin, CSSProperties> = {
    sm: { top: "35px" },
    md: { top: "55px" },
};

export interface DropdownPropOptions {
    text: ReactNode;
    icon: ReactNode;
    disabled?: boolean;
    onClick: () => void;
    demarcateTop?: boolean;
}

interface DropdownProps {
    margin: Margin;
    controller: ReactNode;
    options: Array<DropdownPropOptions>;
}

export const Dropdown: FC<DropdownProps> = ({ options, controller, margin }) => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen(!isOpen)

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef}>
            <div className='pointer' onClick={toggleDropdown}>
                {controller}
            </div>
            {
                isOpen && <StyledDropdown style={{ ...gapMarginMap[margin] }}>
                    {
                        options.map(({ icon, text, onClick, demarcateTop, disabled }, index) => <div key={index}>
                            {demarcateTop && <StyledHR />}
                            <p className={disabled ? "disabledEvents disabled" : ""} onClick={() => { if (disabled) return null; setIsOpen(false); onClick(); }}>
                                {icon}
                                {text}
                            </p>
                        </div>)
                    }
                </StyledDropdown>
            }
        </div>
    );
};