import { Navigate } from "react-router-dom";
import { ReactElement, useContext } from "react";
import { AuthContext } from "../../../context/auth";
import { useRouteResolver } from "../../../hooks/use-route-resolver.hook";

interface PublicRouteProps {
    children: ReactElement;
}

export const PublicRoute = ({ children }: PublicRouteProps) => {
    const { dashboard } = useRouteResolver()
    const { state } = useContext(AuthContext);

    if (state.user) {
        return <Navigate to={dashboard({})} replace />;
    }

    return children;
};
