import { useApi } from "../../hooks/use-api";
import { DiskManager } from "../../utils/diskManager";
import { createContext, ReactNode, useCallback, useMemo, useState } from "react";
import { AuthStateResourcesValues, CourseStatus, CourseTabPills, PageFiltersValues, StudentStatus, TutorStatus, User, UserInformation } from "shared-library";

interface AuthStatePreferencesValues {
    grid: boolean;
    pages: {
        tutors: {
            limit: number;
            query?: string;
            status?: TutorStatus;
            nextBatchIndex?: string;
            filter?: PageFiltersValues;
        };
        students: {
            limit: number;
            query?: string;
            status?: StudentStatus;
            nextBatchIndex?: string;
            filter?: PageFiltersValues;
        };
        courses: {
            limit: number;
            query?: string;
            status?: CourseStatus;
            nextBatchIndex?: string;
            filter?: PageFiltersValues;
            enrollmentStatus?: CourseTabPills;
        };
    };
}

interface AuthStateValues {
    user?: User;
    version: string;
    resources: AuthStateResourcesValues;
    preferences: AuthStatePreferencesValues;
}

interface AuthValues {
    state: AuthStateValues;
    logout: () => void;
    login: (user: User) => void;
    setUser: (user: User) => void;
    setUserInfo: (userInfo: UserInformation) => void;
    setPreferences: (preferences: AuthStatePreferencesValues) => void;
    setResources: (resources: AuthStateResourcesValues) => void;
}

const diskManager = new DiskManager("_authData")

const defaultState: AuthStateValues = {
    version: "1.0.10",
    preferences: {
        grid: true,
        pages: {
            tutors: {
                query: "",
                limit: 20,
                status: TutorStatus.ACTIVE,
                filter: PageFiltersValues.latestFirst
            },
            students: {
                query: "",
                limit: 20,
                status: StudentStatus.ACTIVE,
                filter: PageFiltersValues.latestFirst
            },
            courses: {
                query: "",
                limit: 20,
                status: CourseStatus.ACTIVE,
                filter: PageFiltersValues.latestFirst
            }
        }
    },
    resources: {}
}

const getInitialState = (): AuthStateValues => {
    let _savedStateStr = diskManager.retrieve()
    if (!_savedStateStr) return { ...defaultState }
    const _savedState = JSON.parse(_savedStateStr) as AuthStateValues
    if (_savedState.version !== defaultState.version) {
        // Version update occurred 
        return { ...defaultState }
    }
    return _savedState
}

const initialState = getInitialState()
const ctxDefaultValue: AuthValues = {
    state: initialState,
    login: () => undefined,
    logout: () => undefined,
    setUser: () => undefined,
    setUserInfo: () => undefined,
    setResources: () => undefined,
    setPreferences: () => undefined,
};

export const AuthContext = createContext<AuthValues>(ctxDefaultValue);
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {

    const { endSession } = useApi()
    const [state, setState] = useState(ctxDefaultValue.state);

    const logout = useCallback(() => {
        if (state.user?.info.session.id) {
            endSession({ sessionSortKey: state.user.info.session.sortKey })
        }
        setState(defaultState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user?.info.session.id]);

    const login = useCallback((user: User) => setState({ ...defaultState, user }), []);
    const setUser = useCallback((user: User) => setState({ ...state, user }), [state]);
    const setResources = useCallback((resources: AuthStateResourcesValues) => setState({ ...state, resources }), [state]);
    const setPreferences = useCallback((preferences: AuthStatePreferencesValues) => setState({ ...state, preferences }), [state]);
    const setUserInfo = useCallback((user: UserInformation) => setState({ ...state, user: { ...(state.user as User), info: { ...(state.user as User).info, user } } }), [state]);

    const value = useMemo(() => {
        diskManager.save(JSON.stringify(state))
        return { state, login, logout, setPreferences, setUserInfo, setResources, setUser }
    }, [state, login, logout, setPreferences, setUserInfo, setResources, setUser]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
