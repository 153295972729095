import parse from 'html-react-parser';
import styled from "styled-components";
import { OffCanvas } from 'react-offcanvas';
import { useApi } from "../../hooks/use-api";
import { AuthContext } from '../../context/auth';
import { TextInput } from '../../components/Form';
import { useForm } from '../../hooks/use-form.hook';
import { useTheme } from '../../hooks/use-theme.hook';
import { downloadFile } from '../../utils/downloadFile';
import { useNotify } from '../../hooks/use-notify.hook';
import { Page } from "../../components/Layout/Page/Page";
import { Spinner } from "../../components/Spinner/Spinner";
import { Group } from "../../components/Layout/Group/Group";
import { FC, useContext, useEffect, useState } from "react";
import { FileInput } from '../../components/Form/FileUpload';
import { useMutation, useQuery } from "@tanstack/react-query";
import { downloadTxtFile } from '../../utils/downloadTxtFile';
import { RecordTypes, RoleTypes, Policy } from 'shared-library';
import { Button, StepButton } from "../../components/Form/Button";
import { PolicyCard } from '../../components/App/Cards/PolicyCard';
import { RichText } from '../../components/Form/RichText/RichText';
import { useNotification } from '../../hooks/use-notification.hook';
import { TopMenu, TopMenuProfile } from "../../components/App/TopMenu";
import { FileViewer } from '../../components/App/FolderCards/FileViewer';
import { CloudDownload, JournalText, Pencil, Trash, X } from "react-bootstrap-icons";
import { StyledContentWrapper, StyledCreationWrapper, StyledH5, StyledHR, StyledOffCanvasMenu, StyledTitle, StyledTitleDescDiv, forPhoneOnly } from "../../components/Elements";


const StyledGroup = styled(Group)`
    .left {
        flex-basis: 35%;
        ${forPhoneOnly("flex-basis: 100%;")}
    }
`

export const Policies = () => {

    const blankPolicy: Policy = {
        id: "",
        title: "",
        sortKey: "",
        updatedOn: "",
        companyId: "",
        creatorSub: "",
        description: "",
        creationDate: "",
        recordType: RecordTypes.COMPANYPOLICY,
    }

    const { confirm } = useNotify()
    const { notify } = useNotification();
    const { state } = useContext(AuthContext);
    const { getPolicies, deletePolicy } = useApi();
    const [newPolicyCanvas, setNewPolicyCanvas] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState<Policy | undefined>();
    const [edittingPolicy, setEdittingPolicy] = useState<Policy>({ ...blankPolicy });

    const { data, isLoading, refetch } = useQuery({
        retry: false,
        queryFn: getPolicies,
        queryKey: ["policies"],
    });

    const { isPending: mutatingPolicyDeletionAsync, mutateAsync: mutatePolicyDeletionAsync } = useMutation({
        mutationFn: deletePolicy
    });

    const onDelete = async () => {
        try {
            if (selectedPolicy) {
                await mutatePolicyDeletionAsync(selectedPolicy.id)
                notify({ type: "success", title: "Successful!", body: `Policy has been successfully deleted!` });
                refetch()
                setSelectedPolicy(undefined)
            }
        } catch (error) {
        }
    }

    return (
        <Page>
            <StyledGroup direction="row">
                <StyledContentWrapper className="left slideInLeft">
                    <Group>
                        <TopMenu hideProfile hideNotification titles={[{
                            text: 'Policies',
                        }]} newItemLink={state.user?.info.session.role === RoleTypes.ADMIN ? () => { setEdittingPolicy({ ...blankPolicy }); setNewPolicyCanvas(true) } : undefined} />
                        <Group gap="xs">
                            <br />
                            <br />
                            {
                                !data || isLoading ? <Spinner text="Fetching Policies" style={{ margin: 60 }} /> :
                                    <>
                                        {
                                            data.map(policy => <PolicyCard active={policy.id === selectedPolicy?.id} policy={policy} key={policy.id} onClick={() => setSelectedPolicy(policy)} />)
                                        }
                                    </>
                            }
                        </Group>
                    </Group>
                </StyledContentWrapper>
                <Group className="slideInRight" style={{ flex: 1 }}>
                    <Group direction="row" align="center" justify="end">
                        <TopMenuProfile />
                    </Group>
                    {
                        !selectedPolicy ? <Group align="center" justify="center">
                            <img src="/assets/images/select-policy-to-continue.png" style={{ width: '350px', opacity: 0.8, marginTop: '100px' }} alt="Select to continue" />
                        </Group> :
                            <Group align="center" justify="center" style={{ padding: '0px 20px' }}>
                                <br />
                                <br />
                                <StyledTitle>
                                    {selectedPolicy.title}
                                </StyledTitle>
                                <StyledTitleDescDiv id='policyDescription' style={{ textAlign: 'center' }}>
                                    {parse(selectedPolicy.description)}
                                </StyledTitleDescDiv>
                                {
                                    selectedPolicy.file && <Group>
                                        <StyledHR />
                                        <FileViewer file={selectedPolicy.file} />
                                        <br />
                                    </Group>
                                }
                                <Group direction="row" gap="xs">
                                    {
                                        state.user?.info.session.role === RoleTypes.ADMIN && <StepButton rightIcon={<Pencil />} onClick={() => { setEdittingPolicy({ ...selectedPolicy }); setNewPolicyCanvas(true) }} text="Edit" />
                                    }
                                    {
                                        selectedPolicy.file ? <StepButton rightIcon={<CloudDownload />} onClick={() => downloadFile(selectedPolicy.file?.url as string, `${selectedPolicy.file?.fileName}.${selectedPolicy.file?.ext as string}`)} text="Download" /> :
                                            <StepButton rightIcon={<CloudDownload />} onClick={() => downloadTxtFile(document.getElementById('policyDescription')?.textContent || "", selectedPolicy.title)} text="Download" />
                                    }
                                    {
                                        state.user?.info.session.role === RoleTypes.ADMIN && <StepButton loading={mutatingPolicyDeletionAsync} classNamesArr={["danger"]} rightIcon={<Trash />} onClick={() => confirm({ onConfirmation: onDelete })} text="Delete Policy" />
                                    }
                                </Group>
                            </Group>
                    }
                </Group>
            </StyledGroup>
            <NewPolicyCanvas open={newPolicyCanvas} policy={edittingPolicy} setOpen={setNewPolicyCanvas} onSuccess={() => { refetch(); setSelectedPolicy(undefined) }} />
        </Page>
    );
}

const NewPolicyCanvas: FC<{ open: boolean; policy: Policy; setOpen: (open: boolean) => void; onSuccess?: () => void }> = ({ open, policy, setOpen, onSuccess }) => {

    const theme = useTheme();
    const { notify } = useNotification();
    const { createUpdatePolicy } = useApi();

    const { onSubmit, onChange, reset, errors, formValues, updateValues } = useForm({
        defaultValues: policy,
        validations: {
            title: { required: true, max: 60 },
            description: { required: true, max: 10000, min: 10 },
        }
    })

    const { isPending: mutatingPolicyAsync, mutateAsync: mutatePolicyAsync } = useMutation({
        mutationFn: createUpdatePolicy,
    });

    useEffect(() => {
        if (formValues.id !== policy.id) {
            updateValues(policy)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy])

    const updatePolicy = async (data: Policy) => {
        try {
            if (data.fileId && !data.file) {
                delete data.fileId
            }
            await mutatePolicyAsync(data)
            notify({ type: "success", title: "Successful!", body: `Policy has been successfully ${data.id ? 'updated' : 'created'}!` });
            reset()
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <JournalText size={20} style={{ marginRight: '5px' }} /> {formValues.id ? 'Edit' : 'New'} Policy
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper>
                        <form onSubmit={onSubmit(updatePolicy)}>
                            <Group gap='sm'>
                                <TextInput
                                    isRequired
                                    type="text"
                                    label="Title"
                                    placeholder="Title"
                                    error={errors.title}
                                    value={formValues.title}
                                    onChange={onChange("title")}
                                />
                                <RichText
                                    isRequired
                                    label={`Description`}
                                    error={errors.description}
                                    placeholder={`Description`}
                                    value={formValues.description}
                                    parentStyle={{ maxWidth: '100%' }}
                                    onChange={onChange("description")}
                                />
                                <FileInput
                                    error={errors.file}
                                    file={formValues.file}
                                    label={`Policy document`}
                                    onChange={onChange("file")}
                                    parentStyle={{ maxWidth: '100%' }}
                                    fileType={"DOCUMENTS"}
                                />
                                <br />
                                <Button text={"Submit"} type='submit' loading={mutatingPolicyAsync} />
                            </Group>
                        </form>
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}