import { XIcon } from "../Icons/XIcon";
import styled from "styled-components";
import styles from "./Notifications.module.scss";
import { useCallback, useEffect, useRef } from "react";
import type { Notification } from "../../context/notification";

const displayDuration = 5000;

const typeMap = {
  default: {
    style: "",
  },
  success: {
    style: 'success',
  },
  error: {
    style: 'error',
  },
};

interface NotificationItemProps {
  notification: Notification;
  onRemove: (id: string) => void;
}

const NotificationChildDiv = styled.div`
  border-color: ${(props) => props.theme.colors.primary};
  &.error {
    border-color: ${(props) => props.theme.colors.danger} !important;
  }

  &.success {
    border-color: ${(props) => props.theme.colors.success} !important;
  }
`

const NotificationChildTitle = styled.h5`
  &.error {
    color: ${(props) => props.theme.colors.danger} !important;
  }

  &.success {
    color: ${(props) => props.theme.colors.success} !important;
  }
`
const NotificationDiv = styled.div`
  background: ${(props) => props.theme.colors.white};
`

export const NotificationItem = ({ notification, onRemove }: NotificationItemProps) => {
  const { id, type, title, body } = notification;
  const { style } = typeMap[type];

  const displayDurationTimeoutId = useRef<number>();

  const onMouseEnter = () => {
    clearTimeout(displayDurationTimeoutId.current);
  };

  const onMouseLeave = () => {
    displayDurationTimeoutId.current = window.setTimeout(removeItem, displayDuration / 2);
  };

  const removeItem = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  useEffect(() => {
    displayDurationTimeoutId.current = window.setTimeout(removeItem, displayDuration);
    return () => clearTimeout(displayDurationTimeoutId.current);
  }, [removeItem]);

  return (
    <NotificationDiv
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={styles.notification}
      role="log"
    >
      <NotificationChildDiv className={style}>
        <NotificationChildTitle className={`${style} ${styles.title}`}>{title}</NotificationChildTitle>
        {body && <p>{body}</p>}
      </NotificationChildDiv>

      <button
        onClick={removeItem}
        className={styles.close}
        title="Close Notification"
        aria-label="Close Notification"
      >
        <XIcon />
      </button>
    </NotificationDiv>
  );
};
