import { css } from "styled-components";

export const forPhoneOnly = (styles: string) => css`
  @media (max-width: 599px) {
    ${styles}
  }
`;

export const boxShadow = (color = "rgba(0, 0, 0, 0.1)") => css`
    box-shadow: 4px 2px 4px ${color};
`;

export const transition = css`
    transition: all 0.25s;
`;

export const growShadow = () => css`
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
`;

export const grow = css`
  ${transition}
  &:hover {
    ${growShadow}
  }
`;

export const growSmallerShadow = () => css`
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
`;

export const growSmaller = css`
  ${transition}
  &:hover {
    ${growSmallerShadow}
  }
`;

export const focus = css`
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const focusVisible = css`
  &:focus-visible {
    outline: 1px solid ${(props) => props.theme.colors.primary};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
    transition: none;
  }
`;

export const keyboardFocusOnly = css`
    ${focus}
    ${focusVisible}
`;