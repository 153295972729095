import { FC, useRef } from "react";
import styled from "styled-components";
import { transition } from "../../Elements";
import { FolderFile } from "shared-library";
import { Group } from "../../Layout/Group/Group";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { useTheme } from "../../../hooks/use-theme.hook";
import { useFullscreen, useToggle, useVideo } from "react-use";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Dash, FastForward, Fullscreen, FullscreenExit, Pause, Play, Plus, Rewind, VolumeDown, VolumeMute } from "react-bootstrap-icons";

export const FileViewer: FC<{ file: FolderFile }> = ({ file }) => {

    return <>
        {
            file.fileType === "PHOTOS" ? <>
                <img src={file.url} alt={file.fileName} width="100%" />
            </> : <>
                {
                    file.fileType === "VIDEOS" ? <VideoPlayer file={file} /> : <DocViewer
                        documents={[
                            { uri: file.url },
                        ]}
                        config={{
                            header: {
                                disableHeader: false,
                                disableFileName: false,
                                retainURLParams: false,
                            },
                            csvDelimiter: ",",
                            pdfZoom: {
                                defaultZoom: 1.1,
                                zoomJump: 0.2,
                            },
                            pdfVerticalScrollByDefault: true
                        }}
                        pluginRenderers={DocViewerRenderers} />
                }
            </>
        }
    </>
}

const StyledVideoContainer = styled.div`
    ${transition}
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .controller {
        z-index:2;
        visibility: hidden;
        padding: 10px 20px;
        border-radius: 50px;
        margin: -100px 30px 0 30px;
        background: rgba(0,0,0,0.4);

        .item {
            cursor: pointer;
            border-width: 0;
            text-align: center;
            border-radius: 50%;
            padding: 4px 6px 0px 6px;
            color: ${props => props.theme.colors.white};
            &.borderRounded {
                border: 1px solid ${props => props.theme.colors.white};
            }
        }
    }

    &:hover {
        .controller {
            visibility: visible;
        }
    }

`

const VideoPlayer = ({ file }: { file: FolderFile }) => {

    const ref = useRef(null)
    const [show, toggle] = useToggle(false);
    const isFullscreen = useFullscreen(ref, show, { onClose: () => toggle(false) });

    const theme = useTheme()
    const [video, state, controls] = useVideo(<video width={'100%'} src={file.url} autoPlay={false} />);

    return (<StyledVideoContainer ref={ref}>
        {video}
        {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
        <Group className="controller" align="center" justify="space-between" direction="row">

            <Group gap="xs">
                <Group direction="row" align="center" justify="center" gap="xs">
                    {state.muted ?
                        <span className="item" onClick={controls.unmute}><VolumeMute color={theme.colors.danger} size={22} /></span> :
                        <span className="item" onClick={controls.mute}><VolumeDown color={theme.colors.white} size={22} /></span>
                    }
                    <span className="item" onClick={() => { controls.volume(state.volume - 0.1); controls.unmute() }}><Dash size={22} /></span>
                    <span className="item" onClick={() => { controls.volume(state.volume + 0.1); controls.unmute() }}><Plus size={22} /></span>
                </Group>
                <ProgressBar height={2} value={state.volume} maxValue={1} color={theme.colors.white} bgColor={theme.colors.black} />
            </Group>

            <Group direction="row" align="center" justify="center" gap="xs">
                {state.playing ?
                    <span className="item borderRounded" onClick={controls.pause}><Pause size={22} /></span> :
                    <span className="item borderRounded" onClick={controls.play}><Play size={22} /></span>
                }
            </Group>

            <Group direction="row" align="center" justify="center" gap="xs">
                <span className="item borderRounded" onClick={() => controls.seek(state.time - 5)}><Rewind size={18} /></span>
                <span className="item borderRounded" onClick={() => controls.seek(state.time + 5)}><FastForward size={18} /></span>
                <span className="item" onClick={() => toggle()}>
                    {
                        isFullscreen ? <FullscreenExit size={22} /> : <Fullscreen size={22} />
                    }
                </span>
            </Group>
        </Group>
    </StyledVideoContainer>
    );
}