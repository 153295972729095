import { ErrorPage } from "./ErrorPage";

type FallbackProps = {
  error: {
    message: string;
  };
};

export const ErrorFallback = ({ error }: FallbackProps) => {
  return <ErrorPage message={error.message} />;
};

export const InternalServerError = () => {
  return <ErrorPage message="INTERNAL SERVER ERROR" />;
};

export const NotFoundError = () => {
  return <ErrorPage message="404, PAGE NOT FOUND" />;
};

export const AccessDeniedError = () => {
  return <ErrorPage message="ACCESS DENIED" />;
};
