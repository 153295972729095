import { upperFirst } from 'lodash';
import parse from 'html-react-parser';
import { FC, useContext } from "react";
import styled from "styled-components";
import { Dropdown } from "../Dropdown";
import { RoleTypes } from 'shared-library';
import { useNavigate } from "react-router-dom";
import { Group } from "../../Layout/Group/Group";
import { AuthContext } from "../../../context/auth";
import { useTheme } from '../../../hooks/use-theme.hook';
import { DropdownPropOptions } from "../Dropdown/Dropdown";
import { AppNotifications } from "../AppNotifications/AppNotifications";
import { useRouteResolver } from '../../../hooks/use-route-resolver.hook';
import { CompanyProfilePages, ProfilePages, createImageFromInitials } from 'shared-library';
import { Activity, BoxArrowRight, BuildingGear, CashCoin, PersonFillLock, PersonLinesFill, PersonX } from "react-bootstrap-icons";
import { StyledProfilePicture, StyledChapterChildTitle, StyledTitleDescDiv, forPhoneOnly, growSmaller, StyledSmall } from "../../Elements";

const StyledPageDesc = styled.p`
    margin-top: -7px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.primary};
`

const StyledName = styled.div`
    font-weight: 600;
    margin-bottom: 4px;
    color: ${(props) => props.theme.colors.black};
`

const StyledProfileGroup = styled(Group)`
    width: 300px;
    padding-top: 35px;
    ${forPhoneOnly("width: 100%;")}
    .content {
        ${growSmaller}
        padding: 0 10px 0 10px;
        border-radius: 10px;
        border: 1px solid ${props => props.theme.colors.grey6};
    }
`

export const TopMenuProfile: FC<{ viewType?: "sm" | "lg" }> = ({ viewType = "sm" }) => {

    const theme = useTheme()
    const navigate = useNavigate();
    const { state, logout } = useContext(AuthContext);
    const { deactivateProfile } = useRouteResolver()

    if (!state.user) return <></>

    const options: Array<DropdownPropOptions> = [
        {
            icon: <PersonLinesFill color={theme.colors.black2} />,
            onClick: () => navigate(`/${ProfilePages.PersonalInformation}`),
            text: <span style={{ color: theme.colors.black2 }}>Profile</span>
        },
        {
            icon: <Activity color={theme.colors.black2} />,
            onClick: () => navigate(`/${ProfilePages.LoginActivities}`),
            text: <span style={{ color: theme.colors.black2 }}>Login Activities</span>
        },
        {
            icon: <PersonFillLock color={theme.colors.black2} />,
            onClick: () => navigate(`/${ProfilePages.SecuritySettings}`),
            text: <span style={{ color: theme.colors.black2 }}>Security Settings</span>
        },
        ...(
            state.user.info.session.role === RoleTypes.ADMIN ? [
                {
                    demarcateTop: true,
                    icon: <CashCoin color={theme.colors.black2} />,
                    text: <span style={{ color: theme.colors.black2 }}>Billing</span>,
                    onClick: () => navigate(`/${state.user?.info.session.role}/${CompanyProfilePages.Billing}`)
                },
                {
                    icon: <BuildingGear color={theme.colors.black2} />,
                    text: <span style={{ color: theme.colors.black2 }}>Company Profile</span>,
                    onClick: () => navigate(`/${state.user?.info.session.role}/${CompanyProfilePages.CompanyInformation}`)
                },
            ] : []
        ),
        ...(
            state.user.info.session.roles.length > 1 ? [
                {
                    demarcateTop: true,
                    onClick: () => deactivateProfile(),
                    icon: <PersonX color={theme.colors.black2} />,
                    text: <span style={{ color: theme.colors.black2 }}>Switch Profile</span>
                },
                {
                    onClick: logout,
                    icon: <BoxArrowRight color={theme.colors.danger} />,
                    text: <span style={{ color: theme.colors.danger }}>Log out</span>
                }
            ] : [
                {
                    onClick: logout,
                    demarcateTop: true,
                    icon: <BoxArrowRight color={theme.colors.danger} />,
                    text: <span style={{ color: theme.colors.danger }}>Log out</span>
                }
            ]
        ),

    ]

    return (
        <>
            {
                viewType === "sm" ? <>
                    <AppNotifications />
                    <StyledProfilePicture src={state.user.picture || createImageFromInitials(state.user.fullName as string, theme.colors.white, theme.colors.secondary)} alt="Profile" />
                    <Group style={{ cursor: 'pointer', position: 'relative', minWidth: '80px' }} justify="center">
                        <Dropdown controller={<>
                            <StyledName>{state.user.nickName}</StyledName>
                            <StyledPageDesc>{upperFirst(state.user.info.session.role as string)}</StyledPageDesc>
                        </>} margin={"md"} options={options} />
                    </Group>
                </> : <StyledProfileGroup>
                    <Group className="content">
                        <Group direction="row">
                            <StyledProfilePicture style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '8px',
                                marginTop: '-20px'
                            }} src={state.user.picture || createImageFromInitials(state.user.fullName as string, theme.colors.white, theme.colors.secondary, 150)} alt="Profile" />
                            <Group style={{
                                cursor: 'pointer',
                                position: 'relative',
                                paddingTop: '25px'
                            }}>
                                <Dropdown controller={<>
                                    <StyledName>{state.user.nickName}</StyledName>
                                    <StyledPageDesc>{upperFirst(state.user.info.session.role as string)}</StyledPageDesc>
                                </>} margin={"md"} options={options} />
                            </Group>
                        </Group>

                        <Group justify="start">
                            <Group gap='xs'>
                                <StyledChapterChildTitle>Bio</StyledChapterChildTitle>
                                {
                                    !!state.user.info.user.bio ?
                                        <StyledTitleDescDiv style={{
                                            fontSize: "smaller",
                                            color: theme.colors.grey
                                        }}>
                                            {parse(state.user.info.user.bio)}
                                            <br />
                                        </StyledTitleDescDiv> :
                                        <StyledSmall style={{ padding: '0 0 10px 0', color: theme.colors.gray2 }}><i>Your bio is empty!</i></StyledSmall>
                                }
                            </Group>
                        </Group>
                    </Group>
                </StyledProfileGroup>
            }
        </>
    );
};

