"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordTypes = void 0;
var RecordTypes;
(function (RecordTypes) {
    RecordTypes["USER"] = "USER";
    RecordTypes["COMPANY"] = "COMPANY";
    RecordTypes["USERLOG"] = "USERLOG";
    RecordTypes["USEREVENT"] = "USEREVENT";
    RecordTypes["COMPANYTEST"] = "COMPANYTEST";
    RecordTypes["COMPANYFILE"] = "COMPANYFILE";
    RecordTypes["COMPANYADMIN"] = "COMPANYADMIN";
    RecordTypes["COMPANYTUTOR"] = "COMPANYTUTOR";
    RecordTypes["COMPANYPOLICY"] = "COMPANYPOLICY";
    RecordTypes["COMPANYCOURSE"] = "COMPANYCOURSE";
    RecordTypes["COMPANYDOMAIN"] = "COMPANYDOMAIN";
    RecordTypes["COMPANYSTUDENT"] = "COMPANYSTUDENT";
    RecordTypes["COMPANYINVOICE"] = "COMPANYINVOICE";
    RecordTypes["USERNOTIFICATION"] = "USERNOTIFICATION";
    RecordTypes["COMPANYSUPERADMIN"] = "COMPANYSUPERADMIN";
    RecordTypes["COMPANYENROLLMENT"] = "COMPANYENROLLMENT";
    RecordTypes["COMPANYCERTIFICATE"] = "COMPANYCERTIFICATE";
    RecordTypes["COMPANYTUTORREVIEW"] = "COMPANYTUTORREVIEW";
    RecordTypes["COMPANYTESTATTEMPT"] = "COMPANYTESTATTEMPT";
    RecordTypes["COMPANYUSERSESSION"] = "COMPANYUSERSESSION";
    RecordTypes["COMPANYCOURSEREVIEW"] = "COMPANYCOURSEREVIEW";
    RecordTypes["COMPANYMONTHLYSTUDENTSIGNUP"] = "COMPANYMONTHLYSTUDENTSIGNUP";
    RecordTypes["COMPANYUSERDAILYLOGINDURATION"] = "COMPANYUSERDAILYLOGINDURATION";
    RecordTypes["COMPANYMONTHLYCOURSEENROLLMENT"] = "COMPANYMONTHLYCOURSEENROLLMENT";
})(RecordTypes || (exports.RecordTypes = RecordTypes = {}));
