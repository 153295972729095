import { useState, useEffect } from "react";
import { useApi } from "../../hooks/use-api";
import { NotFoundError } from "../ErrorPage/Errors";
import { AdminCoursesEditor } from "./AdminCoursesEditor";
import { Spinner } from "../../components/Spinner/Spinner";
import { useParams, useSearchParams } from "react-router-dom";
import { Course, CourseForm, CourseSteps } from "shared-library";

export const AdminCoursesEdit = () => {

    const { getCourse } = useApi()
    const { courseId = "" } = useParams()
    const [searchParams] = useSearchParams();

    const [{ initialCourseData, isLoading }, setData] = useState<{ isLoading: boolean; initialCourseData: Course | undefined }>({ isLoading: true, initialCourseData: undefined })

    useEffect(() => {
        async function fetchData() {
            const initialCourseData = await getCourse(courseId)
            const isLoading = false
            setData({ isLoading, initialCourseData })
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId])

    if (!initialCourseData) {
        return isLoading ? <Spinner text="Fetching Course" style={{ margin: 60 }} /> : <NotFoundError />
    }

    const getStep = (): CourseSteps => {
        if (searchParams.get('editor') === CourseSteps.Settings) return CourseSteps.Settings
        if (searchParams.get('editor') === CourseSteps.Curriculum) return CourseSteps.Curriculum
        if (searchParams.get('editor') === CourseSteps.CourseMedia) return CourseSteps.CourseMedia
        return CourseSteps.BasicInformation
    }

    const courseDefaultValues: CourseForm = {
        step: getStep(),
        id: initialCourseData.id,
        level: initialCourseData.level,
        title: initialCourseData.title,
        color: initialCourseData.color,
        price: initialCourseData.price,
        assets: initialCourseData.assets,
        status: initialCourseData.status,
        tutorId: initialCourseData.tutorId,
        settings: initialCourseData.settings,
        duration: initialCourseData.duration,
        curriculum: initialCourseData.curriculum,
        description: initialCourseData.description,
        isPartialUpdate: !!searchParams.get('editor'),
    }

    return <AdminCoursesEditor courseDefaultValues={courseDefaultValues} />;
}