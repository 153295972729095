import styled from "styled-components";
import { ReactElement, useState } from "react";
import { ProgressStep } from "../ProgressStep";
import { Group } from "../../Layout/Group/Group";
import { useTheme } from "../../../hooks/use-theme.hook";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";

interface CarouselProps {
    elements: ReactElement[];
    defaultSelectedItem?: number;
}

const StyledCarousel = styled(Group)`
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;

    .child-container {
        width: 100%;
        height: 700px;
        max-height: 700px;

        video,
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    button {
        border-radius: 4px;
        background: ${props => props.theme.colors.grey4};
        // background: rgba(0,0,0,0.1);
        border: none;
        cursor: pointer;
    }

`

export const Carousel = ({ elements, defaultSelectedItem = 0 }: CarouselProps) => {

    const theme = useTheme()
    const [currentElement, setCurrentElement] = useState(defaultSelectedItem);

    const nextSlide = () => {
        setCurrentElement((prevIndex) =>
            prevIndex === elements.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentElement((prevIndex) =>
            prevIndex === 0 ? elements.length - 1 : prevIndex - 1
        );
    };

    return (
        <>
            <StyledCarousel direction="row" gap="xs" justify="space-between" wrap="nowrap">
                <button onClick={prevSlide} disabled={currentElement === 0}><CaretLeftFill /></button>
                <Group style={{ flex: 1 }} align="center" justify="center" className="child-container">
                    {elements[currentElement]}
                </Group>
                <button onClick={nextSlide} disabled={currentElement === elements.length - 1}><CaretRightFill /></button>
            </StyledCarousel>
            <ProgressStep color={theme.colors.grey} totalSteps={elements.length} active={currentElement + 1} />
        </>
    )
}