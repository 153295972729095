import { parseResponse } from "./common";
import { useContext, useMemo } from "react";
import { AuthContext } from "../../context/auth";
import { Get, Post, Remove, Write } from "./types";
import { CompanyContext } from "../../context/company";

export const useFetch = () => {
    const { state } = useContext(AuthContext);
    const { state: AppCompany } = useContext(CompanyContext);

    const headers = useMemo(
        () => ({
            headers: {
                "Content-Type": "application/json",
                ...(state.user?.jwt ? { "Authorization": `Bearer ${state.user.jwt}` } : {}),
                ...(AppCompany.company?.id ? { "X-Client-Id": AppCompany.company.id } : {}),
                ...(state.user?.info.session.sortKey ? { "X-Session-Id": state.user.info.session.sortKey } : {})
            },
        }),
        [AppCompany.company?.id, state.user?.info.session.sortKey, state.user?.jwt]
    );

    const write: Write = async (method, url, payload, customOptions = {}) => {
        const options = {
            method,
            body: JSON.stringify(payload),
            ...headers,
            ...customOptions,
        };
        const response = await fetch(url, options);
        const { status, statusText } = response;
        if (![200, 202, 204].includes(status)) {
            const data = await response.json();
            throw data?.errorCode || statusText;
        }
        return parseResponse(response);
    };

    const get: Get = async (url, customOptions = {}) => {
        const options = { ...headers, ...customOptions };
        const response = await fetch(url, options);
        const { status, statusText } = response;
        if (![200, 202, 204].includes(status)) {
            const data = await response.json();
            throw data?.errorCode || statusText;
        }
        const data = await response.json();
        return data;
    };

    const post: Post = (url, payload, customOptions = {}) => {
        return write("POST", url, payload, customOptions);
    };

    const patch: Post = (url, payload, customOptions = {}) => {
        return write("PATCH", url, payload, customOptions);
    };

    const put: Post = (url, payload, customOptions = {}) => {
        return write("PUT", url, payload, customOptions);
    };

    const remove: Remove = (url, payload = {}, customOptions = {}) => {
        return write("DELETE", url, payload, customOptions);
    };

    return { get, patch, post, put, remove };
};
