import React from 'react';
import styled from 'styled-components';

interface LoadMoreProps {
    loading?: boolean;
    onclick: () => void;
}

const StyledLoadMoreContainerDiv = styled.div`
    text-align: center;
    border-radius: 20px;
    padding: 6px;
    cursor: pointer;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primary4};
`

export const LoadMore: React.FC<LoadMoreProps> = ({ onclick, loading }) => {
    return (
        <StyledLoadMoreContainerDiv className={`${loading ? 'disabledEvents' : ''}`} onClick={onclick}>
            <small>Load more</small>
        </StyledLoadMoreContainerDiv>
    );
};