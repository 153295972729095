import "@fontsource/nunito";
import { useContext } from 'react';
import "@fontsource/nunito/400.css";
import { Skeleton } from "./Skeleton";
import "@fontsource/nunito/400-italic.css";
import { Spinner } from "../Spinner/Spinner";
import { Login } from '../../pages/Login/Login';
import { Roles } from "../../pages/Roles/Roles";
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from "react-error-boundary";
import { Profile } from '../../pages/Profile/Profile';
import { PublicRoute } from './PublicRoute/PublicRoute';
import { AuthContextProvider } from '../../context/auth';
import { Policies } from '../../pages/Policies/Policies';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';
import { AdminTests } from '../../pages/AdminTests/AdminTests';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CourseReviews } from "../../pages/Reviews/CourseReviews";
import { AdminTutors } from '../../pages/AdminTutors/AdminTutors';
import { Certificates } from "../../pages/Certificates/Certificates";
import { AdminTestsNew } from '../../pages/AdminTests/AdminTestsNew';
import { AdminUploads } from '../../pages/AdminUploads/AdminUploads';
import { AdminCourses } from '../../pages/AdminCourses/AdminCourses';
import { ReactQueryContextProvider } from '../../context/react-query';
import { AdminTestsEdit } from '../../pages/AdminTests/AdminTestsEdit';
import { AdminStudents } from '../../pages/AdminStudents/AdminStudents';
import { NotificationContextProvider } from '../../context/notification';
import { AdminCoursesNew } from '../../pages/AdminCourses/AdminCoursesNew';
import { AdminDashboard } from '../../pages/AdminDashboard/AdminDashboard';
import { AdminUploadsNew } from '../../pages/AdminUploads/AdminUploadsNew';
import { StudentCourses } from "../../pages/StudentCourses/StudentCourses";
import { Notifications } from '../../components/Notifications/Notifications';
import { AdminTestsPreview } from '../../pages/AdminTests/AdminTestsPreview';
import { AdminCoursesView } from '../../pages/AdminCourses/AdminCoursesView';
import { AdminCoursesEdit } from '../../pages/AdminCourses/AdminCoursesEdit';
import { CompanyContext, CompanyContextProvider } from '../../context/company';
import { AdminTestsResponses } from '../../pages/AdminTests/AdminTestsResponses';
import { AdminEnrollments } from "../../pages/AdminEnrollments/AdminEnrollments";
import { StudentCourseLearn } from "../../pages/StudentCourseLearn/StudentCourseLearn";
import { CompanyProfilePages, ProfilePages, RoleTypes, AppThemes } from 'shared-library';
import { ErrorFallback, InternalServerError, NotFoundError } from '../../pages/ErrorPage/Errors';
import { AdminCompanyProfile, AdminCompanyWebsiteEditor } from "../../pages/AdminCompanyProfile/AdminCompanyProfile";
import { Welcome } from "../../pages/Welcome/Welcome";
import { PublicPolicies } from "../../pages/Welcome/Policies";


const Navigation = () => {
    const { state: AppCompany } = useContext(CompanyContext);
    return (
        <>
            <Spinner show={!AppCompany?.company} theme="fade" text="Unpacking" style={{ margin: 180 }} />
            {
                AppCompany?.company && <ThemeProvider theme={AppCompany.company.theme as AppThemes}>
                    <ErrorBoundary
                        FallbackComponent={({ error }) => (
                            <Skeleton>
                                <ErrorFallback error={error} />
                            </Skeleton>
                        )}
                    >
                        <Routes>
                            <Route path="/">

                                <Route path="" element={
                                    <PublicRoute>
                                        <Welcome />
                                    </PublicRoute>
                                } />

                                <Route path="login" element={
                                    <Skeleton>
                                        <PublicRoute>
                                            <Login />
                                        </PublicRoute>
                                    </Skeleton>
                                } />

                                <Route path={RoleTypes.ADMIN}>
                                    <Route
                                        path="dashboard"
                                        element={
                                            <Skeleton>
                                                <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                    <AdminDashboard />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />
                                    <Route path="courses">
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminCourses />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path="new"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminCoursesNew />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":courseId/view"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminCoursesView />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":courseId/edit"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminCoursesEdit />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":courseId/reviews"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <CourseReviews />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                    </Route>
                                    <Route path="tests">
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminTests />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path="new"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminTestsNew />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path="edit/:testId"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminTestsEdit />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path="preview/:testId"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminTestsPreview />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path="responses/:testId"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminTestsResponses />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                    </Route>
                                    <Route
                                        path="students"
                                        element={
                                            <Skeleton>
                                                <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                    <AdminStudents />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />
                                    <Route
                                        path="tutors">
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminTutors />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                    </Route>
                                    <Route
                                        path="enrollments">
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminEnrollments />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                    </Route>

                                    <Route path="uploads">
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminUploads />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":_id"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                        <AdminUploadsNew />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                    </Route>

                                    <Route
                                        path={CompanyProfilePages.CompanyInformation}
                                        element={
                                            <Skeleton>
                                                <PrivateRoute>
                                                    <AdminCompanyProfile page={CompanyProfilePages.CompanyInformation} />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />

                                    <Route path={CompanyProfilePages.Themes}>
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute>
                                                        <AdminCompanyProfile page={CompanyProfilePages.Themes} />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route path="customise"
                                            element={
                                                <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                    <AdminCompanyWebsiteEditor />
                                                </PrivateRoute>
                                            }
                                            index
                                        />
                                    </Route>

                                    <Route
                                        path={CompanyProfilePages.Billing}
                                        element={
                                            <Skeleton>
                                                <PrivateRoute>
                                                    <AdminCompanyProfile page={CompanyProfilePages.Billing} />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />

                                    <Route
                                        path="certificates"
                                        element={
                                            <Skeleton>
                                                <PrivateRoute roleType={RoleTypes.ADMIN}>
                                                    <Certificates />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />
                                </Route>

                                <Route path={RoleTypes.TUTOR}>
                                    <Route
                                        path="dashboard"
                                        element={
                                            <Skeleton>
                                                <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                    <AdminDashboard />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />
                                    <Route path="courses">
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminCourses />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path="new"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminCoursesNew />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":courseId/view"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminCoursesView />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":courseId/edit"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminCoursesEdit />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":courseId/reviews"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <CourseReviews />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                    </Route>
                                    <Route path="tests">
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminTests />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path="new"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminTestsNew />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path="edit/:testId"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminTestsEdit />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path="preview/:testId"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminTestsPreview />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path="responses/:testId"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminTestsResponses />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                    </Route>
                                    <Route
                                        path="students"
                                        element={
                                            <Skeleton>
                                                <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                    <AdminStudents />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />
                                    <Route
                                        path="enrollments">
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminEnrollments />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                    </Route>
                                    <Route path="uploads">
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminUploads />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":_id"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                        <AdminUploadsNew />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                    </Route>
                                    <Route
                                        path="certificates"
                                        element={
                                            <Skeleton>
                                                <PrivateRoute roleType={RoleTypes.TUTOR}>
                                                    <Certificates />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />
                                </Route>

                                <Route path={RoleTypes.STUDENT}>
                                    <Route
                                        path="dashboard"
                                        element={
                                            <Skeleton>
                                                <PrivateRoute roleType={RoleTypes.STUDENT}>
                                                    <AdminDashboard />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />
                                    <Route path="courses">
                                        <Route
                                            path=""
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.STUDENT}>
                                                        <StudentCourses />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":courseId/view"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.STUDENT}>
                                                        <AdminCoursesView />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":courseId/learn"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.STUDENT}>
                                                        <StudentCourseLearn />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                        <Route
                                            path=":courseId/reviews"
                                            element={
                                                <Skeleton>
                                                    <PrivateRoute roleType={RoleTypes.STUDENT}>
                                                        <CourseReviews />
                                                    </PrivateRoute>
                                                </Skeleton>
                                            }
                                            index
                                        />
                                    </Route>
                                    <Route
                                        path="certificates"
                                        element={
                                            <Skeleton>
                                                <PrivateRoute roleType={RoleTypes.STUDENT}>
                                                    <Certificates />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />
                                </Route>

                                <Route
                                    path="roles"
                                    element={
                                        <Skeleton>
                                            <PrivateRoute>
                                                <Roles />
                                            </PrivateRoute>
                                        </Skeleton>
                                    }
                                    index
                                />

                                <Route
                                    path={ProfilePages.PersonalInformation}
                                    element={
                                        <Skeleton>
                                            <PrivateRoute>
                                                <Profile page={ProfilePages.PersonalInformation} />
                                            </PrivateRoute>
                                        </Skeleton>
                                    }
                                    index
                                />

                                <Route
                                    path={ProfilePages.Notifications}
                                    element={
                                        <Skeleton>
                                            <PrivateRoute>
                                                <Profile page={ProfilePages.Notifications} />
                                            </PrivateRoute>
                                        </Skeleton>
                                    }
                                    index
                                />

                                <Route
                                    path={ProfilePages.LoginActivities}
                                    element={
                                        <Skeleton>
                                            <PrivateRoute>
                                                <Profile page={ProfilePages.LoginActivities} />
                                            </PrivateRoute>
                                        </Skeleton>
                                    }
                                    index
                                />

                                <Route
                                    path={ProfilePages.SecuritySettings}
                                    element={
                                        <Skeleton>
                                            <PrivateRoute>
                                                <Profile page={ProfilePages.SecuritySettings} />
                                            </PrivateRoute>
                                        </Skeleton>
                                    }
                                    index
                                />

                                <Route path="policies">
                                    <Route
                                        path=""
                                        element={
                                            <Skeleton>
                                                <PrivateRoute>
                                                    <Policies />
                                                </PrivateRoute>
                                            </Skeleton>
                                        }
                                        index
                                    />

                                    <Route path="tnc" element={<Skeleton><PublicPolicies /></Skeleton>} index />
                                    <Route path="refund" element={<Skeleton><PublicPolicies /></Skeleton>} index />
                                    <Route path="privacy" element={<Skeleton><PublicPolicies /></Skeleton>} index />

                                </Route>
                                <Route path="500" element={<Skeleton><InternalServerError /></Skeleton>} />
                                <Route path="*" element={<Skeleton><NotFoundError /></Skeleton>} />
                            </Route>
                        </Routes>
                    </ErrorBoundary>
                    <Notifications />
                </ThemeProvider>
            }
        </>
    )
}

function App() {
    return (
        <NotificationContextProvider>
            <AuthContextProvider>
                <ReactQueryContextProvider>
                    <BrowserRouter>
                        <CompanyContextProvider>
                            <Navigation />
                        </CompanyContextProvider>
                    </BrowserRouter>
                </ReactQueryContextProvider>
            </AuthContextProvider>
        </NotificationContextProvider>
    );
}

export default App;
