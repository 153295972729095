import Moment from "react-moment";
import { upperFirst } from "lodash";
import styled from "styled-components";
import { OffCanvas } from "react-offcanvas";
import { useApi } from "../../hooks/use-api";
import { AuthContext } from "../../context/auth";
import { useSearchParams } from "react-router-dom";
import { useForm } from "../../hooks/use-form.hook";
import { Button } from "../../components/Form/Button";
import { useTheme } from "../../hooks/use-theme.hook";
import { TopMenu } from "../../components/App/TopMenu";
import { useNotify } from "../../hooks/use-notify.hook";
import { Dropdown } from "../../components/App/Dropdown";
import { Page } from "../../components/Layout/Page/Page";
import { Spinner } from "../../components/Spinner/Spinner";
import { Group } from "../../components/Layout/Group/Group";
import { LoadMore } from "../../components/App/LoadMore/LoadMore";
import { useNotification } from "../../hooks/use-notification.hook";
import { CheckBoxInput } from "../../components/Form/CheckBox/CheckBox";
import { MailEditor } from "../../components/App/MailEditor/MailEditor";
import { useCurrencySymbol } from "../../hooks/use-currency-symbol.hook";
import { SelectInput } from "../../components/Form/SelectInput/SelectInput";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { EnrollmentStatuses, InvoiceStatuses, PaymentOptions } from "../../utils/data";
import { ArrowRight, CashCoin, Check2All, CheckSquare, CircleFill, DatabaseCheck, EnvelopePlus, Filter, PersonX, Square, ThreeDots, Unlock, X } from "react-bootstrap-icons";
import { UserAsAnActor, createImageFromInitials, pluralize, AllCoursesSchema, AllStudentsSchema, Enrollment, EnrollmentStatus, InvoiceStatus, PaymentMethods, EnrollmentActions } from "shared-library";
import { StyledAlert, StyledContentWrapper, StyledCreationWrapper, StyledDropdown, StyledH5, StyledH6, StyledHR, StyledOffCanvasMenu, StyledSmall, StyledSpan, StyledTable, StyledUserPicture, forPhoneOnly } from "../../components/Elements";


const StyledCoursesFilterDropdown = styled(StyledDropdown)`
    top: 45px;
    width: 300px;
    ${forPhoneOnly("left: -90px; width: 330px;")}
`

interface NewEnrollmentInterface {
    courseId: string;
    studentId: string;
    enrollmentId?: string;
    paymentStatus: InvoiceStatus;
    paymentMethod: PaymentMethods;
}

export const AdminEnrollments = () => {

    const { getEnrollments } = useApi()
    const [searchParams] = useSearchParams();

    const [enrollmentFilters, setEnrollmentFilters] = useState<{
        limit: number;
        nextBatchIndex?: string;
        courseIds?: Array<string>;
        studentIds?: Array<string>;
        statusIds?: Array<EnrollmentStatus>;
    }>({
        limit: 50,
        courseIds: searchParams.get('course') ? [searchParams.get('course') as string] : [],
        studentIds: searchParams.get('student') ? [searchParams.get('student') as string] : [],
        statusIds: searchParams.get('status') ? [searchParams.get('status') as EnrollmentStatus] : [],
    })

    const { data: enrollments, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getEnrollments({
            nextBatchIndex,
            limit: enrollmentFilters.limit,
            courseIds: enrollmentFilters.courseIds,
            statusIds: enrollmentFilters.statusIds,
            studentIds: enrollmentFilters.studentIds,
        }),
        initialPageParam: enrollmentFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["enrollments", enrollmentFilters.studentIds, enrollmentFilters.courseIds, enrollmentFilters.statusIds, enrollmentFilters.limit, enrollmentFilters.nextBatchIndex],
    });

    const theme = useTheme()
    const { confirm } = useNotify()
    const { notify } = useNotification();
    const coursesDropdownRef = useRef<HTMLDivElement>(null);
    const studentsDropdownRef = useRef<HTMLDivElement>(null);
    const enrollmentStatusDropdownRef = useRef<HTMLDivElement>(null);
    const { state, setResources, setUserInfo } = useContext(AuthContext);
    const [coursesFilterIsOpen, setCoursesFilterIsOpen] = useState(false);
    const [studentsFilterIsOpen, setStudentsFilterIsOpen] = useState(false);
    const [enrollmentStatusesFilterIsOpen, setEnrollmentStatusesFilterIsOpen] = useState(false);
    const { getResources, terminateEnrollment, fixEnrollmentActionRequired, getUserinfo } = useApi();
    const { data: resources } = useQuery({
        queryKey: ["allActiveCourses", "allActiveStudents"],
        queryFn: () => getResources(["activeCourses", "activeStudents"]),
        enabled: !state.resources.activeCourses?.length || !state.resources.activeStudents?.length
    });

    useEffect(() => {
        if (resources) {
            setResources({ ...state.resources, activeCourses: resources.activeCourses as Array<AllCoursesSchema>, activeStudents: resources.activeStudents as Array<AllStudentsSchema> })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources])

    const [mailEditorCanvasOpen, setMailEditorCanvasOpen] = useState(false)
    const [newEnrollmentCanvasOpen, setNewEnrollmentCanvasOpen] = useState(false)
    const [enrollmentToApprove, setEnrollmentToApprove] = useState<NewEnrollmentInterface>()

    const handleClickOutside = (event: MouseEvent) => {
        if (coursesDropdownRef.current && !coursesDropdownRef.current.contains(event.target as Node) && !((event.target as Node) instanceof SVGElement)) {
            setCoursesFilterIsOpen(false);
        }
        if (studentsDropdownRef.current && !studentsDropdownRef.current.contains(event.target as Node) && !((event.target as Node) instanceof SVGElement)) {
            setStudentsFilterIsOpen(false);
        }
        if (enrollmentStatusDropdownRef.current && !enrollmentStatusDropdownRef.current.contains(event.target as Node) && !((event.target as Node) instanceof SVGElement)) {
            setEnrollmentStatusesFilterIsOpen(false);
        }
    }

    const { isPending: mutatingTerminateEnrollmentAsync, mutateAsync: mutateTerminateEnrollmentAsync } = useMutation({
        mutationFn: terminateEnrollment
    });

    const { isPending: mutatingUnlockEnrollmentAsync, mutateAsync: mutateUnlockEnrollmentAsync } = useMutation({
        mutationFn: fixEnrollmentActionRequired
    });

    const [usersToMail, setUsersToMail] = useState<Array<UserAsAnActor>>([])
    const [selectedEnrollmentRecords, setSelectedEnrollmentRecords] = useState<Array<Enrollment>>([])
    const [filters, setFilters] = useState<{
        courseIds: Array<string>;
        studentIds: Array<string>;
        statusIds: Array<EnrollmentStatus>;
    }>({
        courseIds: searchParams.get('course') ? [searchParams.get('course') as string] : [],
        studentIds: searchParams.get('student') ? [searchParams.get('student') as string] : [],
        statusIds: searchParams.get('status') ? [searchParams.get('status') as EnrollmentStatus] : [],
    });

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [filters]);

    const flattenEnrollments = useCallback(() => {
        let enrollmentsArr: Array<Enrollment> = []
        if (enrollments?.pages) {
            for (const page of enrollments.pages) {
                enrollmentsArr = [...enrollmentsArr, ...page.data]
            }
        }
        return enrollmentsArr
    },
        [enrollments?.pages]
    );

    const terminateEnrollmentFunc = async (enrollment: Enrollment) => {
        try {
            if (!mutatingTerminateEnrollmentAsync) {
                await mutateTerminateEnrollmentAsync({ enrollmentSortKey: enrollment.sortKey })
                notify({ type: "success", title: "Successful!", body: `Enrollment has been successfully terminated!` });
                refetch()
                setUserInfo(await getUserinfo())
            }
        } catch (error) {
        }
    }

    const unlockEnrollmentFunc = async (enrollment: Enrollment) => {
        try {
            if (!mutatingUnlockEnrollmentAsync) {
                await mutateUnlockEnrollmentAsync({ enrollmentSortKey: enrollment.sortKey, actionRequired: EnrollmentActions.Unlock })
                notify({ type: "success", title: "Successful!", body: `Course material has been successfully unlocked!` });
                refetch()
                setUserInfo(await getUserinfo())
            }
        } catch (error) {
        }
    }

    return (
        <Page>
            <StyledContentWrapper>
                <Group gap="xs">
                    <TopMenu hideNotification titles={[{
                        text: 'Enrollments',
                    }]} newItemLink={(v) => { setEnrollmentToApprove(undefined); setNewEnrollmentCanvasOpen(v); }} />
                    <Group gap="xs" className="slideUp">
                        <br />
                        <br />
                        <br />
                        <Group direction='row' gap="xs" justify='end'>

                            <div style={{ position: 'relative' }} ref={enrollmentStatusDropdownRef}>
                                <div className='pointer' onClick={() => setEnrollmentStatusesFilterIsOpen(true)}>
                                    <Group direction='row' align='center' gap='xs' style={{
                                        height: '35px',
                                        padding: '4px 15px',
                                        borderRadius: '3px',
                                        color: theme.colors.grey,
                                        backgroundColor: theme.colors.white,
                                        border: '1px solid ' + theme.colors.gray,
                                    }}>
                                        <Filter />
                                        <small style={{ paddingTop: '2px' }}>
                                            {
                                                filters.statusIds.length ? <>
                                                    {
                                                        pluralize('Status', filters.statusIds.length)
                                                    }
                                                    <span style={{ fontSize: '10px' }}>
                                                        &nbsp;({filters.statusIds.length})
                                                    </span>
                                                </> : "Statuses"
                                            }
                                        </small>
                                    </Group>
                                </div>
                                {
                                    enrollmentStatusesFilterIsOpen && <StyledDropdown style={{ top: "45px", width: '230px' }}>
                                        {
                                            EnrollmentStatuses.map(({ label: title, value: id }) => <p key={id} onClick={() => setFilters({ ...filters, statusIds: filters.statusIds.includes(id) ? filters.statusIds.filter(v => v !== id) : [...filters.statusIds, id] })}>
                                                {
                                                    filters.statusIds.includes(id) ? <CheckSquare /> : <Square />
                                                }
                                                {title}
                                            </p>)
                                        }
                                        <Group style={{
                                            padding: '10px 8px 4px 8px'
                                        }}>
                                            <Button text='Filter' onClick={() => {
                                                setEnrollmentFilters({ ...enrollmentFilters, statusIds: [...filters.statusIds] })
                                                setEnrollmentStatusesFilterIsOpen(false)
                                            }} />
                                        </Group>
                                    </StyledDropdown>
                                }
                            </div>

                            <div style={{ position: 'relative' }} ref={coursesDropdownRef}>
                                <div className='pointer' onClick={() => setCoursesFilterIsOpen(true)}>
                                    <Group direction='row' align='center' gap='xs' style={{
                                        height: '35px',
                                        padding: '4px 15px',
                                        borderRadius: '3px',
                                        color: theme.colors.grey,
                                        backgroundColor: theme.colors.white,
                                        border: '1px solid ' + theme.colors.gray,
                                    }}>
                                        <Filter />
                                        <small style={{ paddingTop: '2px' }}>
                                            {
                                                filters.courseIds.length ? <>
                                                    {
                                                        pluralize('Course', filters.courseIds.length)
                                                    }
                                                    <span style={{ fontSize: '10px' }}>
                                                        &nbsp;({filters.courseIds.length})
                                                    </span>
                                                </> : "Courses"
                                            }
                                        </small>
                                    </Group>
                                </div>
                                {
                                    coursesFilterIsOpen && <StyledCoursesFilterDropdown>
                                        {
                                            (state.resources.activeCourses || []).map(({ title, id }) => <p key={id} onClick={() => setFilters({ ...filters, courseIds: filters.courseIds.includes(id) ? filters.courseIds.filter(v => v !== id) : [...filters.courseIds, id] })}>
                                                {
                                                    filters.courseIds.includes(id) ? <CheckSquare /> : <Square />
                                                }
                                                {title}
                                            </p>)
                                        }
                                        <Group style={{
                                            padding: '10px 8px 4px 8px'
                                        }}>
                                            <Button text='Filter' onClick={() => {
                                                setEnrollmentFilters({ ...enrollmentFilters, courseIds: [...filters.courseIds] })
                                                setCoursesFilterIsOpen(false)
                                            }} />
                                        </Group>
                                    </StyledCoursesFilterDropdown>
                                }
                            </div>

                            <div style={{ position: 'relative' }} ref={studentsDropdownRef}>
                                <div className='pointer' onClick={() => setStudentsFilterIsOpen(true)}>
                                    <Group direction='row' align='center' gap='xs' style={{
                                        height: '35px',
                                        padding: '4px 15px',
                                        borderRadius: '3px',
                                        color: theme.colors.grey,
                                        backgroundColor: theme.colors.white,
                                        border: '1px solid ' + theme.colors.gray,
                                    }}>
                                        <Filter />
                                        <small style={{ paddingTop: '2px' }}>
                                            {
                                                filters.studentIds.length ? <>
                                                    {
                                                        pluralize('Student', filters.studentIds.length)
                                                    }
                                                    <span style={{ fontSize: '10px' }}>
                                                        &nbsp;({filters.studentIds.length})
                                                    </span>
                                                </> : "Students"
                                            }
                                        </small>
                                    </Group>
                                </div>
                                {
                                    studentsFilterIsOpen && <StyledDropdown style={{ top: "45px", width: '230px' }}>
                                        {
                                            (state.resources.activeStudents || []).map(({ picture, fullName, sub }) => <p key={sub} onClick={() => setFilters({ ...filters, studentIds: filters.studentIds.includes(sub) ? filters.studentIds.filter(v => v !== sub) : [...filters.studentIds, sub] })}>
                                                {
                                                    filters.studentIds.includes(sub) ? <CheckSquare /> : <Square />
                                                }
                                                <StyledUserPicture style={{
                                                    width: '25px',
                                                    height: '25px',
                                                }} src={picture || createImageFromInitials(fullName, theme.colors.white, theme.colors.secondary, 25)} alt="Profile" />
                                                <StyledSmall>{fullName}</StyledSmall>
                                            </p>)
                                        }
                                        <Group style={{
                                            padding: '10px 8px 4px 8px'
                                        }}>
                                            <Button text='Filter' onClick={() => {
                                                setEnrollmentFilters({ ...enrollmentFilters, studentIds: [...filters.studentIds] })
                                                setStudentsFilterIsOpen(false)
                                            }} />
                                        </Group>
                                    </StyledDropdown>
                                }
                            </div>
                        </Group>
                        {
                            !enrollments || isLoading ? <Spinner text="Fetching Attempts" style={{ margin: 60 }} /> :
                                <Group style={{
                                    minHeight: 700,
                                    overflowX: "auto"
                                }}>
                                    <StyledTable className="hasSnNo">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th><StyledH6>STUDENT</StyledH6></th>
                                                <th><StyledH6>COURSE</StyledH6></th>
                                                <th><StyledH6>ENROLLED ON</StyledH6></th>
                                                <th><StyledH6>STATUS</StyledH6></th>
                                                <th><StyledH6>ACTIONS REQUIRED</StyledH6></th>
                                                <th>
                                                    {
                                                        flattenEnrollments().length > 0 && selectedEnrollmentRecords.length > 0 && <div style={{ position: 'relative' }}>
                                                            <Dropdown margin={"sm"} controller={<StyledSpan>
                                                                <ThreeDots size={16} />
                                                            </StyledSpan>} options={[
                                                                {
                                                                    icon: <EnvelopePlus style={{ color: theme.colors.success }} />,
                                                                    text: "Email selected",
                                                                    onClick: () => {
                                                                        setUsersToMail(selectedEnrollmentRecords.map(({ student }) => student).filter(student => student) as Array<UserAsAnActor>);
                                                                        setMailEditorCanvasOpen(true)
                                                                    },
                                                                },
                                                            ]} />
                                                        </div>
                                                    }
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                flattenEnrollments().map(enrollment => <tr key={enrollment.id} >
                                                    <td>
                                                        {
                                                            !!enrollment.student && <CheckBoxInput
                                                                parentStyle={{ marginTop: '5px' }}
                                                                onChange={(selected) => setSelectedEnrollmentRecords(selected ? [...selectedEnrollmentRecords, enrollment] : selectedEnrollmentRecords.filter(({ id }) => id !== enrollment.id))}
                                                                value={!!selectedEnrollmentRecords.find(({ id }) => id === enrollment.id)}
                                                            />
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            enrollment.student && <Group align="center" gap="sm" direction="row">
                                                                <StyledUserPicture style={{
                                                                    width: '25px',
                                                                    height: '25px',
                                                                }} src={enrollment.student.picture || createImageFromInitials(enrollment.student.fullName, theme.colors.white, theme.colors.secondary, 25)} alt="Profile" />
                                                                <Group gap="none">
                                                                    <b><StyledSmall>{enrollment.student.fullName}</StyledSmall></b>
                                                                    <StyledSmall>{enrollment.student.email}</StyledSmall>
                                                                </Group>
                                                            </Group>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            enrollment.course && <Group align="center" gap="sm" wrap="nowrap" direction="row">
                                                                <CircleFill size={10} color={enrollment.course.color} />
                                                                <StyledSmall>{enrollment.course.title}</StyledSmall></Group>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            enrollment.enrollmentDate && <StyledSmall><Moment format={state.user?.info.user.dateFormat + ", hh:mm A"}>{enrollment.enrollmentDate}</Moment></StyledSmall>
                                                        }
                                                    </td>
                                                    <td>
                                                        <StyledSmall>
                                                            <b style={{ color: enrollment.status === EnrollmentStatus.APPROVED ? theme.colors.success : (enrollment.status === EnrollmentStatus.REJECTED ? theme.colors.danger : theme.colors.gray4) }}>{upperFirst(enrollment.status)}</b>
                                                        </StyledSmall>
                                                    </td>
                                                    <td>
                                                        {
                                                            enrollment.actionsRequired.map((actionRequired, index) => <div key={index}>
                                                                <StyledSmall>
                                                                    <b style={{ color: actionRequired === EnrollmentActions.Unlock ? theme.colors.danger : (actionRequired === EnrollmentActions.Payment ? theme.colors.danger : theme.colors.gray4) }}><ArrowRight size={14} style={{ paddingTop: 1 }} /> &nbsp; {upperFirst(actionRequired)}</b>
                                                                </StyledSmall>
                                                            </div>)
                                                        }
                                                    </td>
                                                    <td>
                                                        <div style={{ position: 'relative' }}>
                                                            <Dropdown margin={"sm"} controller={<StyledSpan>
                                                                <ThreeDots size={16} />
                                                            </StyledSpan>} options={[
                                                                ...(enrollment.status === EnrollmentStatus.PENDING ? [
                                                                    {
                                                                        icon: <Check2All style={{ color: theme.colors.success }} />,
                                                                        text: "Approve",
                                                                        onClick: () => {
                                                                            setEnrollmentToApprove({
                                                                                enrollmentId: enrollment.id,
                                                                                courseId: enrollment.courseId,
                                                                                studentId: enrollment.studentId,
                                                                                paymentStatus: InvoiceStatus.Paid,
                                                                                paymentMethod: PaymentMethods.Manual
                                                                            });
                                                                            setNewEnrollmentCanvasOpen(true);
                                                                        },
                                                                    },
                                                                    {
                                                                        icon: <PersonX style={{ color: theme.colors.danger }} />,
                                                                        text: "Reject",
                                                                        onClick: () => confirm({
                                                                            onConfirmation: () => terminateEnrollmentFunc(enrollment)
                                                                        })
                                                                    },
                                                                ] : []),
                                                                ...(enrollment.status === EnrollmentStatus.APPROVED && enrollment.actionsRequired.includes(EnrollmentActions.Unlock) ? [
                                                                    {
                                                                        icon: <Unlock style={{ color: theme.colors.secondary }} />,
                                                                        text: "Unlock Material",
                                                                        onClick: () => unlockEnrollmentFunc(enrollment)
                                                                    },
                                                                ] : []),
                                                                {
                                                                    disabled: !enrollment.student,
                                                                    icon: <EnvelopePlus style={{ color: theme.colors.success }} />,
                                                                    text: "Email student",
                                                                    onClick: () => {
                                                                        if (enrollment.student) {
                                                                            setUsersToMail([enrollment.student]);
                                                                            setMailEditorCanvasOpen(true)
                                                                        }
                                                                    },
                                                                },
                                                                ...(enrollment.status === EnrollmentStatus.APPROVED ? [
                                                                    {
                                                                        icon: <PersonX style={{ color: theme.colors.danger }} />,
                                                                        text: "Terminate",
                                                                        onClick: () => confirm({
                                                                            onConfirmation: () => terminateEnrollmentFunc(enrollment)
                                                                        })
                                                                    },
                                                                ] : []),
                                                            ]} />
                                                        </div>
                                                    </td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </StyledTable>
                                    {
                                        hasNextPage && <LoadMore loading={isFetchingNextPage} onclick={fetchNextPage} />
                                    }
                                </Group>
                        }
                    </Group>
                </Group>
                <MailEditor open={mailEditorCanvasOpen} emails={usersToMail.map(({ email }) => email)} setOpen={canvasState => { setMailEditorCanvasOpen(canvasState); }} />
                <NewEnrollment enrollmentRecord={enrollmentToApprove} open={newEnrollmentCanvasOpen} onSuccess={refetch} setOpen={canvasState => { setNewEnrollmentCanvasOpen(canvasState); }} />
            </StyledContentWrapper>
        </Page>
    )
}


const NewEnrollment: FC<{ open: boolean; setOpen: (open: boolean) => void; onSuccess?: () => void; enrollmentRecord?: NewEnrollmentInterface }> = ({ open, setOpen, onSuccess, enrollmentRecord }) => {

    const theme = useTheme()
    const { enrollAStudent } = useApi()
    const { notify } = useNotification();
    const { prepPrice } = useCurrencySymbol()
    const { state } = useContext(AuthContext)
    const { isPending: mutatingNewEnrollmentAsync, mutateAsync: mutateNewEnrollmentAsync } = useMutation({
        mutationFn: enrollAStudent,
    });

    const [selectedCourse, setSelectedCourse] = useState<AllCoursesSchema>()
    const { onSubmit, errors, onChange, formValues, reset, updateValues } = useForm<NewEnrollmentInterface>({
        defaultValues: enrollmentRecord || {
            courseId: "",
            studentId: "",
            paymentStatus: InvoiceStatus.Paid,
            paymentMethod: PaymentMethods.Manual
        },
        validations: {
            courseId: { required: true },
            studentId: { required: true },
        }
    })

    useEffect(() => {
        if (enrollmentRecord) {
            updateValues(enrollmentRecord)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enrollmentRecord])

    const [dropdownKey, setDropDownKey] = useState(0); // Force dropdown to rerender after form successfully submitted
    useEffect(() => {
        setSelectedCourse(state.resources.activeCourses?.find(({ id }) => id === formValues.courseId))
    }, [formValues.courseId, state.resources.activeCourses])

    const enrollAStudentFunc = async (data: NewEnrollmentInterface) => {
        try {
            await mutateNewEnrollmentAsync(data)
            reset()
            notify({ type: "success", title: "Successful!", body: `Enrollment record has been successfully created!` });
            setDropDownKey(prevKey => prevKey + 1);
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <DatabaseCheck size={20} style={{ marginRight: '5px' }} /> Enroll a student
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper>
                        <form onSubmit={onSubmit(enrollAStudentFunc)}>
                            <Group gap='sm'>

                                <SelectInput
                                    isRequired
                                    label="Student"
                                    key={dropdownKey}
                                    placeholder="Student"
                                    error={errors.studentId}
                                    parentStyle={{ width: '100%' }}
                                    onChange={onChange("studentId")}
                                    disabled={!!formValues.enrollmentId}
                                    options={(state.resources?.activeStudents || []).map(({ sub: value, fullName: label }) => ({ value, label }))}
                                    value={(state.resources?.activeStudents || []).map(({ sub: value, fullName: label }) => ({ value, label })).find(d => d.value === formValues.studentId)}
                                />

                                <SelectInput
                                    isRequired
                                    label="Course"
                                    placeholder="Course"
                                    error={errors.courseId}
                                    key={`${dropdownKey}.1`}
                                    parentStyle={{ width: '100%' }}
                                    onChange={onChange("courseId")}
                                    disabled={!!formValues.enrollmentId}
                                    options={(state.resources?.activeCourses || []).map(({ id: value, title: label }) => ({ value, label }))}
                                    value={(state.resources?.activeCourses || []).map(({ id: value, title: label }) => ({ value, label })).find(d => d.value === formValues.courseId)}
                                />

                                {
                                    selectedCourse && selectedCourse.price.amount > 0 && <>
                                        <StyledAlert align="center" wrap="nowrap" gap="sm" direction="row" className="info">
                                            <CashCoin size={18} />
                                            <b>Please note that {prepPrice(selectedCourse.price.currency, selectedCourse.price.amount)} payment is required for this enrollment.</b>
                                        </StyledAlert>

                                        <SelectInput
                                            isRequired
                                            label="Payment Method"
                                            placeholder="Payment Method"
                                            error={errors.paymentMethod}
                                            key={`${dropdownKey}.2`}
                                            parentStyle={{ width: '100%' }}
                                            onChange={onChange("paymentMethod")}
                                            options={PaymentOptions.filter(({ enabled }) => enabled)}
                                            value={PaymentOptions.find(d => d.value === formValues.paymentMethod)}
                                        />

                                        <SelectInput
                                            isRequired
                                            label="Payment Status"
                                            placeholder="Payment Status"
                                            error={errors.paymentStatus}
                                            key={`${dropdownKey}.3`}
                                            parentStyle={{ width: '100%' }}
                                            onChange={onChange("paymentStatus")}
                                            value={InvoiceStatuses.find(d => d.value === formValues.paymentStatus)}
                                            options={InvoiceStatuses.filter(invoiceStatus => invoiceStatus.value !== InvoiceStatus.Cancelled)}
                                        />
                                    </>
                                }

                                <br />
                                <Button text={formValues.paymentStatus === InvoiceStatus.Paid ? "Enroll" : (formValues.enrollmentId ? "Update" : "Create")} type='submit' loading={mutatingNewEnrollmentAsync} />
                            </Group>
                        </form>
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}
