import Moment from "react-moment";
import { FC, useContext } from "react";
import { Certificate } from "shared-library";
import { AuthContext } from "../../../context/auth";
import { useTheme } from "../../../hooks/use-theme.hook";
import { ChevronRight, Award } from "react-bootstrap-icons";
import { StyledTestGroup, StyledTitleDesc } from "../../Elements";

interface CertificateCardProps {
    active?: boolean;
    onClick?: () => void;
    certificate: Certificate;
}

export const CertificateCard: FC<CertificateCardProps> = ({ onClick, active, certificate }) => {
    const theme = useTheme();
    const { state } = useContext(AuthContext)

    return (
        <StyledTestGroup className={`${active ? 'active' : ''} ${onClick ? 'pointer' : ''}`} onClick={() => onClick && onClick()}>
            <span className="icon">
                <Award />
            </span>
            <div style={{ flex: 1 }}>
                <b>{certificate.title || 'Untitled certificate'}</b>
                <StyledTitleDesc style={{ color: theme.colors.gray3, marginTop: '-4px' }}>
                    <small>Uploaded <b>{(state.resources.activeCourses || []).find(({ id }) => id === certificate.courseId)?.title || 'Deleted Course'}</b> certification for <b>{(state.resources.activeStudents || []).find(({ sub }) => sub === certificate.studentId)?.fullName || 'Deleted Student'}</b> about <Moment fromNow>{certificate.creationDate}</Moment></small>
                </StyledTitleDesc>
            </div>
            <span className="icon-right"><ChevronRight /></span>
        </StyledTestGroup>
    );
};
