"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortAlphabetically = void 0;
const sortAlphabetically = (arr, key) => {
    let ret = arr.slice().sort(function (a, b) {
        let textA = String(a[key]).toUpperCase();
        let textB = String(b[key]).toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return ret;
};
exports.sortAlphabetically = sortAlphabetically;
