import { RoleCard } from "./RoleCard";
import styles from "./Roles.module.scss";
import { RoleTypes } from "shared-library";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../context/auth";
import { useTheme } from "../../hooks/use-theme.hook";
import { Page } from "../../components/Layout/Page/Page";
import { Group } from "../../components/Layout/Group/Group";
import { User, createImageFromInitials } from "shared-library";
import { StyledProfilePicture } from "../../components/Elements";
import { useRouteResolver } from "../../hooks/use-route-resolver.hook";
import { PersonLock, PersonVcard, PersonVideo3, PersonX } from "react-bootstrap-icons";

export const Roles = () => {

    const theme = useTheme()
    const { state } = useContext(AuthContext);
    const { activateProfile } = useRouteResolver()

    const roles = useCallback(() => {
        const AllRoleTypes = [
            {
                text: "ADMIN",
                id: RoleTypes.ADMIN,
                icon: <PersonLock color={theme.colors.grey} size={18} />,
                description: "Elevated profile for system management",
            },
            {
                text: "TUTOR",
                id: RoleTypes.TUTOR,
                icon: <PersonVideo3 color={theme.colors.grey} size={18} />,
                description: "Tutor profile for course management.",
            },
            {
                text: "STUDENT",
                id: RoleTypes.STUDENT,
                icon: <PersonVcard color={theme.colors.grey} size={18} />,
                description: "Student profile and course participation.",
            },
            {
                text: "SUPER ADMIN",
                id: RoleTypes.SUPER_ADMIN,
                icon: <PersonX color={theme.colors.grey} size={18} />,
                description: "Super admin profle with possesses ultimate authority.",
            }
        ]
        if (state.user?.info.session.roles) {
            return AllRoleTypes.filter(({ id }) => (state.user as User).info.session.roles.includes(id))
        }
        return []
    }, [state.user, theme.colors.grey])

    return (
        <Page>
            <br />
            <Group align="center" justify="center">
                <Group gap="xs" justify="center" align="center" className={styles.title}>
                    <StyledProfilePicture style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '8px'
                    }} src={state.user?.picture || createImageFromInitials(state.user?.fullName as string, theme.colors.white, theme.colors.secondary, 50)} alt="Profile" />
                    <h4>Hello {state.user?.nickName}</h4>
                    {
                        state.user?.info.session.lastSession.startDate ? <p className="desc">WELCOME BACK, PLEASE SELECT PROFILE TO PROCEED</p> :
                            <p className="desc">WELCOME, PLEASE SELECT PROFILE TO PROCEED</p>
                    }
                </Group>
                <Group direction="row" justify="center">
                    {
                        roles().map(({ id, text, icon, description }) => <RoleCard key={id}
                            icon={icon}
                            title={text}
                            desc={description}
                            onClick={() => activateProfile(id)}
                        />)
                    }
                </Group>
            </Group>
        </Page>
    );
};
