import parse from 'html-react-parser';
import styled from "styled-components";
import { Dropdown } from "../Dropdown";
import { FC, useContext } from "react";
import styles from "./CourseCards.module.scss";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../hooks/use-api";
import { Group } from "../../Layout/Group/Group";
import { forPhoneOnly, grow, StyledBanner, StyledHR } from "../../Elements";
import { AuthContext } from "../../../context/auth";
import { useMutation } from "@tanstack/react-query";
import { CourseSteps, RoleTypes, hexToRgba } from 'shared-library';
import { useTheme } from "../../../hooks/use-theme.hook";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { useNotify } from "../../../hooks/use-notify.hook";
import { useNotification } from "../../../hooks/use-notification.hook";
import { Course, EnrollmentStatus, getCourseProgressPercentage } from "shared-library";
import { Binoculars, CaretRightFill, CircleSquare, Collection, Gear, Heart, HeartFill, Image, Pencil, StarHalf, ThreeDots, Trash } from "react-bootstrap-icons";


interface CourseCardsProps {
    className?: string;
    courses: Array<Course>;
}

interface CourseCardProps {
    course: Course;
    className?: string;
    forceColumn?: boolean;
}

const StyledCourseCardsWrapper = styled.div`
    ${grow}

    .thumbnail-parent {
        min-width: 350px;
        position: relative;
        ${forPhoneOnly("width: 100%;")}
    }

    &.grid {
        padding: 0;
        width: 23.8%;
        min-width: 300px;
        ${forPhoneOnly("width: 100%;")}
        .thumbnail-parent {
            min-width: 100%;
            position: relative;
            ${forPhoneOnly("width: 100%;")}
        }
    }


`

const StyledEnrollButton = styled.div`
    padding-left: 10px;
    display: flex;
    gap: 0.25rem;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`

const StyledHeartIcon = styled.div`
    ${grow}
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 5px;
    padding: 10px 10px 4px 10px;
    border-radius: 50%;
    text-align: center;
`

export const CourseCards: FC<CourseCardsProps> = ({ className, courses }) => {
    return (
        <Group style={{ margin: '10px 0' }} direction='row' align='start' gap="sm">
            {
                courses.map(course => <CourseCard key={course.id} className={className} course={course} />)
            }
        </Group>)
}

export const CourseCard: FC<CourseCardProps> = ({ className, course, forceColumn }) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const { confirm } = useNotify();
    const { updateUserinfo } = useApi();
    const { notify } = useNotification();
    const { state, setUserInfo } = useContext(AuthContext);
    const { mutateAsync: mutateFavoriteCoursesAsync } = useMutation({
        mutationFn: updateUserinfo,
    });

    const addPopWishlist = async (course: Course) => {
        if (!state.user?.info) return
        try {
            const prop = state.user.info.user.stats.courses.favorites.includes(course.id) ? "stats.courses.favorites.pop" : "stats.courses.favorites.push"
            const updatedUserInfo = await mutateFavoriteCoursesAsync({
                prop,
                userInformation: {
                    ...state.user.info.user,
                    stats: {
                        ...state.user.info.user.stats,
                        courses: {
                            ...state.user.info.user.stats.courses,
                            favorites: state.user.info.user.stats.courses.favorites.includes(course.id) ? state.user.info.user.stats.courses.favorites.filter(fc => fc !== course.id) : [...state.user.info.user.stats.courses.favorites, course.id],
                        }
                    }
                }
            })
            setUserInfo(updatedUserInfo)
            notify({ type: "success", title: "Successful!", body: `Bookmarks has been successfully updated!` });
        } catch (error) {
        }
    }

    return (
        <StyledCourseCardsWrapper style={{ color: course.color, background: hexToRgba(course.color, 10) }} className={`${styles.mcWrapper} ${forceColumn === undefined ? (state.preferences.grid ? ('grid ' + styles.grid) : '') : (styles.forceColumn)} ${className ? className : ''}`}>
            <div className="thumbnail-parent">
                <StyledBanner $url={`${course.assets.thumbnail?.url || '/assets/images/ImagePlaceholder.jpg'}`} $height="250px" />
                {
                    state.user?.info.session.role === RoleTypes.STUDENT && <StyledHeartIcon onClick={() => addPopWishlist(course)} style={{ color: course.color, background: hexToRgba(course.color, 20) }}>
                        {state.user?.info.user.stats.courses.favorites?.includes(course.id) ? <HeartFill /> : <Heart />}
                    </StyledHeartIcon>
                }
            </div>

            <Group gap="xs" className={styles.details}>
                <Group gap="xs" direction="row">
                    <div style={{ flex: 1, fontSize: '18px' }}><b>{course.title}</b></div>
                    <div style={{ position: 'relative' }}>
                        <Dropdown margin={"sm"} controller={<>
                            <ThreeDots size={22} />
                        </>} options={[
                            {
                                icon: <Binoculars style={{ color: theme.colors.success }} />,
                                text: "Details",
                                onClick: () => navigate(`${course.id}/view`),
                            },
                            ...(state.user?.info.session.role !== RoleTypes.STUDENT ? [
                                {
                                    icon: <Collection style={{ color: theme.colors.primary }} />,
                                    text: "Edit Curriculum",
                                    onClick: () => navigate(`${course.id}/edit?editor=${CourseSteps.Curriculum}`),
                                },
                                {
                                    icon: <Pencil style={{ color: theme.colors.secondary }} />,
                                    text: "Basic Information",
                                    onClick: () => navigate(`${course.id}/edit?editor=${CourseSteps.BasicInformation}`),
                                },
                                {
                                    text: "Enrollments",
                                    onClick: () => navigate(`/${state.user?.info.session.role}/enrollments?course=${course.id}`),
                                    icon: <CircleSquare style={{ color: theme.colors.success }} />
                                },
                                {
                                    text: "Reviews",
                                    disabled: !course.stats.totalRatings,
                                    onClick: () => navigate(`${course.id}/reviews`),
                                    icon: <StarHalf style={{ color: theme.colors.secondary }} />,
                                },
                                {
                                    icon: <Image style={{ color: theme.colors.secondary }} />,
                                    text: "Change Photo",
                                    onClick: () => navigate(`${course.id}/edit?editor=${CourseSteps.CourseMedia}`),
                                },
                                {
                                    icon: <Gear style={{ color: theme.colors.success }} />,
                                    text: "Settings",
                                    onClick: () => navigate(`${course.id}/edit?editor=${CourseSteps.Settings}`),
                                },
                                ...(state.user?.info.session.role === RoleTypes.ADMIN ? [
                                    {
                                        demarcateTop: true,
                                        icon: <Trash style={{ color: theme.colors.danger }} />,
                                        text: "Delete",
                                        onClick: () => confirm({
                                            onConfirmation: console.log
                                        })
                                    }
                                ] : []),
                            ] : []),
                        ]} />
                    </div>
                </Group>
                <div className="truncate">
                    <small>{parse(course.description)}</small>
                </div>
                {
                    state.user?.info.session.role === RoleTypes.STUDENT && <>
                        <StyledHR $color={hexToRgba(course.color, 10)} />
                        <Group gap="xs" align="center" direction="row">
                            <div style={{ flex: 1 }}>
                                <ProgressBar value={getCourseProgressPercentage(course, state.user.info.user.stats.courses.enrollments)} color={course.color} />
                            </div>
                            {
                                state.user.info.user.stats.courses.enrollments.find(enrollment => enrollment.startDate && !enrollment.endDate && enrollment.courseId === course.id && enrollment.status === EnrollmentStatus.APPROVED) ? <StyledEnrollButton onClick={() => navigate(`/${state.user?.info.session.role}/courses/${course.id}/learn`)}>
                                    <small>Continue</small>
                                    <CaretRightFill />
                                </StyledEnrollButton> : <StyledEnrollButton onClick={() => navigate(`/${state.user?.info.session.role}/courses/${course.id}/view`)}>
                                    <small>Details</small>
                                    <CaretRightFill />
                                </StyledEnrollButton>
                            }

                        </Group>
                    </>
                }
            </Group>
        </StyledCourseCardsWrapper>
    )
}