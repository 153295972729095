import { Config } from "../../config";
import { useMatch } from "react-router-dom";
import { useContext, useEffect } from "react";
import { useTheme } from "../../hooks/use-theme.hook";
import { CompanyContext } from "../../context/company";
import DIYWebsiteBuilder from "diy-website-builder-lib";
import { StyledDIYWebsiteBuilderContainer } from "../../components/Elements";

export const PublicPolicies = () => {

    const theme = useTheme();
    const { state: AppCompany } = useContext(CompanyContext);
    const match = useMatch('/policies/:lastPart')
    const value = match?.params.lastPart;

    useEffect(() => {
        if (!value || !["tnc", "privacy", "refund"].includes(value)) return
        const container = document.getElementById('container')
        if (container && AppCompany.company?.landingPageDetails.shortId) {
            new DIYWebsiteBuilder({
                container,
                mode: "render",
                startPage: value,
                renderWithRoutes: false,
                defaultFontName: "Nunito",
                renderBasePath: "/policies",
                sidebarColor: theme.colors.white,
                hoverColor: theme.colors.primary,
                primaryColor: theme.colors.primary,
                apiKey: Config.DIYWebsiteBuilderAPIKey,
                shortId: AppCompany.company.landingPageDetails.shortId,
            })
        }
    }, [AppCompany.company?.landingPageDetails.shortId, theme.colors.primary, theme.colors.white, value])

    return (
        <StyledDIYWebsiteBuilderContainer id='container'></StyledDIYWebsiteBuilderContainer>
    );
};