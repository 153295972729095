import { useCallback } from "react";
import getSymbolFromCurrency from 'currency-symbol-map'

export const useCurrencySymbol = () => {

    const getSymbol = useCallback((currency: string): string => getSymbolFromCurrency(currency) || currency, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const prepPrice = useCallback((currency: string, amount: number): string => `${getSymbol(currency)}${amount}`, [])

    return { getSymbol, prepPrice }
};
