import { FC } from "react";
import styled from "styled-components";
import { StyledSpan } from "../../Elements";
import { Group } from "../../Layout/Group/Group";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, BarElement, LineElement, PointElement, Tooltip, CategoryScale, LinearScale, Legend } from "chart.js";
import { useTheme } from "../../../hooks/use-theme.hook";
ChartJS.register(ArcElement, BarElement, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

interface ChartsPropsInterface {
    type: "doughnut" | "bar" | "line";
    label?: string;
    color?: string;
    percentage?: number; //required if doughnut
    barData?: Array<{ //required if bar
        label: string;
        value: number;
    }>;
    lineData?: {
        label: string;
        color: string;
        data: Array<{ //required if line
            label: string;
            value: number;
        }>
    };
}

const StyledChartsParent = styled(Group)`
    &.doughnut {
        height: 170px;
        width: 170px;

        canvas {
            z-index: 1;
        }

        .middleText {
            z-index: 0;
            height: 170px;
            width: 170px;
            margin-left: -185px;

            div {
                margin-top: -10px;
            }
        }
    }

    &.bar {
        height: 170px;
        width: 170px;
    }
`

export const Charts: FC<ChartsPropsInterface> = ({
    type,
    label,
    color,
    percentage = 0,
    barData = [],
    lineData = {
        color: "",
        label: "",
        data: []
    } }) => {

    const theme = useTheme()
    const doughNutData = {
        labels: [],
        borderWidth: 0,
        datasets: [{
            label,
            data: [percentage, 100 - percentage],
            backgroundColor: [
                color || theme.colors.success,
                theme.colors.grey6,
            ],
            hoverOffset: 4
        }]
    };

    const barChartData = {
        labels: barData.map(({ label }) => label),
        datasets: [0, 1, 2].map(mult => ({
            label: '',
            barPercentage: 1,
            categoryPercentage: 0.4,
            borderRadius: 10,
            borderSkipped: true,
            data: barData.map(({ value }) => mult * value),
            backgroundColor: barData.map(() => mult === 2 ? theme.colors.success4 : theme.colors.success),
            borderColor: [],
            borderWidth: 0
        }))
    };

    const lineChartData = {
        labels: lineData.data.map(({ label }) => label),
        datasets: [{
            label: lineData.label,
            data: lineData.data.map(({ value }) => value),
            fill: false,
            tension: 0.5,
            borderColor: lineData.color,
            pointRadius: 1,
            pointHitRadius: 1,
        }]
    }

    return (
        <StyledChartsParent className={type}>
            {
                type === "doughnut" && <>
                    <Doughnut data={doughNutData} options={{
                        cutout: 65,
                        responsive: true,
                    }} />
                    <Group className="middleText" gap="none" justify="center" align="center">
                        <b style={{ color: color || theme.colors.success }}>{percentage}%</b>
                        <div>
                            <StyledSpan><small>{label}</small></StyledSpan>
                        </div>
                    </Group>
                </>
            }

            {
                type === "bar" && <>
                    <Bar data={barChartData} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false
                            }
                        },
                        scales: {
                            y: {
                                ticks: {
                                    color: theme.colors.grey,
                                    font: {
                                        family: "Nunito",
                                        size: 10
                                    },
                                },
                                stacked: true,
                                beginAtZero: true,
                                border: {
                                    display: false,
                                },
                                grid: {
                                    color: theme.colors.grey6
                                }
                            },
                            x: {
                                ticks: {
                                    color: theme.colors.grey,
                                    font: {
                                        family: "Nunito",
                                        size: 10
                                    },
                                },
                                stacked: true,
                                beginAtZero: true,
                                grid: {
                                    color: theme.colors.white
                                }
                            }
                        }
                    }} />
                </>
            }

            {
                type === "line" && <>
                    <Line data={lineChartData} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false
                            }
                        },
                        scales: {
                            y: {
                                ticks: {
                                    color: theme.colors.grey,
                                    font: {
                                        family: "Nunito",
                                        size: 10
                                    },
                                },
                                beginAtZero: true,
                                border: {
                                    display: false,
                                },
                                grid: {
                                    color: theme.colors.grey6
                                }
                            },
                            x: {
                                ticks: {
                                    color: theme.colors.grey,
                                    font: {
                                        family: "Nunito",
                                        size: 10
                                    },
                                },
                                beginAtZero: true,
                                grid: {
                                    color: theme.colors.white
                                }
                            }
                        }
                    }} />
                </>
            }
        </StyledChartsParent>
    )
}