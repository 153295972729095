import { useApi } from "../../hooks/use-api";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "../../hooks/use-theme.hook";
import { TopMenu } from "../../components/App/TopMenu";
import { Page } from "../../components/Layout/Page/Page";
import { Group } from "../../components/Layout/Group/Group";
import { FileSizeMeasurements, Folder } from "shared-library";
import { FolderCards } from "../../components/App/FolderCards";
import { StyledContentWrapper } from "../../components/Elements";
import { PageFilters } from '../../components/App/PageFilters/PageFilters';

export const AdminUploads = () => {

    const theme = useTheme()
    const { getDashboardData } = useApi();
    const { data: dashboardData } = useQuery({
        queryKey: ["dashboard"],
        queryFn: getDashboardData
    })

    const folders: Folder[] = [
        {
            id: "PHOTOS",
            color: theme.colors.gray6,
            meta: dashboardData?.storage.stats.PHOTOS || {
                files: 0,
                fileSize: {
                    total: 0,
                    fileSizeIn: FileSizeMeasurements.MB
                },
            }
        },
        {
            id: "PRESENTATIONS",
            color: theme.colors.success,
            meta: dashboardData?.storage.stats.PRESENTATIONS || {
                files: 0,
                fileSize: {
                    total: 0,
                    fileSizeIn: FileSizeMeasurements.MB
                },
            }
        },
        {
            id: "VIDEOS",
            color: theme.colors.secondary,
            meta: dashboardData?.storage.stats.VIDEOS || {
                files: 0,
                fileSize: {
                    total: 0,
                    fileSizeIn: FileSizeMeasurements.MB
                },
            }
        },
        {
            id: "AUDIOS",
            color: theme.colors.gray5,
            meta: dashboardData?.storage.stats.AUDIOS || {
                files: 0,
                fileSize: {
                    total: 0,
                    fileSizeIn: FileSizeMeasurements.MB
                },
            }
        },
        {
            id: "DOCUMENTS",
            color: theme.colors.primary,
            meta: dashboardData?.storage.stats.DOCUMENTS || {
                files: 0,
                fileSize: {
                    total: 0,
                    fileSizeIn: FileSizeMeasurements.MB
                },
            }
        }
    ]

    return (
        <Page>
            <StyledContentWrapper>
                <Group>
                    <TopMenu className="slideDown" hideNotification titles={[{
                        text: 'Uploads',
                    }]} />
                    <Group gap="xs" className="slideUp">
                        <br />
                        <br />
                        <Group direction='row' justify='end'>
                            <PageFilters />
                        </Group>
                        <FolderCards folders={folders} />
                    </Group>
                </Group>
            </StyledContentWrapper>
        </Page>
    );
};
