import { FC } from "react";
import Moment from "react-moment";
import { times, floor, ceil } from "lodash";
import { Group } from "../../Layout/Group/Group";
import { useTheme } from "../../../hooks/use-theme.hook";
import { useNotify } from "../../../hooks/use-notify.hook";
import { createImageFromInitials, Review } from "shared-library";
import { Star, StarFill, StarHalf, Trash } from "react-bootstrap-icons";
import { StyledIcon, StyledSmall, StyledUserPicture } from "../../Elements";

interface ReviewCardsProps {
    className?: string;
    deletable?: boolean;
    reviews: Array<Review>;
    onDeleteClick?: (reviewId: string) => void;
}

export const ReviewCards: FC<ReviewCardsProps> = ({ className, reviews, onDeleteClick, deletable }) => {

    const theme = useTheme()
    const { confirm } = useNotify()

    return (
        <Group style={{ marginTop: '10px', marginBottom: '10px' }} gap="sm">
            {
                reviews.map(review => <Group direction="row" wrap="nowrap" justify="space-between" align="center" key={review.id} className={`${className ? className : ''}`}>
                    <Group direction="row" wrap="nowrap" style={{ flex: 1 }}>
                        <StyledUserPicture style={{ marginTop: '3px' }} src={review.user?.picture || createImageFromInitials(review.user?.name || "Unknown User", theme.colors.white, theme.colors.secondary)} alt="Profile" />
                        <Group style={{ flex: 1 }} gap="none">
                            <Group justify="space-between" direction="row">
                                <b>{review.user?.name || "Unknown User"}</b>
                                <Group direction="row" gap="none" wrap="nowrap">
                                    {
                                        times(floor(review.rating), (i) => <StarFill size={14} key={i} style={{ color: theme.colors.secondary }} />)
                                    }
                                    {
                                        floor(review.rating) !== review.rating && <StarHalf size={14} key={review.rating} style={{ color: theme.colors.secondary }} />
                                    }
                                    {
                                        times(5 - ceil(review.rating), (i) => <Star size={14} key={i} style={{ color: theme.colors.secondary }} />)
                                    }
                                </Group>
                            </Group>
                            <StyledSmall>
                                {review.description}
                            </StyledSmall>
                            <StyledSmall style={{ marginTop: '10px' }}><i><Moment fromNow>{review.creationDate}</Moment></i></StyledSmall>
                        </Group>
                    </Group>
                    {
                        deletable && <StyledIcon onClick={() => confirm({
                            onConfirmation: () => onDeleteClick && onDeleteClick(review.id)
                        })} className="small">
                            <Trash color={theme.colors.danger} />
                        </StyledIcon>
                    }

                </Group>)
            }
        </Group>)
}