"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSize = exports.getSizeFromFile = void 0;
const folder_enum_1 = require("../enums/folder.enum");
const getSizeFromFile = (fileSize) => {
    let i = 0;
    let fSExt = new Array(folder_enum_1.FileSizeMeasurements.Bytes, folder_enum_1.FileSizeMeasurements.KB, folder_enum_1.FileSizeMeasurements.MB, folder_enum_1.FileSizeMeasurements.GB);
    while (fileSize > 900) {
        fileSize /= 1024;
        i++;
    }
    const exactSize = (Math.round(fileSize * 100) / 100);
    fSExt[i];
    return {
        fileSizeIn: fSExt[i],
        total: exactSize
    };
};
exports.getSizeFromFile = getSizeFromFile;
const parseSize = (fromSize, response) => {
    if (fromSize.fileSizeIn === response)
        return { ...fromSize };
    if (!fromSize.total)
        return { ...fromSize, fileSizeIn: response };
    const unitInMb = {
        MB: 1,
        GB: 1000,
        KB: 0.000977,
        Bytes: 0.000001,
    };
    const sizeInMB = +(fromSize.total * unitInMb[fromSize.fileSizeIn]).toFixed(2);
    if (response === folder_enum_1.FileSizeMeasurements.MB)
        return { ...fromSize, total: sizeInMB, fileSizeIn: response };
    const sizeInByte = +(sizeInMB * 1024 * 1024).toFixed(2);
    if (response === folder_enum_1.FileSizeMeasurements.Bytes)
        return { ...fromSize, total: sizeInByte, fileSizeIn: response };
    const sizeInKByte = +(sizeInMB * 1024).toFixed(2);
    if (response === folder_enum_1.FileSizeMeasurements.KB)
        return { ...fromSize, total: sizeInKByte, fileSizeIn: response };
    const sizeInGByte = +(sizeInMB * (1 / 1024)).toFixed(2);
    return { ...fromSize, total: sizeInGByte, fileSizeIn: response };
};
exports.parseSize = parseSize;
