import moment from "moment";
import Moment from "react-moment";
import parse from 'html-react-parser';
import styled from "styled-components";
import { OffCanvas } from "react-offcanvas";
import { isEqual, upperFirst } from "lodash";
import { useApi } from "../../hooks/use-api";
import { useForm } from "../../hooks/use-form.hook";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Form/Button";
import { useTheme } from "../../hooks/use-theme.hook";
import { TopMenu } from "../../components/App/TopMenu";
import { Page } from "../../components/Layout/Page/Page";
import { Spinner } from "../../components/Spinner/Spinner";
import { Group } from "../../components/Layout/Group/Group";
import { AuthContext } from "../../context/auth/auth.context";
import { LoadMore } from "../../components/App/LoadMore/LoadMore";
import { NavPills } from "../../components/App/NavPills/NavPills";
import { useNotification } from "../../hooks/use-notification.hook";
import { RichText } from "../../components/Form/RichText/RichText";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useState, useContext, FC, useCallback, useRef } from "react";
import { DateTimeInput, Switch, TextInput } from "../../components/Form";
import { SelectInput } from "../../components/Form/SelectInput/SelectInput";
import { Activity, AppIndicator, Camera2, Gear, GeoAlt, PencilFill, Person, PersonFillLock, PersonLinesFill, X } from "react-bootstrap-icons";
import { MoreUserDetailsSession, DateFormats, createImageFromInitials, sortAlphabetically, FullAddressInformation, UserInformationSettingsNotifications, UserInformationSettingsSecurity, ProfilePages, defaultDateFormat, countries } from "shared-library";
import { StyledChapterChildTitle, StyledCreationWrapper, StyledGroupRow, StyledH5, StyledH6, StyledHR, StyledIcon, StyledOffCanvasMenu, StyledProfilePicture, StyledSmall, StyledSpan, StyledTable, StyledTitleDesc, StyledTitleDescDiv, transition } from "../../components/Elements";


const StyledName = styled.div`
    font-size: 28px;
    margin-top: -10px;
    margin-bottom: -7px;
    color: ${(props) => props.theme.colors.black};
`

const StyledPageDesc = styled.p`
    font-size: 18px;
    color: ${(props) => props.theme.colors.primary};
`

const StyledProfilePictureContainer = styled.div`
    width: 100px;
    height: 100px;
    cursor: pointer;
    position: relative;

    .iconContainer {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 6px;
        ${transition}
        padding-top: 28%;
        position: absolute;
        border-radius: 4px;
        text-align: center;
        visibility: hidden;
        background: rgba(0,0,0,0.1);
        color: ${props => props.theme.colors.black};
    }

    &:hover {
        .iconContainer {
            visibility: visible;
        }
    }
`

export const Profile = ({ page }: { page: ProfilePages; }) => {

    const theme = useTheme();
    const navigate = useNavigate()
    const profileInputFile = useRef<HTMLInputElement>(null)
    const { updateUserinfo, getLoginActivities } = useApi()
    const { state, setUserInfo } = useContext(AuthContext)
    const [notificationSettings, setNotificationSettings] = useState<UserInformationSettingsNotifications>(state.user?.info.user.settings.notifications || {
        accountTips: false,
        newBrowserLogin: false,
        unusualActivities: false,
        salesAndLatestNews: false,
        newFeaturesAndUpdates: false,
    })

    const [securitySettings, setSecuritySettings] = useState<UserInformationSettingsSecurity>(state.user?.info.user.settings.security || {
        saveActivityLog: false,
    })

    const { mutateAsync: mutateUserInfoAsync } = useMutation({
        mutationFn: updateUserinfo,
    });

    const [loginActivitiesFilters] = useState<{
        limit: number;
        nextBatchIndex?: string;
    }>({
        limit: 50,
    })
    const { data: loginActivities, isLoading: loginActivitiesLoading, isFetchingNextPage: loginActivitiesIsFetchingNextPage, fetchNextPage: loginActivitiesFetchNextPage, hasNextPage: loginActivitiesHasNextPage } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getLoginActivities({
            nextBatchIndex,
            limit: loginActivitiesFilters.limit,
        }),
        enabled: page === ProfilePages.LoginActivities,
        initialPageParam: loginActivitiesFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["loginActivities", loginActivitiesFilters.limit, loginActivitiesFilters.nextBatchIndex],
    });

    const flattenLoginActivities = useCallback(() => {
        let loginActivitiesArr: Array<MoreUserDetailsSession> = []
        if (loginActivities?.pages) {
            for (const page of loginActivities.pages) {
                loginActivitiesArr = [...loginActivitiesArr, ...page.data]
            }
        }
        return loginActivitiesArr
    },
        [loginActivities?.pages]
    );

    const [bioCanvasOpen, setBioCanvasOpen] = useState(false)
    const [preferencesCanvasOpen, setPreferencesCanvasOpen] = useState(false)
    const [personalInfoCanvasOpen, setPersonalInfoCanvasOpen] = useState(false)
    const [passwordChangeCanvasOpen, setPasswordChangeCanvasOpen] = useState(false)

    const updateNotificationSettings = useCallback(async (property: keyof UserInformationSettingsNotifications, value: boolean) => {
        if (!state.user?.info) return
        const updatedNotificationSettings = { ...notificationSettings, [property]: value }
        setNotificationSettings(updatedNotificationSettings)
        try {
            const updatedUserInfo = await mutateUserInfoAsync({
                userInformation: {
                    ...state.user.info.user,
                    settings: {
                        ...state.user.info.user.settings,
                        notifications: updatedNotificationSettings
                    }
                }, prop: "settings.notifications"
            })
            setUserInfo(updatedUserInfo)

        } catch (error) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user?.info])

    const updateSecuritySettings = useCallback(async (property: keyof UserInformationSettingsSecurity, value: boolean) => {
        if (!state.user?.info) return
        const updatedSecuritySettings = { ...securitySettings, [property]: value }
        setSecuritySettings(updatedSecuritySettings)
        try {
            const updatedUserInfo = await mutateUserInfoAsync({
                userInformation: {
                    ...state.user.info.user,
                    settings: {
                        ...state.user.info.user.settings,
                        security: updatedSecuritySettings
                    }
                }, prop: "settings.security"
            })
            setUserInfo(updatedUserInfo)
        } catch (error) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user?.info])

    const tabs = [
        {
            icon: <PersonLinesFill />,
            text: "Personal Information",
            id: ProfilePages.PersonalInformation,
            onclick: () => navigate(`/${ProfilePages.PersonalInformation}`),
        },
        {
            icon: <AppIndicator />,
            text: "Notification Settings",
            id: ProfilePages.Notifications,
            onclick: () => navigate(`/${ProfilePages.Notifications}`),
        },
        {
            icon: <Activity />,
            text: "Login Activities",
            id: ProfilePages.LoginActivities,
            onclick: () => navigate(`/${ProfilePages.LoginActivities}`),
        },
        {
            icon: <PersonFillLock />,
            text: "Security Settings",
            id: ProfilePages.SecuritySettings,
            onclick: () => navigate(`/${ProfilePages.SecuritySettings}`),
        },
    ]

    const handleProfilePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            console.log(event.target.files[0]);
        }
    };

    return (
        <Page>
            <Group style={{ padding: '0px 20px' }}>
                <TopMenu className="slideDown" hideNotification titles={[]} />
                <Group gap="md" className="slideUp">
                    <Group direction="row" align="end">
                        <input type='file' accept="image/*" ref={profileInputFile} onChange={handleProfilePictureChange} style={{ display: 'none' }} />
                        <StyledProfilePictureContainer onClick={() => profileInputFile?.current?.click && profileInputFile.current.click()}>
                            <div className="iconContainer">
                                <Camera2 size={28} />
                            </div>
                            <StyledProfilePicture style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '8px'
                            }} src={state.user?.picture || createImageFromInitials(state.user?.fullName as string, theme.colors.white, theme.colors.secondary, 200)} alt="Profile" />
                        </StyledProfilePictureContainer>
                        <Group gap="none">
                            <StyledPageDesc>{upperFirst(state.user?.info.session.role as string)}</StyledPageDesc>
                            <StyledName>{state.user?.fullName}</StyledName>
                            <StyledSpan>{state.user?.email}</StyledSpan>
                        </Group>
                    </Group>
                    <Group direction='row'>
                        <NavPills active={page} tabs={tabs} />
                    </Group>
                    <Group>
                        {
                            page === ProfilePages.PersonalInformation && <>
                                <Group gap="none">
                                    <StyledName>{tabs.find(({ id }) => id === page)?.text}</StyledName>
                                    <StyledTitleDescDiv>Edit your personal information and address.</StyledTitleDescDiv>
                                </Group>
                                <Group justify="space-between" style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: theme.colors.grey6
                                }} wrap="nowrap" align="center" direction="row">
                                    <StyledH6>Bio</StyledH6>
                                    <StyledIcon onClick={() => setBioCanvasOpen(true)} className="small">
                                        <PencilFill />
                                    </StyledIcon>
                                </Group>
                                <div style={{
                                    fontSize: '14px',
                                    color: theme.colors.gray5
                                }}>{state.user?.info.user.bio ? parse(state.user.info.user.bio) : <StyledSmall>N/A</StyledSmall>}</div>
                                <br />
                                <Group justify="space-between" style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: theme.colors.grey6
                                }} wrap="nowrap" align="center" direction="row">
                                    <StyledH6>Basic Information</StyledH6>
                                    <StyledIcon onClick={() => setPersonalInfoCanvasOpen(true)} className="small">
                                        <PencilFill />
                                    </StyledIcon>
                                </Group>

                                <StyledGroupRow wrap="nowrap" direction="row" style={{
                                    paddingBottom: '10px',
                                    borderBottom: '1px solid ' + theme.colors.grey6
                                }}>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>First Name</StyledSmall>
                                            <p>{state.user?.firstName}</p>
                                        </Group>
                                    </div>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Last Name</StyledSmall>
                                            <p>{state.user?.lastName}</p>
                                        </Group>
                                    </div>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Preferred Name</StyledSmall>
                                            <p>{state.user?.nickName}</p>
                                        </Group>
                                    </div>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Email</StyledSmall>
                                            <p>{state.user?.email}</p>
                                        </Group>
                                    </div>
                                </StyledGroupRow>
                                <StyledGroupRow wrap="nowrap" direction="row" style={{
                                    paddingBottom: '0px',
                                    borderBottom: '1px solid ' + theme.colors.grey6
                                }}>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Phone No.</StyledSmall>
                                            <p>{state.user?.mobile || <StyledSmall>N/A</StyledSmall>}</p>
                                        </Group>
                                    </div>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Date of Birth</StyledSmall>
                                            {
                                                state.user?.dob ? <Moment format={state.user?.info.user.dateFormat}>{state.user.dob}</Moment> : <StyledSmall>N/A</StyledSmall>
                                            }
                                        </Group>
                                    </div>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Nationality</StyledSmall>
                                            <p>{state.user?.info.user.nationality || <StyledSmall>N/A</StyledSmall>}</p>
                                        </Group>
                                    </div>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Address</StyledSmall>
                                            <p>{state.user?.address || <StyledSmall>N/A</StyledSmall>}</p>
                                        </Group>
                                    </div>
                                </StyledGroupRow>

                                <StyledGroupRow wrap="nowrap" justify="space-between" direction="row" style={{
                                    paddingBottom: '10px',
                                }}>
                                    <div className="col4">
                                        <Group gap="none">
                                            <StyledSmall style={{ color: theme.colors.grey }}>Website</StyledSmall>
                                            <div style={{
                                                fontSize: '14px',
                                                color: theme.colors.gray5
                                            }}>{state.user?.website ? <Link to={state.user.website} style={{
                                                textDecoration: "none",
                                                color: theme.colors.primary,
                                            }} rel="noreferrer" target="_blank">{state.user.website}</Link> : <StyledSmall>N/A</StyledSmall>}</div>
                                        </Group>
                                    </div>
                                </StyledGroupRow>
                                <br />

                                <Group justify="space-between" style={{
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: theme.colors.grey6
                                }} wrap="nowrap" align="center" direction="row">
                                    <StyledH6>Preferences</StyledH6>
                                    <StyledIcon onClick={() => setPreferencesCanvasOpen(true)} className="small">
                                        <PencilFill />
                                    </StyledIcon>
                                </Group>

                                <Group gap="none">
                                    <StyledSmall style={{ color: theme.colors.grey }}>Date Format</StyledSmall>
                                    <p>{state.user?.info.user.dateFormat || <StyledSmall>N/A</StyledSmall>}</p>
                                </Group>
                                <Group gap="none">
                                    <StyledSmall style={{ color: theme.colors.grey }}>Timezone</StyledSmall>
                                    <p>{state.user?.timezone || <StyledSmall>N/A</StyledSmall>}</p>
                                </Group>
                                <PersonalInformationEditor open={personalInfoCanvasOpen} setOpen={setPersonalInfoCanvasOpen} />
                                <BioEditor open={bioCanvasOpen} setOpen={setBioCanvasOpen} />
                                <PreferencesEditor open={preferencesCanvasOpen} setOpen={setPreferencesCanvasOpen} />
                            </>
                        }

                        {
                            page === ProfilePages.Notifications && <>
                                <Group gap="none">
                                    <StyledName>{tabs.find(({ id }) => id === page)?.text}</StyledName>
                                    <StyledTitleDescDiv>You will get only notifications on the settings you enabled.</StyledTitleDescDiv>
                                </Group>

                                {
                                    state.user?.info.user.settings.notifications && <>
                                        <br />
                                        <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                            <Group gap="none">
                                                <StyledChapterChildTitle>Unusual Activities</StyledChapterChildTitle>
                                                <StyledTitleDesc>Email me whenever encounter unusual activity</StyledTitleDesc>
                                            </Group>
                                            <Switch value={notificationSettings.unusualActivities} onChange={(unusualActivities) => updateNotificationSettings("unusualActivities", unusualActivities)}></Switch>
                                        </Group>
                                        <StyledHR />

                                        <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                            <Group gap="none">
                                                <StyledChapterChildTitle>New Browser Login</StyledChapterChildTitle>
                                                <StyledTitleDesc>Email me if new browser is used to sign in</StyledTitleDesc>
                                            </Group>
                                            <Switch value={notificationSettings.newBrowserLogin} onChange={(newBrowserLogin) => updateNotificationSettings("newBrowserLogin", newBrowserLogin)}></Switch>
                                        </Group>
                                        <StyledHR />

                                        <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                            <Group gap="none">
                                                <StyledChapterChildTitle>Sales & Latest News</StyledChapterChildTitle>
                                                <StyledTitleDesc>Notify me by email about sales and latest news</StyledTitleDesc>
                                            </Group>
                                            <Switch value={notificationSettings.salesAndLatestNews} onChange={(salesAndLatestNews) => updateNotificationSettings("salesAndLatestNews", salesAndLatestNews)}></Switch>
                                        </Group>
                                        <StyledHR />

                                        <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                            <Group gap="none">
                                                <StyledChapterChildTitle>New Features & Updates</StyledChapterChildTitle>
                                                <StyledTitleDesc>Email me about new features and updates</StyledTitleDesc>
                                            </Group>
                                            <Switch value={notificationSettings.newFeaturesAndUpdates} onChange={(newFeaturesAndUpdates) => updateNotificationSettings("newFeaturesAndUpdates", newFeaturesAndUpdates)}></Switch>
                                        </Group>
                                        <StyledHR />

                                        <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                            <Group gap="none">
                                                <StyledChapterChildTitle>Account Tips</StyledChapterChildTitle>
                                                <StyledTitleDesc>Email me about tips on using your account</StyledTitleDesc>
                                            </Group>
                                            <Switch value={notificationSettings.accountTips} onChange={(accountTips) => updateNotificationSettings("accountTips", accountTips)}></Switch>
                                        </Group>
                                        <br />
                                        <br />
                                        <br />
                                    </>
                                }

                            </>
                        }

                        {
                            page === ProfilePages.LoginActivities && <>
                                <Group justify="space-between" direction="row" align="center">
                                    <Group gap="none">
                                        <StyledName>{tabs.find(({ id }) => id === page)?.text}</StyledName>
                                        <StyledTitleDescDiv>Here is your login activity logs for the past 90 days.</StyledTitleDescDiv>
                                    </Group>
                                    <Group direction="row">
                                        <Group gap="none">
                                            <StyledH6><StyledSpan>LAST LOGIN</StyledSpan></StyledH6>
                                            <small>
                                                <b>
                                                    <Moment format={state.user?.info.user.dateFormat + ", hh:mm A"}>{state.user?.info.session.lastSession?.startDate}</Moment>
                                                </b>
                                            </small>
                                        </Group>
                                        <Group gap="none">
                                            <StyledH6><StyledSpan>LOGIN IP</StyledSpan></StyledH6>
                                            <small>
                                                <b>
                                                    {state.user?.info.session.lastSession?.ip}
                                                </b>
                                            </small>
                                        </Group>
                                    </Group>
                                </Group>

                                {
                                    !loginActivities || loginActivitiesLoading ? <Spinner text="Fetching Activities" style={{ margin: 60 }} /> :
                                        <>
                                            <StyledTable>
                                                <thead>
                                                    <tr>
                                                        <th><StyledH6>BROWSER</StyledH6></th>
                                                        <th><StyledH6>IP</StyledH6></th>
                                                        <th><StyledH6>SESSION START TIME</StyledH6></th>
                                                        <th><StyledH6>SESSION END TIME</StyledH6></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        flattenLoginActivities().map(activity => <tr key={activity.id}>
                                                            <td><StyledSmall>{activity.browser}</StyledSmall></td>
                                                            <td><StyledSmall>{activity.ip}</StyledSmall></td>
                                                            <td><StyledSmall><Moment format={state.user?.info.user.dateFormat + ", hh:mm A"}>{activity.startDate}</Moment></StyledSmall></td>
                                                            <td>
                                                                {
                                                                    activity.endDate && <StyledSmall><Moment format={state.user?.info.user.dateFormat + ", hh:mm A"}>{activity.endDate}</Moment></StyledSmall>
                                                                }
                                                            </td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </StyledTable>
                                            {
                                                loginActivitiesHasNextPage && <LoadMore loading={loginActivitiesIsFetchingNextPage} onclick={loginActivitiesFetchNextPage} />
                                            }
                                            <br />
                                            <br />
                                        </>
                                }

                            </>
                        }

                        {
                            page === ProfilePages.SecuritySettings && <>
                                <Group gap="none">
                                    <StyledName>{tabs.find(({ id }) => id === page)?.text}</StyledName>
                                    <StyledTitleDescDiv>These settings are helps you keep your account secure.</StyledTitleDescDiv>
                                </Group>

                                {
                                    state.user?.info.user.settings.security && <>
                                        <br />
                                        <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                            <Group gap="none">
                                                <StyledChapterChildTitle>Change Password</StyledChapterChildTitle>
                                                <StyledTitleDesc>Set a unique password to protect your account.</StyledTitleDesc>
                                            </Group>
                                            <Group gap="none" align="end">
                                                <span className="pointer" onClick={() => setPasswordChangeCanvasOpen(true)} style={{ color: theme.colors.primary }}>change</span>
                                                <StyledSmall><i>Last changed: <Moment format={state.user?.info.user.dateFormat + ", hh:mm A"}>{state.user?.info.user.lastPasswordChangedOn}</Moment></i></StyledSmall>
                                            </Group>
                                        </Group>
                                        <StyledHR />

                                        <Group direction="row" justify="space-between" align="center" wrap="nowrap">
                                            <Group gap="none">
                                                <StyledChapterChildTitle>Save my Activity Logs</StyledChapterChildTitle>
                                                <StyledTitleDesc>You can save your all activity logs including unusual activity detected.</StyledTitleDesc>
                                            </Group>
                                            <Switch value={securitySettings.saveActivityLog} onChange={(saveActivityLog) => updateSecuritySettings("saveActivityLog", saveActivityLog)}></Switch>
                                        </Group>

                                        <PasswordChange open={passwordChangeCanvasOpen} setOpen={setPasswordChangeCanvasOpen} />
                                    </>
                                }

                            </>
                        }
                    </Group>
                </Group>
            </Group>
        </Page>
    );
};

const PersonalInformationEditor: FC<{ open: boolean; setOpen: (open: boolean) => void; onSuccess?: () => void }> = ({ open, setOpen, onSuccess }) => {

    interface DefaultValues {
        dob: string;
        email: string;
        mobile: string;
        website: string;
        nickName: string;
        lastName: string;
        firstName: string;
        nationality: string;
    }

    const theme = useTheme();
    const { notify } = useNotification();
    const { state, setUser } = useContext(AuthContext)
    const { updatePersonalInformationDetails, updateUserinfo } = useApi()

    const defaultValues: DefaultValues = {
        dob: state.user?.dob || "",
        email: state.user?.email || "",
        mobile: state.user?.mobile || "",
        website: state.user?.website || "",
        nickName: state.user?.nickName || "",
        lastName: state.user?.lastName || "",
        firstName: state.user?.firstName || "",
        nationality: state.user?.info.user.nationality || "",
    }
    const { onSubmit, errors, onChange, formValues } = useForm({
        defaultValues,
        validations: {
            mobile: { max: 14 },
            dob: { date: true },
            website: { url: true },
            lastName: { max: 60, required: true },
            nickName: { max: 10, required: true },
            firstName: { max: 60, required: true },
            email: { email: true, required: true },
        }
    })
    const { onSubmit: onAddressSubmit, errors: addressErrors, onChange: addressOnChange, formValues: addressFormValues } = useForm<FullAddressInformation>({
        defaultValues: {
            zip: state.user?.info.user.fullAddress.zip || "",
            city: state.user?.info.user.fullAddress.city || "",
            state: state.user?.info.user.fullAddress.state || "",
            line1: state.user?.info.user.fullAddress.line1 || "",
            line2: state.user?.info.user.fullAddress.line2 || "",
            country: state.user?.info.user.fullAddress.country || "",
            countryCode: state.user?.info.user.fullAddress.countryCode || "",
        },
        validations: {
            zip: { max: 10 },
            city: { max: 60 },
            state: { max: 60 },
            line1: { max: 120 },
            line2: { max: 120 },
        }
    })
    const [isPersonal, setIsPersonal] = useState(true)
    const { isPending: mutatingPersonalInformationAsync, mutateAsync: mutatePersonalInformationAsync } = useMutation({
        mutationFn: updatePersonalInformationDetails,
    });
    const { isPending: mutatingAddressAsync, mutateAsync: mutateAddressAsync } = useMutation({
        mutationFn: updateUserinfo,
    });

    const updatePersonalInfo = async (data: DefaultValues) => {
        if (!state.user?.info) return
        try {
            await mutatePersonalInformationAsync({
                dob: data.dob,
                email: data.email,
                mobile: data.mobile,
                website: data.website,
                lastName: data.lastName,
                nickName: data.nickName,
                firstName: data.firstName,
                fullName: `${data.firstName} ${data.lastName}`,
            })

            if (!isEqual(state.user?.info.user.nationality, data.nationality)) {
                await mutateAddressAsync({
                    userInformation: {
                        ...state.user.info.user,
                        nationality: data.nationality,
                    }, prop: "nationality"
                })
            }

            setUser({
                ...state.user,
                dob: data.dob,
                email: data.email,
                mobile: data.mobile,
                website: data.website,
                lastName: data.lastName,
                nickName: data.nickName,
                firstName: data.firstName,
                fullName: `${data.firstName} ${data.lastName}`,
                info: {
                    ...state.user.info,
                    user: {
                        ...state.user.info.user,
                        nationality: data.nationality
                    }
                }
            })

            notify({ type: "success", title: "Successful!", body: `Personal Information has been successfully updated!` });
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    const updateAddress = async (data: FullAddressInformation) => {
        if (!state.user?.info) return
        try {
            if (data.country) {
                data.countryCode = countries.find(({ name }) => name === data.country)?.name as string
            }

            const updatedUserInfo = await mutateAddressAsync({
                userInformation: {
                    ...state.user.info.user,
                    fullAddress: data,
                }, prop: "fullAddress"
            })

            setUser({
                ...state.user,
                address: [
                    data.line1,
                    data.line2,
                    data.zip,
                    data.city,
                    data.state,
                    data.country,
                ].filter(v => v).join(", "),
                info: {
                    ...state.user.info,
                    user: updatedUserInfo
                }
            })
            notify({ type: "success", title: "Successful!", body: `Address has been successfully created!` });
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <Person size={20} style={{ marginRight: '5px' }} /> Basic Information
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" wrap="nowrap" style={{ padding: '4px 20px' }}>
                    <NavPills active={isPersonal ? "personal" : "address"} tabs={[
                        {
                            id: "personal",
                            icon: <Person />,
                            text: "Personal",
                            onclick: () => setIsPersonal(true),
                        },
                        {
                            id: "address",
                            text: "Address",
                            icon: <GeoAlt />,
                            onclick: () => setIsPersonal(false),
                        },

                    ]} />
                    <StyledCreationWrapper>
                        {
                            isPersonal ? <form onSubmit={onSubmit(updatePersonalInfo)}>
                                <Group gap='sm'>
                                    <Group direction="row" gap="sm" justify="space-between" wrap="nowrap">
                                        <TextInput
                                            isRequired
                                            type="text"
                                            label="First Name"
                                            placeholder="First Name"
                                            error={errors.firstName}
                                            style={{ width: '180px' }}
                                            value={formValues.firstName}
                                            onChange={onChange("firstName")}
                                        />
                                        <TextInput
                                            isRequired
                                            type="text"
                                            label="Last Name"
                                            placeholder="Last Name"
                                            error={errors.lastName}
                                            value={formValues.lastName}
                                            onChange={onChange("lastName")}
                                        />
                                    </Group>
                                    <Group direction="row" wrap="nowrap">
                                        <TextInput
                                            isRequired
                                            type="text"
                                            label="Preferred Name"
                                            error={errors.nickName}
                                            style={{ width: '180px' }}
                                            value={formValues.nickName}
                                            placeholder="Preferred Name"
                                            onChange={onChange("nickName")}
                                        />
                                        <TextInput
                                            readonly
                                            isRequired
                                            type="email"
                                            label="Email"
                                            placeholder="Email"
                                            error={errors.email}
                                            value={formValues.email}
                                            onChange={onChange("email")}
                                        />
                                    </Group>
                                    <Group direction="row" wrap="nowrap">
                                        <TextInput
                                            type="text"
                                            label="Phone No."
                                            error={errors.mobile}
                                            placeholder="Phone No."
                                            value={formValues.mobile}
                                            style={{ width: '180px' }}
                                            onChange={onChange("mobile")}
                                        />
                                        <DateTimeInput
                                            error={errors.dob}
                                            format='YYYY-MM-DD'
                                            label="Date of Birth"
                                            value={formValues.dob}
                                            showYearDropdown
                                            placeholder="Date of Birth"
                                            onChange={onChange("dob")}
                                            maxDate={moment().toDate()}
                                        />
                                    </Group>
                                    <Group direction="row" wrap="nowrap">
                                        <SelectInput
                                            label="Nationality"
                                            placeholder="Nationality"
                                            error={errors.nationality}
                                            parentStyle={{ width: '180px' }}
                                            onChange={onChange("nationality")}
                                            options={countries.map(({ name: value, name: label }) => ({ value, label }))}
                                            value={countries.map(({ name: value, name: label }) => ({ value, label })).find(d => d.value === formValues.nationality)}
                                        />
                                        <TextInput
                                            type="url"
                                            label="Website"
                                            placeholder="Website"
                                            error={errors.website}
                                            value={formValues.website}
                                            onChange={onChange("website")}
                                        />
                                    </Group>

                                    <br />
                                    <Button text={"Save"} type='submit' loading={mutatingPersonalInformationAsync} />
                                </Group>
                            </form> : <form onSubmit={onAddressSubmit(updateAddress)}>
                                <Group gap='sm'>
                                    <TextInput
                                        type="text"
                                        label="Line 1"
                                        placeholder="Line 1"
                                        error={addressErrors.line1}
                                        value={addressFormValues.line1}
                                        onChange={addressOnChange("line1")}
                                    />

                                    <TextInput
                                        type="text"
                                        label="Line 2"
                                        placeholder="Line 2"
                                        error={addressErrors.line2}
                                        value={addressFormValues.line2}
                                        onChange={addressOnChange("line2")}
                                    />

                                    <TextInput
                                        type="text"
                                        label="Post Code"
                                        placeholder="Post Code"
                                        error={addressErrors.zip}
                                        value={addressFormValues.zip}
                                        onChange={addressOnChange("zip")}
                                    />

                                    <TextInput
                                        type="text"
                                        label="City"
                                        placeholder="City"
                                        error={addressErrors.city}
                                        value={addressFormValues.city}
                                        onChange={addressOnChange("city")}
                                    />

                                    <Group direction="row" justify="space-between" wrap="nowrap">

                                        <TextInput
                                            type="text"
                                            label="State"
                                            placeholder="State"
                                            error={addressErrors.state}
                                            value={addressFormValues.state}
                                            onChange={addressOnChange("state")}
                                        />

                                        <SelectInput
                                            label="Country"
                                            placeholder="Country"
                                            error={addressErrors.country}
                                            parentStyle={{ width: '180px' }}
                                            onChange={addressOnChange("country")}
                                            options={countries.map(({ name: value, name: label }) => ({ value, label }))}
                                            value={countries.map(({ name: value, name: label }) => ({ value, label })).find(d => d.value === addressFormValues.country)}
                                        />

                                    </Group>

                                    <br />
                                    <Button text={"Update Address"}
                                        disabled={
                                            !addressFormValues.zip &&
                                            !addressFormValues.city &&
                                            !addressFormValues.line2 &&
                                            !addressFormValues.line1 &&
                                            !addressFormValues.state &&
                                            !addressFormValues.country
                                        } type='submit' loading={mutatingAddressAsync} />
                                </Group>
                            </form>
                        }

                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}

const BioEditor: FC<{ open: boolean; setOpen: (open: boolean) => void; onSuccess?: () => void }> = ({ open, setOpen, onSuccess }) => {

    const theme = useTheme();
    const { updateUserinfo } = useApi()
    const { notify } = useNotification();
    const { state, setUserInfo } = useContext(AuthContext)

    const { onSubmit, errors, onChange, formValues } = useForm({
        defaultValues: {
            bio: state.user?.info.user.bio || "",
        },
        validations: {
            bio: { max: 2000 },
        }
    })
    const { isPending: mutatingBioAsync, mutateAsync: mutateBioAsync } = useMutation({
        mutationFn: updateUserinfo,
    });

    const updateBio = async (data: { bio: string; }) => {
        if (!state.user?.info) return
        try {

            const updatedUserInfo = await mutateBioAsync({
                userInformation: {
                    ...state.user.info.user,
                    bio: data.bio,
                }, prop: "bio"
            })

            setUserInfo(updatedUserInfo)
            notify({ type: "success", title: "Successful!", body: `Bio has been successfully updated!` });
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <PersonLinesFill size={20} style={{ marginRight: '5px' }} /> Bio
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper>
                        <form onSubmit={onSubmit(updateBio)}>
                            <Group gap='sm'>
                                <RichText
                                    parentStyle={{ maxWidth: '100%' }}
                                    value={formValues.bio}
                                    placeholder={`Bio`}
                                    error={errors.bio}
                                    onChange={onChange("bio")}
                                />
                                <br />
                                <Button text={"Update"} type='submit' loading={mutatingBioAsync} />
                            </Group>
                        </form>

                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}

const PreferencesEditor: FC<{ open: boolean; setOpen: (open: boolean) => void; onSuccess?: () => void }> = ({ open, setOpen, onSuccess }) => {

    interface DefaultValues {
        timezone: string;
        dateFormat: string;
    }

    const theme = useTheme();
    const { notify } = useNotification();
    const { state, setUser } = useContext(AuthContext)
    const { updatePersonalInformationDetails, updateUserinfo } = useApi()

    const defaultValues: DefaultValues = {
        timezone: state.user?.timezone || "",
        dateFormat: state.user?.info.user.dateFormat || "",
    }
    const { onSubmit, errors, onChange, formValues } = useForm({
        defaultValues
    })
    const { isPending: mutatingPreferencesAsync, mutateAsync: mutatePreferencesAsync } = useMutation({
        mutationFn: updatePersonalInformationDetails,
    });

    const { isPending: mutatingPreferenceAsync, mutateAsync: mutatePreferenceAsync } = useMutation({
        mutationFn: updateUserinfo,
    });


    const updatePreferences = async (data: DefaultValues) => {
        if (!state.user?.info) return
        try {
            await mutatePreferencesAsync({
                timezone: data.timezone,
            })
            await mutatePreferenceAsync({
                userInformation: {
                    ...state.user.info.user,
                    dateFormat: data.dateFormat,
                }, prop: "dateFormat"
            })
            setUser({
                ...state.user,
                timezone: data.timezone,
                info: {
                    ...state.user.info,
                    user: {
                        ...state.user.info.user,
                        dateFormat: data.dateFormat
                    }
                }
            })
            notify({ type: "success", title: "Successful!", body: `Preferences have been successfully updated!` });
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    const allTimezones = useCallback(() => {
        let timezones: Array<{ label: string; value: string; }> = []
        for (const country of countries) {
            for (const timezone of country.timezones) {
                timezones.push({
                    value: timezone,
                    label: `${country.name} - ${timezone}`
                })
            }
        }
        return sortAlphabetically(timezones, "label")
    }, [])

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <Gear size={20} style={{ marginRight: '5px' }} /> Preferences
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" wrap="nowrap" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper>
                        <form onSubmit={onSubmit(updatePreferences)}>
                            <Group gap='sm'>
                                <SelectInput
                                    label="Date Format"
                                    placeholder="Date Format"
                                    error={errors.dateFormat}
                                    onChange={onChange("dateFormat")}
                                    options={DateFormats.map(format => ({ value: format, label: format }))}
                                    value={DateFormats.map(format => ({ value: format, label: format })).find(d => d.value === formValues.dateFormat)}
                                />

                                <SelectInput
                                    label="Timezone"
                                    placeholder="Timezone"
                                    error={errors.timezone}
                                    options={allTimezones()}
                                    onChange={onChange("timezone")}
                                    value={allTimezones().find(d => d.value === formValues.timezone)}
                                />
                                <br />
                                <Button text={"Save"} type='submit' loading={mutatingPreferencesAsync || mutatingPreferenceAsync} />
                            </Group>
                        </form>
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}

const PasswordChange: FC<{ open: boolean; setOpen: (open: boolean) => void; onSuccess?: () => void }> = ({ open, setOpen, onSuccess }) => {

    const { notify } = useNotification();
    const { changePassword, updateUserinfo } = useApi();
    const { state, setUserInfo } = useContext(AuthContext);

    const { onSubmit: onResetSubmit, onChange: onResetChange, reset, errors: resetErrors, formValues: resetFormValues } = useForm({
        defaultValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validations: {
            oldPassword: { required: true },
            newPassword: { required: true, min: 8 },
            confirmPassword: { required: true, min: 8 },
        }
    })

    const theme = useTheme();
    const { isPending: mutatingPasswordAsync, mutateAsync: mutatePasswordAsync } = useMutation({
        mutationFn: changePassword,
    });

    const { isPending: mutatingUserInfoAsync, mutateAsync: mutateUserInfoAsync } = useMutation({
        mutationFn: updateUserinfo,
    });

    const updatePassword = async ({ oldPassword, newPassword }: { oldPassword: string; newPassword: string; confirmPassword: string; }) => {
        if (!state.user?.info) return
        try {
            const passwordChanged = await mutatePasswordAsync({ oldPassword, newPassword })
            if (passwordChanged) {
                const updatedUserInfo = await mutateUserInfoAsync({
                    userInformation: {
                        ...state.user.info.user,
                        lastPasswordChangedOn: moment().format(defaultDateFormat)
                    }, prop: "lastPasswordChangedOn"
                })

                setUserInfo(updatedUserInfo)
                notify({ type: "success", title: "Successful!", body: `Password has been successfully updated!` });
                reset()
                onSuccess && onSuccess()
                setOpen(false)
            }
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <PersonFillLock size={20} style={{ marginRight: '5px' }} /> Change Password
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper>
                        <form onSubmit={onResetSubmit(updatePassword)}>
                            <Group gap='sm'>
                                <TextInput
                                    isRequired
                                    type="password"
                                    label="Current Password"
                                    placeholder="Current Password"
                                    error={resetErrors.oldPassword}
                                    value={resetFormValues.oldPassword}
                                    onChange={onResetChange("oldPassword")}
                                />

                                <TextInput
                                    isRequired
                                    type="password"
                                    label="New Password"
                                    placeholder="New Password"
                                    error={resetErrors.newPassword}
                                    value={resetFormValues.newPassword}
                                    onChange={onResetChange("newPassword")}
                                />

                                <TextInput
                                    isRequired
                                    type="password"
                                    label="Repeat New Password"
                                    placeholder="Repeat New Password"
                                    error={resetErrors.confirmPassword}
                                    value={resetFormValues.confirmPassword}
                                    onChange={onResetChange("confirmPassword")}
                                />

                                <br />
                                <Button disabled={resetFormValues.confirmPassword !== resetFormValues.newPassword && !!resetFormValues.newPassword} text={"Update"} type='submit' loading={mutatingPasswordAsync || mutatingUserInfoAsync} />
                            </Group>
                        </form>
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}