import { AuthContext } from "../auth";
import { ReactNode, useContext, useMemo } from "react";
import { ErrorCodeMeaning, ErrorCodes } from "shared-library";
import { useConsoleLog } from "../../hooks/use-console-log.hook";
import { useNotification } from "../../hooks/use-notification.hook";
import { DefaultOptions, MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";

const notifyError = {
  type: "error",
  title: "Unexpected Error",
  body: "Something went wrong. If the problem persists, please contact support.",
} as const;

const defaultOptions: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: true,
    retry: 1,
  },
};

export const ReactQueryContextProvider = ({ children }: { children: ReactNode }) => {

  const { log } = useConsoleLog();
  const { notify } = useNotification();
  const { logout } = useContext(AuthContext)

  const queryClient = useMemo(() => {
    const onError = (e: any) => {

      if ([ErrorCodes.INVALID_SESSION_TOKEN, ErrorCodes.ACCESS_DENIED].includes(e) || "Failed to fetch" === e?.message) {
        logout()
        return
      }

      if (!ErrorCodeMeaning[e as ErrorCodes]) {
        // Unknown error
        if (typeof e === "string") {
          notify({ ...notifyError, body: e });
        } else {
          notify(notifyError);
        }
        return
      }
      notify({ ...notifyError, body: ErrorCodeMeaning[e as ErrorCodes] });
      log(e);
    };

    const queryCache = new QueryCache({ onError });
    const mutationCache = new MutationCache({ onError });

    return new QueryClient({ defaultOptions, queryCache, mutationCache });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, notify]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
