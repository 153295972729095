import { Config } from "../../config";
import { useContext, useEffect } from "react";
import { useTheme } from "../../hooks/use-theme.hook";
import { CompanyContext } from "../../context/company";
import DIYWebsiteBuilder from "diy-website-builder-lib";
import { StyledDIYWebsiteBuilderContainer } from "../../components/Elements";

export const Welcome = () => {

    const theme = useTheme();
    const { state: AppCompany } = useContext(CompanyContext);

    useEffect(() => {
        const container = document.getElementById('container')
        if (container && AppCompany.company?.landingPageDetails.shortId) {
            new DIYWebsiteBuilder({
                container,
                mode: "render",
                startPage: "index.html",
                renderWithRoutes: false,
                defaultFontName: "Nunito",
                sidebarColor: theme.colors.white,
                hoverColor: theme.colors.primary,
                primaryColor: theme.colors.primary,
                apiKey: Config.DIYWebsiteBuilderAPIKey,
                shortId: AppCompany.company.landingPageDetails.shortId,
            })
        }
    }, [AppCompany.company?.landingPageDetails.shortId, theme.colors.primary, theme.colors.white])

    return (
        <StyledDIYWebsiteBuilderContainer id='container'></StyledDIYWebsiteBuilderContainer>
    );
};