import { RoleTypes } from 'shared-library';
import { ReactElement, useContext } from "react";
import { AuthContext } from "../../../context/auth";
import { Navigate, useLocation } from "react-router-dom";
import { NotFoundError } from "../../../pages/ErrorPage/Errors";

interface PrivateRouteProps {
    roleType?: RoleTypes;
    children: ReactElement;
}

export const PrivateRoute = ({ children, roleType }: PrivateRouteProps) => {
    const { pathname } = useLocation();
    const { state } = useContext(AuthContext);

    if (!state.user) {
        return <Navigate to={`/login`} replace />;
    }

    if (pathname !== "/roles" && !state.user.info.session.role && state.user.info.session.roles.length) {
        return <Navigate to={`/roles`} replace />;
    }

    if (pathname === "/") {
        return <Navigate to={`/${state.user.info.session.role}/dashboard`} replace />;
    }

    if (roleType && state.user.info.session.role !== roleType) {
        return <NotFoundError />;
    }

    return children;
};
