import { CSSProperties } from "react";
import styled from "styled-components";
import styles from "./Spinner.module.scss";


interface SpinnerProps {
    text?: string;
    show?: boolean;
    style?: CSSProperties;
    theme?: "primary" | "fade";
}

const StyledSpinner = styled.div`

    &.fade {
        color: #C2D9FF;
        &:before {
            border-color: #F7F8FA;
            border-top-color: #C2D9FF;
        }
    }

    &.primary {
        color: ${props => props.theme?.colors?.primary};
        &:before {
          border-color: ${props => props.theme?.colors?.primary4};
          border-top-color: ${props => props.theme?.colors?.primary};
        }
    }

`

export const Spinner = ({ show = true, text = "PLEASE WAIT", theme = "primary", style = {} }: SpinnerProps) => {
    if (!show) return null;

    return (
        <div className={styles.container} style={style}>
            <StyledSpinner className={`${styles.spinner} ${theme}`} role="status">
                <div className={styles.text}>{text}</div>
            </StyledSpinner>
        </div>
    );
};
