import { EventRecurrence, AppThemes, ThemeIds, InvoiceStatus, EnrollmentStatus, PaymentMethods, FileTypes, CourseDurationIn, CourseGroupResourceTypes, CourseLevels } from "shared-library"

export const DurationInOptions = [
    { value: CourseDurationIn.DAYS, label: 'Days' },
    { value: CourseDurationIn.WEEKS, label: 'Weeks' },
    { value: CourseDurationIn.MONTHS, label: 'Months' },
    { value: CourseDurationIn.YEARS, label: 'Years' },
]

export const LevelOptions = [
    { value: CourseLevels.BEGINNER, label: 'Beginner' },
    { value: CourseLevels.INTERMEDIATE, label: 'Intermediate' },
    { value: CourseLevels.EXPERT, label: 'Expert' },
]

export const EventRecurrenceOptions = [
    { value: EventRecurrence.NOREPEAT, label: 'No Repeat' },
    { value: EventRecurrence.DAILY, label: 'Daily' },
    { value: EventRecurrence.WEEKLY, label: 'Weekly' },
    { value: EventRecurrence.BIWEEKLY, label: 'Bi Weekly' },
    { value: EventRecurrence.MONTHLY, label: 'Monthly' },
    { value: EventRecurrence.YEARLY, label: 'Yearly' },
]

export const FileTypesFromCourseGroupResourceTypes = (mgit: keyof typeof CourseGroupResourceTypes): Array<FileTypes> => {

    switch (mgit) {
        case "PHOTOS":
            return [
                FileTypes.PNG,
                FileTypes.JPG,
                FileTypes.JPEG,
            ]

        case "VIDEOS":
            return [
                FileTypes.MKV,
                FileTypes.MP4,
                FileTypes.MOV,
            ]

        case "AUDIOS":
            return [
                FileTypes.MP3,
            ]

        case "PRESENTATIONS":
            return [
                FileTypes.PPT,
            ]

        case "DOCUMENTS":
            return [
                FileTypes.TXT,
                FileTypes.DOC,
            ]

        default:
            return []
    }
}

export const PaymentOptions = [
    { value: PaymentMethods.Card, enabled: false, label: 'Card' },
    { value: PaymentMethods.Manual, enabled: true, label: 'Manual' },
    { value: PaymentMethods.Paypal, enabled: false, label: 'Paypal' },
]

export const InvoiceStatuses = [
    { value: InvoiceStatus.Due, label: "Due" },
    { value: InvoiceStatus.Paid, label: "Paid" },
    { value: InvoiceStatus.Cancelled, label: "Cancelled" },
]

export const EnrollmentStatuses = [
    { value: EnrollmentStatus.APPROVED, label: "Approved" },
    { value: EnrollmentStatus.REJECTED, label: "Rejected" },
    { value: EnrollmentStatus.PENDING, label: "Pending" },
]

export const themes: Array<AppThemes> = [
    {
        colors: {
            bg: '#F2F6FF',
            white: '#FDFEFF',
            gray: '#f2efed',
            gray2: '#d6d2cb',
            gray3: '#bab1a5',
            gray4: '#716c64',
            gray5: '#4c4843',
            gray6: '#262422',
            black: '#252525',
            black2: '#8B8D91',
            black3: '#DEDEDE',
            danger: '#DF4725',
            danger2: '#EDC2BE',
            danger3: '#EFD3D3',
            danger4: '#F0E5EA',
            primary: '#0262FD',
            primary2: '#ABCAFF',
            primary3: '#C2D9FF',
            primary4: '#DAE8FF',
            secondary: '#F38321',
            secondary2: '#F3D4BD',
            secondary3: '#F3DFD3',
            secondary4: '#F2EBE9',
            success: '#51B849',
            success2: '#C2E4C9',
            success3: '#D2EADB',
            success4: '#E2F0ED',
            grey: '#ABB0C5',
            grey2: '#DDE2EE',
            grey3: '#E4E8F3',
            grey4: '#EBF0FA',
            grey5: '#CCC',
            grey6: '#F7F8FA',
            lightGray: '#f6f5f4',
        },
        isDefault: true,
        id: ThemeIds.Serenity
    },
    {
        colors: {
            bg: '#eef0fc',
            white: '#fcfcfc',
            gray: '#f2efed',
            gray2: '#d6d2cb',
            gray3: '#bab1a5',
            gray4: '#716c64',
            gray5: '#4c4843',
            gray6: '#262422',
            black: '#252525',
            black2: '#8B8D91',
            black3: '#DEDEDE',
            danger: '#DF4725',
            danger2: '#EDC2BE',
            danger3: '#EFD3D3',
            danger4: '#F0E5EA',
            primary: '#091540',
            primary2: '#B6B9C6',
            primary3: '#CED0D9',
            primary4: '#E7E8EC',
            secondary: '#013328',
            secondary2: '#B3C2BF',
            secondary3: '#CCD6D4',
            secondary4: '#E6EBEA',
            success: '#2A4849',
            success2: '#C0C9C9',
            success3: '#D4DADB',
            success4: '#EAEDED',
            grey: '#ABB0C5',
            grey2: '#DDE2EE',
            grey3: '#E4E8F3',
            grey4: '#EBF0FA',
            grey5: '#CCC',
            grey6: '#F7F8FA',
            lightGray: '#f6f5f4',
        },
        isDefault: false,
        id: ThemeIds.Elysium
    },
]
