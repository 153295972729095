import { InputContainer } from "../";
import { CSSProperties } from "react";
import styled from "styled-components";
import { forPhoneOnly, growSmaller, transition } from "../../Elements";
import { FormComponentProps } from "../../../types/form-component-props";


interface RadioButtonInputProps extends FormComponentProps {
    label: string;
    value: boolean;
    error?: string;
    readonly?: boolean;
    disabled?: boolean;
    description?: string;
    showErrorMsg?: boolean;
    parentStyle?: CSSProperties;
    onChange: (value: boolean) => void;
    theme?: "success" | "primary" | "danger";
}

const RadioButtonContainer = styled.label`
    position: relative;
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 2px 12px;
    border-radius: 14px;
    min-width: 400px;
    font-size: 14px;
    ${forPhoneOnly("min-width: 300px; padding: 4px;")}
    color: ${(props) => props.theme.colors.gray4};
    border: 1px dashed ${(props) => props.theme.colors.grey6};
    background: ${(props) => props.theme.colors.grey6};

    &.checked {
        &.primary {
            background: ${(props) => props.theme.colors.primary4};
            border-color: ${(props) => props.theme.colors.primary};
        }
        &.success {
            background: ${(props) => props.theme.colors.success4};
            border-color: ${(props) => props.theme.colors.success};
        }
        &.danger {
            background: ${(props) => props.theme.colors.danger4};
            border-color: ${(props) => props.theme.colors.danger};
        }
    }

    &.enabled {
        ${growSmaller}
    }

    &.disabled {
        cursor: default;
        opacity: .7;
    }

    &.readonly {
        cursor: default;
    }
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const CircleIcon = styled.svg<{ checked: boolean }>`
    ${transition}
    fill: none;
    width: 40px;
    height: 40px;
    .outer {
        &.primary {
            fill: ${(props) => props.checked ? props.theme.colors.primary : props.theme.colors.grey2};
        }
        &.success {
            fill: ${(props) => props.checked ? props.theme.colors.success : props.theme.colors.grey2};
        }
        &.danger {
            fill: ${(props) => props.checked ? props.theme.colors.danger : props.theme.colors.grey2};
        }
    }
    .inner {
        fill: ${(props) => props.checked ? props.theme.colors.white : props.theme.colors.grey2};
    }
`;

const Label = styled.span`
  margin-left: 0.5em;
`;

export const RadioButtonInput = (props: RadioButtonInputProps) => {
    const {
        value,
        label,
        description,
        error,
        theme = "primary",
        readonly = false,
        disabled = false,
        showErrorMsg = true,
        onChange,
    } = props;

    return (
        <InputContainer
            description={description}
            error={showErrorMsg ? error : ""}
            style={props.parentStyle}
            render={(ID) => (
                <RadioButtonContainer id={ID} className={`${value ? "checked" : ""} ${disabled ? "disabled" : readonly ? "readonly" : "enabled"} ${theme}`}>
                    <HiddenCheckbox checked={value} readOnly={readonly} disabled={disabled} onChange={() => !disabled && !readonly && onChange(!value)} />
                    <CircleIcon
                        checked={value}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle className={`outer ${theme}`} cx="20" cy="20" r="15" />
                        <circle className="inner" cx="20" cy="20" r="6" />
                    </CircleIcon>
                    {
                        label && <Label>{label}</Label>
                    }
                </RadioButtonContainer>
            )}
        />
    );
};
