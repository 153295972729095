import Moment from "react-moment";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { LoadMore } from "../LoadMore/LoadMore";
import { useApi } from "../../../hooks/use-api";
import { Group } from "../../Layout/Group/Group";
import { AuthContext } from "../../../context/auth";
import { useTheme } from "../../../hooks/use-theme.hook";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { NotificationStatus, Notification, NotificationType, RoleTypes } from "shared-library";
import { StyledChapterChildTitle, StyledSmall, StyledSpan, forPhoneOnly, growSmaller, transition } from "../../Elements";
import { Bell, CheckCircle, CheckCircleFill, Dot, ExclamationCircle, ExclamationOctagon, ExclamationTriangle, XLg } from "react-bootstrap-icons";

const StyledDropdown = styled.div`
    z-index: 2; 
    top: 50px; 
    width: 400px;     
    right: -160px;
    position: absolute; 
    ${forPhoneOnly("width: 350px; left: -180px;")}
`

const StyledNotificationContainer = styled.div`
    padding: 10px 20px; 
    border-radius: 4px;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid ${(props) => props.theme.colors.bg};
    background-color: ${(props) => props.theme.colors.white};

    &.grow {
        ${growSmaller}
        max-height: 610px;
    }

    .markAll {
        font-size: 12px;
        cursor: pointer;
        color: ${(props) => props.theme.colors.primary};
    }

    .notificationContainer {
        padding: 20px 0 0 0;
        .notificationRow:not(:last-child) {
            border-bottom: 1px solid ${(props) => props.theme.colors.bg};
        }
        .notificationRow {
            ${transition}
            padding: 10px 8px;

            &.unread {
                background-color: ${(props) => props.theme.colors.bg};
            }

            .visibleToggle {
                visibility: hidden;
            }

            &:hover {
                .visibleToggle {
                    visibility: visible;
                }
            }

            .alertTypes {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                &.info {
                    color: ${(props) => props.theme.colors.primary};
                    background-color: ${(props) => props.theme.colors.primary4};
                }

                &.alert {
                    color: ${(props) => props.theme.colors.secondary};
                    background-color: ${(props) => props.theme.colors.secondary4};
                }

                &.danger {
                    color: ${(props) => props.theme.colors.danger};
                    background-color: ${(props) => props.theme.colors.danger4}; 
                }

                &.success {
                    color: ${(props) => props.theme.colors.success};
                    background-color: ${(props) => props.theme.colors.success4};
                }

            }
        }
    }
`

export const AppNotifications: FC<{ viewType?: "sm" | "lg"; className?: string; }> = ({ viewType = "sm", className = "" }) => {

    const theme = useTheme();
    const { getNotifications } = useApi()

    const [notificationFilters] = useState<{
        limit: number;
        nextBatchIndex?: string;
        status?: NotificationStatus;
    }>({
        limit: 20,
    })

    const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getNotifications({
            nextBatchIndex,
            limit: notificationFilters.limit,
        }),
        refetchOnMount: false,
        initialPageParam: notificationFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["notifications", notificationFilters.limit, notificationFilters.nextBatchIndex],
    });


    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside)
    }, []);

    const flattenNotifications = useCallback(() => {
        let notificationsArr: Array<Notification> = []
        if (data?.pages) {
            for (const page of data.pages) {
                notificationsArr = [...notificationsArr, ...page.data]
            }
        }
        return notificationsArr
    },
        [data?.pages]
    );

    return <>
        {
            viewType === "sm" && <div ref={dropdownRef} className={`${className || ""}`} style={{ position: 'relative' }}>
                <StyledSpan onClick={() => setIsOpen(true)} style={{ cursor: 'pointer', position: 'relative' }}>
                    {
                        flattenNotifications().find(({ status }) => status === NotificationStatus.UNREAD) && <Dot style={{
                            top: '-18px',
                            right: '-12px',
                            position: 'absolute',
                            color: theme.colors.danger
                        }} size={28} />
                    }
                    <Bell size={20} />
                </StyledSpan>
                {isOpen && (<StyledDropdown>
                    <Notifications notifications={flattenNotifications()} refresh={refetch} loading={isFetchingNextPage} loadMore={hasNextPage ? fetchNextPage : undefined} />
                </StyledDropdown>)}
            </div>
        }
        {
            viewType === "lg" &&
            <Notifications className={`grow ${className || ""}`} refresh={refetch} loading={isFetchingNextPage} notifications={flattenNotifications()} loadMore={hasNextPage ? fetchNextPage : undefined} />
        }
    </>;
};


enum AlertType {
    INFO = "info",
    ALERT = "alert",
    DANGER = "danger",
    SUCCESS = "success",
}


const notificationAlertTypeIconMap: Record<AlertType, ReactNode> = {
    success: <CheckCircle size={16} />,
    info: <ExclamationCircle size={16} />,
    danger: <ExclamationOctagon size={16} />,
    alert: <ExclamationTriangle style={{ marginTop: '-4px' }} size={16} />,
};


const Notifications: FC<{ notifications: Array<Notification>; className?: string; loading?: boolean; refresh?: () => void; loadMore?: () => void; }> = ({
    notifications, className, refresh, loadMore, loading
}) => {

    const navigate = useNavigate()
    const { state } = useContext(AuthContext)
    const { markNotificationsAsRead, deleteNotifications } = useApi()
    const { isPending: mutatingNotificationUpdateAsync, mutateAsync: mutateNotificationUpdateAsync } = useMutation({
        mutationFn: markNotificationsAsRead
    });
    const { isPending: mutatingNotificationDeletionAsync, mutateAsync: mutateNotificationDeletionAsync } = useMutation({
        mutationFn: deleteNotifications
    });

    const markNotificationsRead = useCallback(async (notificationSortKeys: string[]) => {
        try {
            if (!mutatingNotificationUpdateAsync) {
                await mutateNotificationUpdateAsync(notificationSortKeys)
                refresh && refresh()
            }
        } catch (error) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutatingNotificationUpdateAsync])

    const removeNotifications = async (notificationSortKeys: string[]) => {
        try {
            if (!mutatingNotificationDeletionAsync) {
                await mutateNotificationDeletionAsync(notificationSortKeys)
                refresh && refresh()
            }
        } catch (error) {
        }
    }

    const getURL = useCallback(({ courseId, type, actor }: Notification): string | undefined => {
        if ([NotificationType.COURSEREQUEST, NotificationType.LECTURECOMPLETED].includes(type) && courseId && state.user?.info.session.role !== RoleTypes.STUDENT) {
            return `/${state.user?.info.session.role}/enrollments?course=${courseId}${actor?.sub ? `&student=${actor.sub}` : ""}`
        }
        if ([NotificationType.COURSEMATERIALUNLOCKED].includes(type) && courseId && state.user?.info.session.role === RoleTypes.STUDENT) {
            return `/${state.user?.info.session.role}/courses/${courseId}/learn`
        }
        if ([NotificationType.COURSEREVIEWSUBMITTED].includes(type) && courseId && state.user?.info.session.role !== RoleTypes.STUDENT) {
            return `/${state.user?.info.session.role}/courses/${courseId}/reviews`
        }
        return
    }, [state.user?.info.session.role])

    const notificationNotificationTypeIconMap = useCallback((notification: Notification): Record<NotificationType, { icon: ReactNode; class: AlertType; title: ReactNode; body: ReactNode; }> => {
        return {
            newStudent: { icon: notificationAlertTypeIconMap.success, class: AlertType.SUCCESS, title: <b>New Student</b>, body: <><b>{notification.actor?.nickName || "Unknown Student"}</b> signed up. No action required.</> },
            lectureCompleted: { icon: notificationAlertTypeIconMap.success, class: AlertType.SUCCESS, title: <b>Lecture Completed</b>, body: <><b>{notification.actor?.nickName || "Unknown Student"}</b> completed a lecture in <b>{notification.course?.title || "Deleted Course"}</b>.</> },
            newCertificateAssigned: { icon: notificationAlertTypeIconMap.success, class: AlertType.SUCCESS, title: <b>New Certifcate Assigned</b>, body: <>You have received a new <b>{notification.course?.title || "Deleted Course"}</b> certification.</> },
            courseMaterialUnlocked: { icon: notificationAlertTypeIconMap.success, class: AlertType.SUCCESS, title: <b>Learning Material Unlocked</b>, body: <>Learning material unlocked for <b>{notification.course?.title || "Deleted Course"}</b>.</> },
            courseEnrolled: { icon: notificationAlertTypeIconMap.success, class: AlertType.SUCCESS, title: <b>New Course Enrolled</b>, body: <>You are now enrolled to <b>{notification.course?.title || "Deleted Course"}</b>.</> },
            tutorReviewSubmitted: { icon: notificationAlertTypeIconMap.success, class: AlertType.SUCCESS, title: <b>New Review Received</b>, body: <><b>{notification.actor?.nickName || "Deleted User"}</b> submitted a review.</> },
            courseReviewSubmitted: { icon: notificationAlertTypeIconMap.success, class: AlertType.SUCCESS, title: <b>New Review Received</b>, body: <><b>{notification.actor?.nickName || "Deleted User"}</b> submitted a review for <b>{notification.course?.title || "Deleted Course"}</b>.</> },
            courseRequest: { icon: notificationAlertTypeIconMap.info, class: AlertType.INFO, title: <b>New Course Request</b>, body: <><b>{notification.actor?.nickName || "Deleted Student"}</b> sent a request to join <b>{notification.course?.title || "Deleted Course"}</b>.</> },
            paymentRequired: { icon: notificationAlertTypeIconMap.danger, class: AlertType.DANGER, title: <b>Payment Required</b>, body: <>Payment required on new issued invoice {!!notification.invoiceNumber && <>(<b>{notification.invoiceNumber}</b>)</>}.</> },
            courseEnrollmentTerminated: { icon: notificationAlertTypeIconMap.danger, class: AlertType.DANGER, title: <b>Enrollment Ended</b>, body: <>Enrollment to <b>{notification.course?.title || "Deleted Course"}</b> has been abruptly terninated.</> },
            enrollmentRequestRejected: { icon: notificationAlertTypeIconMap.danger, class: AlertType.DANGER, title: <b>Enrollment Request Rejected</b>, body: <>Request to join <b>{notification.course?.title || "Deleted Course"}</b> has been rejected.</> },
            newCourseEnrollment: { icon: notificationAlertTypeIconMap.success, class: AlertType.SUCCESS, title: <b>New Course Enrollment</b>, body: <><b>{notification.actor?.nickName || "Deleted student"}</b> has been enrolled to <b>{notification.course?.title || "deleted course"}</b>.</> },
        }
    }, []);

    const gotoNotificationURL = (notification: Notification) => {
        markNotificationsRead([notification.sortKey])
        navigate(getURL(notification) as string)
    }

    return <StyledNotificationContainer className={className || ""}>
        <Group justify="space-between" align="center" direction="row">
            <StyledChapterChildTitle>Recent Notifications</StyledChapterChildTitle>
            {
                notifications.find(({ status }) => status === NotificationStatus.UNREAD) && <div className={`markAll ${mutatingNotificationUpdateAsync ? 'disabledEvents' : ''}`} onClick={() => markNotificationsRead(notifications.filter(({ status }) => status === NotificationStatus.UNREAD).map(({ sortKey }) => sortKey))}>Mark all as read</div>
            }
        </Group>
        <Group>
            {
                notifications.length ? <Group gap="none" className="notificationContainer">
                    {
                        notifications.map(notification => <Group gap="sm" onClick={() => getURL(notification) ? gotoNotificationURL(notification) : null} className={`notificationRow ${notification.status} ${getURL(notification) ? "pointer" : ""}`} direction="row" align="center" justify="space-between" key={notification.id}>
                            <div className={`alertTypes ${notificationNotificationTypeIconMap(notification)[notification.type].class}`}>
                                {
                                    notificationNotificationTypeIconMap(notification)[notification.type].icon
                                }
                            </div>
                            <Group gap="none" style={{ flex: 1 }}>
                                <Group gap='none' direction='row'>
                                    <small>
                                        {notificationNotificationTypeIconMap(notification)[notification.type].title}
                                    </small>
                                    <Dot />
                                    <StyledSmall>
                                        <i><Moment fromNow>{notification.creationDate}</Moment></i>
                                    </StyledSmall>
                                </Group>
                                <StyledSmall>
                                    {notificationNotificationTypeIconMap(notification)[notification.type].body}

                                </StyledSmall>
                            </Group>
                            <Group gap="xs" direction="row" className="visibleToggle">
                                {
                                    notification.status === NotificationStatus.UNREAD && <StyledSpan className={`pointer ${mutatingNotificationUpdateAsync ? 'disabledEvents' : ''}`} onClick={(e) => { e.stopPropagation(); markNotificationsRead([notification.sortKey]) }}>
                                        <CheckCircleFill />
                                    </StyledSpan>
                                }
                                <StyledSpan className={`pointer ${mutatingNotificationDeletionAsync ? 'disabledEvents' : ''}`} onClick={(e) => { e.stopPropagation(); removeNotifications([notification.sortKey]) }}>
                                    <XLg />
                                </StyledSpan>
                            </Group>
                        </Group>)
                    }
                </Group> : <Group align="center" gap="none">
                    <img src="/assets/images/emptyNotificationIcon.png" alt="Empty Notification Icon" />
                    <StyledSmall><i>No new notifications</i></StyledSmall>
                </Group>
            }
            {
                loadMore && <LoadMore loading={loading} onclick={() => loadMore()} />
            }
        </Group>
    </StyledNotificationContainer>
}