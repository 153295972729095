import Moment from "react-moment";
import CountUp from "react-countup";
import parse from 'html-react-parser';
import styled from "styled-components";
import { OffCanvas } from "react-offcanvas";
import { useParams } from "react-router-dom";
import { useApi } from "../../hooks/use-api";
import { AuthContext } from "../../context/auth";
import { NotFoundError } from "../ErrorPage/Errors";
import { Button } from "../../components/Form/Button";
import { useTheme } from "../../hooks/use-theme.hook";
import { TopMenu } from "../../components/App/TopMenu";
import { Page } from "../../components/Layout/Page/Page";
import { Spinner } from "../../components/Spinner/Spinner";
import { Group } from "../../components/Layout/Group/Group";
import { Charts } from '../../components/App/Charts/Charts';
import { LoadMore } from "../../components/App/LoadMore/LoadMore";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { StatsCard } from '../../components/App/Dashboard/StatsCard';
import { RadioButtonInput } from "../../components/Form/RadioButton/RadioButton";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CheckSquare, Filter, Hourglass, People, PersonCheck, Square, ViewList, X, XLg } from "react-bootstrap-icons";
import { Test, TestAttempt, AllCoursesSchema, percentageCalculator, createImageFromInitials, AllStudentsSchema, pluralize } from "shared-library";
import { StyledContentWrapper, StyledCreationWrapper, StyledDropdown, StyledGroupRow, StyledH5, StyledH6, StyledHR, StyledOffCanvasMenu, StyledSmall, StyledSpan, StyledStatsCard, StyledTable, StyledTitle, StyledTitleDescDiv, StyledUserPicture, forPhoneOnly } from "../../components/Elements";

export const AdminTestsResponses = () => {

    const { getTest } = useApi()
    const { testId = "" } = useParams()

    const { data: test, isLoading } = useQuery({
        queryKey: ["test", testId],
        queryFn: () => getTest(testId)
    })

    if (!test || isLoading) {
        return isLoading ? <Spinner text="Fetching Test" style={{ margin: 60 }} /> : <NotFoundError />
    }

    return (<AdminTestsResponsesHandler test={test} />);
}

const StyledCoursesFilterDropdown = styled(StyledDropdown)`
    top: 45px;
    width: 300px;
    ${forPhoneOnly("left: -90px; width: 330px;")}
`

const AdminTestsResponsesHandler = ({ test }: { test: Test }) => {

    const theme = useTheme()
    const { getResources, getTestAttempts } = useApi()
    const { state, setResources } = useContext(AuthContext);
    const coursesDropdownRef = useRef<HTMLDivElement>(null);
    const studentsDropdownRef = useRef<HTMLDivElement>(null);
    const [coursesFilterIsOpen, setCoursesFilterIsOpen] = useState(false);
    const [studentsFilterIsOpen, setStudentsFilterIsOpen] = useState(false);
    const { data: resources } = useQuery({
        queryKey: ["allActiveCourses", "allActiveStudents"],
        queryFn: () => getResources(["activeCourses", "activeStudents"]),
        enabled: !state.resources.activeCourses?.length
    });

    useEffect(() => {
        if (resources) {
            setResources({ ...state.resources, activeCourses: resources.activeCourses as Array<AllCoursesSchema>, activeStudents: resources.activeStudents as Array<AllStudentsSchema> })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources])

    const [highlightedAttempt, setHighlightedAttempt] = useState<TestAttempt>()
    const [testResponseViewerCanvasOpen, setTestResponseViewerCanvasOpen] = useState(false)

    const [filters, setFilters] = useState<{
        userSubs: Array<string>;
        courseIds: Array<string>;
    }>({ courseIds: [], userSubs: [] });

    const [params, setParams] = useState<{
        limit: number;
        testId: string;
        nextBatchIndex?: string;
        userSubs: Array<string>;
        courseIds: Array<string>;
    }>({ limit: 50, testId: test.id, courseIds: [], userSubs: [] });


    const handleClickOutside = (event: MouseEvent) => {
        if (coursesDropdownRef.current && !coursesDropdownRef.current.contains(event.target as Node) && !((event.target as Node) instanceof SVGElement)) {
            setCoursesFilterIsOpen(false);
        }
        if (studentsDropdownRef.current && !studentsDropdownRef.current.contains(event.target as Node) && !((event.target as Node) instanceof SVGElement)) {
            setStudentsFilterIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [params]);

    const { data: testAttempts, isLoading: testAttemptsLoading, isFetchingNextPage: testAttemptsIsFetchingNextPage, fetchNextPage: testAttemptsFetchNextPage, hasNextPage: testAttemptsHasNextPage } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getTestAttempts({
            nextBatchIndex,
            limit: params.limit,
            testId: params.testId,
            userSubs: params.userSubs,
            courseIds: params.courseIds,
        }),
        initialPageParam: params.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["testAttempts", params.testId, params.limit, params.userSubs, params.courseIds, params.nextBatchIndex],
    });

    const flattenTestAttempts = useCallback(() => {
        let testAttemptsArr: Array<TestAttempt> = []
        if (testAttempts?.pages) {
            for (const page of testAttempts.pages) {
                testAttemptsArr = [...testAttemptsArr, ...page.data]
            }
        }
        return testAttemptsArr
    },
        [testAttempts?.pages]
    );

    return (
        <Page>
            <StyledContentWrapper>
                <Group gap="xs">
                    <TopMenu hideNotification titles={[{
                        text: 'Tests',
                        link: `/${state.user?.info.session.role}/tests`
                    }, {
                        text: 'Responses'
                    }]} />
                    <Group gap="xs" className="slideUp">
                        <br />
                        <Group gap="none">
                            <StyledTitle>{test.title}</StyledTitle>
                            <StyledTitleDescDiv>{parse(test.description)}</StyledTitleDescDiv>
                        </Group>
                        <br />
                        <Group direction='row'>
                            <StyledStatsCard style={{
                                paddingBottom: '20px'
                            }}>
                                <Charts
                                    type="doughnut"
                                    percentage={percentageCalculator(test.stats.passed, test.stats.passed + test.stats.failed)}
                                    label="pass rate" />
                            </StyledStatsCard>

                            <Group gap="none" style={{ flex: 1 }}>
                                <StyledGroupRow>
                                    <StatsCard
                                        styles={{
                                            height: '90px'
                                        }}
                                        title="Passed"
                                        icon={<PersonCheck size={22} />}
                                        value={<CountUp end={test.stats.passed} />}
                                        mainColor={theme.colors.success}
                                        miniColor={theme.colors.success4}
                                    />
                                    <StatsCard
                                        styles={{
                                            height: '90px'
                                        }}
                                        title="Failed"
                                        icon={<XLg size={22} />}
                                        value={<CountUp end={test.stats.failed} />}
                                        mainColor={theme.colors.danger}
                                        miniColor={theme.colors.danger4}
                                    />

                                </StyledGroupRow>
                            </Group>

                            <Group gap="none" style={{ flex: 1 }}>
                                <StyledGroupRow>
                                    <StatsCard
                                        styles={{
                                            height: '90px'
                                        }}
                                        title="Pending"
                                        icon={<Hourglass size={22} />}
                                        value={<CountUp end={test.stats.pending} />}
                                        mainColor={theme.colors.secondary}
                                        miniColor={theme.colors.secondary4}
                                    />
                                    <StatsCard
                                        styles={{
                                            height: '90px'
                                        }}
                                        title="In-Progress"
                                        icon={<People size={22} />}
                                        value={<CountUp end={test.stats.inProgress} />}
                                        mainColor={theme.colors.grey}
                                        miniColor={theme.colors.grey4}
                                    />
                                </StyledGroupRow>
                            </Group>
                        </Group>
                        <br />
                        <Group gap="sm">
                            <Group direction='row' gap="xs" justify='end'>
                                <div style={{ position: 'relative' }} ref={coursesDropdownRef}>
                                    <div className='pointer' onClick={() => setCoursesFilterIsOpen(true)}>
                                        <Group direction='row' align='center' gap='xs' style={{
                                            height: '35px',
                                            padding: '4px 15px',
                                            borderRadius: '3px',
                                            color: theme.colors.grey,
                                            backgroundColor: theme.colors.white,
                                            border: '1px solid ' + theme.colors.gray,
                                        }}>
                                            <Filter />
                                            <small style={{ paddingTop: '2px' }}>
                                                {
                                                    filters.courseIds.length ? <>
                                                        {
                                                            pluralize('Course', filters.courseIds.length)
                                                        }
                                                        <span style={{ fontSize: '10px' }}>
                                                            &nbsp;({filters.courseIds.length})
                                                        </span>
                                                    </> : "Courses"
                                                }
                                            </small>
                                        </Group>
                                    </div>
                                    {
                                        coursesFilterIsOpen && <StyledCoursesFilterDropdown>
                                            {
                                                (state.resources.activeCourses || []).map(({ title, id }) => <p key={id} onClick={() => setFilters({ ...filters, courseIds: filters.courseIds.includes(id) ? filters.courseIds.filter(v => v !== id) : [...filters.courseIds, id] })}>
                                                    {
                                                        filters.courseIds.includes(id) ? <CheckSquare /> : <Square />
                                                    }
                                                    {title}
                                                </p>)
                                            }
                                            <Group style={{
                                                padding: '10px 8px 4px 8px'
                                            }}>
                                                <Button text='Filter' onClick={() => {
                                                    setParams({ ...params, courseIds: [...filters.courseIds] })
                                                    setCoursesFilterIsOpen(false)
                                                }} />
                                            </Group>
                                        </StyledCoursesFilterDropdown>
                                    }
                                </div>

                                <div style={{ position: 'relative' }} ref={studentsDropdownRef}>
                                    <div className='pointer' onClick={() => setStudentsFilterIsOpen(true)}>
                                        <Group direction='row' align='center' gap='xs' style={{
                                            height: '35px',
                                            padding: '4px 15px',
                                            borderRadius: '3px',
                                            color: theme.colors.grey,
                                            backgroundColor: theme.colors.white,
                                            border: '1px solid ' + theme.colors.gray,
                                        }}>
                                            <Filter />
                                            <small style={{ paddingTop: '2px' }}>
                                                {
                                                    filters.userSubs.length ? <>
                                                        {
                                                            pluralize('Student', filters.userSubs.length)
                                                        }
                                                        <span style={{ fontSize: '10px' }}>
                                                            &nbsp;({filters.userSubs.length})
                                                        </span>
                                                    </> : "Students"
                                                }
                                            </small>
                                        </Group>
                                    </div>
                                    {
                                        studentsFilterIsOpen && <StyledDropdown style={{ top: "45px", width: '230px' }}>
                                            {
                                                (state.resources.activeStudents || []).map(({ picture, fullName, sub }) => <p key={sub} onClick={() => setFilters({ ...filters, userSubs: filters.userSubs.includes(sub) ? filters.userSubs.filter(v => v !== sub) : [...filters.userSubs, sub] })}>
                                                    {
                                                        filters.userSubs.includes(sub) ? <CheckSquare /> : <Square />
                                                    }
                                                    <StyledUserPicture style={{
                                                        width: '25px',
                                                        height: '25px',
                                                    }} src={picture || createImageFromInitials(fullName, theme.colors.white, theme.colors.secondary, 25)} alt="Profile" />
                                                    <StyledSmall>{fullName}</StyledSmall>
                                                </p>)
                                            }
                                            <Group style={{
                                                padding: '10px 8px 4px 8px'
                                            }}>
                                                <Button text='Filter' onClick={() => {
                                                    setParams({ ...params, userSubs: [...filters.userSubs] })
                                                    setStudentsFilterIsOpen(false)
                                                }} />
                                            </Group>
                                        </StyledDropdown>
                                    }
                                </div>
                            </Group>
                            {
                                !testAttempts || testAttemptsLoading ? <Spinner text="Fetching Attempts" style={{ margin: 60 }} /> :
                                    <Group style={{
                                        overflowX: "auto"
                                    }}>
                                        <StyledTable className="hasSnNo">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th><StyledH6>STUDENT</StyledH6></th>
                                                    <th><StyledH6>COURSE</StyledH6></th>
                                                    <th><StyledH6>ATTEMPT NO.</StyledH6></th>
                                                    <th><StyledH6>SCORE</StyledH6></th>
                                                    <th><StyledH6>START TIME</StyledH6></th>
                                                    <th><StyledH6>END TIME</StyledH6></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    flattenTestAttempts().map(attempt => <tr key={attempt.id} className={`${attempt.id === highlightedAttempt?.id ? "active" : ""} ${!attempt.completed ? "disabledEvents" : "pointer"}`} onClick={() => { setHighlightedAttempt(attempt); setTestResponseViewerCanvasOpen(true); }}>
                                                        <td>
                                                            {
                                                                attempt.student && <StyledUserPicture style={{
                                                                    width: '25px',
                                                                    height: '25px',
                                                                }} src={attempt.student.picture || createImageFromInitials(attempt.student.fullName, theme.colors.white, theme.colors.secondary, 25)} alt="Profile" />
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                attempt.student && <Group gap="none">
                                                                    <b><StyledSmall>{attempt.student.fullName}</StyledSmall></b>
                                                                    <StyledSmall>{attempt.student.email}</StyledSmall>
                                                                </Group>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                attempt.course && <StyledSmall>{attempt.course.title}</StyledSmall>
                                                            }
                                                        </td>
                                                        <td>
                                                            <StyledSmall>{attempt.attemptCount}</StyledSmall>
                                                        </td>
                                                        <td>
                                                            <Group gap="xs" align="end" direction="row">
                                                                <b style={{
                                                                    color: theme.colors.success
                                                                }}>
                                                                    {attempt.finalScoreInPercentage}%
                                                                </b>
                                                                <StyledSmall>({attempt.finalScore}/{attempt.totalScore})</StyledSmall>
                                                            </Group>
                                                        </td>
                                                        <td><StyledSmall><Moment format={state.user?.info.user.dateFormat + ", hh:mm A"}>{attempt.startDateTime}</Moment></StyledSmall></td>
                                                        <td>
                                                            {
                                                                attempt.endDateTime && <StyledSmall><Moment format={state.user?.info.user.dateFormat + ", hh:mm A"}>{attempt.endDateTime}</Moment></StyledSmall>
                                                            }
                                                        </td>
                                                    </tr>)
                                                }
                                            </tbody>
                                        </StyledTable>
                                        {
                                            testAttemptsHasNextPage && <LoadMore loading={testAttemptsIsFetchingNextPage} onclick={testAttemptsFetchNextPage} />
                                        }
                                    </Group>
                            }
                        </Group>
                    </Group>
                </Group>
                <TestResponseViewer open={testResponseViewerCanvasOpen} setOpen={canvasState => { setTestResponseViewerCanvasOpen(canvasState); setHighlightedAttempt(undefined) }} attempt={highlightedAttempt} test={test} />
            </StyledContentWrapper>
        </Page>
    )
}

const TestResponseViewer: FC<{ open: boolean; setOpen: (open: boolean) => void; test: Test; attempt?: TestAttempt }> = ({ open, setOpen, test, attempt }) => {

    const theme = useTheme()
    const { state } = useContext(AuthContext)
    if (!attempt) return null

    const printQuestion = (questionId: string, optionIds: Array<string>, answers: Array<string>) => {
        const questionOri = test.questions.find(({ questionId: _questionId }) => questionId === _questionId)
        if (!questionOri) return <StyledSmall key={questionId}><i>Deleted question</i></StyledSmall>

        const printOption = (optionId: string) => {
            const optionOri = questionOri.options.find(({ optionId: _optionId }) => optionId === _optionId)
            if (!optionOri) return <StyledSmall key={optionId}><i>Deleted option</i></StyledSmall>
            return <RadioButtonInput key={optionId}
                label={optionOri.option}
                onChange={() => null}
                value={optionOri.isAnswer || answers.includes(optionOri.optionId)}
                theme={optionOri.isAnswer ? 'success' : 'danger'}
                readonly={true}
            />
        }

        return <Group key={questionId}>
            <StyledHR />
            <StyledTitleDescDiv className={`${attempt.test?.settings.disableTextCopy ? 'disableSelect' : ''}`}>
                {parse(questionOri.question)}
            </StyledTitleDescDiv>
            <Group align='center' gap='xs'>
                {
                    optionIds.map(optionId => printOption(optionId))
                }
            </Group>
        </Group>
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <ViewList size={20} style={{ marginRight: '5px' }} /> Test Result
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper>
                        <Group gap='sm'>
                            <Group gap="none">
                                <StyledTitle>
                                    {attempt.test?.title}
                                </StyledTitle>
                                <StyledTitleDescDiv>
                                    <small>
                                        <StyledSpan><i>taken by </i></StyledSpan>
                                        <b>{attempt.student?.fullName || "Deleted student"}</b>
                                    </small>
                                </StyledTitleDescDiv>
                                <StyledTitleDescDiv>
                                    <small>
                                        <StyledSpan><i>on </i></StyledSpan>
                                        <b><Moment format={state.user?.info.user.dateFormat + ", hh:mm A"}>{attempt.startDateTime}</Moment></b>
                                    </small>
                                </StyledTitleDescDiv>
                                <StyledTitleDescDiv>
                                    <small>
                                        <StyledSpan><i>scored </i></StyledSpan>
                                        <b>{attempt.finalScoreInPercentage}%</b>
                                    </small>
                                </StyledTitleDescDiv>
                            </Group>
                            {
                                attempt.previousQuestions.map(question => printQuestion(question.questionId, question.optionIds, question.answers))
                            }
                            {
                                printQuestion(attempt.currentQuestion.question.questionId, attempt.currentQuestion.question.options.map(({ optionId }) => optionId), attempt.currentQuestion.question.answers)
                            }
                            {
                                attempt.nextQuestions.map(question => printQuestion(question.questionId, question.optionIds, question.answers))
                            }
                            <br />
                        </Group>
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}