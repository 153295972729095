import { useState } from "react";
import { InputContainer } from "../";
import styled from "styled-components";
import { focus } from "../../Elements";
import styles from "./Switch.module.scss";
import { FormComponentProps } from "../../../types/form-component-props";

interface CheckboxProps extends FormComponentProps {
  value: boolean;
  onChange?: (value: boolean) => void;
}

const StyledSwitchDiv = styled.div`
  &[data-focus="true"] {
    ${focus}
  }

  input {
    &:focus+.slider {
      box-shadow: 0 0 1px ${(props) => props.theme.colors.grey6};
    }
    &:checked+.slider {
        background-color: ${(props) => props.theme.colors.primary};
    }
  }

  .slider {
    background-color: ${(props) => props.theme.colors.grey5};
    &:before {
      background-color: ${(props) => props.theme.colors.white};
    }
  }
`

export const Switch = (props: CheckboxProps) => {
  const { value, label, error, description, isRequired, onChange } = props;
  const [focus, setFocus] = useState(false);

  return (
    <>
      <InputContainer
        label={label as string}
        style={{ flexDirection: "row", gap: "2rem" }}
        description={description}
        error={error}
        isRequired={isRequired}
        render={(ID) => (
          <StyledSwitchDiv
            className={styles.switch}
            data-focus={focus}
            data-checked={value}
            data-testid="switch"
            onClick={() => onChange && onChange(!value)}
          >
            <div className={styles.container}>
              <input
                type="checkbox"
                id={ID}
                checked={value}
                onChange={() => onChange && onChange(!value)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                className={styles.hiddenInput}
              />
              <span className={`${styles.slider} slider`}></span>
            </div>
          </StyledSwitchDiv>
        )}
      />
    </>
  );
};
