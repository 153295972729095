import { useApi } from "../../hooks/use-api";
import { AuthContext } from "../../context/auth";
import { TopMenu } from "../../components/App/TopMenu";
import { Page } from "../../components/Layout/Page/Page";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useContext, useState } from "react";
import { Spinner } from "../../components/Spinner/Spinner";
import { Group } from "../../components/Layout/Group/Group";
import { CourseCards } from "../../components/App/CourseCards";
import { StyledContentWrapper } from "../../components/Elements";
import { LoadMore } from "../../components/App/LoadMore/LoadMore";
import { NavPills } from "../../components/App/NavPills/NavPills";
import { Course, CourseTabPills, PageFiltersValues } from 'shared-library';
import { PageFilters } from '../../components/App/PageFilters/PageFilters';

export const StudentCourses = () => {

    const { getCourses } = useApi()
    const { state, setPreferences } = useContext(AuthContext)
    const [courseFilters, setCourseFilters] = useState(state.preferences.pages.courses)
    const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getCourses({
            nextBatchIndex,
            limit: courseFilters.limit,
            query: courseFilters.query,
            filter: courseFilters.filter,
            enrollmentStatus: courseFilters.enrollmentStatus,
        }),
        initialPageParam: courseFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["courses", courseFilters.enrollmentStatus, courseFilters.query, courseFilters.limit, courseFilters.nextBatchIndex],
    });

    const onEnrollmentStatusChange = (_id: string) => {
        const enrollmentStatus = _id as CourseTabPills
        setCourseFilters({ ...courseFilters, enrollmentStatus })
        setPreferences({ ...state.preferences, pages: { ...state.preferences.pages, courses: { ...state.preferences.pages.courses, enrollmentStatus } } })
    }

    const onQueryChange = (query: string) => {
        setCourseFilters({ ...courseFilters, query })
        setPreferences({ ...state.preferences, pages: { ...state.preferences.pages, courses: { ...state.preferences.pages.courses, query } } })
    }

    const onFiltersChange = (filter?: PageFiltersValues) => {
        setCourseFilters({ ...courseFilters, filter })
        setPreferences({ ...state.preferences, pages: { ...state.preferences.pages, courses: { ...state.preferences.pages.courses, filter } } })
    }

    const flattenCourses = useCallback(() => {
        let courses: Array<Course> = []
        if (data?.pages) {
            for (const page of data.pages) {
                courses = [...courses, ...page.data]
            }
        }
        return courses
    },
        [data?.pages]
    );


    return (
        <Page>
            <StyledContentWrapper>
                <Group>
                    <TopMenu className="slideDown" hideNotification titles={[{
                        text: 'Courses',
                    }]} />
                    <Group gap="xs" className="slideUp">
                        <br />
                        <br />
                        <Group direction='row' justify='space-between'>
                            <NavPills active={courseFilters.enrollmentStatus || ""} tabs={[
                                {
                                    text: "All",
                                    id: "",
                                    onclick: onEnrollmentStatusChange,
                                },
                                {
                                    text: "Enrolled",
                                    id: CourseTabPills.ENROLLED,
                                    onclick: onEnrollmentStatusChange,
                                },
                                {
                                    id: CourseTabPills.INPROGRESS,
                                    onclick: onEnrollmentStatusChange,
                                    text: <span>In Progress</span>,
                                },
                                {
                                    id: CourseTabPills.PENDING,
                                    onclick: onEnrollmentStatusChange,
                                    text: <span>Pending</span>,
                                },
                                {
                                    id: CourseTabPills.COMPLETED,
                                    onclick: onEnrollmentStatusChange,
                                    text: <span>Completed</span>,
                                },
                                {
                                    id: CourseTabPills.BOOKMARKED,
                                    onclick: onEnrollmentStatusChange,
                                    text: <span>Bookmarked</span>,
                                }
                            ]} />
                            <PageFilters query={courseFilters.query} filter={courseFilters.filter} onQueryChange={onQueryChange} onFiltersChange={onFiltersChange} />
                        </Group>

                        {
                            !data || isLoading ? <Spinner text="Fetching Courses" style={{ margin: 60 }} /> :
                                <>
                                    <CourseCards courses={flattenCourses()} />
                                    {
                                        hasNextPage && <LoadMore loading={isFetchingNextPage} onclick={fetchNextPage} />
                                    }
                                </>
                        }
                    </Group>
                </Group>
            </StyledContentWrapper>
        </Page>
    );
};
