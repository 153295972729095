import Moment from "react-moment";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import { Dropdown } from '../Dropdown';
import { PopUpForm } from '../PopUpForm';
import { Button } from "../../Form/Button";
import { useApi } from '../../../hooks/use-api';
import { Group } from "../../Layout/Group/Group";
import styles from './DashboardCalendar.module.scss';
import { DateTimeInput, TextInput } from '../../Form';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from '../../../hooks/use-form.hook';
import { useTheme } from "../../../hooks/use-theme.hook";
import moment, { Moment as MomentInterface } from 'moment';
import { useNotify } from '../../../hooks/use-notify.hook';
import { EventRecurrenceOptions } from '../../../utils/data';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SelectInput } from '../../Form/SelectInput/SelectInput';
import { useNotification } from '../../../hooks/use-notification.hook';
import { Event, EventRecurrence, EventStatus, RecordTypes } from 'shared-library';
import { StyledDirection, StyledH6, StyledChapterTitle, StyledSmall, StyledSpan, StyledTitleDesc, growSmaller } from "../../Elements";
import { ArrowCounterclockwise, ArrowLeft, Copy, ArrowRight, CheckCircleFill, CircleFill, ClockFill, Dot, Pencil, PlusLg, ThreeDots, Trash } from "react-bootstrap-icons";

const StyledDayContainer = styled.div`
    text-align: center;
    font-weight: bold;
    padding: 5px;
    font-size: 10px;
    color: ${props => props.theme.colors.primary};
`

const StyledCalendarTable = styled.table`
    td {
        padding: 8px 0;
        text-align: center;
        font-weight: lighter;
        font-size: 13px;

        div {
            margin: auto;
            width: 30px;
            cursor: pointer;
            padding: 6px 7px;
            border-radius: 50px;
            position: relative;
            &:hover {
                ${growSmaller}
            }

            span {
                top: -3px;
                right: -5px;
                position: absolute;
            }
        }

        &.disabled {
            div {
                cursor: default;
                color: ${props => props.theme.colors.gray2};
            }
        }

        &.disabled-noClick {
            div {
                cursor: not-allowed;
                color: ${props => props.theme.colors.gray2};
                &:hover {
                    box-shadow: none;
                }
            }
        }

        &.today {
            div {
                ${growSmaller}
                color: ${props => props.theme.colors.white};
                background: ${props => props.theme.colors.primary};
            }
        }

        &.selected {
            div {
                ${growSmaller}
                color: ${props => props.theme.colors.white};
                background: ${props => props.theme.colors.secondary};
            }
        }

    }
`

const StyledEventGroup = styled(Group)`
    ${growSmaller}
    background: ${props => props.theme.colors.white};
`

const StyledAddNewEvent = styled.div`
    border-radius: 2px;
    padding: 4px 6px 0 6px;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
`

export const DashboardCalendar: React.FC = () => {

    const theme = useTheme()
    const { confirm } = useNotify()
    const { createEvent } = useApi()
    const { notify } = useNotification()
    const { getEvents, updateEvent, deleteEvent } = useApi()
    const [eventOptionOpened, setEventOptionOpened] = useState<string>("");
    const [currentDate, setCurrentDate] = useState<MomentInterface>(moment());
    const [eventDate, setEventDate] = useState<string>(currentDate.format("YYYY-MM-DD"));
    const { data: eventsThisMonth = [], refetch: refetchEventsThisMonth } = useQuery({
        queryKey: ["events", currentDate.format("YYYY-MM")],
        queryFn: () => getEvents(currentDate.format("YYYY-MM")),
    });

    const renderHeader = (): JSX.Element => {
        const dateFormat: string = "MMMM, YYYY";
        return (
            <Group direction="row" align="center" justify="space-between">
                <div style={{ fontSize: '24px', fontWeight: 'lighter' }}><Moment date={currentDate} format={dateFormat} /></div>
                <Group gap="xs" direction="row">
                    <StyledDirection onClick={prevMonth}>
                        <ArrowLeft />
                    </StyledDirection>
                    <StyledDirection onClick={nextMonth}>
                        <ArrowRight />
                    </StyledDirection>
                </Group>
            </Group>
        );
    };

    const renderDays = (): JSX.Element => {

        const days: JSX.Element[] = [];
        const weekdays: string[] = moment.weekdaysShort().map(str => str.substring(0, 2));
        for (let i = 1; i < 8; i++) {
            days.push(<th key={i}>
                <StyledDayContainer key={i}>
                    {weekdays[i % 7]}
                </StyledDayContainer>
            </th>);
        }
        return <thead><tr>{days}</tr></thead>;
    };

    const nextMonth = (): void => setCurrentDate(currentDate.clone().add(1, 'month'))
    const prevMonth = (): void => setCurrentDate(currentDate.clone().subtract(1, 'month'));
    const refreshEventsThisMonth = (event: Event) => event.day.startsWith(currentDate.format("YYYY-MM")) && refetchEventsThisMonth();

    const updateEventStatus = async (event: Event, status = EventStatus.COMPLETED) => {
        event.status = status
        await updateEvent(event)
        notify({ type: "success", title: "Completed!", body: `${event.title} has been successfully updated!` });
        refreshEventsThisMonth(event)
    };

    const removeEvent = async (event: Event) => {
        await deleteEvent(event)
        notify({ type: "success", title: "Completed!", body: `${event.title} has been successfully deleted!` });
        refreshEventsThisMonth(event)
    };

    const duplicateEvent = async (_event: Event) => {
        const event = cloneDeep(_event)
        event.id = ""
        await createEvent(event)
        notify({ type: "success", title: "Duplicated!", body: `${event.title} has been successfully duplicated!` });
        refreshEventsThisMonth(event)
    };

    return (
        <Group>
            {renderHeader()}
            <StyledCalendarTable>
                {renderDays()}
                <tbody>
                    <RenderCells currentDate={currentDate} events={eventsThisMonth} onDaySelected={setEventDate} />
                </tbody>
            </StyledCalendarTable>
            <br />
            <Group>
                <Group direction='row' align='center' justify='space-between'>
                    <StyledChapterTitle style={{ marginBottom: '10px' }}>
                        <b>Upcoming events</b>
                        <StyledSpan>
                            <small style={{ paddingLeft: '5px', fontSize: '10px', fontWeight: 'lighter' }}>
                                ({eventsThisMonth.filter(({ day }) => day.startsWith(eventDate)).length})
                            </small>
                        </StyledSpan>
                    </StyledChapterTitle>
                    <PopUpForm
                        controller={
                            <StyledAddNewEvent>
                                <PlusLg />
                            </StyledAddNewEvent>
                        }
                        controllerDisabled={!!eventOptionOpened}
                        children={<RenderNewEventForm day={eventDate} onCompleted={refreshEventsThisMonth} />}
                        margin='sm'
                        title={<StyledH6><b>New Event</b></StyledH6>}
                        stateUpdate={(state) => state ? setEventOptionOpened("new") : setEventOptionOpened("")}
                    />
                </Group>

                {
                    eventsThisMonth.filter(({ day }) => day.startsWith(eventDate)).map(thisDayEvent => <StyledEventGroup
                        className={styles.eventContainer}
                        direction='row'
                        wrap='nowrap'
                        align='center'
                        justify='space-between'
                        key={thisDayEvent.id}
                    >
                        <Group direction='row' align='center' wrap='nowrap'>
                            <div>
                                <CircleFill color={thisDayEvent.color} size={8} />
                            </div>
                            <Group gap='none'>
                                <Group gap='xs' direction='row'>
                                    <small>
                                        <b style={{
                                            textDecorationLine: thisDayEvent.status === EventStatus.COMPLETED ? 'line-through' : 'none'
                                        }}>{thisDayEvent.title}</b>
                                    </small>
                                    <Dot />
                                    <StyledSmall style={{ paddingTop: '1px' }}>
                                        <ClockFill />
                                    </StyledSmall>
                                    <StyledSmall>
                                        <Moment format="hh:mm A" date={thisDayEvent.day} />
                                    </StyledSmall>
                                </Group>
                                <StyledTitleDesc>{thisDayEvent.description}</StyledTitleDesc>
                            </Group>
                        </Group>

                        <Group direction='row' wrap='nowrap' className={`${eventOptionOpened === thisDayEvent.id ? "" : (eventOptionOpened ? styles.invisibleToggle : styles.visibleToggle)}`} gap='xs'>
                            {
                                thisDayEvent.status === EventStatus.PENDING && <PopUpForm
                                    controller={
                                        <StyledSpan>
                                            <Pencil />
                                        </StyledSpan>
                                    }
                                    children={<RenderNewEventForm day={eventDate} event={thisDayEvent} onCompleted={refreshEventsThisMonth} />}
                                    title={<StyledH6><b>Edit {thisDayEvent.title}</b></StyledH6>}
                                    stateUpdate={(state) => state ? setEventOptionOpened(thisDayEvent.id) : setEventOptionOpened("")}
                                />
                            }

                            <StyledSpan>
                                <Dropdown margin={"sm"} controller={<>
                                    <ThreeDots />
                                </>} options={[
                                    ...thisDayEvent.status === EventStatus.PENDING ? [{
                                        icon: <CheckCircleFill style={{ color: theme.colors.success }} />,
                                        text: "Mark as done",
                                        onClick: () => updateEventStatus(thisDayEvent, EventStatus.COMPLETED),
                                    },
                                    {
                                        icon: <Copy style={{ color: theme.colors.success }} />,
                                        text: "Duplicate",
                                        onClick: () => duplicateEvent(thisDayEvent),
                                    }
                                    ] : [],
                                    ...thisDayEvent.status === EventStatus.COMPLETED ? [{
                                        icon: <ArrowCounterclockwise style={{ color: theme.colors.secondary }} />,
                                        text: "Unmark as done",
                                        onClick: () => updateEventStatus(thisDayEvent, EventStatus.PENDING),
                                    }] : [],
                                    {
                                        icon: <Trash style={{ color: theme.colors.danger }} />,
                                        text: "Delete",
                                        onClick: () => confirm({ onConfirmation: () => removeEvent(thisDayEvent) }),
                                    }
                                ]} />
                            </StyledSpan>
                        </Group>
                    </StyledEventGroup>)
                }
            </Group>
        </Group>
    );
};

const RenderCells: FC<{ currentDate: MomentInterface; events: Array<Event>; onDaySelected?: (day: string) => void }> = ({ currentDate, events, onDaySelected }) => {

    const theme = useTheme()
    const [selectedDay, setSelectedDay] = useState("");
    const [weeks, setWeeks] = useState<Array<Array<{ day: string; class: "disabled" | "disabled-noClick" | "selected" | 'today' | ''; }>>>([]);

    useEffect(() => {
        const monthStart: MomentInterface = currentDate.clone().startOf('month');
        const monthEnd: MomentInterface = currentDate.clone().endOf('month');
        const startDate: MomentInterface = monthStart.clone().startOf('week').add(1, 'day'); // Start from Monday
        const endDate: MomentInterface = monthEnd.clone().endOf('week');

        const rows: Array<Array<{ day: string; class: "disabled" | "disabled-noClick" | "selected" | 'today' | ''; }>> = [];
        let days: MomentInterface = startDate.clone();

        while (days.isBefore(endDate, 'day')) {
            let row: Array<{ day: string; class: "disabled" | "disabled-noClick" | "selected" | 'today' | ''; }> = [];
            for (let i = 0; i < 7; i++) {
                row.push({
                    day: days.format("YYYY-MM-DD"),
                    class: currentDate.format("YYYY-MM") !== days.format("YYYY-MM") ? "disabled-noClick" : (days.isSameOrAfter(moment(), "day") ? (days.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? 'today' : (days.format('YYYY-MM-DD') === selectedDay ? 'selected' : '')) : 'disabled')
                });
                days.add(1, 'day');
            }
            rows.push(row)
        }
        setWeeks(rows)
    }, [currentDate, selectedDay])

    const daySelected = (day: string) => {
        if (!day.startsWith(currentDate.format("YYYY-MM"))) return
        setSelectedDay(day);
        onDaySelected && onDaySelected(day);
    }
    const findEventColor = (day: string): string | undefined => events.find(({ day: eventDay }) => eventDay.startsWith(day))?.color

    return <>
        {
            weeks.map((week, weekIndex) => <tr key={weekIndex}>
                {
                    week.map((day, dayIndex) => <td key={dayIndex} className={day.class}>
                        <div onClick={() => daySelected(day.day)}>
                            {
                                !!findEventColor(day.day) && <span><Dot color={["selected", "today"].includes(day.class) ? theme.colors.white : findEventColor(day.day)} size={22} /></span>
                            }
                            {moment(day.day).format('D')}
                        </div>
                    </td>)
                }
            </tr>)
        }
    </>
};

export const RenderNewEventForm: FC<{
    day?: string;
    event?: Event;
    closeParent?: () => void;
    onCompleted?: (event: Event) => void;
}> = ({ event, day, onCompleted, closeParent }) => {

    const theme = useTheme()
    const { createEvent, updateEvent } = useApi()
    const { isPending: mutatingEventCreationAsync, mutateAsync: mutateEventCreationAsync } = useMutation({
        mutationFn: createEvent
    });
    const { isPending: mutatingEventUpdateAsync, mutateAsync: mutateEventUpdateAsync } = useMutation({
        mutationFn: updateEvent
    });

    const validations = {
        description: { max: 260 },
        day: { required: true, date: true },
        title: { required: true, min: 3, max: 60 },
    };
    const { formValues, errors, onChange, onSubmit } = useForm<Event>({
        defaultValues: event || {
            id: "",
            title: "",
            description: "",
            creationDate: "",
            sortKey: "",
            companyId: "",
            userId: "",
            updatedOn: "",
            recordType: RecordTypes.USEREVENT,
            status: EventStatus.PENDING,
            recurring: EventRecurrence.NOREPEAT,
            color: theme.colors.secondary,
            day: moment(day).format("YYYY-MM-DD") + " 09:00:00",
        },
        validations,
    });

    const createOnClick = async (data: Event) => {
        try {
            const eventChanges = data.id ? await mutateEventUpdateAsync(data) : await mutateEventCreationAsync(data)
            onCompleted && onCompleted(eventChanges)
            closeParent && closeParent()
        } catch (error) {
        }
    }

    return <form onSubmit={onSubmit(createOnClick)}>
        <Group gap='xs'>
            <DateTimeInput
                size='sm'
                isRequired
                showTimeSelect
                dateFormat="Pp"
                error={errors.day}
                label="Date / Time"
                value={formValues.day}
                placeholder="Date / Time"
                format='YYYY-MM-DD HH:mm'
                onChange={onChange("day")}
                minDate={moment().toDate()}
            />

            <TextInput
                size='sm'
                isRequired
                type="text"
                label="Event title"
                error={errors.title}
                value={formValues.title}
                placeholder="Event title"
                onChange={onChange("title")}
            />

            <TextInput
                size='sm'
                type="text"
                error={errors.description}
                label="Description (Optional)"
                onChange={onChange("description")}
                value={formValues.description || ""}
                placeholder="Description (Optional)"
            />

            <SelectInput
                size='sm'
                label="Repeat"
                placeholder="Repeat"
                error={errors.recurring}
                parentStyle={{ flex: 1 }}
                onChange={onChange("recurring")}
                options={EventRecurrenceOptions}
                value={EventRecurrenceOptions.find(d => d.value === formValues.recurring)}
            />

            <TextInput
                size='sm'
                isRequired
                type="color"
                label="Color"
                placeholder="Color"
                error={errors.color}
                value={formValues.color}
                onChange={onChange("color")}
            />
            <br />
            <Button text={formValues.id ? "Update" : "Create"} type='submit' loading={mutatingEventCreationAsync || mutatingEventUpdateAsync} />
        </Group>
    </form>
};


