import 'react-quill/dist/quill.snow.css';
import { CSSProperties } from 'styled-components';
import { useTheme } from '../../../hooks/use-theme.hook';
import { InputContainer } from '../InputContainer/InputContainer';
import Select, { GroupBase, OptionsOrGroups } from 'react-select';


type Size = "sm" | "md";

interface SelectInputProps {
    value: any;
    size?: Size;
    error?: string;
    disabled?: boolean;
    placeholder?: string;
    isRequired?: boolean;
    description?: string;
    showErrorMsg?: boolean;
    parentStyle?: CSSProperties;
    label?: string;
    options: OptionsOrGroups<unknown, GroupBase<{
        value: string;
        label: string;
    }>>;
    onChange: (value: string) => void;
}

export const SelectInput = ({
    value,
    error,
    label,
    options,
    onChange,
    isRequired,
    description,
    parentStyle,
    size = "md",
    placeholder,
    disabled = false,
    showErrorMsg = true,
}: SelectInputProps) => {

    const theme = useTheme();
    const classNames = `${error ? 'hasError' : ''}`;

    const sizeMap: Record<Size, string> = {
        sm: '30px',
        md: '35px',
    };

    const style = {
        input: (base: any) => ({
            ...base,
            color: 'transparent'
        }),
        control: (base: any) => ({
            ...base,
            borderColor: theme.colors.gray,
            borderRadius: '3px',
            fontSize: '14px',
            minHeight: `${sizeMap[size]}`,
            '&:hover': {
                borderColor: theme.colors.gray,
            },
            'div': {
                padding: '0px 3px',
            },
        }),
        container: (base: any) => ({
            ...base,
            '&.hasError .lms__control': {
                borderColor: theme.colors.danger,
            },
        }),
        placeholder: (base: any) => ({
            ...base,
            color: theme.colors.gray2
        }),
        indicatorSeparator: (base: any) => ({
            ...base,
            backgroundColor: theme.colors.gray
        }),
    };

    return (<InputContainer
        label={label}
        description={description}
        error={showErrorMsg ? error : ""}
        isRequired={isRequired}
        style={parentStyle}
        render={(ID) => (
            <Select id={ID}
                styles={style}
                value={value}
                isDisabled={disabled}
                classNamePrefix="lms"
                className={classNames}
                onChange={(e: any) => onChange(e?.value)}
                placeholder={placeholder}
                options={options}
            />
        )}
    />);
}