import styled from "styled-components";
import { OffCanvas } from "react-offcanvas";
import { useApi } from "../../hooks/use-api";
import { TextInput } from "../../components/Form";
import { useTheme } from "../../hooks/use-theme.hook";
import { Button } from "../../components/Form/Button";
import { TopMenu } from "../../components/App/TopMenu";
import { Page } from "../../components/Layout/Page/Page";
import { Spinner } from "../../components/Spinner/Spinner";
import { Group } from "../../components/Layout/Group/Group";
import { TutorCards } from "../../components/App/TutorCards";
import { useState, useContext, useCallback, FC } from "react";
import { AuthContext } from "../../context/auth/auth.context";
import { LoadMore } from "../../components/App/LoadMore/LoadMore";
import { NavPills } from "../../components/App/NavPills/NavPills";
import { useForm, validateNested } from "../../hooks/use-form.hook";
import { useNotification } from "../../hooks/use-notification.hook";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { PersonAdd, PersonPlus, Upload, X } from "react-bootstrap-icons";
import { PageFilters } from "../../components/App/PageFilters/PageFilters";
import { BulkUploader } from "../../components/Form/BulkUploader/BulkUploader";
import { NewTutorForm, Tutor, TutorStatus, PageFiltersValues } from "shared-library";
import { StyledContentWrapper, StyledErrorText, StyledH5, StyledHR, StyledOffCanvasMenu } from "../../components/Elements";

export const AdminTutors = () => {

    const { getTutors } = useApi()
    const { state, setPreferences } = useContext(AuthContext)
    const [newTutorFormOpen, setNewTutorFormOpen] = useState(false)
    const [tutorFilters, setTutorFilters] = useState(state.preferences.pages.tutors)
    const { data, isLoading, isFetchingNextPage, refetch, fetchNextPage, hasNextPage } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getTutors({
            nextBatchIndex,
            limit: tutorFilters.limit,
            query: tutorFilters.query,
            status: tutorFilters.status,
            filter: tutorFilters.filter,
        }),
        initialPageParam: tutorFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["tutors", tutorFilters.status, tutorFilters.query, tutorFilters.limit, tutorFilters.nextBatchIndex],
    });

    const onStatusChange = (_id: string) => {
        const status = _id as TutorStatus
        setTutorFilters({ ...tutorFilters, status })
        setPreferences({ ...state.preferences, pages: { ...state.preferences.pages, tutors: { ...state.preferences.pages.tutors, status } } })

    }

    const onQueryChange = (query: string) => {
        setTutorFilters({ ...tutorFilters, query })
        setPreferences({ ...state.preferences, pages: { ...state.preferences.pages, tutors: { ...state.preferences.pages.tutors, query } } })
    }

    const onFiltersChange = (filter?: PageFiltersValues) => {
        setTutorFilters({ ...tutorFilters, filter })
        setPreferences({ ...state.preferences, pages: { ...state.preferences.pages, tutors: { ...state.preferences.pages.tutors, filter } } })
    }

    const flattenTutors = useCallback(() => {
        let tutors: Array<Tutor> = []
        if (data?.pages) {
            for (const page of data.pages) {
                tutors = [...tutors, ...page.data]
            }
        }
        return tutors
    },
        [data?.pages]
    );

    return (
        <Page>
            <StyledContentWrapper>
                <Group>
                    <TopMenu className="slideDown" hideNotification titles={[{
                        text: 'Tutors',
                    }]} newItemLink={setNewTutorFormOpen} />
                    <Group gap="xs" className="slideUp">
                        <br />
                        <br />
                        <Group direction='row' justify='space-between'>
                            <NavPills active={tutorFilters.status || ""} tabs={[
                                {
                                    id: "",
                                    text: "All",
                                    onclick: onStatusChange,
                                },
                                {
                                    id: TutorStatus.ACTIVE,
                                    text: "Active",
                                    onclick: onStatusChange,
                                },
                                {
                                    id: TutorStatus.PENDING,
                                    text: "Pending",
                                    onclick: onStatusChange,
                                },
                                {
                                    id: TutorStatus.ARCHIVED,
                                    text: "Archived",
                                    onclick: onStatusChange,
                                }
                            ]} />
                            <PageFilters query={tutorFilters.query} filter={tutorFilters.filter} onQueryChange={onQueryChange} onFiltersChange={onFiltersChange} />
                        </Group>
                        {
                            !data || isLoading ? <Spinner text="Fetching Tutors" style={{ margin: 60 }} /> :
                                <>
                                    <TutorCards tutors={flattenTutors()} />
                                    {
                                        hasNextPage && <LoadMore loading={isFetchingNextPage} onclick={fetchNextPage} />
                                    }
                                </>
                        }
                        <AdminNewTutor open={newTutorFormOpen} setOpen={setNewTutorFormOpen} onSuccess={() => (!tutorFilters.status || tutorFilters.status === TutorStatus.PENDING) ? refetch() : null} />
                    </Group>
                </Group>
            </StyledContentWrapper>
        </Page>
    );
};


const StyledCreationWrapper = styled(Group)`
    padding: 30px 20px;
    border-radius: 4px;
    background: ${props => props.theme.colors.white};
`

const AdminNewTutor: FC<{ open: boolean; setOpen: (open: boolean) => void; onSuccess?: () => void }> = ({ open, setOpen, onSuccess }) => {

    const bulkUploadSchema: NewTutorForm = {
        firstName: "",
        lastName: "",
        email: "",
    }

    const theme = useTheme()
    const { createTutors } = useApi()
    const { notify } = useNotification();
    const [isBulk, setIsBulk] = useState(true)
    const { state, setResources } = useContext(AuthContext)
    const { onSubmit, errors, onChange, formValues, reset } = useForm({
        defaultValues: bulkUploadSchema,
        validations: {
            firstName: { max: 60, required: true },
            lastName: { max: 60, required: true },
            email: { email: true, required: true },
        }
    })
    const [bulkUploadErrors, setBulkUploadErrors] = useState<Array<string>>([])
    const { isPending: mutatingTutorsAsync, mutateAsync: mutateTutorsAsync } = useMutation({
        mutationFn: createTutors,
    });

    const validateNewTutor = (tutors: Array<NewTutorForm>) => {
        let errors: Array<string> = []

        for (let index = 0; index < tutors.length; index++) {
            const tutor = tutors[index];

            const result = validateNested<NewTutorForm>(tutor, {
                firstName: { required: true, max: 30 },
                lastName: { required: true, max: 30 },
                email: { required: true, max: 30, email: true },
            })

            if (!result.valid) {
                // for (const key in result.errors) {
                for (const key of Object.keys(result.errors) as (keyof NewTutorForm)[]) {
                    if (result.errors.hasOwnProperty(key)) {
                        const error = result.errors[key];
                        if (error) {
                            errors.push(`${error} on line ${index + 1}`)
                        }
                    }
                }
            }

        }
        setBulkUploadErrors(errors)
        return !errors.length
    }

    const createNewTutors = async (tutors: Array<NewTutorForm>) => {
        if (!validateNewTutor(tutors)) {
            return
        }
        try {
            await mutateTutorsAsync(tutors)
            notify({ type: "success", title: "Successful!", body: `Tutors have been successfully created!` });
            const { activeTutors, ...newResource } = state.resources;
            setResources(newResource)
            onSuccess && onSuccess()
            setOpen(false)
            reset()
        } catch (error) {
        }
    }

    const createOnClick = async (data: NewTutorForm) => {
        try {
            await mutateTutorsAsync([data])
            notify({ type: "success", title: "Successful!", body: `Tutor has been successfully created!` });
            const { activeTutors, ...newResource } = state.resources;
            setResources(newResource)
            onSuccess && onSuccess()
            setOpen(false)
            reset()
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <PersonAdd size={24} style={{ marginRight: '5px' }} /> New Tutor
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <NavPills active={isBulk ? "bulk" : "one"} tabs={[
                        {
                            id: "bulk",
                            icon: <Upload />,
                            text: "Bulk Upload",
                            onclick: () => setIsBulk(true),
                        },
                        {
                            id: "one",
                            icon: <PersonPlus />,
                            text: "Create One",
                            onclick: () => setIsBulk(false),
                        },

                    ]} />

                    <StyledCreationWrapper>
                        {
                            isBulk ? <>
                                <BulkUploader schema={bulkUploadSchema} loading={mutatingTutorsAsync} onDataReady={createNewTutors} />
                            </> : <>
                                <form onSubmit={onSubmit(createOnClick)}>
                                    <Group gap='sm'>
                                        <Group direction="row" justify="space-between" wrap="nowrap">

                                            <TextInput
                                                isRequired
                                                type="text"
                                                label="First Name"
                                                placeholder="First Name"
                                                error={errors.firstName}
                                                style={{ width: '180px' }}
                                                value={formValues.firstName}
                                                onChange={onChange("firstName")}
                                            />
                                            <TextInput
                                                isRequired
                                                type="text"
                                                label="Last Name"
                                                placeholder="Last Name"
                                                error={errors.lastName}
                                                value={formValues.lastName}
                                                onChange={onChange("lastName")}
                                            />
                                        </Group>
                                        <TextInput
                                            isRequired
                                            type="email"
                                            label="Email"
                                            placeholder="Email"
                                            error={errors.email}
                                            value={formValues.email}
                                            onChange={onChange("email")}
                                        />
                                        <br />
                                        <Button text={"Create"} type='submit' loading={mutatingTutorsAsync} />
                                    </Group>
                                </form>
                            </>
                        }

                        <Group gap="none">
                            {
                                bulkUploadErrors.map((error, index) => <StyledErrorText key={index}>- {error}</StyledErrorText>)
                            }
                        </Group>
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}