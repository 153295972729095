"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardTypes = exports.PaymentMethods = exports.InvoiceBilledTo = exports.InvoiceStatus = void 0;
var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus["Due"] = "due";
    InvoiceStatus["Paid"] = "paid";
    InvoiceStatus["Cancelled"] = "cancelled";
})(InvoiceStatus || (exports.InvoiceStatus = InvoiceStatus = {}));
var InvoiceBilledTo;
(function (InvoiceBilledTo) {
    InvoiceBilledTo["Company"] = "company";
    InvoiceBilledTo["Student"] = "student";
})(InvoiceBilledTo || (exports.InvoiceBilledTo = InvoiceBilledTo = {}));
var PaymentMethods;
(function (PaymentMethods) {
    PaymentMethods["Card"] = "card";
    PaymentMethods["Paypal"] = "paypal";
    PaymentMethods["Manual"] = "manual";
})(PaymentMethods || (exports.PaymentMethods = PaymentMethods = {}));
var CardTypes;
(function (CardTypes) {
    CardTypes["elo"] = "elo";
    CardTypes["mir"] = "mir";
    CardTypes["jcb"] = "jcb";
    CardTypes["visa"] = "visa";
    CardTypes["hiper"] = "hiper";
    CardTypes["maestro"] = "maestro";
    CardTypes["discover"] = "discover";
    CardTypes["unionpay"] = "unionpay";
    CardTypes["diners"] = "diners-club";
    CardTypes["hipercard"] = "hipercard";
    CardTypes["mastercard"] = "mastercard";
    CardTypes["american"] = "american-express";
})(CardTypes || (exports.CardTypes = CardTypes = {}));
