import { Link } from "react-router-dom";
import { Page } from "../../components/Layout/Page/Page";
import { Group } from "../../components/Layout/Group/Group";
import { StyledH4 } from "../../components/Elements";

type ErrorType = {
  message?: string;
};

export const ErrorPage = ({ message = "AN ERROR HAS OCCURRED." }: ErrorType) => {
  return (
    <Page>
      <Group gap="md" style={{ maxWidth: 700, margin: "60px auto" }}>
        <StyledH4>OOPS! {message}</StyledH4>
        <Link to="/" role="button" className="btn">
          <div className="text">GO BACK</div>
        </Link>
      </Group>
    </Page>
  );
};
