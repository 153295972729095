import { FC } from "react";
import Moment from "react-moment";
import { Policy } from "shared-library";
import { useTheme } from "../../../hooks/use-theme.hook";
import { StyledTestGroup, StyledTitleDesc } from "../../Elements";
import { ChevronRight, FileRichtext } from "react-bootstrap-icons";

interface PolicyCardProps {
    policy: Policy;
    active?: boolean;
    onClick?: () => void;
}

export const PolicyCard: FC<PolicyCardProps> = ({ onClick, active, policy }) => {
    const theme = useTheme();

    return (
        <StyledTestGroup className={`${active ? 'active' : ''} ${onClick ? 'pointer' : ''}`} onClick={() => onClick && onClick()}>
            <span className="icon">
                <FileRichtext />
            </span>
            <div style={{ flex: 1 }}>
                <b>{policy.title || 'Untitled policy'}</b>
                <StyledTitleDesc style={{ color: theme.colors.gray3, marginTop: '-4px' }}>
                    <small>Updated <Moment fromNow>{policy.updatedOn || policy.creationDate}</Moment></small>
                </StyledTitleDesc>
            </div>
            <span className="icon-right"><ChevronRight /></span>
        </StyledTestGroup>
    );
};
