import Moment from "react-moment";
import { Dropdown } from "../Dropdown";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { Spinner } from "../../Spinner/Spinner";
import { useApi } from "../../../hooks/use-api";
import { LoadMore } from "../LoadMore/LoadMore";
import { Group } from "../../Layout/Group/Group";
import { FileUpload } from "../../Form/FileUpload";
import { AuthContext } from "../../../context/auth";
import styles from "./FolderNavigation.module.scss";
import { useTheme } from "../../../hooks/use-theme.hook";
import { PageFilters } from "../PageFilters/PageFilters";
import { CompanyContext } from "../../../context/company";
import { useCallback, useContext, useState } from "react";
import { DropdownPropOptions } from "../Dropdown/Dropdown";
import { useNotify } from "../../../hooks/use-notify.hook";
import { ScreenRecorder } from "../ScreenRecorder/ScreenRecorder";
import { useNotification } from "../../../hooks/use-notification.hook";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { FileTypesFromCourseGroupResourceTypes } from "../../../utils/data";
import { Dot, FilePdfFill, PlusLg, ThreeDots, Trash } from "react-bootstrap-icons";
import { FolderFile, CourseGroupResourceTypes, PageFiltersValues } from "shared-library";
import { StyledBanner, StyledHR, StyledSmall, StyledTable, forPhoneOnly, grow } from "../../Elements";

interface FolderNavigationProps {
    isPage: boolean;
    onInsert?: (file: FolderFile) => void;
    folderId: keyof typeof CourseGroupResourceTypes;
}

export const FolderNavigation = ({ folderId, isPage, onInsert }: FolderNavigationProps) => {

    const { notify } = useNotification();
    const { getFiles, uploadFile, deleteFile } = useApi()
    const { state: AppCompany } = useContext(CompanyContext)

    const [fileFilters, setFileFilters] = useState<{
        limit: number;
        query?: string;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
        _fileType: keyof typeof CourseGroupResourceTypes;
    }>({
        limit: 50,
        _fileType: folderId,
        filter: PageFiltersValues.latestFirst
    })
    const { data: files, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getFiles({
            nextBatchIndex,
            limit: fileFilters.limit,
            query: fileFilters.query,
            filter: fileFilters.filter,
            _fileType: fileFilters._fileType,
        }),
        refetchOnWindowFocus: false,
        initialPageParam: fileFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["files", fileFilters._fileType, fileFilters.query, fileFilters.limit, fileFilters.nextBatchIndex],
    });

    const { isPending: mutatingFileUploadAsync, mutateAsync: mutateFileUploadAsync } = useMutation({
        mutationFn: uploadFile,
    });

    const { mutateAsync: mutateFileDeletionAsync } = useMutation({
        mutationFn: deleteFile,
    });

    const onQueryChange = (query: string) => {
        setFileFilters({ ...fileFilters, query })
    }

    const onFiltersChange = (filter?: PageFiltersValues) => {
        setFileFilters({ ...fileFilters, filter })
    }

    const flattenFiles = useCallback(() => {
        let _files: Array<FolderFile> = []
        if (files?.pages) {
            for (const page of files.pages) {
                _files = [..._files, ...page.data]
            }
        }
        return _files
    },
        [files?.pages]
    );

    const uploadFiles = async (fileList: FileList) => {

        if (AppCompany.config?.storage && !mutatingFileUploadAsync) {
            const files: Array<File> = [];
            for (let index = 0; index < fileList.length; index++) {
                files.push(fileList[index])
            }
            try {
                await Promise.all(files.map(file => mutateFileUploadAsync({ file, fileType: folderId, isPublic: folderId === "PHOTOS" })))
                refetch()
            } catch (error) {
            }

        }
    }

    const onDelete = async (file: FolderFile) => {
        try {
            await mutateFileDeletionAsync(file)
            notify({ type: "success", title: "Successful!", body: `File has been successfully deleted!` });
            refetch()
        } catch (error) {
        }
    }

    return (<Group gap="none">
        <br />
        <FileUpload
            multiple={true}
            placeholder={"Browse or drop files here."}
            onChange={fl => uploadFiles(fl as FileList)}
            types={FileTypesFromCourseGroupResourceTypes(folderId)}
            disabled={!AppCompany.config?.storage || mutatingFileUploadAsync}
        />
        <br />
        <Group direction='row' align="center" justify='space-between'>
            <div>
                {
                    folderId === "VIDEOS" && <ScreenRecorder />
                }
            </div>
            <PageFilters query={fileFilters.query} filter={fileFilters.filter} onQueryChange={onQueryChange} onFiltersChange={onFiltersChange} />
        </Group>
        {
            !files || isLoading ? <Spinner text="Fetching Files" style={{ margin: 60 }} /> :
                <>
                    <StyledHR />
                    <FilesNavigator isPage={isPage} files={flattenFiles()} folderId={folderId} onInsert={onInsert} onDelete={onDelete} />
                    {
                        hasNextPage && <LoadMore loading={isFetchingNextPage} onclick={fetchNextPage} />
                    }
                </>
        }
    </Group >);
};


interface FileTypeFolderNavigation {
    isPage: boolean;
    files: Array<FolderFile>;
    onInsert?: (file: FolderFile) => void;
    onDelete?: (file: FolderFile) => void;
    folderId: keyof typeof CourseGroupResourceTypes
}

const StyledFileGroup = styled.div`
    width: 220px;
    ${forPhoneOnly("width: 50%;")}
    .fileParent {
        ${grow}
        border-color: ${props => props.theme.colors.grey3};
    }
`

const StyledFilesTableWrapper = styled.div`
  max-width: 100% !important;
  ${forPhoneOnly("padding: 8px;overflow-x: auto;")}

    td:first-child {
        width: 75%;
        text-align: left;
        ${forPhoneOnly("width: 20%;")}
    }
`

const FilesNavigator = ({ files, isPage, onInsert, onDelete, folderId }: FileTypeFolderNavigation) => {

    const theme = useTheme();
    const { confirm } = useNotify();
    const { state } = useContext(AuthContext);


    const getOptions = useCallback((file: FolderFile): Array<DropdownPropOptions> => {
        const options: Array<DropdownPropOptions> = []
        if (isPage) {
            options.push({
                demarcateTop: true,
                icon: <Trash style={{ color: theme.colors.danger }} />,
                text: "Delete",
                onClick: () => confirm({
                    onConfirmation: () => onDelete && onDelete(file)
                })
            })
        } else {
            options.push({
                icon: <PlusLg style={{ color: theme.colors.success }} />,
                text: "Insert",
                onClick: () => onInsert ? onInsert(file) : null,
            })
        }
        return options
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPage, onDelete, onInsert, theme.colors.danger, theme.colors.success])

    return (
        <>
            {
                state.preferences.grid ? <div className={styles.fileWrapper}>
                    {
                        files.map(file => <StyledFileGroup key={file.id}>
                            <Group gap="xs" className={`fileParent ${styles.fileParent}`}>
                                {
                                    folderId === "PHOTOS" && <StyledBanner $url={file.url} />
                                }

                                {
                                    folderId === "VIDEOS" && <ReactPlayer style={{ borderRadius: 0 }} controls={true} width={'96%'} height={'100px'} url={file.url} />
                                }

                                {
                                    folderId === "DOCUMENTS" && <FilePdfFill color={theme.colors.secondary} size={64} />
                                }

                                <Group direction="row" wrap="nowrap" justify="space-between">
                                    <b className="truncate">{file.fileName}</b>
                                    <div style={{ position: 'relative' }}>
                                        <Dropdown margin={"sm"} controller={<>
                                            <ThreeDots size={18} />
                                        </>} options={getOptions(file)} />
                                    </div>
                                </Group>
                                <Group gap="none" style={{ marginTop: '-4px' }} direction="row">
                                    <StyledSmall>{file.ext.toUpperCase()}</StyledSmall>
                                    <StyledSmall><Dot size={16} /></StyledSmall>
                                    <StyledSmall>{file.fileSize.total} {file.fileSize.fileSizeIn.toUpperCase()}</StyledSmall>
                                </Group>
                                <StyledSmall style={{ marginTop: '-7px' }}><Moment fromNow>{file.creationDate}</Moment></StyledSmall>
                            </Group>
                        </StyledFileGroup>)
                    }
                </div> : <StyledFilesTableWrapper>
                    <StyledTable>
                        <thead>
                            <tr>
                                <th>Details</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                files.map(file => <tr key={file.id}>
                                    <td style={{ paddingTop: '5px' }}>
                                        <Group gap="xs" align="center" direction="row">
                                            {
                                                folderId === "PHOTOS" && <div style={{ width: '50px' }}><StyledBanner $url={file.url} $height="50px" /></div>
                                            }
                                            {
                                                folderId === "VIDEOS" && <div style={{ width: '100px' }}><ReactPlayer controls={false} width={'100%'} height={'50px'} url={file.url} /></div>
                                            }
                                            {
                                                folderId === "DOCUMENTS" && <FilePdfFill color={theme.colors.secondary} size={44} />
                                            }
                                            <Group gap="none">
                                                <b className="truncate">{file.fileName}</b>
                                                <Group gap="none" style={{ marginTop: '-4px' }} direction="row">
                                                    <StyledSmall>{file.ext.toUpperCase()}</StyledSmall>
                                                    <StyledSmall><Dot size={16} /></StyledSmall>
                                                    <StyledSmall>{file.fileSize.total} {file.fileSize.fileSizeIn.toUpperCase()}</StyledSmall>
                                                </Group>
                                                <StyledSmall style={{ marginTop: '-4px' }}><Moment fromNow>{file.creationDate}</Moment></StyledSmall>
                                            </Group>
                                        </Group>
                                    </td>
                                    <td>
                                        <div style={{ position: 'relative', color: theme.colors.grey }}>
                                            <Dropdown margin={"sm"} controller={<>
                                                <ThreeDots size={22} />
                                            </>} options={getOptions(file)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </StyledTable>
                </StyledFilesTableWrapper>
            }
        </>
    )
}