"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateFormats = void 0;
exports.DateFormats = [
    "MMM Do, YYYY",
    "D MMM YYYY",
    "YYYY-MM-DD",
    "YYYY/MM/DD",
    "MMMM Do YYYY",
    "dddd, MMMM Do YYYY"
];
