import { Camera } from 'react-bootstrap-icons';
import React, { useState, useRef } from 'react';
import { OutlineButton } from '../../Form/Button';

export const ScreenRecorder: React.FC = () => {
    const screenVideoRef = useRef<HTMLVideoElement>(null);
    const cameraVideoRef = useRef<HTMLVideoElement>(null);
    const [recording, setRecording] = useState(false);
    const [screenStream, setScreenStream] = useState<MediaStream | null>(null);
    const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);

    const startRecording = async () => {
        try {
            const screenMediaStream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    // @ts-ignore
                    mediaSource: 'screen',
                },
            });
            setScreenStream(screenMediaStream);

            const cameraMediaStream = await navigator.mediaDevices.getUserMedia({
                video: true,
            });
            setCameraStream(cameraMediaStream);

            const combinedStream = new MediaStream();
            screenMediaStream.getVideoTracks().forEach((track) => combinedStream.addTrack(track));
            cameraMediaStream.getVideoTracks().forEach((track) => combinedStream.addTrack(track));

            const screenVideo = screenVideoRef.current;
            const cameraVideo = cameraVideoRef.current;

            if (screenVideo && cameraVideo) {
                screenVideo.srcObject = combinedStream;
                cameraVideo.srcObject = cameraMediaStream;
            }

            setRecording(true);

            setTimeout(() => {
                setRecording(false);
                screenMediaStream.getTracks().forEach((track) => track.stop());
                cameraMediaStream.getTracks().forEach((track) => track.stop());
            }, 30000); // Stop recording after 30 seconds (adjust as needed)
        } catch (error) {
            console.error('Error starting screen recording:', error);
        }
    };

    return (
        <div>
            <OutlineButton icon={<Camera />} onClick={startRecording} disabled={recording} text={recording ? 'Recording...' : 'Start Recording'} />
            {screenStream && cameraStream && (
                <div className="rounded-frame">
                    <video ref={screenVideoRef} autoPlay muted />
                    <video ref={cameraVideoRef} autoPlay />
                </div>
            )}

            <style>
                {`
          .rounded-frame {
            position: fixed;
            bottom: 20px;
            right: 20px;
            display: flex;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          }

          video {
            width: 200px;
            height: 150px;
            margin: 5px;
          }
        `}
            </style>
        </div>
    );
};

