"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventRecurrence = exports.EventStatus = void 0;
var EventStatus;
(function (EventStatus) {
    EventStatus["PENDING"] = "pending";
    EventStatus["COMPLETED"] = "completed";
})(EventStatus || (exports.EventStatus = EventStatus = {}));
var EventRecurrence;
(function (EventRecurrence) {
    EventRecurrence["DAILY"] = "daily";
    EventRecurrence["YEARLY"] = "yearly";
    EventRecurrence["WEEKLY"] = "weekly";
    EventRecurrence["MONTHLY"] = "monthly";
    EventRecurrence["NOREPEAT"] = "no-repeat";
    EventRecurrence["BIWEEKLY"] = "bi-weekly";
})(EventRecurrence || (exports.EventRecurrence = EventRecurrence = {}));
