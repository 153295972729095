"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileTypes = exports.FileSizeMeasurements = void 0;
var FileSizeMeasurements;
(function (FileSizeMeasurements) {
    FileSizeMeasurements["KB"] = "KB";
    FileSizeMeasurements["MB"] = "MB";
    FileSizeMeasurements["GB"] = "GB";
    FileSizeMeasurements["Bytes"] = "Bytes";
})(FileSizeMeasurements || (exports.FileSizeMeasurements = FileSizeMeasurements = {}));
var FileTypes;
(function (FileTypes) {
    FileTypes["CSV"] = "CSV";
    FileTypes["MP3"] = "MP3";
    FileTypes["MP4"] = "MP4";
    FileTypes["MKV"] = "MKV";
    FileTypes["MOV"] = "MOV";
    FileTypes["JPG"] = "JPG";
    FileTypes["PNG"] = "PNG";
    FileTypes["PPT"] = "PPT";
    FileTypes["DOC"] = "DOC";
    FileTypes["TXT"] = "TXT";
    FileTypes["PDF"] = "PDF";
    FileTypes["JPEG"] = "JPEG";
})(FileTypes || (exports.FileTypes = FileTypes = {}));
