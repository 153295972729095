"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCodeMeaning = exports.ErrorCodes = void 0;
var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["INVALID_ID"] = "INVALID_ID";
    ErrorCodes["TEST_IN_USE"] = "TEST_IN_USE";
    ErrorCodes["INVALID_URL"] = "INVALID_URL";
    ErrorCodes["USER_EXISTS"] = "USER_EXISTS";
    ErrorCodes["UPDATE_FAILED"] = "UPDATE_FAILED";
    ErrorCodes["INVALID_EMAIL"] = "INVALID_EMAIL";
    ErrorCodes["ACCESS_DENIED"] = "ACCESS_DENIED";
    ErrorCodes["FILE_NOT_FOUND"] = "FILE_NOT_FOUND";
    ErrorCodes["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    ErrorCodes["TUTOR_NOT_FOUND"] = "TUTOR_NOT_FOUND";
    ErrorCodes["MISSING_RESOURCE"] = "MISSING_RESOURCE";
    ErrorCodes["FORBIDDEN_COURSE"] = "FORBIDDEN_COURSE";
    ErrorCodes["INVALID_DATE_TIME"] = "INVALID_DATE_TIME";
    ErrorCodes["INVALID_FILE_SIZE"] = "INVALID_FILE_SIZE";
    ErrorCodes["NO_UPDATABLE_PARAM"] = "NO_UPDATABLE_PARAM";
    ErrorCodes["INVALID_PARAM_FOUND"] = "INVALID_PARAM_FOUND";
    ErrorCodes["SERVICE_NOT_AVAILABLE"] = "SERVICE_NOT_AVAILABLE";
    ErrorCodes["MAXIMUM_LIMIT_REACHED"] = "MAXIMUM_LIMIT_REACHED";
    ErrorCodes["DUPLICATE_EMAIL_FOUND"] = "DUPLICATE_EMAIL_FOUND";
    ErrorCodes["INVALID_RESOURCE_TYPE"] = "INVALID_RESOURCE_TYPE";
    ErrorCodes["INVALID_SESSION_TOKEN"] = "INVALID_SESSION_TOKEN";
    ErrorCodes["INVALID_REQUEST_METHOD"] = "INVALID_REQUEST_METHOD";
    ErrorCodes["RESOURCE_ALREADY_EXISTS"] = "RESOURCE_ALREADY_EXISTS";
    ErrorCodes["DOMAIN_RECORD_UNRESOLVED"] = "DOMAIN_RECORD_UNRESOLVED";
    ErrorCodes["PARAM_UPDATE_NOT_ALLOWED"] = "PARAM_UPDATE_NOT_ALLOWED";
    ErrorCodes["PRIMARY_RESOURCE_NOT_FOUND"] = "PRIMARY_RESOURCE_NOT_FOUND";
    ErrorCodes["ENROLLMENT_RECORD_NOT_FOUND"] = "ENROLLMENT_RECORD_NOT_FOUND";
    ErrorCodes["INVALID_POSSIBLE_VALUE_FOUND"] = "INVALID_POSSIBLE_VALUE_FOUND";
    ErrorCodes["MAXIMUM_FIELD_LENGTH_REACHED"] = "MAXIMUM_FIELD_LENGTH_REACHED";
    ErrorCodes["ENROLLMENT_RECORD_ALREADY_EXIST"] = "ENROLLMENT_RECORD_ALREADY_EXIST";
})(ErrorCodes || (exports.ErrorCodes = ErrorCodes = {}));
var ErrorCodeMeaning;
(function (ErrorCodeMeaning) {
    ErrorCodeMeaning["USER_EXISTS"] = "User exists!";
    ErrorCodeMeaning["INVALID_URL"] = "URL is invalid!";
    ErrorCodeMeaning["ACCESS_DENIED"] = "Access denied!";
    ErrorCodeMeaning["INVALID_ID"] = "Invalid Identifier!";
    ErrorCodeMeaning["USER_NOT_FOUND"] = "User not found!";
    ErrorCodeMeaning["FILE_NOT_FOUND"] = "File not found!";
    ErrorCodeMeaning["TUTOR_NOT_FOUND"] = "Tutor not found!";
    ErrorCodeMeaning["INVALID_EMAIL"] = "Invalid email found!";
    ErrorCodeMeaning["INVALID_FILE_SIZE"] = "Invalid file size!";
    ErrorCodeMeaning["INVALID_DATE_TIME"] = "Invalid date time!";
    ErrorCodeMeaning["INVALID_SESSION_TOKEN"] = "Invalid Session Token";
    ErrorCodeMeaning["DUPLICATE_EMAIL_FOUND"] = "Email already exists!";
    ErrorCodeMeaning["INVALID_RESOURCE_TYPE"] = "Invalid resource type!";
    ErrorCodeMeaning["MAXIMUM_LIMIT_REACHED"] = "Maximum limit reached!";
    ErrorCodeMeaning["INVALID_PARAM_FOUND"] = "Invalid parameter found!";
    ErrorCodeMeaning["INVALID_REQUEST_METHOD"] = "Invalid request method!";
    ErrorCodeMeaning["UPDATE_FAILED"] = "Update failed, please try again later!";
    ErrorCodeMeaning["RESOURCE_ALREADY_EXISTS"] = "The resource already exists!";
    ErrorCodeMeaning["PARAM_UPDATE_NOT_ALLOWED"] = "Parameter update not allowed!";
    ErrorCodeMeaning["PRIMARY_RESOURCE_NOT_FOUND"] = "Primary resource not found!";
    ErrorCodeMeaning["ENROLLMENT_RECORD_NOT_FOUND"] = "Enrollment record not found!";
    ErrorCodeMeaning["MAXIMUM_FIELD_LENGTH_REACHED"] = "Maximum field length reached!";
    ErrorCodeMeaning["TEST_IN_USE"] = "Selected test cannot be undated as it is currently in use.";
    ErrorCodeMeaning["FORBIDDEN_COURSE"] = "You are not permitted to enroll to the selected course.";
    ErrorCodeMeaning["NO_UPDATABLE_PARAM"] = "At least one user property must be provided for update.";
    ErrorCodeMeaning["INVALID_POSSIBLE_VALUE_FOUND"] = "Value provided not in list of possible values!";
    ErrorCodeMeaning["MISSING_RESOURCE"] = "The requested resource does not exist or has been deleted.";
    ErrorCodeMeaning["DOMAIN_RECORD_UNRESOLVED"] = "Domain record is unresolved, please contact support!";
    ErrorCodeMeaning["ENROLLMENT_RECORD_ALREADY_EXIST"] = "Enrollment record already exist for the selected student.";
    ErrorCodeMeaning["SERVICE_NOT_AVAILABLE"] = "Service is currently not available, please try again later or contact support!";
})(ErrorCodeMeaning || (exports.ErrorCodeMeaning = ErrorCodeMeaning = {}));
