import styled from "styled-components";
import { FC, useContext } from "react";
import { Folder } from "shared-library";
import { useNavigate } from "react-router-dom";
import styles from "./FolderCards.module.scss";
import { Group } from "../../Layout/Group/Group";
import { AuthContext } from "../../../context/auth";
import { StyledH5, StyledH6, StyledTable, forPhoneOnly, grow } from "../../Elements";
import { FiletypeMp3, FiletypeMp4, FiletypePdf, FiletypePng, FiletypePpt } from "react-bootstrap-icons";


interface FolderCardsProps {
    className?: string;
    folders: Array<Folder>;
}

const StyledFolderCardsWrapper = styled.div`
    flex-basis: 20%;
    ${forPhoneOnly("padding: 8px;flex-basis: 100%;")}
    .folderParent {
        ${grow}
        margin: 0px 5px 10px 5px;
        background: ${props => props.theme.colors.white};
    }
`

const StyledFolderTableWrapper = styled.div`
    ${forPhoneOnly("padding: 8px;")}

    tbody tr {
        ${grow}
    }

    td:nth-child(2) {
        width: 85%;
        ${forPhoneOnly("width: 50%;")}
    }
`

export const FolderCards: FC<FolderCardsProps> = (props) => {
    const navigate = useNavigate()
    const { state } = useContext(AuthContext)
    const { folders } = props

    return (<Group style={{ marginTop: '30px' }}>
        {
            state.preferences.grid && <Group direction="row" gap="none">
                {
                    folders.map(folder => <StyledFolderCardsWrapper key={folder.id}>
                        <div className={`folderParent ${styles.folderWrapper} ${props.className ? props.className : ''}`} onClick={() => navigate(folder.id)}>
                            <Group gap="xs" justify="center" align="center">
                                {folder.id === "PRESENTATIONS" && <FiletypePpt color={folder.color} size={24} />}
                                {folder.id === "AUDIOS" && <FiletypeMp3 color={folder.color} size={24} />}
                                {folder.id === "VIDEOS" && <FiletypeMp4 color={folder.color} size={24} />}
                                {folder.id === "DOCUMENTS" && <FiletypePdf color={folder.color} size={24} />}
                                {folder.id === "PHOTOS" && <FiletypePng color={folder.color} size={24} />}
                                <Group gap="none" align="center">
                                    <StyledH5><b>{folder.id.toUpperCase()}</b></StyledH5>
                                    <StyledH6><b>{folder.meta.files}</b> files</StyledH6>
                                    <StyledH6>{folder.meta.fileSize.total} {folder.meta.fileSize.fileSizeIn.toUpperCase()}</StyledH6>
                                </Group>
                            </Group>
                        </div>
                    </StyledFolderCardsWrapper>)
                }
            </Group>
        }

        {
            !state.preferences.grid && <StyledFolderTableWrapper className={`${props.className ? props.className : ''}`}>
                <StyledTable className="hasSnNo">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Description</th>
                            <th>Items</th>
                            <th>Size</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            folders.map(folder => <tr key={folder.id} onClick={() => navigate(folder.id)} className="pointer">
                                <td style={{ paddingTop: '10px' }}>
                                    {folder.id === "PRESENTATIONS" && <FiletypePpt color={folder.color} size={24} />}
                                    {folder.id === "AUDIOS" && <FiletypeMp3 color={folder.color} size={24} />}
                                    {folder.id === "VIDEOS" && <FiletypeMp4 color={folder.color} size={24} />}
                                    {folder.id === "DOCUMENTS" && <FiletypePdf color={folder.color} size={24} />}
                                    {folder.id === "PHOTOS" && <FiletypePng color={folder.color} size={24} />}
                                </td>
                                <td>{folder.id.toUpperCase()}</td>
                                <td><small>{folder.meta.files}</small></td>
                                <td><small>{folder.meta.fileSize.total} {folder.meta.fileSize.fileSizeIn.toUpperCase()}</small></td>
                            </tr>)
                        }
                    </tbody>
                </StyledTable>
            </StyledFolderTableWrapper>
        }

    </Group>)
}