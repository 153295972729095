"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validURL = void 0;
const validURL = (url) => {
    if (!url)
        return false;
    url = `${url}`.trim();
    if (!url.includes('.'))
        return false;
    // eslint-disable-next-line no-useless-escape
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const pattern = new RegExp(expression);
    return (!!pattern.test(url));
};
exports.validURL = validURL;
