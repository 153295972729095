import { grow } from "../../Elements";
import styled from "styled-components";
import styles from "./ProgressStep.module.scss";
import { useTheme } from "../../../hooks/use-theme.hook";

interface ProgressStepProps {
    color?: string;
    active: number;
    totalSteps: number;
}

const StyledLine = styled.div`
    ${grow}
`

export const ProgressStep = ({ totalSteps, active, color }: ProgressStepProps) => {

    const theme = useTheme()
    const timesArray = Array.from({ length: totalSteps }, (_, index) => index + 1);

    return (<div className={styles.pgWrapper}>
        {
            timesArray.map(ta => <StyledLine style={{ background: color || theme.colors.primary }} key={ta} className={`${styles.pgDot} ${ta === active ? styles.active : ''}`}></StyledLine>)
        }
    </div>)
}