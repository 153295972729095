import styles from "./Group.module.scss";
import { CSSProperties, ReactNode } from "react";

type Size = "xs" | "sm" | "md" | "lg" | "xl" | "smm" | "none";

interface GroupProps {
  gap?: Size;
  id?: string;
  className?: string;
  children: ReactNode;
  onClick?: () => void;
  style?: CSSProperties;
  wrap?: "wrap" | "nowrap";
  direction?: "row" | "column";
  align?: "start" | "center" | "end" | "normal";
  justify?: "auto" | "space-between" | "center" | "end" | "start";
}

const gapSizeMap: Record<Size, string> = {
  xs: styles.xs,
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
  xl: styles.xl,
  smm: styles.smm,
  none: styles.none,
};

export const Group = (props: GroupProps) => {
  const {
    id = "",
    gap = "sm",
    wrap = "wrap",
    direction = "column",
    justify = "auto",
    align = "normal",
    children,
    className = "",
    style = {},
    onClick = () => null
  } = props;

  const classNames = `${className} ${styles.group} ${gapSizeMap[gap]}`;
  const inlineStyles: CSSProperties = { ...style, flexDirection: direction, justifyContent: justify, alignItems: align, flexWrap: wrap };

  return (
    <div id={id} className={classNames} onClick={onClick} style={inlineStyles}>
      {children}
    </div>
  );
};
