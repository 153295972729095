import CountUp from "react-countup";
import parse from 'html-react-parser';
import styled from "styled-components";
import { useApi } from "../../hooks/use-api";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { NotFoundError } from "../ErrorPage/Errors";
import { Page } from "../../components/Layout/Page/Page";
import { useCallback, useContext, useState } from "react";
import { Spinner } from "../../components/Spinner/Spinner";
import { Group } from "../../components/Layout/Group/Group";
import { RoleTypes, Review, hexToRgba } from "shared-library";
import { ReviewCards } from "../../components/App/ReviewCards";
import { LoadMore } from "../../components/App/LoadMore/LoadMore";
import { useNotification } from '../../hooks/use-notification.hook';
import { TopMenu, TopMenuProfile } from "../../components/App/TopMenu";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { StyledBanner, StyledContentWrapper, StyledChapterTitle, StyledSpan, forPhoneOnly } from "../../components/Elements";

const StyledGroup = styled(Group)`
    .left {
        flex-basis: 35%;
        ${forPhoneOnly("flex-basis: 100%;")}
    }
`

export const CourseReviews = () => {

    const { courseId } = useParams()
    const { notify } = useNotification();
    const { state } = useContext(AuthContext);
    const { getCourse, getCourseReviews, deleteReviews } = useApi()
    const { data: course, isLoading } = useQuery({
        queryKey: ["course", courseId],
        queryFn: () => getCourse(courseId as string),
    });

    const [reviewsFilters] = useState<{
        limit: number;
        courseId: string;
        nextBatchIndex?: string;
    }>({
        limit: 20,
        courseId: courseId as string,
    })
    const { data: reviews, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getCourseReviews({
            nextBatchIndex,
            limit: reviewsFilters.limit,
            courseId: reviewsFilters.courseId,
        }),
        initialPageParam: reviewsFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["reviews", courseId, reviewsFilters.limit, reviewsFilters.nextBatchIndex],
    });

    const { isPending: mutatingReviewDeletionAsync, mutateAsync: mutateReviewDeletionAsync } = useMutation({
        mutationFn: deleteReviews
    });

    const flattenReviews = useCallback(() => {
        let _reviews: Array<Review> = []
        if (reviews?.pages) {
            for (const page of reviews.pages) {
                _reviews = [..._reviews, ...page.data]
            }
        }
        return _reviews
    },
        [reviews?.pages]
    );

    if (!course || isLoading) {
        if (!course && !isLoading) {
            return <NotFoundError />
        }
        return <Spinner text="Fetching Course" style={{ margin: 200 }} />
    }

    const deleteReview = async (reviewId: string) => {
        try {
            if (!mutatingReviewDeletionAsync) {
                await mutateReviewDeletionAsync({ reviewIds: [reviewId], courseId: course.id })
                refetch && refetch()
                notify({ type: "success", title: "Successful!", body: `Review has been successfully deleted!` });
                course.stats.totalRatings--
            }
        } catch (error) {
        }
    }

    return (
        <Page>
            <StyledGroup direction="row">
                <StyledContentWrapper className="left slideInLeft">
                    <Group>
                        <TopMenu hideProfile hideNotification titles={[{
                            text: 'Courses',
                            link: `/${state.user?.info.session.role}/courses`
                        }, {
                            text: course.title,
                        }]} />

                        <Group gap="xs">
                            <br />
                            <br />
                            <br />
                            <Group style={{
                                color: course.color,
                                padding: '10px',
                                borderRadius: '8px',
                                background: hexToRgba(course.color, 10),
                            }}>
                                <StyledBanner $url={`${course.assets.thumbnail?.url || '/assets/images/ImagePlaceholder.jpg'}`} $height="150px" $css="border-radius: 8px;" />
                                {
                                    (!!course.title || !!course.description) && <Group gap="xs">
                                        <Group gap="xs" direction="row">
                                            <div style={{ flex: 1, fontSize: '18px' }}><b>{course.title}</b></div>
                                        </Group>
                                        <small>{parse(course.description)}</small>
                                    </Group>
                                }
                            </Group>
                        </Group>
                    </Group>
                </StyledContentWrapper>
                <Group className="slideInRight" style={{ flex: 1 }}>
                    <Group direction="row" align="center" justify="end">
                        <TopMenuProfile />
                    </Group>
                    <br />
                    <br />
                    <StyledChapterTitle><b>Reviews</b><StyledSpan><small style={{ paddingLeft: '5px', fontSize: '10px', fontWeight: 'lighter' }}>(<CountUp end={course.stats.totalRatings} />)</small></StyledSpan></StyledChapterTitle>
                    <ReviewCards deletable={RoleTypes.ADMIN === state.user?.info.session.role} onDeleteClick={deleteReview} reviews={flattenReviews()} />
                    {
                        hasNextPage && <LoadMore loading={isFetchingNextPage} onclick={fetchNextPage} />
                    }
                </Group>
            </StyledGroup>
        </Page>
    );
};
