"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createImageFromInitials = void 0;
const getInitials = (name) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
                nameSplit[1].substring(0, 1);
    }
    else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    }
    else
        return '';
    return initials.toUpperCase();
};
const createImageFromInitials = (name, fontColor = "#ffffff", bgColor = "#000000", size = 50) => {
    if (!name?.trim())
        return;
    name = getInitials(name);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = canvas.height = size;
    context.fillStyle = bgColor;
    context.fillRect(0, 0, size, size);
    context.fillStyle = fontColor;
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.font = `${size / 2}px Arial`;
    context.fillText(name, (size / 2), (size / 2));
    return canvas.toDataURL();
};
exports.createImageFromInitials = createImageFromInitials;
