import { useContext } from "react";
import styled from "styled-components";
import styles from "./Footer.module.scss";
import { CompanyContext } from "../../../context/company";
import { Item } from "../../../components/Layout/Item/Item";
import { Group } from "../../../components/Layout/Group/Group";
import { StyledNavLink, forPhoneOnly, StyledHR } from "../../Elements";


const StyledFooter = styled.footer`
    background: ${(props) => props.theme.colors.lightGray};
    hr {
        background: ${(props) => props.theme.colors.gray5};
    }
    & > div {
        color: ${(props) => props.theme.colors.gray4};
        a {
            color: ${(props) => props.theme.colors.gray4};
        }
        ${forPhoneOnly("padding: 0 1rem;")};
    }
`

export const Footer = () => {
    const { state: AppCompany } = useContext(CompanyContext)
    return (
        <StyledFooter className={styles.footer}>
            <Group gap="sm">
                <Group gap="sm" direction="row" style={{ alignItems: "center" }}>
                    <img style={{ opacity: 0.5 }} src={AppCompany.company?.logos.sm} width="35" height="35" alt={AppCompany.company?.name + " logo"} />
                    <Item>
                        <p>© {AppCompany.company?.name}</p>
                        <p>
                            {AppCompany.company?.address}
                        </p>
                    </Item>
                </Group>
                <StyledHR />
                <Item>
                    <StyledNavLink to="/policies/tnc">Terms & Conditions</StyledNavLink> &nbsp; / &nbsp;{" "}
                    <StyledNavLink to="/policies/privacy">
                        Privacy Policy
                    </StyledNavLink>{" "}
                    &nbsp; / &nbsp; <StyledNavLink to="/policies/refund">Refund Policy</StyledNavLink>
                </Item>
            </Group>
        </StyledFooter>
    )
};
