"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationType = exports.NotificationStatus = void 0;
var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus["READ"] = "read";
    NotificationStatus["UNREAD"] = "unread";
})(NotificationStatus || (exports.NotificationStatus = NotificationStatus = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["NEWSTUDENT"] = "newStudent";
    NotificationType["COURSEREQUEST"] = "courseRequest";
    NotificationType["COURSEENROLLED"] = "courseEnrolled";
    NotificationType["PAYMENTREQUIRED"] = "paymentRequired";
    NotificationType["LECTURECOMPLETED"] = "lectureCompleted";
    NotificationType["NEWCOURSEENROLLMENT"] = "newCourseEnrollment";
    NotificationType["TUTORREVIEWSUBMITTED"] = "tutorReviewSubmitted";
    NotificationType["COURSEREVIEWSUBMITTED"] = "courseReviewSubmitted";
    NotificationType["NEWCERTIFICATEASSIGNED"] = "newCertificateAssigned";
    NotificationType["COURSEMATERIALUNLOCKED"] = "courseMaterialUnlocked";
    NotificationType["ENROLLMENTREQUESTREJECTED"] = "enrollmentRequestRejected";
    NotificationType["COURSEENROLLMENTTERMINATED"] = "courseEnrollmentTerminated";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
