import { useApi } from "../../hooks/use-api";
import { useQuery } from "@tanstack/react-query";
import { Company, AppThemes, DynamicConfig } from "shared-library";
import { DiskManager } from "../../utils/diskManager";
import { getCurrentUrlWithoutProtocol } from "../../utils/getCurrentUrlWithoutProtocol";
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

const companyDomain = getCurrentUrlWithoutProtocol()
const diskManager = new DiskManager(companyDomain)

interface AppCompanyValues {
    state: {
        company?: Company;
        config?: DynamicConfig;
    };
    changeTheme: (theme: AppThemes) => void;
    updateCompany: (company: Company) => void;
}

const stateFromDisk = diskManager.retrieve()
const ctxDefaultValue: AppCompanyValues = {
    changeTheme: () => undefined,
    updateCompany: () => undefined,
    state: {
        company: stateFromDisk ? JSON.parse(stateFromDisk) as Company : undefined
    }
};

export const CompanyContext = createContext<AppCompanyValues>(ctxDefaultValue);
export const CompanyContextProvider = ({ children }: { children: ReactNode }) => {

    const { getDomainDetails } = useApi()
    const { data: companyDetails } = useQuery({
        refetchOnWindowFocus: false,
        queryKey: ["company", companyDomain],
        queryFn: () => getDomainDetails({ domain: companyDomain })
    })
    const [state, setState] = useState(ctxDefaultValue.state);

    useEffect(() => {
        if (companyDetails) {
            setState({ ...state, company: companyDetails.company, config: companyDetails.config })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyDetails]);

    useEffect(() => {
        if (state.company) {
            diskManager.save(JSON.stringify(state.company))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.company]);

    const updateCompany = useCallback((company: Company) => setState(state => ({ ...state, company })), []);
    const changeTheme = useCallback((theme: AppThemes) => setState(state => ({ ...state, company: state.company ? { ...state.company, themeId: theme.id, theme } : undefined })), []);

    const value = useMemo(() => ({ state, changeTheme, updateCompany }), [state, changeTheme, updateCompany]);
    return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};
