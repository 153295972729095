import { FC, useCallback } from "react";
import styled from "styled-components";
import styles from "./CreditCard.module.scss";
import { CardTypes, PaymentCard } from "shared-library";
import { boxShadow, forPhoneOnly } from "../../Elements";
const creditCardType = require("credit-card-type");


interface CreditCardProps {
    active?: boolean;
    card: PaymentCard;
}

const StyledCardWrapper = styled.div`
    ${forPhoneOnly("width: 100%;")}
    .card {
        ${forPhoneOnly("width: 100%;")}
    }
    &.active {
        .front,
        .back {
            ${boxShadow()}
        }
    }
`

export const CreditCard: FC<CreditCardProps> = ({ card, active = false }) => {

    const { last4, number, name, type, expiry, cvc } = card;

    const findCardType = useCallback((): CardTypes | undefined => {
        if (number && !type) {
            const visaCards = creditCardType(number);
            if (visaCards.length) {
                return visaCards[0].type as CardTypes
            }
        }
        return type
    }, [number, type])

    return (
        <StyledCardWrapper className={`${styles['rccs']} ${active ? "active" : ""}`}>
            <div className={`${styles.rccs__card} card ${styles['rccs__card--' + (type || findCardType())]}`}>
                <div className={`${styles['rccs__card--front']} front`}>
                    <div className={styles['rccs__card__background']}></div>
                    <div className={styles['rccs__issuer']}></div>
                    <div className={styles['rccs__cvc__front']}>{cvc}</div>
                    <div className={`${styles.rccs__number} ${styles['rccs--filled']}`}>{number || `**** **** **** ${last4}`}</div>
                    <div className={`${styles.rccs__name} ${styles['rccs--filled']}`}>{name}</div>
                    <div className={`${styles.rccs__expiry} ${styles['rccs--filled']}`}>
                        <div className={styles['rccs__expiry__valid']}>valid thru</div>
                        <div className={styles['rccs__expiry__value']}>{expiry}</div>
                    </div>
                    <div className={styles['rccs__chip']}></div>
                </div>
                <div className={`${styles['rccs__card--back']} back`}>
                    <div className={styles['rccs__card__background']}></div>
                    <div className={styles['rccs__stripe']}></div>
                    <div className={styles['rccs__signature']}></div>
                    <div className={styles['rccs__cvc']}>{cvc}</div>
                    <div className={styles['rccs__issuer']}></div>
                </div>
            </div>
        </StyledCardWrapper>
    )
}