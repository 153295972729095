import { Navbar } from "../Navbar";
import { Footer } from "../Footer";
import styles from "./Skeleton.module.scss";
import { Group } from "../../Layout/Group/Group";
import { AuthContext } from "../../../context/auth";
import styled, { useTheme } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { ReactNode, useContext, useState } from "react";
import { CompanyContext } from "../../../context/company";
import { createImageFromInitials, RoleTypes } from "shared-library";
import { boxShadow, forPhoneOnly, StyledIcon, StyledNavLink, transition } from "../../Elements";
import { Award, Book, CloudPlus, List, People, PersonAdd, PersonVideo2, Tv, UiChecks, XLg } from "react-bootstrap-icons";


const StyledWrapper = styled.div`
    ${transition};
    display: flex;
    height: 100vh;
    flex-direction: row;
    ${forPhoneOnly("flex-direction: column;")}
    ::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.colors.secondary};
    }
`

const StyledSideBar = styled.div`
    width: 250px;
    padding: 20px;
    position: relative;
    background-color: ${(props) => props.theme.colors.white};
    box-sizing: border-box;
    overflow-y: auto;
    ${forPhoneOnly("width: 56%; position: absolute; left: 0; bottom: 0; top: 0; z-index: 3;")}
`

const StyledMainMenu = styled.div`
    flex: 1;
    padding: 20px;
    overflow-y: auto; 
    position: relative;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.colors.white};
    ${forPhoneOnly("width: 100%;")}
`

const StyledMenuGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0.6rem;
    color: ${(props) => props.theme.colors.grey};
    span {
        padding-top: 3.5px;
    }

    div {
        border-radius: 4px;
        padding: 7px 10px 4px 10px;
        color: ${(props) => props.theme.colors.grey};
        background-color: transparent;
    }

    &.active {
        align-items: center;
        div {
            color: ${(props) => props.theme.colors.white};
            ${(props) => boxShadow(props.theme.colors.primary2)}
            background-color: ${(props) => props.theme.colors.primary};
        }
        span {
            padding-top: 0px;
            font-weight: bold;
            color: ${(props) => props.theme.colors.primary};
        }
    }

`

const StyledSideBarFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
        color: ${(props) => props.theme.colors.grey};
    }
`

const StyledMobileIcons = styled(StyledIcon)`
    display: none;
    cursor: pointer;

    &.closer {
        top: 0px;
        right: 10px;
        position: absolute;
        color: ${props => props.theme.colors.danger};
        background: transparent;
    }

    &.opener {
        color: ${props => props.theme.colors.primary};
        background: ${props => props.theme.colors.primary4};
    }
    ${forPhoneOnly("display: inline-block;")}
  `

const StyledMain = styled.main`
  color: ${(props) => props.theme.colors.gray5};
  background-color: ${(props) => props.theme.colors.white};
  ${forPhoneOnly(`padding: 1.5rem 1rem 3rem 1rem;`)}
`

export const Skeleton = ({ children }: { children: ReactNode }) => {

    const theme = useTheme()
    const location = useLocation();
    const { state } = useContext(AuthContext);
    const { state: AppCompany } = useContext(CompanyContext)
    const [mobileSideMenu, setMobileSideMenu] = useState(true)

    if (!state.user?.info.session.role) {
        return (
            <Group direction="column" justify="space-between" style={{ minHeight: '100vh' }}>
                <Navbar />
                <StyledMain style={{ margin: '50px 0 100px' }} className={styles.body}>
                    {children}
                </StyledMain>
                <Footer />
            </Group>
        )
    }
    return <>
        <StyledWrapper>
            {
                mobileSideMenu && <StyledSideBar>
                    <StyledMobileIcons className="closer" onClick={() => setMobileSideMenu(false)}>
                        <XLg size={32} />
                    </StyledMobileIcons>
                    <Group justify="space-between" wrap="nowrap" style={{ height: '100%' }}>
                        <div>
                            <Group direction="row" justify="center">
                                <StyledNavLink to="/" role="logo">
                                    <img src={AppCompany.company?.logos.nm || createImageFromInitials(AppCompany.company?.name as string, theme.colors.white, theme.colors.secondary, 150)} width="150" height="150" alt={AppCompany.company?.name + " logo"} />
                                </StyledNavLink>
                            </Group>

                            <Group gap="xs" style={{ marginLeft: '30px', marginTop: '50px' }}>
                                <StyledNavLink to={`/${state.user?.info.session.role}/dashboard`}>
                                    <StyledMenuGroup className={location.pathname.startsWith(`/${state.user?.info.session.role}/dashboard`) ? 'active' : ''}>
                                        <div>
                                            <Tv />
                                        </div>
                                        <span>Dashboard</span>
                                    </StyledMenuGroup>
                                </StyledNavLink>
                                <StyledNavLink to={`/${state.user?.info.session.role}/courses`}>
                                    <StyledMenuGroup className={location.pathname.startsWith(`/${state.user?.info.session.role}/courses`) ? 'active' : ''}>
                                        <div>
                                            <Book />
                                        </div>
                                        <span>Courses</span>
                                    </StyledMenuGroup>
                                </StyledNavLink>
                                {
                                    [RoleTypes.ADMIN, RoleTypes.TUTOR].includes(state.user?.info.session.role as RoleTypes) && <>
                                        <StyledNavLink to={`/${state.user?.info.session.role}/enrollments`}>
                                            <StyledMenuGroup className={location.pathname.startsWith(`/${state.user?.info.session.role}/enrollments`) ? 'active' : ''}>
                                                <div>
                                                    <PersonAdd />
                                                </div>
                                                <span>Enrollments</span>
                                            </StyledMenuGroup>
                                        </StyledNavLink>
                                        <StyledNavLink to={`/${state.user?.info.session.role}/students`}>
                                            <StyledMenuGroup className={location.pathname.startsWith(`/${state.user?.info.session.role}/students`) ? 'active' : ''}>
                                                <div>
                                                    <People />
                                                </div>
                                                <span>Students</span>
                                            </StyledMenuGroup>
                                        </StyledNavLink>
                                        {
                                            state.user?.info.session.role === RoleTypes.ADMIN && <StyledNavLink to={`/${state.user?.info.session.role}/tutors`}>
                                                <StyledMenuGroup className={location.pathname.startsWith(`/${state.user?.info.session.role}/tutors`) ? 'active' : ''}>
                                                    <div>
                                                        <PersonVideo2 />
                                                    </div>
                                                    <span>Tutors</span>
                                                </StyledMenuGroup>
                                            </StyledNavLink>
                                        }
                                        <StyledNavLink to={`/${state.user?.info.session.role}/uploads`}>
                                            <StyledMenuGroup className={location.pathname.startsWith(`/${state.user?.info.session.role}/uploads`) ? 'active' : ''}>
                                                <div>
                                                    <CloudPlus />
                                                </div>
                                                <span>Uploads</span>
                                            </StyledMenuGroup>
                                        </StyledNavLink>
                                        <StyledNavLink to={`/${state.user?.info.session.role}/tests`}>
                                            <StyledMenuGroup className={location.pathname.startsWith(`/${state.user?.info.session.role}/tests`) ? 'active' : ''}>
                                                <div>
                                                    <UiChecks />
                                                </div>
                                                <span>Tests</span>
                                            </StyledMenuGroup>
                                        </StyledNavLink>
                                    </>
                                }
                                <StyledNavLink to={`/${state.user?.info.session.role}/certificates`}>
                                    <StyledMenuGroup className={location.pathname.startsWith(`/${state.user?.info.session.role}/certificates`) ? 'active' : ''}>
                                        <div>
                                            <Award />
                                        </div>
                                        <span>Certificates</span>
                                    </StyledMenuGroup>
                                </StyledNavLink>
                            </Group>
                        </div>
                        <StyledSideBarFooter>
                            {
                                AppCompany.company?.logos?.cert ? <img src={AppCompany.company.logos.cert} width="79" height="87" alt={AppCompany.company.name + " cert"} /> : null
                            }
                            <Link to={`/policies`}>Policies</Link>
                        </StyledSideBarFooter>
                    </Group>
                </StyledSideBar>
            }

            <StyledMainMenu>
                {
                    !mobileSideMenu && <StyledMobileIcons className="opener" onClick={() => setMobileSideMenu(true)}>
                        <List size={24} />
                    </StyledMobileIcons>
                }
                {children}
            </StyledMainMenu>
        </StyledWrapper>
    </>
};