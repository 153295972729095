import { User } from "shared-library";
import { useApi } from "../../hooks/use-api";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { TextInput } from "../../components/Form";
import { useForm } from "../../hooks/use-form.hook";
import { Button } from "../../components/Form/Button";
import { CompanyContext } from "../../context/company";
import { Group } from "../../components/Layout/Group/Group";
import { useNotification } from "../../hooks/use-notification.hook";
import { useRouteResolver } from "../../hooks/use-route-resolver.hook";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

export const Login = () => {
    const navigate = useNavigate();
    const { getSessionDetails } = useApi();
    const { login } = useContext(AuthContext);
    const { state: AppCompany } = useContext(CompanyContext)
    const { onSubmit, onChange, errors, formValues } = useForm({
        defaultValues: {
            email: "",
            password: ""
        },
        validations: {
            password: { required: true },
            email: { email: true, required: true },
        }
    })

    const { onSubmit: onResetSubmit, onChange: onResetChange, errors: resetErrors, formValues: resetFormValues } = useForm({
        defaultValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validations: {
            newPassword: { required: true, min: 8 },
            confirmPassword: { required: true, min: 8 },
        }
    })

    const { notify } = useNotification();
    const { dashboard } = useRouteResolver()
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstLogin, setFirstLogin] = useState(false);
    const [userAttributes, setUserAttributes] = useState({});
    const [cognitoObj, setCognitoObj] = useState<CognitoUser>();

    const authenticate = () => {
        setIsLoading(true);

        const authenticationDetails = new AuthenticationDetails({
            Username: formValues.email,
            Password: formValues.password,
        });

        const userPool = new CognitoUserPool({
            UserPoolId: AppCompany.config?.auth.userPoolId as string,
            ClientId: AppCompany.config?.auth.userPoolClientId as string
        });

        const cognitoUser = new CognitoUser({
            Pool: userPool,
            Username: formValues.email,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess(result) {
                const token = result.getIdToken().getJwtToken();
                onSuccess(token, cognitoUser);
            },

            newPasswordRequired(userAttr) {
                delete userAttr.email_verified;
                delete userAttr.phone_number_verified;
                delete userAttr.email;

                userAttr.name = userAttr.name ? userAttr.name : "User";

                setFirstLogin(true);
                setUserAttributes(userAttr);
                setCognitoObj(cognitoUser);
            },

            onFailure(err) {
                notify({ type: "error", title: "Login Error!", body: err.message });
                setIsLoading(false);
            },
        });
    };

    const newPasswordHandler = () => {
        if (!cognitoObj) return;

        if (resetFormValues.newPassword !== resetFormValues.confirmPassword) {
            notify({
                type: "error",
                title: "Reset Password Error!",
                body: "The password confirmation does not match",
            });
            return;
        }
        cognitoObj.completeNewPasswordChallenge(resetFormValues.newPassword, userAttributes, {
            onSuccess(result) {
                const token = result.getIdToken().getJwtToken();
                onSuccess(token, cognitoObj);
            },

            onFailure(err) {
                notify({ type: "error", title: "Login Error!", body: err.message });
                setIsLoading(false);
            },
        });
    };

    const onSuccess = (jwt: string, cognitoUser: CognitoUser) => {
        cognitoUser.getUserAttributes(async (err, result) => {
            if (err) return console.log(err.message);
            const fullName = result?.find(({ Name, Value }) => (Name === "name" ? Value : false))?.Value || "";
            const firstName = result?.find(({ Name, Value }) => (Name === "given_name" ? Value : false))?.Value || fullName.split(" ")[0] || "";

            try {
                const info = await getSessionDetails({ jwt })
                const user: User = {
                    jwt,
                    info,
                    fullName,
                    firstName,
                    sub: result?.find(({ Name, Value }) => (Name === "sub" ? Value : ''))?.Value || '',
                    email: result?.find(({ Name, Value }) => (Name === "email" ? Value : ''))?.Value || '',
                    timezone: result?.find(({ Name, Value }) => (Name === "locale" ? Value : ''))?.Value || '',
                    picture: result?.find(({ Name, Value }) => (Name === "picture" ? Value : ''))?.Value || '',
                    dob: result?.find(({ Name, Value }) => (Name === "birthdate" ? Value : false))?.Value || '',
                    address: result?.find(({ Name, Value }) => (Name === "address" ? Value : false))?.Value || '',
                    website: result?.find(({ Name, Value }) => (Name === "website" ? Value : false))?.Value || '',
                    mobile: result?.find(({ Name, Value }) => (Name === "phone_number" ? Value : ''))?.Value || '',
                    nickName: result?.find(({ Name, Value }) => (Name === "nickname" ? Value : false))?.Value || firstName,
                    verified: result?.find(({ Name, Value }) => (Name === "email_verified" ? Value : ''))?.Value === 'true',
                    lastName: result?.find(({ Name, Value }) => (Name === "family_name" ? Value : false))?.Value || fullName.split(" ").pop() || "",
                }

                login(user);
                navigate(dashboard({ user }));

            } catch (error) {
                navigate(`/500`);
            }
        });
    };

    return (
        <Group style={{ maxWidth: 400, margin: "50px auto" }}>
            {!isFirstLogin ? (
                <form onSubmit={onSubmit(authenticate)}>
                    <Group>
                        <h5>LOGIN</h5>
                        <TextInput
                            isRequired
                            type="email"
                            label="Email"
                            placeholder="Email"
                            error={errors.email}
                            value={formValues.email}
                            onChange={onChange("email")}
                        />
                        <TextInput
                            isRequired
                            type="password"
                            label="Password"
                            placeholder="Password"
                            error={errors.password}
                            value={formValues.password}
                            onChange={onChange("password")}
                        />
                        <Button text="LOGIN" type="submit" disabled={isLoading} loading={isLoading} />
                    </Group>
                </form>
            ) : (
                <form onSubmit={onResetSubmit(newPasswordHandler)}>
                    <Group>
                        <h5>RESET PASSWORD</h5>

                        <TextInput
                            isRequired
                            type="password"
                            label="Password"
                            placeholder="Password"
                            error={resetErrors.newPassword}
                            value={resetFormValues.newPassword}
                            onChange={onResetChange("newPassword")}
                        />

                        <TextInput
                            isRequired
                            type="password"
                            label="Confirm Password"
                            placeholder="Confirm Password"
                            error={resetErrors.confirmPassword}
                            value={resetFormValues.confirmPassword}
                            onChange={onResetChange("confirmPassword")}
                        />
                        <Button text="Reset Password" type="submit" />
                    </Group>
                </form>
            )}
        </Group>
    );
};
