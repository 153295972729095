"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserInformationUpdateProps = void 0;
exports.UserInformationUpdateProps = [
    "bio",
    "dateFormat",
    "nationality",
    "fullAddress",
    "settings.security",
    "lastPasswordChangedOn",
    "settings.notifications",
    "stats.courses.favorites.pop",
    "stats.courses.favorites.push",
];
