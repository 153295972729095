"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionFrequency = exports.MoreUserDetailsSessionStatus = exports.RoleTypes = void 0;
var RoleTypes;
(function (RoleTypes) {
    RoleTypes["ADMIN"] = "admin";
    RoleTypes["TUTOR"] = "tutor";
    RoleTypes["STUDENT"] = "student";
    RoleTypes["SUPER_ADMIN"] = "super-admin";
})(RoleTypes || (exports.RoleTypes = RoleTypes = {}));
var MoreUserDetailsSessionStatus;
(function (MoreUserDetailsSessionStatus) {
    MoreUserDetailsSessionStatus["ENDED"] = "ended";
    MoreUserDetailsSessionStatus["STARTED"] = "started";
})(MoreUserDetailsSessionStatus || (exports.MoreUserDetailsSessionStatus = MoreUserDetailsSessionStatus = {}));
var SubscriptionFrequency;
(function (SubscriptionFrequency) {
    SubscriptionFrequency["Monthly"] = "monthly";
})(SubscriptionFrequency || (exports.SubscriptionFrequency = SubscriptionFrequency = {}));
