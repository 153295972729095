import { RecordTypes, Test, TestStatus } from "shared-library";
import { AdminTestEditor } from "./AdminTestEditor";

export const AdminTestsNew = () => {
    const emptyTest: Test = {
        id: "",
        title: "",
        sortKey: "",
        companyId: "",
        questions: [],
        creatorSub: "",
        description: "",
        creationDate: "",
        status: TestStatus.DRAFT,
        recordType: RecordTypes.COMPANYTEST,
        stats: {
            passed: 0,
            failed: 0,
            pending: 0,
            inProgress: 0,
            totalUniqueAttempts: 0,
        },
        settings: {
            maxAttempt: 1,
            durationInMins: 30,
            disableTextCopy: true,
            allowAnswerChange: false,
            passMarkInPercentage: 80,
            allowBackNavigation: false,
            shuffleOptionArrangements: false,
            shuffleQuestionArrangements: false,
            autoShowCorrectAnswerAfterAnswering: false,
            allowForwardNavigationWithUnansweredPreviousQuestions: false,
        }
    }
    return (<AdminTestEditor testInput={emptyTest} />);
}
