"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageFiltersValues = void 0;
var PageFiltersValues;
(function (PageFiltersValues) {
    PageFiltersValues["azSort"] = "azSort";
    PageFiltersValues["zaSort"] = "zaSort";
    PageFiltersValues["latestLast"] = "latestLast";
    PageFiltersValues["latestFirst"] = "latestFirst";
})(PageFiltersValues || (exports.PageFiltersValues = PageFiltersValues = {}));
