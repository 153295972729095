import { useParams } from "react-router-dom";
import { useApi } from "../../hooks/use-api";
import { useQuery } from "@tanstack/react-query";
import { NotFoundError } from "../ErrorPage/Errors";
import { AdminTestEditor } from "./AdminTestEditor";
import { Spinner } from "../../components/Spinner/Spinner";

export const AdminTestsEdit = () => {

    const { getTest } = useApi()
    const { testId = "" } = useParams()
    const { data: initialTest, isLoading } = useQuery({
        queryKey: ["test", testId],
        queryFn: () => getTest(testId)
    })

    if (!initialTest) {
        return isLoading ? <Spinner text="Fetching Test" style={{ margin: 60 }} /> : <NotFoundError />
    }
    return (<AdminTestEditor testInput={initialTest} />);
}