import { FC, useState } from "react";
import '@smastrom/react-rating/style.css';
import { Button } from "../../Form/Button";
import { OffCanvas } from "react-offcanvas";
import { useTheme } from "styled-components";
import { useApi } from "../../../hooks/use-api";
import { Rating } from "@smastrom/react-rating";
import { Group } from "../../Layout/Group/Group";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "../../../hooks/use-form.hook";
import { EnvelopePlus, X } from "react-bootstrap-icons";
import { TextArea } from "../../Form/TextInput/TextInput";
import { Review, UserStatEnrollment } from "shared-library";
import { useNotification } from "../../../hooks/use-notification.hook";
import { StyledOffCanvasMenu, StyledH5, StyledHR, StyledCreationWrapper, StyledBadge } from "../../Elements";

export const ReviewCourse: FC<{
    open: boolean;
    review?: Review;
    courseTitle: string;
    enrollment: UserStatEnrollment;
    setOpen: (open: boolean) => void;
    onSuccess?: (review: Review) => void;
}> = ({
    open,
    review,
    setOpen,
    onSuccess,
    enrollment,
    courseTitle,
}) => {

        const theme = useTheme();
        const { submitAReview } = useApi();
        const { notify } = useNotification();

        const [rating, setRating] = useState(review?.rating || 0);
        const { onSubmit, errors, onChange, formValues, reset } = useForm({
            defaultValues: {
                description: review?.description || "",
            },
            validations: {
                description: { max: 1000, required: true },
            }
        })
        const { isPending: mutatingReviewSubmissionAsync, mutateAsync: mutateReviewSubmissionAsync } = useMutation({
            mutationFn: submitAReview,
        });

        const sendReviewSubmission = async (data: { description: string; }) => {
            try {
                const reviewUpdated = await mutateReviewSubmissionAsync({ reviewId: review?.id, courseId: enrollment.courseId, description: data.description, rating })
                notify({ type: "success", title: "Successful!", body: `Review has been successfully submitted!` });
                reset()
                onSuccess && onSuccess(reviewUpdated)
                setOpen(false)
            } catch (error) {
            }
        }

        return (
            <OffCanvas
                transitionDuration={300}
                isMenuOpened={open}
                position={"right"}
                effect={"overlay"}
            >
                <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                    <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                        <StyledH5>
                            <EnvelopePlus size={20} style={{ marginRight: '5px' }} /> Review
                        </StyledH5>
                        <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                    </Group>
                    <StyledHR />
                    <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                        <StyledCreationWrapper>
                            <form onSubmit={onSubmit(sendReviewSubmission)}>
                                <Group gap="xs" direction="row">
                                    {<StyledBadge>{courseTitle}</StyledBadge>}
                                </Group>
                                <StyledHR />
                                <Group gap='sm'>
                                    <Rating
                                        halfFillMode="svg"
                                        style={{ maxWidth: 180 }}
                                        value={rating}
                                        onChange={setRating}
                                    />
                                    <TextArea
                                        isRequired
                                        label="Description"
                                        parentStyle={{ maxWidth: '100%' }}
                                        value={formValues.description}
                                        placeholder={`Description`}
                                        error={errors.description}
                                        onChange={onChange("description")}
                                    />
                                    <Button text={"Review"} type='submit' disabled={!formValues.description.length || !rating} loading={mutatingReviewSubmissionAsync} />
                                </Group>
                            </form>
                        </StyledCreationWrapper>
                    </Group>
                </StyledOffCanvasMenu>
            </OffCanvas>
        )
    }

export const ReviewTutor: FC<{ open: boolean; setOpen: (open: boolean) => void; onSuccess?: () => void; tutor: { fullName: string; sub: string; } }> = ({ open, setOpen, onSuccess, tutor }) => {

    const theme = useTheme();
    const { submitAReview } = useApi();
    const { notify } = useNotification();

    const [rating, setRating] = useState(0)
    const { onSubmit, errors, onChange, formValues, reset } = useForm({
        defaultValues: {
            description: "",
        },
        validations: {
            description: { max: 1000, required: true },
        }
    })
    const { isPending: mutatingReviewSubmissionAsync, mutateAsync: mutateReviewSubmissionAsync } = useMutation({
        mutationFn: submitAReview,
    });

    const sendReviewSubmission = async (data: { description: string; }) => {
        try {
            await mutateReviewSubmissionAsync({ tutorId: tutor.sub, description: data.description, rating })
            notify({ type: "success", title: "Successful!", body: `Review has been successfully submitted!` });
            reset()
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <EnvelopePlus size={20} style={{ marginRight: '5px' }} /> Review
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper>
                        <form onSubmit={onSubmit(sendReviewSubmission)}>
                            <Group gap="xs" direction="row">
                                {<StyledBadge>{tutor.fullName}</StyledBadge>}
                            </Group>
                            <StyledHR />
                            <Group gap='sm'>
                                <Rating
                                    halfFillMode="svg"
                                    style={{ maxWidth: 180 }}
                                    value={rating}
                                    onChange={setRating}
                                />
                                <TextArea
                                    isRequired
                                    label="Description"
                                    parentStyle={{ maxWidth: '100%' }}
                                    value={formValues.description}
                                    placeholder={`Description`}
                                    error={errors.description}
                                    onChange={onChange("description")}
                                />
                                <Button text={"Review"} type='submit' disabled={!formValues.description.length || !rating} loading={mutatingReviewSubmissionAsync} />
                            </Group>
                        </form>
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}