"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateIsBetween = exports.getLastXMonths = exports.getLastXDays = exports.convertLocaleToUTCByOffset = exports.convertTimeToLocale = exports.currentFullDate = exports.today = exports.defaultDateFormat = void 0;
const moment = require("moment");
const momentTZ = require("moment-timezone");
exports.defaultDateFormat = "YYYY-MM-DD HH:mm:ss";
const today = () => moment().format("YYYY-MM-DD");
exports.today = today;
/**
 *
 * @returns current date time in utc
 */
const currentFullDate = () => moment().format(exports.defaultDateFormat);
exports.currentFullDate = currentFullDate;
/**
 * converts UTC datetime to user's locale
 * @param date
 * @param timezone
 * @param format
 * @returns
 */
const convertTimeToLocale = (date, timezone, format = exports.defaultDateFormat) => {
    const jun = momentTZ(date);
    return jun.tz(timezone).format(format);
};
exports.convertTimeToLocale = convertTimeToLocale;
/**
 * Converts user's time locale to utc
 * @param date
 * @param timezone
 * @param format
 * @returns
 */
const convertLocaleToUTCByOffset = (date, timezone, format = exports.defaultDateFormat) => {
    const convertLocaleToUTC = (date, timezone, format = exports.defaultDateFormat) => {
        return moment(moment.tz(date, format, timezone).toISOString()).format(format);
    };
    const janOffset = moment.tz({ month: 0, day: 1 }, timezone).utcOffset();
    const junOffset = moment.tz({ month: 5, day: 1 }, timezone).utcOffset();
    const offset = Math.abs(junOffset - janOffset);
    return convertLocaleToUTC(moment(date).add(offset, "minutes").format(format), timezone, format);
};
exports.convertLocaleToUTCByOffset = convertLocaleToUTCByOffset;
const getLastXDays = (numDays, startFrom) => {
    const formattedDates = [];
    for (let i = 0; i < numDays; i++) {
        formattedDates.push(moment(startFrom).subtract(i, 'days').format('YYYY-MM-DD'));
    }
    return formattedDates.reverse();
};
exports.getLastXDays = getLastXDays;
const getLastXMonths = (months, startFrom) => {
    const formattedDates = [];
    for (let i = 0; i < months; i++) {
        formattedDates.push(moment(startFrom).subtract(i, 'months').format('YYYY-MM'));
    }
    return formattedDates.reverse();
};
exports.getLastXMonths = getLastXMonths;
const dateIsBetween = (from, to, now) => {
    if (!now) {
        now = moment().utc();
    }
    const exp = moment.utc(to);
    const start = moment.utc(from);
    return now.isBetween(start, exp, "days", "[]");
};
exports.dateIsBetween = dateIsBetween;
