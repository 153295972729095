"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CourseLectureResourceTypes = exports.CourseGroupResourceTypes = exports.CourseSteps = exports.CourseLevels = exports.CourseDurationIn = exports.CourseTabPills = exports.CourseStatus = void 0;
var CourseStatus;
(function (CourseStatus) {
    CourseStatus["DRAFT"] = "draft";
    CourseStatus["ACTIVE"] = "active";
    CourseStatus["ARCHIVED"] = "archived";
})(CourseStatus || (exports.CourseStatus = CourseStatus = {}));
var CourseTabPills;
(function (CourseTabPills) {
    CourseTabPills["PENDING"] = "pending";
    CourseTabPills["ENROLLED"] = "enrolled";
    CourseTabPills["COMPLETED"] = "completed";
    CourseTabPills["BOOKMARKED"] = "bookmarked";
    CourseTabPills["INPROGRESS"] = "inProgress";
})(CourseTabPills || (exports.CourseTabPills = CourseTabPills = {}));
var CourseDurationIn;
(function (CourseDurationIn) {
    CourseDurationIn["DAYS"] = "days";
    CourseDurationIn["YEARS"] = "years";
    CourseDurationIn["WEEKS"] = "weeks";
    CourseDurationIn["MONTHS"] = "months";
})(CourseDurationIn || (exports.CourseDurationIn = CourseDurationIn = {}));
var CourseLevels;
(function (CourseLevels) {
    CourseLevels["EXPERT"] = "expert";
    CourseLevels["BEGINNER"] = "beginner";
    CourseLevels["INTERMEDIATE"] = "intermediate";
})(CourseLevels || (exports.CourseLevels = CourseLevels = {}));
var CourseSteps;
(function (CourseSteps) {
    CourseSteps["Settings"] = "settings";
    CourseSteps["Curriculum"] = "curriculum";
    CourseSteps["CourseMedia"] = "course-media";
    CourseSteps["BasicInformation"] = "basic-information";
})(CourseSteps || (exports.CourseSteps = CourseSteps = {}));
exports.CourseGroupResourceTypes = {
    AUDIOS: "audios",
    VIDEOS: "videos",
    PHOTOS: "photos",
    DOCUMENTS: "documents",
    PRESENTATIONS: "presentations",
};
exports.CourseLectureResourceTypes = {
    TESTS: "tests",
    ARTICLES: "articles",
    ...exports.CourseGroupResourceTypes
};
