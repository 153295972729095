import { Spinner } from "./Button";
import styled from "styled-components";
import { keyboardFocusOnly } from "../../Elements";
import { CSSProperties, MouseEvent, ReactNode } from "react";

interface ButtonProps {
    text?: string;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    disabled?: boolean;
    loading?: boolean;
    style?: CSSProperties;
    type?: "button" | "submit";
    classNamesArr?: Array<string>;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled.button`
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary3};
    padding: 0.4rem 1rem;
    border: none;
    cursor: pointer;
    height: 2rem;
    line-height: 1.2rem;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    transition: all 0.2s;
    text-align: left;
    border-radius: 3px;
    ${keyboardFocusOnly}

    .icon {
        padding-top: 2px;
        color: ${(props) => props.theme.colors.primary};
    }

    &:hover {
        background-color: ${(props) => props.theme.colors.primary4};
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;
    }
    
    &:active {
        transform: scale(0.99);
        background-color: ${(props) => props.theme.colors.primary4};
    }

    &.success {
        color: ${(props) => props.theme.colors.success};
        background-color: ${(props) => props.theme.colors.success4};
    
        &:hover {
            background-color: ${(props) => props.theme.colors.success4};
        }

        .icon {
            color: ${(props) => props.theme.colors.success};
        }
    }

    &.danger {
        color: ${(props) => props.theme.colors.danger};
        background-color: ${(props) => props.theme.colors.danger4};
        
        &:hover {
            background-color: ${(props) => props.theme.colors.danger4};
        }

        .icon {
            color: ${(props) => props.theme.colors.danger};
        }
    }

    .text {
        overflow-wrap: break-word;
    }

    &:disabled {
        background-color: ${(props) => props.theme.colors.primary4};
        color: ${(props) => props.theme.colors.primary2};
        cursor: not-allowed;
    
        .icon {
            color: ${(props) => props.theme.colors.primary2};
        }
    
        &.danger {
          background-color: ${(props) => props.theme.colors.danger};
    
          &:hover {
            background-color: ${(props) => props.theme.colors.danger4};
          }
        }
    
        &:active {
          transform: none;
          background-color: ${(props) => props.theme.colors.primary4};
        }
    }  
`

export const StepButton = (props: ButtonProps) => {
    const {
        text,
        leftIcon,
        rightIcon,
        disabled = false,
        loading = false,
        style = {},
        classNamesArr = [],
        type = "button",
        onClick,
    } = props;

    const classNames = ["btn", ...classNamesArr];

    return (
        <StyledButton
            className={classNames.join(" ")}
            onClick={onClick}
            disabled={disabled || loading}
            style={style}
            type={type}
        >
            {loading && <Spinner />}
            {!loading && leftIcon && <span className="icon">{leftIcon}</span>}
            {!loading && <span className="text">{text}</span>}
            {!loading && rightIcon && <span className="icon">{rightIcon}</span>}
        </StyledButton>
    );
};
