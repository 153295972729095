"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCourseChapterProgressPercentage = exports.getCourseProgressPercentage = void 0;
const enrollment_enum_1 = require("../enums/enrollment.enum");
const percentageCalculator_util_1 = require("./percentageCalculator.util");
const getCourseProgressPercentage = (course, enrollments) => {
    const enrollment = enrollments.find(({ courseId, status }) => courseId === course.id && status === enrollment_enum_1.EnrollmentStatus.APPROVED);
    if (!enrollment)
        return 0;
    let lectureIds = [];
    let completedLectureIds = [];
    for (const chapter of course.curriculum.chapters) {
        for (const lecture of chapter.lectures) {
            lectureIds.push(lecture.lectureId);
            if (enrollment.lectures.find(({ lectureId, endDate }) => lectureId === lecture.lectureId && endDate)) {
                completedLectureIds.push(lecture.lectureId);
            }
        }
    }
    return (0, percentageCalculator_util_1.percentageCalculator)(completedLectureIds.length, lectureIds.length);
};
exports.getCourseProgressPercentage = getCourseProgressPercentage;
const getCourseChapterProgressPercentage = (courseChapter, enrollment) => {
    let lectureIds = [];
    let completedLectureIds = [];
    for (const lecture of courseChapter.lectures) {
        lectureIds.push(lecture.lectureId);
        if (enrollment.lectures.find(({ lectureId, endDate }) => lectureId === lecture.lectureId && endDate)) {
            completedLectureIds.push(lecture.lectureId);
        }
    }
    return (0, percentageCalculator_util_1.percentageCalculator)(completedLectureIds.length, lectureIds.length);
};
exports.getCourseChapterProgressPercentage = getCourseChapterProgressPercentage;
