import styled from "styled-components";
import { randomID } from "shared-library";
import styles from "./InputContainer.module.scss";
import { CSSProperties, ReactNode, useMemo } from "react";
import { StyledErrorText, StyledIsRequired } from "../../Elements";
import { FormComponentProps } from "../../../types/form-component-props";

export interface InputContainerProps extends FormComponentProps {
    label?: string;
    error?: string;
    isRequired?: boolean;
    description?: string;
    style?: CSSProperties;
    rightIcon?: ReactNode;
    render: (ID: string) => ReactNode;
}

const StyledLabel = styled.label`
    font-weight: 300;
    font-size: 0.9rem;
    margin-bottom: -5px;
    color: ${(props) => props.theme.colors.gray4};
`

const StyledRightIcon = styled.span`
    position: absolute;
    top: 7px;
    right: 10px;
    color: ${props => props.theme.colors.gray};
`

export const InputContainer = (props: InputContainerProps) => {
    const { label, description, error, style = {}, isRequired = false, render, rightIcon } = props;
    const id = useMemo(randomID, []);

    return (
        <div className={styles.container} style={style}>
            {label && (
                <StyledLabel htmlFor={id}>
                    {label}{isRequired && <StyledIsRequired>*</StyledIsRequired>}
                    {description && <div className="description">{description}</div>}
                </StyledLabel>
            )}
            {
                rightIcon && <StyledRightIcon>
                    {rightIcon}
                </StyledRightIcon>
            }
            {render(id)}
            {error && <StyledErrorText style={{ marginTop: '-7px' }}>{error}</StyledErrorText>}
        </div>
    );
};
