import { useState } from "react";
import { Group } from "../../Layout/Group/Group";
import { useTheme } from "../../../hooks/use-theme.hook";
import styled, { CSSProperties } from "styled-components";
import { OffCanvas, OffCanvasBody } from "react-offcanvas";
import { InputContainer } from "../InputContainer/InputContainer";
import { FolderFile, CourseGroupResourceTypes } from "shared-library";
import { FileTypesFromCourseGroupResourceTypes } from "../../../utils/data";
import { FolderNavigation } from "../../App/FolderNavigation/FolderNavigation";
import { StyledBanner, StyledH5, StyledHR, StyledOffCanvasMenu, StyledSmall, StyledSpan, grow } from "../../Elements";
import { CameraVideo, CameraVideoFill, Dot, FileEarmarkRichtext, FileEarmarkSlides, FileEarmarkSlidesFill, FileText, FileTextFill, Images, Mic, MicFill, X } from "react-bootstrap-icons";

interface FileInputProps {
    label?: string;
    error?: string;
    file?: FolderFile;
    isRequired?: boolean;
    description?: string;
    showErrorMsg?: boolean;
    parentStyle?: CSSProperties;
    onChange?: (file?: FolderFile) => void;
    fileType: keyof typeof CourseGroupResourceTypes
}

const StyledFileUploader = styled.div`
        ${grow}
        border-radius: 3px;
        margin-bottom: 10px;
        padding: 14px 30px;
        min-width: 100%;
        max-width: 100%;
        cursor: pointer;
        color: ${props => props.theme.colors.grey5};
        background: ${props => props.theme.colors.grey6};
        border: 1px solid ${props => props.theme.colors.gray};
        color: ${props => props.theme.colors.grey5};

        &.hasError {
            border: 1px solid ${props => props.theme.colors.danger};
        }
`

export const FileInput = (props: FileInputProps) => {

    const theme = useTheme();
    const { fileType, file, onChange, label, description, showErrorMsg = true, error, isRequired, parentStyle } = props;
    const [canvasOpen, setCanvasOpen] = useState(false)

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={canvasOpen}
            position={"right"}
            effect={"overlay"}
        >
            <OffCanvasBody>
                <InputContainer
                    label={label}
                    description={description}
                    error={showErrorMsg ? error : ""}
                    isRequired={isRequired}
                    style={parentStyle}
                    render={(ID) => (
                        <div id={ID}>
                            <StyledFileUploader className={error ? "hasError" : ""} onClick={() => setCanvasOpen(true)}>
                                <Group direction="row" justify="space-between" wrap="nowrap" align="center" gap="sm">
                                    <Group direction="row" align="center" wrap="nowrap">
                                        {
                                            fileType === "PHOTOS" && <>
                                                {
                                                    file?.url ? <div style={{ width: '150px' }}>
                                                        <StyledBanner $url={file.url} $height="50px" />
                                                    </div> : <img src="/assets/images/emptyImageIcon.png" alt="Empty Icon" width={25} height={25} />
                                                }
                                            </>
                                        }

                                        {
                                            fileType === "VIDEOS" && <>
                                                {
                                                    file?.url ? <CameraVideoFill size={28} /> : <CameraVideo size={28} />
                                                }
                                            </>
                                        }

                                        {
                                            fileType === "AUDIOS" && <>
                                                {
                                                    file?.url ? <MicFill size={28} /> : <Mic size={28} />
                                                }
                                            </>
                                        }

                                        {
                                            fileType === "DOCUMENTS" && <>
                                                {
                                                    file?.url ? <FileTextFill size={28} /> : <FileText size={28} />
                                                }
                                            </>
                                        }

                                        {
                                            fileType === "PRESENTATIONS" && <>
                                                {
                                                    file?.url ? <FileEarmarkSlidesFill size={28} /> : <FileEarmarkSlides size={28} />
                                                }
                                            </>
                                        }

                                        <Group direction="row" gap="xs" align="center">
                                            {
                                                file?.fileName ? <>
                                                    <StyledSmall style={{ color: theme.colors.grey }}>{file.fileName}</StyledSmall>
                                                    <Dot size={18} />
                                                </> : null
                                            }
                                            <StyledSmall style={{ color: theme.colors.grey }}>
                                                Click to change {props.label?.toLowerCase() || 'file'}
                                            </StyledSmall>
                                        </Group>
                                    </Group>
                                    <div style={{ textAlign: 'right' }}>
                                        <StyledSmall>{FileTypesFromCourseGroupResourceTypes(fileType).join(',')}</StyledSmall>
                                        {
                                            !props.isRequired && file?.url && <div style={{
                                                textAlign: 'right',
                                                cursor: 'pointer',
                                                color: theme.colors.danger
                                            }} onClick={(e) => { e.stopPropagation(); onChange && onChange() }}>
                                                <Group direction="row" wrap="nowrap" gap="xs" align="center">
                                                    <X />
                                                    <StyledSmall>
                                                        <StyledSpan>
                                                            Remove
                                                        </StyledSpan>
                                                    </StyledSmall>
                                                </Group>
                                            </div>
                                        }
                                    </div>
                                </Group>
                            </StyledFileUploader>
                        </div>
                    )}
                />
            </OffCanvasBody>
            <StyledOffCanvasMenu className={canvasOpen ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        {
                            fileType === "PHOTOS" && <><Images size={20} style={{ marginRight: '5px' }} /> Photos</>
                        }
                        {
                            fileType === "VIDEOS" && <><CameraVideo size={24} style={{ marginRight: '5px' }} /> Videos</>
                        }
                        {
                            fileType === "AUDIOS" && <><Mic size={24} style={{ marginRight: '5px' }} /> Audios</>
                        }
                        {
                            fileType === "DOCUMENTS" && <><FileEarmarkRichtext size={24} style={{ marginRight: '5px' }} /> Documents</>
                        }
                        {
                            fileType === "PRESENTATIONS" && <><FileEarmarkSlides size={24} style={{ marginRight: '5px' }} /> Presentations</>
                        }
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setCanvasOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <FolderNavigation isPage={false} folderId={fileType} onInsert={(file) => { onChange && onChange(file); setCanvasOpen(false) }} />
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}