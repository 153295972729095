import { ceil } from "lodash";

export const durationFromVideoFile = async (file: File): Promise<number> => {
    return new Promise(resolve => {
        var video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            resolve(ceil(video.duration))
        }
        video.src = URL.createObjectURL(file);
    })
}