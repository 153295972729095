import { useContext } from "react";
import { themes } from "../utils/data";
import { AppThemes } from "shared-library";
import { CompanyContext } from "../context/company";

export const useTheme = () => {
    const { state: AppCompany } = useContext(CompanyContext);
    if (AppCompany.company?.theme) {
        return AppCompany.company.theme
    }
    return themes.find(({ isDefault }) => isDefault) as AppThemes;
};