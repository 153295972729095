import { AuthContext } from '../context/auth';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext } from 'react';
import { RoleTypes, User } from "shared-library";
import { useApi } from './use-api';

interface DashboardProps {
    user?: User;
}

export const useRouteResolver = () => {

    const navigate = useNavigate()
    const { manageSessionProfile } = useApi()
    const { state, setUser } = useContext(AuthContext)

    const dashboard = useCallback(({ user }: DashboardProps): string => {
        if (user) {
            if (user.info.session.role) {
                return `/${user.info.session.role}/dashboard`
            }
            if (!user.info.session.roles.length) {
                return `/access-denied`
            }
            if (user.info.session.roles.length === 1) {
                activateProfile(user.info.session.roles[0], false)
                return `/${user.info.session.roles[0]}/dashboard`
            }
            return `/roles`
        }

        if (state.user) {
            if (state.user.info.session.role) {
                return `/${state.user.info.session.role}/dashboard`
            }
            if (!state.user.info.session.roles.length) {
                return `/access-denied`
            }
            if (state.user.info.session.roles.length === 1) {
                activateProfile(state.user.info.session.roles[0], false)
                return `/${state.user.info.session.roles[0]}/dashboard`
            }
            return `/roles`
        }
        return `/login`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user])

    const activateProfile = useCallback(async (role: RoleTypes, navigateAfter = true) => {
        if (state.user) {
            await manageSessionProfile(role)
            setUser({
                ...state.user,
                info: {
                    ...state.user.info,
                    session: {
                        ...state.user.info.session,
                        role
                    }
                }
            })
            if (navigateAfter) {
                navigate(`/${role}/dashboard`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user])

    const deactivateProfile = useCallback(async () => {
        if (state.user) {
            await manageSessionProfile()
            setUser({
                ...state.user,
                info: {
                    ...state.user.info,
                    session: {
                        ...state.user.info.session,
                        role: undefined
                    }
                }
            })
            navigate(`/roles`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user])

    return { dashboard, activateProfile, deactivateProfile }
}