import moment from "moment";
import { isEmpty } from "lodash";
import { useContext } from "react";
import CountUp from "react-countup";
import styled from "styled-components";
import { RoleTypes } from "shared-library";
import { useApi } from "../../hooks/use-api";
import { AuthContext } from "../../context/auth";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "../../hooks/use-theme.hook";
import { Page } from "../../components/Layout/Page/Page";
import { Spinner } from "../../components/Spinner/Spinner";
import { Charts } from "../../components/App/Charts/Charts";
import { Group } from "../../components/Layout/Group/Group";
import { StatsCard } from "../../components/App/Dashboard/StatsCard";
import { TopMenu, TopMenuProfile } from "../../components/App/TopMenu";
import { CourseCard } from "../../components/App/CourseCards/CourseCards";
import { AppNotifications } from "../../components/App/AppNotifications/AppNotifications";
import { DashboardCalendar } from "../../components/App/DashboardCalendar/DashboardCalendar";
import { BookHalf, Award, ClockHistory, GraphDownArrow, GraphUpArrow, ListCheck, People } from "react-bootstrap-icons";
import { StyledContentWrapper, StyledH5, StyledChapterChildTitle, StyledSmall, StyledSpan, forPhoneOnly, StyledStatsCard } from "../../components/Elements";

const StyledGroup = styled(Group)`
    .left {
        width: 35%;
        ${forPhoneOnly("width: 100%; order: 2;")}
    }

    .right {
        width: 63%;
        ${forPhoneOnly("width: 100%; order: 1;")}
    }

    .stats-card {
        flex: 1;
        ${forPhoneOnly("order: 2;")}
    }

    .profile-card {
        ${forPhoneOnly("order: 1;")}
    }
`

export const AdminDashboard = () => {

    const theme = useTheme();
    const { getDashboardData } = useApi();
    const { state } = useContext(AuthContext);
    const { data, isLoading } = useQuery({
        queryKey: ["dashboard"],
        queryFn: getDashboardData
    })

    const lastActive = ({ value, mainColor }: { value: string; mainColor: string; }): JSX.Element => {
        const currentTime = moment();
        const lastSeen = moment(value);
        const hoursSince = currentTime.diff(lastSeen, 'hours') % 24;
        const daysSince = currentTime.diff(lastSeen, 'days');

        return <Group wrap="nowrap" gap="none" align="center" direction="row">
            {
                daysSince > 0 && <>
                    <StyledH5 style={{ color: mainColor }}>
                        <CountUp end={daysSince} />
                    </StyledH5>
                    <StyledSmall style={{ paddingTop: '8px' }}>d</StyledSmall>
                </>
            }
            {
                hoursSince > 0 && <>
                    <StyledH5 style={{ color: mainColor }}>
                        <CountUp end={hoursSince} />
                    </StyledH5>
                    <StyledSmall style={{ paddingTop: '8px' }}>h</StyledSmall>
                </>
            }

        </Group>
    }

    if (isLoading || !data) {
        return <Spinner text="Preparing Dashboard" style={{ margin: 60 }} />
    }

    return (
        <Page>
            <StyledGroup direction="row" justify="space-between" gap="sm">
                <StyledContentWrapper className="left slideInLeft">
                    <Group>
                        <TopMenu hideProfile hideNotification titles={[{
                            text: 'Dashboard',
                        }]} />
                        <Group gap="xs">
                            <br />
                            <DashboardCalendar />
                        </Group>
                    </Group>
                </StyledContentWrapper>
                <Group className="right slideInRight">
                    <Group direction="row" justify="space-between">
                        <div className="stats-card">
                            <Group gap="sm">
                                <Group gap="none">
                                    <StyledH5>Hello {state.user?.nickName}</StyledH5>
                                    <StyledSpan>WELCOME TO THE DASHBOARD</StyledSpan>
                                </Group>

                                {
                                    state.user?.info.session.role === RoleTypes.ADMIN && <StatsCard
                                        title="Tutors"
                                        icon={<People size={22} />}
                                        value={<CountUp end={data.tutors} />}
                                        mainColor={theme.colors.black}
                                        miniColor={theme.colors.black3}
                                    />
                                }
                                {
                                    state.user?.info.session.role !== RoleTypes.STUDENT && <StatsCard
                                        title="Students"
                                        icon={<People size={22} />}
                                        value={<CountUp end={data.students} />}
                                        mainColor={theme.colors.success}
                                        miniColor={theme.colors.success4}
                                    />
                                }
                                <StatsCard
                                    title="Courses"
                                    icon={<BookHalf size={22} />}
                                    value={<CountUp end={data.courses} />}
                                    mainColor={theme.colors.secondary}
                                    miniColor={theme.colors.secondary4}
                                />
                                <StatsCard
                                    title="Certificates"
                                    icon={<Award size={22} />}
                                    value={<CountUp end={data.certificates} />}
                                    mainColor={theme.colors.success}
                                    miniColor={theme.colors.success4}
                                />
                                {
                                    state.user?.info.session.role !== RoleTypes.STUDENT && <StatsCard
                                        title="Tests"
                                        icon={<ListCheck size={22} />}
                                        value={<CountUp end={data.tests} />}
                                        mainColor={theme.colors.primary}
                                        miniColor={theme.colors.primary4}
                                    />
                                }
                                {
                                    !!state.user?.info.session?.lastSession.endDate && <StatsCard
                                        title="Last active"
                                        icon={<ClockHistory size={22} />}
                                        mainColor={theme.colors.danger}
                                        miniColor={theme.colors.danger4}
                                        value={lastActive({ value: state.user?.info.session?.lastSession.endDate as string, mainColor: theme.colors.danger })}
                                    />
                                }

                                <AppNotifications viewType="lg" />
                                {
                                    !isEmpty(data.highlightedCourses) && <StyledStatsCard gap="none" style={{
                                        padding: '10px',
                                    }}>
                                        {
                                            data.highlightedCourses.map(course => <CourseCard forceColumn={true} key={course.id} course={course} />)
                                        }
                                    </StyledStatsCard>
                                }
                            </Group>
                        </div>
                        <Group className="profile-card">
                            <TopMenuProfile viewType="lg" />
                            <StyledStatsCard gap="sm" style={{
                                padding: '10px',
                            }}>
                                <StyledChapterChildTitle>Past week's activities</StyledChapterChildTitle>
                                <Group align="center">
                                    <Charts
                                        type="bar"
                                        barData={data.minutesPerDay.map(({ day, value }) => ({ label: moment(day).format('dddd').substring(0, 1), value }))} />
                                </Group>
                            </StyledStatsCard>
                            {
                                state.user?.info.session.role !== RoleTypes.STUDENT && <StyledStatsCard gap="none" style={{
                                    padding: '20px 10px',
                                }}>
                                    <Group gap="none">
                                        <StyledChapterChildTitle>Storage used</StyledChapterChildTitle>
                                        <StyledH5 style={{ marginTop: '-4px', color: theme.colors.success }}>
                                            {data.storage.used.total}<StyledSmall>{data.storage.used.fileSizeIn}</StyledSmall>
                                        </StyledH5>
                                    </Group>
                                    <Group align="center">
                                        <Charts
                                            type="doughnut"
                                            percentage={data.storage.percentageUsed}
                                            label="storage used" />
                                    </Group>
                                </StyledStatsCard>
                            }

                        </Group>
                    </Group>
                    {
                        state.user?.info.session.role !== RoleTypes.STUDENT && <Group>
                            <StyledStatsCard gap="none" style={{
                                padding: '10px'
                            }}>
                                <Group direction="row" justify="space-between">
                                    <div>
                                        <StyledChapterChildTitle>New Students</StyledChapterChildTitle>
                                        <StyledH5 style={{ marginTop: '-4px', color: theme.colors.success }}>
                                            {data.studentSignUpRates.currentMonthCount}
                                        </StyledH5>
                                    </div>
                                    <Group align="center" gap="xs" style={{
                                        borderRadius: '6px',
                                        height: '30px',
                                        padding: '5px 10px',
                                        color: theme.colors.success,
                                        background: theme.colors.success4
                                    }} direction="row">
                                        <h6>{data.studentSignUpRates.rateInPercentage}%</h6>
                                        {
                                            data.studentSignUpRates.rateInPercentage >= 0 && <GraphUpArrow size={12} />
                                        }
                                        {
                                            data.studentSignUpRates.rateInPercentage < 0 && <GraphDownArrow size={12} />
                                        }
                                    </Group>
                                </Group>
                                <Charts
                                    type="line"
                                    lineData={{
                                        label: "Sign ups",
                                        color: theme.colors.success,
                                        data: data.studentSignUpRates.lastMonths.map(({ month, value }) => ({ label: moment(month).format("MMM"), value }))
                                    }} />
                            </StyledStatsCard>

                            <StyledStatsCard gap="none" style={{
                                padding: '10px'
                            }}>
                                <Group direction="row" justify="space-between">
                                    <div>
                                        <StyledChapterChildTitle>Course Enrollments</StyledChapterChildTitle>
                                        <StyledH5 style={{ marginTop: '-4px', color: theme.colors.primary }}>
                                            {data.courseEnrollmentRates.currentMonthCount}
                                        </StyledH5>
                                    </div>
                                    <Group align="center" gap="xs" style={{
                                        borderRadius: '6px',
                                        height: '30px',
                                        padding: '5px 10px',
                                        color: theme.colors.primary,
                                        background: theme.colors.primary4
                                    }} direction="row">
                                        <h6>{data.courseEnrollmentRates.rateInPercentage}%</h6>
                                        {
                                            data.courseEnrollmentRates.rateInPercentage >= 0 && <GraphUpArrow size={12} />
                                        }
                                        {
                                            data.courseEnrollmentRates.rateInPercentage < 0 && <GraphDownArrow size={12} />
                                        }
                                    </Group>
                                </Group>
                                <Charts
                                    type="line"
                                    lineData={{
                                        label: "Enrollments",
                                        color: theme.colors.primary,
                                        data: data.courseEnrollmentRates.lastMonths.map(({ month, value }) => ({ label: moment(month).format("MMM"), value }))
                                    }} />
                            </StyledStatsCard>
                        </Group>
                    }
                </Group>
            </StyledGroup>
        </Page>
    );
};
