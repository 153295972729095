import { ReactElement } from "react";
import styled from "styled-components";
import styles from "./Roles.module.scss";
import { boxShadow } from "../../components/Elements";

interface CardProps {
  title: string;
  desc: string;
  icon: ReactElement;
  onClick: () => void;
}

const Box = styled.div`
  cursor: pointer;
  ${props => boxShadow(props.theme.colors.grey6)}
  color: ${props => props.theme.colors.gray5};
  border: 1px solid ${props => props.theme.colors.gray};
  background-color: ${props => props.theme.colors.lightGray};

  .desc {
    color: ${props => props.theme.colors.gray4};
  }

`

export const RoleCard = ({ title, desc, onClick, icon }: CardProps) => {
  return (
    <Box className={styles.box} onClick={onClick}>
      {icon}
      <p>{title}</p>
      <p className={styles.desc}>{desc}</p>
    </Box>
  );
};
