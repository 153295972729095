import styled from "styled-components";
import { OffCanvas } from 'react-offcanvas';
import { useApi } from "../../hooks/use-api";
import { AuthContext } from '../../context/auth';
import { TextInput } from '../../components/Form';
import { useForm } from '../../hooks/use-form.hook';
import { useTheme } from '../../hooks/use-theme.hook';
import { downloadFile } from '../../utils/downloadFile';
import { useNotify } from '../../hooks/use-notify.hook';
import { Page } from "../../components/Layout/Page/Page";
import { Spinner } from "../../components/Spinner/Spinner";
import { Group } from "../../components/Layout/Group/Group";
import { FileInput } from '../../components/Form/FileUpload';
import { downloadTxtFile } from '../../utils/downloadTxtFile';
import { LoadMore } from "../../components/App/LoadMore/LoadMore";
import { Button, StepButton } from "../../components/Form/Button";
import { useNotification } from '../../hooks/use-notification.hook';
import { TopMenu, TopMenuProfile } from "../../components/App/TopMenu";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { FileViewer } from '../../components/App/FolderCards/FileViewer';
import { SelectInput } from "../../components/Form/SelectInput/SelectInput";
import { CertificateCard } from '../../components/App/Cards/CertificateCard';
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { CloudDownload, JournalText, Pencil, Trash, X } from "react-bootstrap-icons";
import { RoleTypes, Certificate, AllStudentsSchema, AllCoursesSchema, RecordTypes, PageFiltersValues } from 'shared-library';
import { StyledContentWrapper, StyledCreationWrapper, StyledH5, StyledHR, StyledOffCanvasMenu, StyledTitle, forPhoneOnly } from "../../components/Elements";


const StyledGroup = styled(Group)`
    .left {
        flex-basis: 35%;
        ${forPhoneOnly("flex-basis: 100%;")}
    }
`

export const Certificates = () => {

    const blankCertificate: Certificate = {
        id: "",
        title: "",
        fileId: "",
        sortKey: "",
        courseId: "",
        companyId: "",
        studentId: "",
        creatorSub: "",
        creationDate: "",
        recordType: RecordTypes.COMPANYCERTIFICATE,
    }

    const { confirm } = useNotify()
    const { notify } = useNotification();
    const { state, setResources } = useContext(AuthContext);
    const { getCertificates, deleteCertificate, getResources } = useApi();
    const [newCertificateCanvas, setNewCertificateCanvas] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState<Certificate | undefined>();
    const [editingCertificate, setEditingCertificate] = useState<Certificate>({ ...blankCertificate });
    const { data: resources } = useQuery({
        queryKey: ["allActiveCourses", "allActiveStudents"],
        queryFn: () => getResources(["activeCourses", "activeStudents"]),
        enabled: (!state.resources.activeCourses?.length || !state.resources.activeStudents?.length) && state.user?.info.session.role !== RoleTypes.STUDENT
    });

    useEffect(() => {
        if (resources) {
            setResources({ ...state.resources, activeCourses: resources.activeCourses as Array<AllCoursesSchema>, activeStudents: resources.activeStudents as Array<AllStudentsSchema> })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources])

    const [certificateFilters] = useState<{
        limit: number;
        query?: string;
        courseId?: string;
        studentId?: string;
        nextBatchIndex?: string;
        filter?: PageFiltersValues;
    }>({
        limit: 30,
        nextBatchIndex: "",
        filter: PageFiltersValues.latestFirst
    })
    const { data, isLoading, isFetchingNextPage, refetch, fetchNextPage, hasNextPage } = useInfiniteQuery({
        queryFn: ({ pageParam: nextBatchIndex }) => getCertificates({
            nextBatchIndex,
            limit: certificateFilters.limit,
            query: certificateFilters.query,
            filter: certificateFilters.filter,
        }),
        retry: false,
        initialPageParam: certificateFilters.nextBatchIndex,
        getNextPageParam: (lastPage) => lastPage.nextBatchIndex,
        queryKey: ["certificates", certificateFilters.filter, certificateFilters.courseId, certificateFilters.studentId, certificateFilters.query, certificateFilters.limit, certificateFilters.nextBatchIndex],
    });

    const { isPending: mutatingCertificateDeletionAsync, mutateAsync: mutateCertificateDeletionAsync } = useMutation({
        mutationFn: deleteCertificate
    });

    const onDelete = async () => {
        try {
            if (selectedCertificate) {
                await mutateCertificateDeletionAsync(selectedCertificate.sortKey)
                notify({ type: "success", title: "Successful!", body: `Certificate has been successfully deleted!` });
                refetch()
                setSelectedCertificate(undefined)
            }
        } catch (error) {
        }
    }

    const flattenCertificates = useCallback(() => {
        let certificates: Array<Certificate> = []
        if (data?.pages) {
            for (const page of data.pages) {
                certificates = [...certificates, ...page.data]
            }
        }
        return certificates
    },
        [data?.pages]
    );

    return (
        <Page>
            <StyledGroup direction="row">
                <StyledContentWrapper className="left slideInLeft">
                    <Group>
                        <TopMenu hideProfile hideNotification titles={[{
                            text: 'Certificates',
                        }]} newItemLink={state.user?.info.session.role !== RoleTypes.STUDENT ? () => { setEditingCertificate({ ...blankCertificate }); setNewCertificateCanvas(true) } : undefined} />
                        <Group gap="xs">
                            <br />
                            <br />
                            {
                                !data || isLoading ? <Spinner text="Fetching Certificates" style={{ margin: 60 }} /> :
                                    <>
                                        {
                                            flattenCertificates().map(certificate => <CertificateCard active={certificate.id === selectedCertificate?.id} certificate={certificate} key={certificate.id} onClick={() => setSelectedCertificate(certificate)} />)
                                        }
                                        {
                                            hasNextPage && <LoadMore loading={isFetchingNextPage} onclick={fetchNextPage} />
                                        }
                                    </>
                            }
                        </Group>
                    </Group>
                </StyledContentWrapper>
                <Group className="slideInRight" style={{ flex: 1 }}>
                    <Group direction="row" align="center" justify="end">
                        <TopMenuProfile />
                    </Group>
                    {
                        !selectedCertificate ? <Group align="center" justify="center">
                            <img src="/assets/images/select-certificate-to-continue.png" style={{ width: '350px', opacity: 0.8, marginTop: '100px' }} alt="Select to continue" />
                        </Group> :
                            <Group align="center" justify="center" style={{ padding: '0px 20px' }}>
                                <br />
                                <br />
                                <StyledTitle>
                                    {selectedCertificate.title}
                                </StyledTitle>
                                {
                                    selectedCertificate.file && <Group>
                                        <StyledHR />
                                        <FileViewer file={selectedCertificate.file} />
                                        <br />
                                    </Group>
                                }
                                <Group direction="row" gap="xs">
                                    {
                                        state.user?.info.session.role === RoleTypes.ADMIN && <StepButton rightIcon={<Pencil />} onClick={() => { setEditingCertificate({ ...selectedCertificate }); setNewCertificateCanvas(true) }} text="Edit" />
                                    }
                                    {
                                        selectedCertificate.file ? <StepButton rightIcon={<CloudDownload />} onClick={() => downloadFile(selectedCertificate.file?.url as string, `${selectedCertificate.file?.fileName}.${selectedCertificate.file?.ext as string}`)} text="Download" /> :
                                            <StepButton rightIcon={<CloudDownload />} onClick={() => downloadTxtFile(document.getElementById('certificateDescription')?.textContent || "", selectedCertificate.title)} text="Download" />
                                    }
                                    {
                                        state.user?.info.session.role === RoleTypes.ADMIN && <StepButton loading={mutatingCertificateDeletionAsync} classNamesArr={["danger"]} rightIcon={<Trash />} onClick={() => confirm({ onConfirmation: onDelete })} text="Delete Certificate" />
                                    }
                                </Group>
                            </Group>
                    }
                </Group>
            </StyledGroup>
            <NewCertificateCanvas open={newCertificateCanvas} certificate={editingCertificate} setOpen={setNewCertificateCanvas} onSuccess={() => { refetch(); setSelectedCertificate(undefined) }} />
        </Page>
    );
}

const NewCertificateCanvas: FC<{ open: boolean; certificate: Certificate; setOpen: (open: boolean) => void; onSuccess?: () => void }> = ({ open, certificate, setOpen, onSuccess }) => {

    const theme = useTheme();
    const { notify } = useNotification();
    const { state } = useContext(AuthContext);
    const { createUpdateCertificate } = useApi();

    const [dropdownKey, setDropDownKey] = useState(0); // Force dropdown to rerender after form successfully submitted
    const { onSubmit, onChange, reset, errors, formValues, updateValues } = useForm({
        defaultValues: certificate,
        validations: {
            file: { required: true },
            courseId: { required: true },
            studentId: { required: true },
            title: { required: true, max: 60 },
        }
    })

    const { isPending: mutatingCertificateAsync, mutateAsync: mutateCertificateAsync } = useMutation({
        mutationFn: createUpdateCertificate,
    });

    useEffect(() => {
        if (formValues.id !== certificate.id) {
            updateValues(certificate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificate])

    const updateCertificate = async (data: Certificate) => {
        try {
            if (data.file) {
                data.fileId = data.file?.id
            }
            await mutateCertificateAsync(data)
            notify({ type: "success", title: "Successful!", body: `Certificate has been successfully ${data.id ? 'updated' : 'created'}!` });
            reset()
            setDropDownKey(prevKey => prevKey + 1);
            onSuccess && onSuccess()
            setOpen(false)
        } catch (error) {
        }
    }

    return (
        <OffCanvas
            transitionDuration={300}
            isMenuOpened={open}
            position={"right"}
            effect={"overlay"}
        >
            <StyledOffCanvasMenu className={open ? 'opened' : 'closed'}>
                <Group justify="space-between" style={{ padding: '14px 14px 0px 14px' }} align="center" direction="row">
                    <StyledH5>
                        <JournalText size={20} style={{ marginRight: '5px' }} /> {formValues.id ? 'Edit' : 'New'} Certificate
                    </StyledH5>
                    <X size={42} className="pointer" onClick={() => setOpen(false)} style={{ color: theme.colors.grey }} />
                </Group>
                <StyledHR />
                <Group className="offcanvas-content" style={{ padding: '4px 20px' }}>
                    <StyledCreationWrapper>
                        <form onSubmit={onSubmit(updateCertificate)}>
                            <Group gap='sm'>
                                <TextInput
                                    isRequired
                                    type="text"
                                    label="Title"
                                    placeholder="Title"
                                    error={errors.title}
                                    value={formValues.title}
                                    onChange={onChange("title")}
                                />

                                <SelectInput
                                    isRequired
                                    label="Student"
                                    key={dropdownKey}
                                    placeholder="Student"
                                    error={errors.studentId}
                                    disabled={!!formValues.id}
                                    parentStyle={{ width: '100%' }}
                                    onChange={onChange("studentId")}
                                    options={(state.resources?.activeStudents || []).map(({ sub: value, fullName: label }) => ({ value, label }))}
                                    value={(state.resources?.activeStudents || []).map(({ sub: value, fullName: label }) => ({ value, label })).find(d => d.value === formValues.studentId)}
                                />

                                <SelectInput
                                    isRequired
                                    label="Course"
                                    placeholder="Course"
                                    error={errors.courseId}
                                    key={`${dropdownKey}.1`}
                                    parentStyle={{ width: '100%' }}
                                    onChange={onChange("courseId")}
                                    options={(state.resources?.activeCourses || []).map(({ id: value, title: label }) => ({ value, label }))}
                                    value={(state.resources?.activeCourses || []).map(({ id: value, title: label }) => ({ value, label })).find(d => d.value === formValues.courseId)}
                                />

                                <FileInput
                                    isRequired
                                    error={errors.file}
                                    file={formValues.file}
                                    label={`Certificate document`}
                                    onChange={onChange("file")}
                                    parentStyle={{ maxWidth: '100%' }}
                                    fileType={"PHOTOS"}
                                />
                                <br />
                                <Button text={"Submit"} type='submit' loading={mutatingCertificateAsync} />
                            </Group>
                        </form>
                    </StyledCreationWrapper>
                </Group>
            </StyledOffCanvasMenu>
        </OffCanvas>
    )
}