import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

interface ConfirmProps {
    title?: string;
    message?: string;
    className?: string;
    onDecline?: () => void,
    declineLabelText?: string;
    onConfirmation: () => void,
    confirmationLabelText?: string;
}

export const useNotify = () => {

    const confirm = (props: ConfirmProps) => {
        const options = {
            title: props.title || 'Are you sure?',
            message: props.message || 'This action cannot be undone.',
            buttons: [
                {
                    onClick: props.onConfirmation,
                    label: props.confirmationLabelText || "Yes",
                },
                {
                    onClick: props.onDecline,
                    label: props.declineLabelText || "No",
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            // keyCodeForClose: [8, 32],
            // willUnmount: () => {},
            // afterClose: () => {},
            // onClickOutside: () => {},
            // onKeypress: () => {},
            // onKeypressEscape: () => {},
            overlayClassName: props.className || ""
        };
        confirmAlert(options);
    }

    return { confirm }
}